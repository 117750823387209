<div class="animated fadeIn">
    <div class="row">
        <div class="col-lg-12">

            <div class="card">
                <div class="card-header">
                    <i class="fa fa-search"></i>Pesquisa de <strong>Leads</strong>
                    <div class="card-header-actions">
                        <button type="button" class="btn btn-minimize" (click)="toggleCollapse()">
                            <i class={{iconCollapse}}></i>
                        </button>
                    </div>
                </div>
                <div [collapse]="isCollapsed" class="card-body">
                    <form class="form-horizontal">

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Integrador:</label>

                                    <ng-select [items]="plataformas" bindLabel="nome" bindValue="id"
                                        placeholder="Selecione" [(ngModel)]="filter.plataforma_id" name="plataforma_id">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tipo de Campanha:</label>

                                    <ng-select [items]="tiposCampanhas" bindLabel="nome" bindValue="id"
                                        placeholder="Selecione um tipo de campanha"
                                        [(ngModel)]="filter.campanha_tipo_id" name="campanha_tipo_id">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tipo de Atividade:</label>

                                    <ng-select [items]="tiposConteudos" bindLabel="nome" bindValue="id"
                                        placeholder="Selecione um tipo de atividade"
                                        [(ngModel)]="filter.conteudo_tipo_id" name="conteudo_tipo_id">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>ID Importação CSV:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text"
                                            [(ngModel)]="filter.conteudo_importacao_id" name="conteudo_importacao_id">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>ID Origem:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.registro_id"
                                            name="registro_id">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>ID Integra:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.id" name="id">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Identificador:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.identificador"
                                            name="identificador">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label for="evento_saidas">WorkFlow Principal:</label>

                                    <ng-select [items]="workflows" bindLabel="nome" bindValue="id"
                                        placeholder="Selecione um WorkFlow" [(ngModel)]="filter.workflow_id"
                                        name="workflows">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="name">Processamento Início:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" ngbDatepicker #d="ngbDatepicker"
                                            name="data_ini" [(ngModel)]="data_ini">
                                        <div class="input-group-append">
                                            <div class="input-group-append">
                                                <span class="input-group-text" (click)="d.toggle()"
                                                    style="cursor: pointer;">
                                                    <i class="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="name">Processamento Fim:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" ngbDatepicker #df="ngbDatepicker"
                                            name="data_fim" [(ngModel)]="data_fim">
                                        <div class="input-group-append">
                                            <div class="input-group-append">
                                                <span class="input-group-text" (click)="df.toggle()"
                                                    style="cursor: pointer;">
                                                    <i class="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Nome do Responsável da Campanha:</label>
                                    <autocomplete-usuario [(ngModel)]="filter" name="filter"></autocomplete-usuario>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Nome do Cliente:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.nome_cliente"
                                            name="nome_cliente">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Nome da Empresa/Propriedade:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.nome_empreendimento"
                                            name="nome_empreendimento">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Porte:</label>

                                    <ng-select [items]="portes" placeholder="Selecione..." bindLabel="nome"
                                        bindValue="nome" [(ngModel)]="filter.porte" name="porte">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>E-mail do Cliente:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.email_cliente"
                                            name="email_cliente">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>E-mail do Responsável:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.email_responsavel"
                                            name="email_responsavel">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="CodProjeto">Projeto Atendimento:</label>
                                    <ng-select [items]="projetos" bindLabel="NomePRATIF" bindValue="CodPRATIF"
                                        placeholder="Selecione um projeto" [(ngModel)]="filter.cod_projeto"
                                        name="CodProjeto" (change)="getAcoesPorProjeto()">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="CodAcao">Ação Atendimento:</label>
                                    <ng-select [items]="acoes" bindLabel="NomeAcao" bindValue="CodAcaoSeq"
                                        placeholder="Selecione um projeto primeiro para selecionar a ação"
                                        [(ngModel)]="filter.cod_acao" name="CodAcao">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Instrumento Atendimento:</label>

                                    <ng-select [items]="categoriasInstrumento" bindLabel="DescCategoria"
                                        bindValue="CodCategoria" placeholder="Selecione um instrumento de atendimento"
                                        [(ngModel)]="filter.cod_categoria" name="cod_categoria">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Palavra Chave de Status/Histórico:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.historico"
                                            name="historico">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Mensagens de Status:</label>

                                    <ng-select [items]="mensagensStatus" bindLabel="msg" bindValue="key"
                                        placeholder="Selecione..." [multiple]="true" [(ngModel)]="mensagens_status_sel"
                                        name="mensagens_status_sel">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Mensagem Impeditiva:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.msg_impeditiva"
                                            name="msg_impeditiva">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Dados Processado:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text"
                                            [(ngModel)]="filter.dados_formatado_json" name="dados_formatado_json">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Protocolo Atendimento:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text"
                                            [(ngModel)]="filter.protocolo_atendimento" name="protocolo_atendimento">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Cód Evento:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.codigo_evento"
                                            name="codigo_evento">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>CPF:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.cpf" name="cpf"
                                            mask="000.000.000-00">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>CNPJ:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.cnpj" name="cnpj"
                                            mask="00.000.000/0000-00">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Tipo Cliente:</label>

                                    <ng-select [items]="listTiposCliente" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.tipo_cliente"
                                        name="tipo_cliente">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Status:</label>

                                    <ng-select [items]="listStatus" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.status" name="status">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Reprocessado De:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="number"
                                            [(ngModel)]="filter.qtd_reprocessado_de" name="qtd_reprocessado_de">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Reprocessado Até:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="number"
                                            [(ngModel)]="filter.qtd_reprocessado_ate" name="qtd_reprocessado_ate">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Registros por Página:</label>

                                    <ng-select [items]="listItemsPerPage" placeholder="Selecione..."
                                        [(ngModel)]="filter.limit" name="limit">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group pull-right">
                                    <br>
                                    <button type="button" class="btn btn-primary" (click)="pesquisar()">
                                        <i class="fa fa-search"></i>
                                        Pesquisar
                                    </button>
                                    &nbsp;&nbsp;
                                    <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                                        <i class="fa fa-ban"></i>
                                        Limpar Filtros
                                    </button>
                                    &nbsp;&nbsp;
                                    <button type="button" class="btn btn-success" (click)="exportar(template)">
                                        <i class="fa fa-file-excel-o"></i>
                                        Exportar para Excel
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                    </form>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="pull-left">
                                <i class="fa fa-align-justify"></i> Resultados ({{totalItems}})
                                <button type="button" class="btn btn-warning" (click)="reprocessarLista()">
                                    <i class="fa fa-refresh"></i>
                                    Reprocessar
                                </button>
                                &nbsp;|&nbsp;

                                <span *ngIf="executarNovoFluxo">
                                    <button type="button" class="btn btn-success" (click)="openModalExecutarFluxo()">
                                        <i class="fa fa-play"></i>
                                        Executar Novo Fluxo
                                    </button>
                                    &nbsp;|&nbsp;
                                </span>

                                <span *ngIf="countLeadsFalhaComProtocoloAtendimentoSas()">
                                    <button type="button" class="btn btn-warning"
                                        (click)="excluirAtendimentosSasLeadsFalha()">
                                        <i class="fa fa-external-link"></i>
                                        Desfazer Atendimentos SAS
                                    </button>
                                    &nbsp;|&nbsp;
                                </span>

                                <button type="button" class="btn btn-danger" (click)="excluirLeads()">
                                    <i class="fa fa-times"></i>
                                    Excluir Leads
                                </button>
                                &nbsp;|&nbsp;
                                <button type="button" placement="bottom"
                                    tooltip="Esconder/Mostrar Mensagens de Histórico" class="btn btn-secondary"
                                    (click)="toggleButtonMsgHistorico()"><strong><i
                                            class="fa {{faButtonMsgHistorico}}"></i> </strong></button>
                                &nbsp;|&nbsp;
                                <div class="form-check form-check-inline">
                                    <label class="switch switch-label switch-pill switch-primary ml-2 mb-0">
                                        <input type="checkbox" class="switch-input" checked value="1"
                                            [(ngModel)]="atualizacaoTelaTempoReal"
                                            (click)="selectAtualizacaoTelaTempoReal();">
                                        <span class="switch-slider" data-checked="Sim" data-unchecked="Não"></span>
                                    </label>
                                    <label class="mb-0 ml-1"><i class="fa fa-info-circle fa-sm"
                                            tooltip="Atualizar status e mensagens dos leads listados na tela em tempo real"></i>
                                        Atualização
                                        em tempo real?</label>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <!-- <table class="table table-responsive-sm table-hover table-outline mb-0"></table> -->
                            <table class="table table-responsive-sm">
                                <thead class="thead-light">
                                    <tr>
                                        <th width="5%">
                                            <input type="checkbox" title="Selecionar Todos" [(ngModel)]="selectedAll"
                                                (change)="selectAll();" style="cursor: pointer;" />
                                        </th>
                                        <th width="25%" class="text-left">Dados da Campanha/Evento</th>
                                        <th width="25%" class="text-left">Dados do Cliente/Empresa/Propriedade</th>
                                        <th width="45%" class="text-left">Status/Mensagens de Processamento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let l of leads; let i = index">
                                        <tr (click)="rowClicked(l)" style="cursor: pointer;">
                                            <td class="text-center" class="group-start">
                                                <input type="checkbox" [(ngModel)]="l.selected" style="cursor: pointer;"
                                                    *ngIf="l.status.trim() == 'Falha' || l.status.trim() == 'Sucesso' || l.status.trim() == 'Aguardando'" />

                                                <div class="btn-group" dropdown placement="bottom left">
                                                    <button dropdownToggle type="button"
                                                        class="btn btn-sm dropdown-toggle">
                                                        Ações <span class="caret"></span>
                                                    </button>
                                                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-left"
                                                        role="menu">
                                                        <li role="menuitem"
                                                            *ngIf="l.status.trim() == 'Falha' || l.status.trim() == 'Aguardando'">
                                                            <button type="button" class="dropdown-item"
                                                                (click)="reprocessarLista(l?.id)"
                                                                tooltip="Reprocessar Lead">
                                                                <i class="fa fa-refresh fa-lg mt-1 "></i> Reprocessar este Lead
                                                            </button>
                                                        </li>
                                                        <li role="menuitem"
                                                            *ngIf="(l.status.trim() == 'Falha' || l.status.trim() == 'Sucesso') && executarNovoFluxo">
                                                            <button type="button" class="dropdown-item"
                                                                (click)=" openModalExecutarFluxo(l)"
                                                                tooltip="Executar Novo Fluxo">
                                                                <i class="fa fa-play fa-lg mt-1 "></i>Executar Novo Fluxo
                                                            </button>
                                                        </li>
                                                        <li role="menuitem"
                                                            *ngIf="l.status.trim() == 'Falha' && (l.protocolo_atendimento != null && l.protocolo_atendimento != '')">
                                                            <button type="button" class="dropdown-item"
                                                                (click)="excluirAtendimentosSasLeadsFalha(l?.id)"
                                                                tooltip="Desfazer Atendimento no SAS do protocolo gerado">
                                                                <i class="fa fa-external-link fa-lg mt-1 "></i> Desfazer Atendimento SAS
                                                            </button>
                                                        </li>
                                                        <li role="menuitem"
                                                            *ngIf="l.status.trim() == 'Falha' || l.status.trim() == 'Aguardando'">
                                                            <button type="button" class="dropdown-item"
                                                                (click)="excluirLeads(l?.id)" tooltip="Excluir Lead">
                                                                <i class="fa fa-times fa-lg mt-1 "></i> Excluir este Lead
                                                            </button>
                                                        </li>
                                                        <li class="divider dropdown-divider"></li>
                                                        <li role="menuitem">
                                                            <a href="#"
                                                                [routerLink]="['/micro-cadastro-cliente/base/pessoas/clientes-form', l?.LocalPessoaId]"
                                                                fragment="clientes-form" class="dropdown-item"
                                                                *ngIf="l?.LocalPessoaId"><i class="fa fa-pencil"></i>
                                                                Editar/Visualizar Pessoa</a>
                                                        </li>
                                                        <li role="menuitem">
                                                            <a href="#"
                                                                [routerLink]="['/micro-cadastro-cliente/base/empresas//empresa-form', l?.LocalEmpresaId]"
                                                                fragment="empresa-form" class="dropdown-item"
                                                                *ngIf="l?.LocalEmpresaId"><i class="fa fa-pencil"></i>
                                                                Editar/Visualizar Empresa</a>
                                                        </li>
                                                        <li class="divider dropdown-divider"></li>
                                                        <li role="menuitem">
                                                            <a href="#"
                                                                [routerLink]="['/campanha', l?.conteudo_publicado_id, 'principal']"
                                                                fragment="campEve" class="dropdown-item"><i
                                                                    class="fa fa-pencil"></i> Visualizar
                                                                Campanha/Eventos</a>
                                                        </li>
                                                        <li role="menuitem">
                                                            <a href="#"
                                                                [routerLink]="['/campanha', l?.conteudo_publicado_id, 'moskit']"
                                                                fragment="campEveMoskCrm" class="dropdown-item"><i
                                                                    class="fa fa-pencil"></i> Visualizar Configurações
                                                                Moskit</a>
                                                        </li>
                                                        <li role="menuitem">
                                                            <a href="#"
                                                                [routerLink]="['/campanha', l?.conteudo_publicado_id, 'sas']"
                                                                fragment="campEveSas" class="dropdown-item"><i
                                                                    class="fa fa-pencil"></i> Visualizar Configurações
                                                                SAS</a>
                                                        </li>
                                                        <li role="menuitem">
                                                            <a href="#"
                                                                [routerLink]="['/campanha', l?.conteudo_publicado_id, 'importacoes-csv']"
                                                                fragment="campImportCsv" class="dropdown-item"
                                                                *ngIf="l?.conteudo_publicado?.conteudo_tipo_id == tipoAtividadeEnum.PLANILHAS"><i
                                                                    class="fa fa-file-excel-o"></i> Importar planilha
                                                                CSV
                                                                nesta campanha</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td class="group-start">
                                                <div>
                                                    <span
                                                        class="badge badge-secondary">{{l?.conteudo_publicado?.plataforma?.nome}}</span>
                                                    &nbsp;|&nbsp;
                                                    <span
                                                        class="badge badge-secondary">{{l?.conteudo_tipo?.nome}}</span>
                                                </div>
                                                <div><strong><i *ngIf="l?.conteudo_publicado?.descricao_campanha"
                                                            class="fa fa-info-circle fa-sm"
                                                            tooltip="{{l?.conteudo_publicado?.descricao_campanha}}"></i>
                                                        {{l.conteudo_publicado?.identificador}}</strong></div>
                                                <div class="small text-muted">
                                                    <span><strong>TIPO
                                                            CAMPANHA:&nbsp;</strong></span>{{l.campanha_tipo?.nome}}
                                                </div>
                                                <div class="small text-muted">
                                                    <span><strong>NOME
                                                            RESPONSÁVEL:&nbsp;</strong></span>{{l.conteudo_publicado?.responsavel_nome}}
                                                </div>
                                                <div class="small text-muted">
                                                    <span><strong>ID INTEGRA:&nbsp;</strong></span>{{l.id}}
                                                </div>
                                                <div class="small text-muted">
                                                    <span><strong>ID IMPORTACÃO
                                                            CSV:&nbsp;</strong></span>{{l.conteudo_fila_importacoes?.conteudo_importacao_id}}
                                                </div>
                                                <div class="small text-muted">
                                                    <span><strong>ID ORIGEM:&nbsp;</strong></span>{{l?.registro_id}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.email_responsavel">
                                                    <span><strong>E-MAIL
                                                            RESP.:&nbsp;</strong></span>{{l?.email_responsavel}}
                                                </div>
                                                <div class="small text-muted"
                                                    *ngIf="l?.gerar_inscricao || l?.gerar_reserva || l?.codigo_evento">
                                                    <span><strong>CÓD. EVENTO:&nbsp;</strong></span>{{l.codigo_evento}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.cod_projeto">
                                                    <span><strong>PROJETO:&nbsp;</strong></span>{{getNomeProjeto(l?.cod_projeto)}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.cod_acao">
                                                    <span><strong>AÇÃO:&nbsp;</strong></span>{{getNomeAcao(l?.cod_acao)}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.cod_categoria">
                                                    <span><strong>INSTRUMENTO:&nbsp;</strong></span>{{getNomeInstrumento(l?.cod_categoria)}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.protocolo_atendimento">
                                                    <span><strong>PROTOCOLO
                                                            ATENDIMENTO:&nbsp;</strong></span>{{l?.protocolo_atendimento}}
                                                </div>
                                                <div class="small text-muted">
                                                    <span><strong>INCLUÍDO:&nbsp;</strong></span>{{l.created | date:
                                                    'dd/MM/yyyy H:mm'}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.data_alteracao">
                                                    <span><strong>ALTERADO:&nbsp;</strong></span>{{l?.data_alteracao |
                                                    date:
                                                    'dd/MM/yyyy H:mm'}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.usuario_alteracao?.nome">
                                                    <span><strong>USUÁRIO:&nbsp;</strong></span>{{l?.usuario_alteracao?.nome}}
                                                </div>
                                            </td>
                                            <td class="group-start">
                                                <div><strong>{{l.nome_cliente}}</strong></div>
                                                <div class="small text-muted">
                                                    <span><strong>E-MAIL:&nbsp;</strong></span>{{l?.email_cliente}}
                                                </div>
                                                <div class="small text-muted">
                                                    <span><strong>CÓD. SAS PF:&nbsp;</strong></span>{{l?.CodParceiroPF}}
                                                </div>
                                                <div class="small text-muted">
                                                    <span><strong>CPF:&nbsp;</strong></span>{{l?.cpf_origem |
                                                    completeDigitoCpf | mask: '000.000.000-00'}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.cpf_encontrado">
                                                    <span><strong>CPF REF.:&nbsp;</strong></span>{{l?.cpf_encontrado |
                                                    completeDigitoCpf | mask: '000.000.000-00'}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.UfPF">
                                                    <span><strong>UF:&nbsp;</strong></span>{{l?.UfPF}}
                                                </div>
                                                <br>
                                                <div><strong>{{l.nome_empreendimento}}</strong></div>
                                                <div class="small text-muted">
                                                    <span><strong>CÓD. SAS PJ:&nbsp;</strong></span>{{l?.CodParceiroPJ}}
                                                </div>
                                                <div class="small text-muted">
                                                    <span><strong>CNPJ:&nbsp;</strong></span>{{l?.cnpj_origem |
                                                    completeDigitoCnpj | mask: '00.000.000/0000-00'}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.cnpj_encontrado">
                                                    <span><strong>CNPJ REF.:&nbsp;</strong></span>{{l?.cnpj_encontrado |
                                                    completeDigitoCnpj | mask: '00.000.000/0000-00'}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.inscricao_estadual">
                                                    <span><strong>INSCRIÇÃO
                                                            ESTADUAL:&nbsp;</strong></span>{{l?.inscricao_estadual}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.dap">
                                                    <span><strong>DAP:&nbsp;</strong></span>{{l?.dap}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.registro_pescador">
                                                    <span><strong>REGISTRO DE
                                                            PESCADOR:&nbsp;</strong></span>{{l?.registro_pescador}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.nirf">
                                                    <span><strong>NIRF:&nbsp;</strong></span>{{l?.nirf}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.cod_sicab">
                                                    <span><strong>CÓD. SICAB:&nbsp;</strong></span>{{l?.cod_sicab}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.CodCaf">
                                                    <span><strong>CÓD. CAF:&nbsp;</strong></span>{{l?.CodCaf}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.porte">
                                                    <span><strong>PORTE:&nbsp;</strong></span>{{l?.porte}}
                                                </div>
                                                <div class="small text-muted" *ngIf="l?.UfPJ">
                                                    <span><strong>UF:&nbsp;</strong></span>{{l?.UfPJ}}
                                                </div>
                                            </td>
                                            <td class="text-left group-start">
                                                <div>
                                                    <span [ngClass]="getClassStatus(l.status)">{{l.status}}</span>
                                                    &nbsp;|&nbsp;
                                                    <span class="badge"
                                                        [ngClass]="l?.tipo_cliente == 'PJ' ? 'badge-warning' : 'badge-info'">{{l?.tipo_cliente}}</span>
                                                    &nbsp;|&nbsp;
                                                    <span class="badge"
                                                        [ngClass]="l?.tipo_cliente == 'PJ' ? 'badge-warning' : 'badge-info'">{{l?.tipo_cliente
                                                        == 'PJ' ? l?.UfPJ : l?.UfPF}}</span>
                                                </div>
                                                <div class="small text-muted">{{l.modified | date: 'dd/MM/yyyy HH:mm'}}
                                                </div>
                                                <div class="small text-muted"><span>Reprocessado</span> |
                                                    {{l.qtd_reprocessado}}</div>

                                                <div class="small text-muted">
                                                    <i style="cursor: pointer;" class="fa fa-code fa-lg mt-2"
                                                        [popover]="popoverObjJson" (click)="getCamposJson(l)"
                                                        popoverTitle="Dados Imputados" placement="bottom"
                                                        tooltip="Clique aqui para ver dados imputados"></i>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <ng-template #popoverObjJson>{{l?.camposJson?.objeto_json}}
                                                    </ng-template>
                                                    <i *ngIf="l.status.trim() != 'Aguardando'" style="cursor: pointer;"
                                                        class="fa fa-code fa-lg mt-2" [popover]="popoverReqJson"
                                                        (click)="getCamposJson(l)" popoverTitle="Requisição WS"
                                                        placement="bottom"
                                                        tooltip="Clique aqui para ver a última requisição do WS chamado"></i>
                                                    <ng-template #popoverReqJson>{{l?.camposJson?.requisicao_json}}
                                                    </ng-template>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <i *ngIf="l.status.trim() != 'Aguardando'" style="cursor: pointer;"
                                                        class="fa fa-code fa-lg mt-2" [popover]="popoverRespJson"
                                                        (click)="getCamposJson(l)" popoverTitle="Resposta WS"
                                                        placement="bottom"
                                                        tooltip="Clique aqui para ver a última resposta do WS chamado"></i>
                                                    <ng-template #popoverRespJson>{{l?.camposJson?.resposta_json}}
                                                    </ng-template>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <i *ngIf="l.status.trim() != 'Aguardando'" style="cursor: pointer;"
                                                        class="fa fa-code fa-lg mt-2" [popover]="popoverDadosFormatJson"
                                                        (click)="getCamposJson(l)"
                                                        popoverTitle="Dados utilizado no processamento"
                                                        placement="bottom"
                                                        tooltip="Clique aqui para ver dados de processamento"></i>
                                                    <ng-template #popoverDadosFormatJson>
                                                        {{l?.camposJson?.dados_formatado_json}}</ng-template>
                                                </div>

                                                <div *ngIf="l.status.trim() == 'Falha' || l.status.trim() == 'Aguardando'"
                                                    class="row text-center">
                                                    <button class="btn btn-sm btn-ghost-primary" type="button"
                                                        (click)="openModalAlterarJson(l,true)">Alterar
                                                        Dados Imputados</button>
                                                </div>
                                                <div *ngIf="l.status.trim() != 'Falha' && l.status.trim() != 'Aguardando'"
                                                    class="row text-center">
                                                    <button class="btn btn-sm btn-ghost-primary" type="button"
                                                        (click)="openModalAlterarJson(l, false)">Visualizar
                                                        Dados Imputados</button>
                                                </div>

                                            <h5>
                                                <i class="nav-icon icon-vector" tooltip="{{l?.workflow?.descricao}}" *ngIf="l?.workflow?.nome"></i>
                                                {{l?.workflow?.nome ? l?.workflow?.nome : 'Ações de Saída Definido na Campanha'}}
                                            </h5>
                                            </td>
                                        </tr>
                                        <tr *ngIf="l.status.trim() == 'Falha'">
                                            <td colspan="4" class="group-end">
                                                <div class="row">
                                                    <!-- Coluna do Problema -->
                                                    <div class="col-md-6">
                                                        <div class="card">
                                                            <div class="card-header bg-danger" style="padding: 3px;">
                                                            </div>
                                                            <div class="card-body">
                                                                <h5 class="card-title text-danger"><i
                                                                        class="fa fa-exclamation-circle"></i> Cenário
                                                                </h5>
                                                                <p class="card-text">{{l?.msg_impeditiva}}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Coluna da Solução -->
                                                    <div class="col-md-6">
                                                        <div class="card">
                                                            <div class="card-header bg-success" style="padding: 3px;">
                                                            </div>
                                                            <div class="card-body">
                                                                <h5 class="card-title text-success"><i
                                                                        class="fa fa-cogs"></i> Solução</h5>
                                                                <p class="card-text"> {{getTituloSolucao(l)}}</p>
                                                                <a href="javascript:void(0);"
                                                                    (click)="openModalDetalhesSolucao(l?.CenarioIaId, l?.msg_impeditiva)">Ver
                                                                    Detalhes</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                        <td colspan="4" class="group-end" *ngIf="isButtonMsgHistorico">
                                            <div class="content-dynamic">
                                                <h5><i class="fa fa-history"></i> Histórico</h5>
                                                <div class="historico-text" [innerHTML]="l?.historico">
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </ng-container>

                                    <tr *ngIf="leads && leads.length == 0">
                                        <td colspan="10"><b>{{!isPesquisado ? "Por favor, selecione os filtros desejados e clique no botão 'Pesquisar' para listar a sua base de leads." : 'Não existem leads registrados com estes parâmetros. Por favor, verifique os filtros.'}}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-md-12 justify-content-center">
                            <pagination [boundaryLinks]="true" [itemsPerPage]="filter.limit" [totalItems]="totalItems"
                                class="text-center" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
                                [maxSize]="maxSize" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
                                lastText="&raquo;"></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template confirm>
    <confirm-modal-component></confirm-modal-component>
</ng-template>

<div bsModal #modalAlterarJson="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" *ngIf="alterarDadosInputados==true">Alterar Parâmetros Imputados</h4>
                <h4 class="modal-title" *ngIf="alterarDadosInputados==false">Visualizar Parâmetros Imputados</h4>
                <button type="button" class="close" (click)="modalAlterarJson.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 text-right">
                        <button class="btn btn-sm btn-ghost-primary" *ngIf="mostrarJsonInputs"
                            (click)="mostrarInputs('JSON')" type="button">Exibir JSON</button>
                        <button class="btn btn-sm btn-ghost-primary" *ngIf="mostrarFormInputs"
                            (click)="mostrarInputs('FORM')" type="button">Exibir Formulário HTML</button>
                    </div>
                    <div class="col-md-12" *ngIf="mostrarFormInputs">
                        <span class="text-danger"><strong>Atenção, não apague as aspas, pode apresentar falha no
                                formato json ao processar</strong></span>
                        <textarea class="form-control" name="json" [(ngModel)]="dataModalJson" cols=15 rows=15
                            (change)="editJson()"></textarea>
                    </div>
                    <div class="col-md-12" *ngIf="mostrarJsonInputs">
                        <div class="form-group">
                            <section *ngFor="let input of divInputs ;let i = index;" class="">
                                <label><b>{{input.nkey}}</b></label>
                                <div class="input-group">
                                    <ng-container *ngIf="input.type !== 'textarea'">
                                        <input value="{{input.nvalue}}" type="{{input.type}}" class="form-control"
                                            (change)="editInput(i,input.nkey,$event)">
                                    </ng-container>
                                    <ng-container *ngIf="input.type === 'textarea'">
                                        <textarea value="{{input.nvalue}}" class="form-control"
                                            (change)="editInput(i,input.nkey,$event)">
                                                    </textarea>
                                    </ng-container>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <br>
                        <button class="btn btn-sm btn-ghost-primary" type="button"
                            (click)="toggleButtonJson()">{{isButtonJson ? 'Esconder': 'Visualizar'}} Json
                            Formatado</button>
                    </div>
                    <div class="col-md-12" *ngIf="isButtonJson">
                        <pre [innerHtml]="dataModalJson"></pre>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="salvarJsonModal()"
                    *ngIf="alterarDadosInputados==true">
                    <i class="fa fa-check"></i>
                    Salvar
                </button>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <button type="button" class="btn btn-secondary" (click)="modalAlterarJson.hide()">Fechar</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
<ng-template #template #lgModal>
    <div class="modal-header bg-primary">
        <h4 class="modal-title pull-left">Exportar para Excel</h4>
    </div>
    <div class="modal-body">
        <app-exportar-leads (exportar)="setColunasExport($event)"></app-exportar-leads>
    </div>
    <div class="modal-footer bg-primary">
        <button type="button" class="btn btn-success" (click)="exportCSV(modalRef)">Sim</button>
        <button type="button" class="btn btn-danger" (click)="modalRef?.hide()">Não</button>
    </div>
</ng-template>

<div bsModal #modalExecutarFluxo="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Execução de Novo Fluxo</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <label>Confirma a execução do lead selecionado para um novo fluxo? Você está prestes a executar
                            {{qtdeItem}} item(ns) selecionado(s).</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Workflow:</label>

                            <ng-select [items]="workflows" bindLabel="nome" bindValue="id"
                                placeholder="Selecione um WorkFlow" [(ngModel)]="fluxo"
                                (change)="mostraWorkflow($event)" name="workflows">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="fluxo">
                    <div class="col-md-12">
                        <alert type="info">
                            <strong>{{workflow.nome}}:</strong> {{workflow.descricao}}
                        </alert>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="salvarExecutarNovoFluxo()">
                    <i class="fa fa-check"></i>
                    Salvar
                </button>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <button type="button" class="btn btn-secondary" (click)="modalExecutarFluxo.hide()">Fechar</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<div bsModal #modalDetalhesSolucao="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Detalhes da Solução</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle"></i> Cenário</h5>
                        <p id="modalCenario">{{dataModalCenarioDesc}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h5 class="text-success"><i class="fa fa-cogs"></i> Descrição da Solução</h5>
                        <div id="modalDescricaoSolucao" innerHTML="{{dataModalSolucao?.solucao}}"></div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="modalDetalhesSolucao.hide()">Fechar</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div class="btn-group" dropdown placement="bottom left">
    <button dropdownToggle type="button" class="btn btn-light dropdown-toggle">
        Ações
        <span class="caret"></span>
    </button>
    <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem">
            <button type="button" class="dropdown-item" (click)="exportarLeads(template)"
                tooltip="Exportar Leads">
                <i class="fa fa-file-excel-o fa-lg mt-1 "></i>Exportar Leads
            </button>
        </li>
        <li role="menuitem">
            <button type="button" class="dropdown-item"
                (click)="reprocessarLeads()" tooltip="Reprocessar Leads">
                <i class="fa fa-refresh fa-lg mt-1 "></i>Reprocessar Leads
            </button>
        </li>
        <li role="menuitem" *ngIf="executarNovoFluxo">
            <button type="button" class="dropdown-item"
                    (click)="openModalExecutarFluxo()" tooltip="Executar Novo Fluxo">
                <i class="fa fa-play fa-lg mt-1 "></i>Executar Novo Fluxo
            </button>
        </li>
        <li role="menuitem">
            <button type="button" class="dropdown-item" (click)="excluirLeads()"
                tooltip="Excluir Leads">
                <i class="fa fa-times fa-lg mt-1 "></i>Excluir Leads
            </button>
        </li>
        <li role="menuitem">
            <button type="button" class="dropdown-item" (click)="excluirAtendimentosSasLeadsFalha()"
                    tooltip="Desfazer Atendimentos SAS">
                <i class="fa fa-external-link fa-lg mt-1 "></i>Desfazer Atendimentos SAS
            </button>
        </li>
        <li role="menuitem">
            <button type="button" class="dropdown-item"
                (click)="reenviarLeadsTravados()" tooltip="Reenviar Leads Travados">
                <i class="fa fa-refresh fa-lg mt-1 "></i>Reenviar Leads Travados
            </button>
        </li>
        <li class="divider dropdown-divider"></li>
        <li role="menuitem" *ngIf="!isEdit">
            <button type="button" class="dropdown-item"
                (click)="editarRegistro()" tooltip="Editar">
                <i class="fa fa-pencil fa-lg mt-1 "></i> Editar/Visualizar Lista
            </button>
        </li>
        <li role="menuitem">
            <button type="button" class="dropdown-item" (click)="deletar()"
                tooltip="Excluir">
                <i class="fa fa-times fa-lg mt-1 "></i> Excluir Lista
            </button>
        </li>

    </ul>
</div>
<div bsModal #modalExecutarFluxo="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Execução de Novo Fluxo</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12" >
                        <label>Confirma a execução do lead selecionado para um novo fluxo? Você está prestes a executar
                            {{qtdeItem}} item(ns) selecionado(s).</label>
                    </div>
                </div>
                <div class="row"  >
                    <div class="col-md-12" >
                        <div class="form-group">
                            <label>Workflow:</label>

                            <ng-select [items]="workflows" bindLabel="nome" bindValue="id"
                                       placeholder="Selecione um WorkFlow" [(ngModel)]="fluxo"
                                       (change)="mostraWorkflow($event)"
                                       name="workflows">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="fluxo">
                    <div class="col-md-12">
                        <alert type="info">
                            <strong>{{workflow.nome}}:</strong> {{workflow.descricao}}
                        </alert>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="salvarExecutarNovoFluxo()"  >
                    <i class="fa fa-check"></i>
                    Salvar
                </button>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <button type="button" class="btn btn-secondary" (click)="modalExecutarFluxo.hide()">Fechar</button>
            </div>
        </div>
    </div>
</div>
<ng-template confirm>
    <confirm-modal-component></confirm-modal-component>
</ng-template>

<ng-template #template #lgModal>
    <div class="modal-header bg-primary">
        <h4 class="modal-title pull-left">Exportar para Excel</h4>
    </div>
    <div class="modal-body">
        <app-exportar-leads (exportar)="setColunasExport($event)"></app-exportar-leads>
    </div>
    <div class="modal-footer bg-primary">
        <button type="button" class="btn btn-success" (click)="exportCSV(modalRef)">Sim</button>
        <button type="button" class="btn btn-danger" (click)="modalRef?.hide()">Não</button>
    </div>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SegmentacaoLeadsService } from '../../../services/segmentacao-leads.service';
import { UsuarioService } from '../../../services/usuario.service';
import { SessaoService } from '../../../services/sessao.service';
import { CustomServiceValidateService } from '../../../shared/control-messages/custom-service-validate.service';
import { ProjetoAcaoService } from '../../../services/projeto-acao.service';
import { CadastroClienteService } from '../../../services/cadastro-cliente.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-segmentacao-leads-form',
  templateUrl: './segmentacao-leads-form.component.html',
  styleUrls: ['./segmentacao-leads-form.component.css']
})
export class SegmentacaoLeadsFormComponent implements OnInit {

  form: FormGroup;
  originalHash: string;
  searchFailedUser = false;
  query = [];
  leads: any = [];
  totalItems: number = 0;
  limitLeads: number = 15;
  projetos: any = [];
  acoes: any = [];
  s: any;
  listInstrucoes = [
    { nome: 'uma das condições', value: 'OR' },
    { nome: 'todas as condições', value: 'AND' }
  ];

  listCampos = [
    { nome: 'Integrador', value: 'Plataformas.nome' },
    { nome: 'Tipo de Campanha', value: 'CampanhaTipos.nome' },
    { nome: 'Tipos de Atividade', value: 'ConteudoTipos.nome' },
    { nome: 'ID Importação CSV', value: 'ConteudoFilaImportacoes.conteudo_importacao_id', tipo: 'int'},
    { nome: 'ID Origem', value: 'ConteudoFilas.registro_id' },
    { nome: 'ID Integra', value: 'ConteudoFilas.id', tipo: 'int' },
    { nome: 'Identificador', value: 'ConteudoPublicados.identificador' },
    { nome: 'WorkFlow Principal', value: 'Workflow.nome' },
    { nome: 'Processamento Início', value: 'ConteudoFilas.modified', tipo: 'date' },
    { nome: 'Processamento Fim', value: 'ConteudoFilas.modified', tipo: 'date' },
    { nome: 'Nome do Responsável da Campanha', value: 'ConteudoPublicados.responsavel_nome' },
    { nome: 'Nome do Cliente', value: 'ConteudoFilas.nome_cliente' },
    { nome: 'Nome da Empresa/Propriedade', value: 'ConteudoFilas.nome_empreendimento' },
    { nome: 'Porte', value: 'ConteudoFilas.porte', tipo: 'select', items: null, bindLabel: "nome", bindValue: "nome", change: null},
    { nome: 'E-mail do Cliente', value: 'ConteudoFilas.email_cliente' },
    { nome: 'E-mail do Responsável', value: 'ConteudoFilas.email_responsavel' },
    { nome: 'Projeto Atendimento', value: 'ConteudoFilas.cod_projeto', tipo: 'select', items: null, bindLabel: "NomePRATIF", bindValue: "CodPRATIF", change: null },
    { nome: 'Ação Atendimento', value: 'ConteudoFilas.cod_acao', tipo: 'select', items: null, bindLabel: "NomeAcao", bindValue: "CodAcaoSeq", change: null },
    { nome: 'Instrumento Atendimento', value: 'ConteudoFilas.cod_categoria' },
    { nome: 'Palavra Chave de Status/Histórico', value: 'ConteudoFilas.historico' },
    { nome: 'Mensagem Impeditiva', value: 'ConteudoFilas.msg_impeditiva' },
    { nome: 'Dados Processado', value: 'ConteudoFilas.dados_formatado_json' },
    { nome: 'Protocolo Atendimento', value: 'ConteudoFilas.protocolo_atendimento' },
    { nome: 'Cód. Evento', value: 'ConteudoFilas.codigo_evento', tipo: 'int' },
    { nome: 'CPF', value: 'COALESCE(ConteudoFilas.cpf_origem, ConteudoFilas.cpf_encontrado)' },
    { nome: 'CNPJ', value: 'COALESCE(ConteudoFilas.cnpj_origem, ConteudoFilas.cnpj_encontrado)' },
    { nome: 'Tipo Cliente', value: 'ConteudoFilas.tipo_cliente' },
    { nome: 'Status', value: 'ConteudoFilas.status' },
    { nome: 'Reprocessado De', value: 'ConteudoFilas.qtd_reprocessado', tipo: 'int' },
    { nome: 'Reprocessado Até', value: 'ConteudoFilas.qtd_reprocessado', tipo: 'int' },
  ];

  listCondicoes = [
    { nome: 'contém', operador: 'LIKE', dica: 'use trecho ou termo inteiro', tipo: 'text' },
    { nome: 'não contém', operador: 'NOT LIKE', dica: 'use trecho ou termo inteiro', tipo: 'text' },
    { nome: 'é igual a', operador: '=', dica: 'use termo inteiro', tipo: 'text' },
    { nome: 'não é igual a', operador: '!=', dica: 'use termo inteiro', tipo: 'text' },
    { nome: 'está preenchido', operador: 'IS NOT NULL', dica: '', tipo: 'text' },
    { nome: 'não está preenchido', operador: 'IS NULL', dica: '', tipo: 'text' },
    { nome: 'antes ou igual a', operador: '<=', dica: 'formato de data dd/mm/yyyy', tipo: 'date' },
    { nome: 'depois ou igual a', operador: '>=', dica: 'formato de data dd/mm/yyyy', tipo: 'date' },
    { nome: 'no dia', operador: '=', dica: 'formato de data dd/mm/yyyy', tipo: 'date' },
    { nome: 'menor ou igual a', operador: '<=', dica: 'Número inteiro', tipo: 'int' },
    { nome: 'maior ou igual a', operador: '>=', dica: 'Número inteiro', tipo: 'int' },
    { nome: 'igual a', operador: '=', dica: 'Número inteiro', tipo: 'int' }
  ];

  constructor(private fb: FormBuilder
    , private segmentacaoLeadsService: SegmentacaoLeadsService
    , private usuarioService: UsuarioService
    , private sessaoService: SessaoService
    , private toastr: ToastrService
    , private router: Router
    , private projetoAcaoService: ProjetoAcaoService
    , private cadastroClienteService: CadastroClienteService
    , private route: ActivatedRoute) { }


  ngOnInit() {
    this.initForm();
    this.projetoAcaoService.listarTodosProjetos().then(response => {
      this.projetos = response;
      const lista = this.listCampos.findIndex(f => f.value == 'ConteudoFilas.cod_projeto');
      this.listCampos[lista].items = response;
      this.listCampos[lista].change = this.getAcoesPorProjeto.bind(this);
    });

    this.cadastroClienteService.listarPortesAtivo().then((response: any) => {
        const lista = this.listCampos.findIndex(f => f.value == 'ConteudoFilas.porte');
        this.listCampos[lista].items = response;
    });

    this.route.params.subscribe(routeParams => {
      if (routeParams.id && routeParams.id != 0) {
        this.getId(routeParams.id);
      }
    });
  }
  generateHash(formValue: any): string {
    return crypto.MD5(JSON.stringify(formValue)).toString();
  }
  getAcoesPorProjeto(projeto) {
    this.acoes = [];
    let codProjeto = null;
    if (typeof projeto === 'object') {
      codProjeto = projeto.CodPRATIF;
    } else if (typeof projeto === 'string') {
      codProjeto = projeto;
    }

    if (projeto != null) {
      this.projetoAcaoService.getAcoesPorProjeto(codProjeto).toPromise().then(response => {
        this.acoes = response;
        const lista = this.listCampos.findIndex(f => f.value == 'ConteudoFilas.cod_acao');
        this.listCampos[lista].items = response;
      });
    }
  }
  getNumSequencia(indexGrupo: number) {
    this.query[indexGrupo].sequencia = (indexGrupo + 1);
    return this.query[indexGrupo].sequencia;
  }

  novoGrupo() {
    this.query.push({
      sequencia: null,
      instrucao: "AND",
      condicoes: [{
        campo: null,
        operador: null,
        valor: null,
        dica: '',
        tipo: ''
      }]
    });
  }

  novaCondicao(indexGrupo: number) {
    this.query[indexGrupo].condicoes.push({
      campo: null,
      operador: null,
      valor: null,
      dica: '',
      tipo: '',
      condicoes: this.listCondicoes,
      esconder_valor: false
    });
  }

  removerGrupo(indexGrupo: number) {
    this.query.splice(indexGrupo, 1);
  }

  removerCondicao(indexGrupo: number, indexCondicao: number) {
    this.query.forEach((grupo, iGrupo) => {
      if (iGrupo == indexGrupo) {
        this.query[indexGrupo].condicoes.splice(indexCondicao, 1);
        if (!this.query[indexGrupo].condicoes.length) {
          this.removerGrupo(indexGrupo);
        }
      }
    });
  }

  setControleCondicao(iGrupo: number, iCondicao: number, condicao: any) {
    this.query[iGrupo].condicoes[iCondicao].dica = condicao.dica;
    this.query[iGrupo].condicoes[iCondicao].tipo = condicao.tipo;
    if (condicao.operador == 'IS NOT NULL' || condicao.operador == 'IS NULL') {
      this.query[iGrupo].condicoes[iCondicao].valor = '';
      this.query[iGrupo].condicoes[iCondicao].esconder_valor = true;
    } else {
      this.query[iGrupo].condicoes[iCondicao].esconder_valor = false;
    }
  }

  initForm() {
    this.novoGrupo();

    this.form = this.fb.group({
      id: [''],
      usuario_resp: [{ id: this.sessaoService.getUsuarioId(), nome: this.sessaoService.getUsuarioNome() }, Validators.required],
      responsavel_id: [''],
      nome: ['', Validators.required],
      filtros_json: [''],
    });
  }

  // facilitar o acesso no html
  get f() { return this.form.controls; }

  salvar() {
    const currentHash = this.generateHash(this.query);
    if (currentHash == this.originalHash) {
        console.log("não vai salvar, está igual");
        return;
    }

    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
      return false;
    }

    this.segmentacaoLeadsService.postVisualizacaoLeadsPorQuery({ query: this.query, limit: this.limitLeads }).toPromise().then(response => {
      this.leads = response.Leads;
      this.totalItems = response.TotalItems;

      this.form.value.filtros_json = JSON.stringify(this.query);
      this.form.value.responsavel_id = this.form.value.usuario_resp && this.form.value.usuario_resp.id ? this.form.value.usuario_resp.id : '',
        this.segmentacaoLeadsService.salvar(this.form.value).subscribe(response => {
          this.toastr.success('Segmentação salva com sucesso!');
          this.router.navigate(['/segmentacao-leads/form/' + response['id']]);
        }, e => {
          CustomServiceValidateService.customMensageService(e.error, this.form);
        });
    }, e => {
      this.toastr.warning('Por favor verifique sua consulta de segmentação, está com erro de sintaxe!', 'Atenção');
    });
  }

  getCampo(campoId: string) {
    return this.listCampos.find(f => f.value == campoId);
  }

  getId(id: number) {
    this.segmentacaoLeadsService.getId(id).toPromise().then(response => {
      //procurar se tem valor do cod_projeto e carregar o combo
      //let estrutura = JSON.parse(response.filtros_json)
      const campoCodProjeto = this.findObjectByCampo(JSON.parse(response['filtros_json']), 'ConteudoFilas.cod_projeto');
      if (campoCodProjeto != null) {
        this.getAcoesPorProjeto(campoCodProjeto.valor);
      }
      this.s = response;
      this.form.patchValue(response);
      this.query = response && response['filtros_json'] ? JSON.parse(response['filtros_json']) : [];
      this.query.forEach((grupo, iGrupo) => {
        grupo.condicoes.forEach((condicao, iCondicao) => {
          this.query[iGrupo].condicoes[iCondicao]['condicoes'] = this.listCondicoes;
        });
      });
      this.originalHash = this.generateHash(this.query);

      this.carregarLeads();
    });
  }

  findObjectByCampo(obj, fieldName) {
    if (Array.isArray(obj)) {
      for (const item of obj) {
        const foundObject = this.findObjectByCampo(item, fieldName);
        if (foundObject) {
          return foundObject;
        }
      }
    } else if (typeof obj === 'object') {
      if (obj.hasOwnProperty('campo') && obj.campo === fieldName) {
        return obj;
      }
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const foundObject = this.findObjectByCampo(obj[key], fieldName);
          if (foundObject) {
            return foundObject;
          }
        }
      }
    }
    return null; // Retorna null se o objeto não for encontrado
  }

  novo($event) {
    if($event?.salvar == true){
        this.salvar();
    }else{
        this.s = null;
        this.query = [];
        this.leads = [];
        this.totalItems = 0;
        this.initForm();
    }
  }

  searchUser = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        term.length < 3 ? of([])
          :
          this.usuarioService.getUsuariosAutoComplete(term).pipe(
            tap(() => this.searchFailedUser = false),
            catchError(() => {
              this.searchFailedUser = true;
              return of([]);
            }))
      )
    );

  formatterUser = (usuario: {
    nome: string
  }) => (usuario.nome);

  carregarLeads() {
    if (this.query) {
      this.segmentacaoLeadsService.postVisualizacaoLeadsPorQuery({ query: this.query, limit: this.limitLeads }).toPromise().then(response => {
        this.leads = response.Leads;
        this.totalItems = response.TotalItems;
      }, e => {
        this.toastr.warning('Não foi possível fazer a pré-visualização da sua consulta, verifique a sintaxe!', 'Atenção');
      });
    }
  }
  mudarCondicao(e: any, g, c) {
    if (e.tipo == 'date') {
      this.query[g].condicoes[c]['condicoes'] = [
        { nome: 'antes ou igual a', operador: '<=', dica: 'formato de data dd/mm/yyyy', tipo: 'date' },
        { nome: 'depois ou igual a', operador: '>=', dica: 'formato de data dd/mm/yyyy', tipo: 'date' },
        { nome: 'no dia', operador: '=', dica: 'formato de data dd/mm/yyyy', tipo: 'date' }
      ];
    } else {
      if (e.tipo == 'select') {
        this.query[g].condicoes[c]['condicoes'] = [
          { nome: 'igual a', operador: '=', dica: 'Selecione', tipo: 'text' },
          { nome: 'não contém', operador: 'NOT LIKE', dica: 'use trecho ou termo inteiro', tipo: 'text' },
          { nome: 'está preenchido', operador: 'IS NOT NULL', dica: '', tipo: 'text' },
          { nome: 'não está preenchido', operador: 'IS NULL', dica: '', tipo: 'text' },
        ];
      } else if (e.tipo == 'int') {
        this.query[g].condicoes[c]['condicoes'] = [
          { nome: 'menor ou igual a', operador: '<=', dica: 'Número inteiro', tipo: 'int' },
          { nome: 'maior ou igual a', operador: '>=', dica: 'Número inteiro', tipo: 'int' },
          { nome: 'igual a', operador: '=', dica: 'Número inteiro', tipo: 'int' }
        ];
      } else {
        this.query[g].condicoes[c]['condicoes'] = [
          { nome: 'contém', operador: 'LIKE', dica: 'use trecho ou termo inteiro', tipo: 'text' },
          { nome: 'não contém', operador: 'NOT LIKE', dica: 'use trecho ou termo inteiro', tipo: 'text' },
          { nome: 'é igual a', operador: '=', dica: 'use termo inteiro', tipo: 'text' },
          { nome: 'não é igual a', operador: '!=', dica: 'use termo inteiro', tipo: 'text' },
          { nome: 'está preenchido', operador: 'IS NOT NULL', dica: '', tipo: 'text' },
          { nome: 'não está preenchido', operador: 'IS NULL', dica: '', tipo: 'text' },
        ];
      }
    }
  }

}

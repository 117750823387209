import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RegionalService } from '../../services/regional.service';
import { ConfirmService } from '../../shared/confirm-modal/confirm-modal-and-service';

@Component({
  selector: 'app-regionais',
  templateUrl: './regionais.component.html',
  styleUrls: ['./regionais.component.scss']
})
export class RegionaisComponent implements OnInit {

  regionais: any;
  filter: any;
  cidades: any;
  totalItems: number;
  currentPage: number = 1;
  maxSize: number;
  listItemsPerPage = [10, 20, 30, 40, 50, 100, 200, 300];
  listStatus = [{ name: 'Sim', value: 1 }, { name: 'Não', value: 0 }, { name: 'Todos', value: '' }];

  constructor(
    private regionalService: RegionalService
    , private confirmService: ConfirmService
    , private toastr: ToastrService
    , private router: Router) { }

  async ngOnInit() {
    this.initFiltros();

    this.regionalService.listarTodasCidadesUf().then(response => {
      this.cidades = response;
    });

    this.getCidades();
  }

  initFiltros() {
    this.filter = {
      nome: '',
      ativo: '',
      CodCid: '',
      page: 1,
      limit: 30
    };
  }

  pageChanged(event: any): void {
    this.filter.page = event.page;
    this.filter.limit = event.itemsPerPage;
    this.getCidades();
  }

  async getCidades(): Promise<void> {
    this.filter.CodCid = this.filter.CodCid ? this.filter.CodCid : '';
    let response = await this.regionalService.getPaginate(this.filter).toPromise();
    this.regionais = response.Regionais;
    this.totalItems = response.TotalItems;

    let maxSize = Math.ceil(this.totalItems / this.filter.limit);
    this.maxSize = maxSize <= 15 ? maxSize : 15;
  }

  pesquisar() {
    this.filter.page = 1;
    this.currentPage = 1;

    this.getCidades();
  }

  editarRegistro(id: number) {
    this.router.navigate(['/regionais/form', id]);
  }

  limparFiltros() {
    this.initFiltros();
  }

  deletar(regional: any = []) {
    this.confirmService.confirm({
      title: 'Exclusão', message: 'Você tem certeza que deseja excluir a regional de nome ' + regional.nome + ' ?'
    }).then(
      () => {
        this.regionalService.delete(regional.id).subscribe(response => {
          this.pesquisar();
          this.toastr.success('Regional removida com sucesso!');
          if (response.message) {
            this.toastr.info(response.message);
          }
        });
      },
      () => { });
  }

}

import {Component, Injectable, Directive, TemplateRef, Input} from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {PlataformaCampoService} from '../../services/plataforma-campo.service';
import {HttpParams} from '@angular/common/http';
import {ConteudoService} from '../../services/conteudo.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../environments/environment';
/**
 * Options passed when opening a confirmation modal
 */
interface ModalOptions {
    /**
     * The title of the confirmation modal
     */
    title: string,

    /**
     * The page in the confirmation modal
     */
    plataforma_id: number,
    size: string
}

/**
 * An internal service allowing to access, from the confirm modal component, the options and the modal reference.
 * It also allows registering the TemplateRef containing the confirm modal component.
 *
 * It must be declared in the providers of the NgModule, but is not supposed to be used in application code
 */
@Injectable()
export class ModalState {
    /**
     * The last options passed ModalService.confirm()
     */
    options: ModalOptions;

    /**
     * The last opened confirmation modal
     */
    modal: NgbModalRef;

    /**
     * The template containing the confirmation modal component
     */
    template: TemplateRef<any>;
}

/**
 * A confirmation service, allowing to open a confirmation modal from anywhere and get back a promise.
 */
@Injectable()
export class ModalService {

    constructor(private modalService: NgbModal, private state: ModalState) { }

    /**
     * Opens a confirmation modal
     * @param options the options for the modal (title and page)
     * @returns {Promise<any>} a promise that is fulfilled when the user chooses to confirm, and rejected when
     * the user chooses not to confirm, or closes the modal
     */
    modal(options: ModalOptions): Promise<any> {
        this.state.options = options;
        this.state.modal = this.modalService.open(this.state.template, { size: options.size });
        return this.state.modal.result;
    }
}

/**
 * The component displayed in the confirmation modal opened by the ModalService.
 */
@Component({
    selector: 'modal-component',
    templateUrl: './modal.component.html',
})
export class ModalModalComponent {
    public formDinamico: any = [];
    dynamicContent: SafeHtml;
    options: ModalOptions;
    public endpoints: any;
    public endpoint: any = 'postAddLead';
    constructor(
        private toastr: ToastrService,
        private state: ModalState,
        private conteudoService: ConteudoService,
        private sanitizer: DomSanitizer,
        private plataformaCamposService: PlataformaCampoService
    ) {
        this.options = state.options;
        this.endpoints = [
            { nome: 'postAddLead', value: 'postAddLead'},
            { nome: 'postAddLeadMessage', value: 'postAddLeadMessage'}];

        this.formDinamico.push({
            'nome': 'plataforma_id', 'campo': {'descricao': 'ID do Integrador no Sistema INTEGRA ', 'nome': 'plataforma_id', 'valor_padrao': state.options.plataforma_id }
        });
        this.formDinamico.push({
            'nome': 'registro_id', 'campo': {'descricao': 'Registro ID de origem da integração', 'nome': 'registro_id', 'valor_padrao': 'id-origem'}
        });
        this.formDinamico.push({
            'nome': 'identificador', 'campo': {'descricao': 'Identificador cadastrado na campanha', 'nome': 'identificador', 'valor_padrao': 'identificador-campanha'}
        });
        this.plataformaCamposService.get({ plataforma_id: state.options.plataforma_id }).subscribe(response => {
            this.formDinamico = this.formDinamico.concat(response);
        });
    }
    salvarJson(f) {
        let json = JSON.stringify(f.form.value);
        navigator.clipboard.writeText(json);
        this.toastr.success('Json copiado na sua área de transferência!');

    }
    salvarUrl() {
         navigator.clipboard.writeText(environment.environment.nomeServiceIntegra + "ConteudoFilas/" + this.endpoint + '.json');
        this.toastr.success('URL copiada na sua área de transferência!');
    }
    salvarRequisicao(f) {

        this.conteudoService.addLead(f.form.value, this.endpoint ).subscribe(response => {
            if (response === true) {
                this.toastr.success('Lead enviado para a fila de mensageria pré-processamento de lead');
            } else if (typeof response.id === 'undefined') {
                this.toastr.error(response.message);
            } else {
                this.toastr.success('Lead criado ID:' + response.id);
            }
        });
    }
    no() {
        this.state.modal.dismiss('not confirmed');
    }
}

/**
 * Directive allowing to get a reference to the template containing the confirmation modal component,
 * and to store it into the internal confirm state service. Somewhere in the view, there must be
 *
 * ```
 * <template confirm>
 *   <confirm-modal-component></confirm-modal-component>
 * </template>
 * ```
 *
 * in order to register the confirm template to the internal confirm state
 */
@Directive({
    selector: "[modal]"
})
export class ModalTemplateDirective {
    constructor(confirmTemplate: TemplateRef<any>, state: ModalState) {
        state.template = confirmTemplate;
    }
}

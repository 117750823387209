import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtendimentoConfiguracoesComponent } from './atendimento-configuracoes.component';
import { AtendimentoConfiguracoesFormComponent } from './atendimento-configuracoes-form/atendimento-configuracoes-form.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ControlMessagesModule } from '../../shared/control-messages/control-messages.module';
import { AtendimentoConfiguracoesRoutingModule } from './atendimento-configuracoes-routing.module';
import { OrderModule } from 'ngx-order-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  imports: [    
    CommonModule,
    AtendimentoConfiguracoesRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ControlMessagesModule,
    NgSelectModule,
    FilterPipeModule,
    OrderModule    
  ],
  declarations: [AtendimentoConfiguracoesComponent, AtendimentoConfiguracoesFormComponent],  
})
export class AtendimentoConfiguracoesModule { }

import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { PrintService } from '../../../services/print.service';
import { ConversoesRdSasService } from '../../../services/conversoes-rd-sas.service';
import { environment } from '../../../../environments/environment';
//import { isObject } from 'util';
import { MyNgbDateParserFormatter } from '../../../shared/datepicker/MyNgbDateParserFormatter';

@Component({
  selector: 'app-rel-conversoes-rd-sas',
  templateUrl: './rel-conversoes-rd-sas.component.html',
  styleUrls: ['./rel-conversoes-rd-sas.component.scss']
})
export class RelConversoesRdSasComponent implements OnInit {

  public isCollapsed: boolean = false;
  public iconCollapse: string = "icon-arrow-up";
  
  public filter: any;
  public listagem: any;

  public data_evento_ini: NgbDateStruct;
  public data_evento_fim: NgbDateStruct;  

  public listStatus = [
    { nome: 'Sucesso', value: 1 },
    { nome: 'Falha', value: 0 },
    { nome: 'Todos', value: '' },
  ];

  public listTipos = [
    { nome: 'Inscrito SAS', value: 'inscrito-curso' },
    { nome: 'Inscrito Planege', value: 'inscrito-planege' },
    { nome: 'Concluiu SAS', value: 'concluiu-curso' },    
    { nome: 'Concluiu Consultoria', value: 'concluiu-consultoria' },    
    { nome: 'Todos', value: '' },
  ];

  public totalItems: number = 0;
  public currentPage: number = 1;
  public maxSize: number;
  public listItemsPerPage = [10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000];

  constructor(private customFormat: MyNgbDateParserFormatter
    , private conversoesRdSasService: ConversoesRdSasService
    , private printService: PrintService) { }

  async ngOnInit() {
    this.initFiltros();
    this.getConversoesRdSas();    
  }

  initFiltros() {    
    this.data_evento_ini = null;
    this.data_evento_fim = null;

    this.filter = {      
      CodCliente: '',
      CodEmpreendimento: '',      
      cliente: { CodParceiro: '' },
      empreendimento: { CodParceiro: '' },
      EventoID: '',       
      DataInicio : '',
      DataFim : '',      
      tipo : '',
      status : '',
      page: 1,
      limit: 30,
      exportar_rel: 0
    };
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.iconCollapse = this.isCollapsed ? "icon-arrow-down" : "icon-arrow-up";
  }

  pageChanged(event: any): void {
    this.filter.page = event.page;
    this.filter.limit = event.itemsPerPage;
    this.getConversoesRdSas();
  }

  async getConversoesRdSas(): Promise<void> {
    //Parser
    this.filter.DataInicio = this.data_evento_ini ? this.customFormat.formatForServer(this.data_evento_ini) : '';
    this.filter.DataFim = this.data_evento_fim ? this.customFormat.formatForServer(this.data_evento_fim) : '';    
    this.filter.CodCliente = this.filter.cliente.CodParceiro ? this.filter.cliente.CodParceiro : '';
    this.filter.CodEmpreendimento = this.filter.empreendimento.CodParceiro ? this.filter.empreendimento.CodParceiro : '';        
    this.filter.status = this.filter.status == 0 || this.filter.status == 1 ? this.filter.status : '';  
    this.filter.exportar_rel = 0;

    let response = await this.conversoesRdSasService.get(this.filter).toPromise();
    this.listagem = response.ConversoesRdSas;
    this.totalItems = response.TotalItems;

    let maxSize = Math.ceil(this.totalItems / this.filter.limit);
    this.maxSize = maxSize <= 15 ? maxSize : 15;
  }

  pesquisar() {
    this.filter.page = 1;
    this.currentPage = 1;
    this.getConversoesRdSas();
  }

  limparFiltros() {
    this.initFiltros();
  }  

  imprimir(){
    this.printService.printDiv('divImpressao');
  }

  exportar(): void {
    this.filter.exportar_rel = 1;
    let url = environment.environment.nomeServiceIntegra +'/Relatorios/exportarConversoesRdSas.json?'
  
    window.open(url + this.serialize(this.filter, null));
  }  
  serialize(obj, prefix) {
    let str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        let k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          this.serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

}

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-paperclip"></i>
        Cadastro de itens da <strong>lista (Campos de seleção utilizados nas integrações)</strong>

        <button type="button" class="btn btn-primary pull-right" (click)="addCampoLista()">
          <i class="fa fa-plus"></i>
          Cadastrar
        </button>
      </div>
      <div class="card-body">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Nome do item da lista:</label>
                <div class="input-group">
                  <input class="form-control" type="text" name="valor" formControlName="valor">
                </div>
                <app-control-messages [control]="form.controls.valor"></app-control-messages>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Código referente no SAS ao item:</label>
                <div class="input-group">
                  <input class="form-control" type="text" name="codigo_referente" formControlName="codigo_referente">
                </div>
                <app-control-messages [control]="form.controls.codigo_referente"></app-control-messages>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </form>

        <div class="row">&nbsp;</div>

        <table class="table">
          <thead>
              <tr>
                <th scope="col">
                  <input type="text" class="form-control" [(ngModel)]="Response.valor" placeholder="Buscar por nome..."/>
                </th>
                <th scope="col">
                  <input type="text" class="form-control" [(ngModel)]="Response.codigo_referente" placeholder="Buscar por código..."/>
                </th>
              </tr>
            </thead>
          <tbody>
            <tr *ngFor="let cl of camposListas | orderBy: 'valor':false | filterBy: Response">
              <td>{{cl.valor}}</td>
              <td>{{cl.codigo_referente}}</td>
              <td class="text-center">
                <button type="button " class="btn btn-sm btn-danger " title="Remover este item da lista" (click)="removerCampoLista(cl) ">
                  <i class="fa fa-times fa-lg mt-1 "></i>
                </button>
              </td>
            </tr>
            <tr *ngIf="camposListas && camposListas.length == 0">
              <td colspan="4">Não existe listas cadastrada para este campo.</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>

<ng-template confirm>
  <confirm-modal-component></confirm-modal-component>
</ng-template>

<div class="animated fadeIn">

  <div class="col-md-12 mb-12">
    <tabset>
      <tab>
        <ng-template tabHeading><i class="icon-list"></i> Pesquisar checagem de cadastros de clientes</ng-template>
          <app-tab-check-consultas></app-tab-check-consultas>
      </tab>
        <tab>
            <ng-template tabHeading><i class="icon-note"></i> Configurações Web Services</ng-template>
            <app-tab-configuracoes></app-tab-configuracoes>
        </tab>
     <!-- <tab>
        <ng-template tabHeading><i class="icon-chart"></i> Pesquisar indicadores de contrato Dataminer</ng-template>
        <app-tab-indicadores></app-tab-indicadores>
      </tab>   -->
    </tabset>
  </div>

</div>

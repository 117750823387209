import { ConfirmModalModule } from './../../shared/confirm-modal/confirm-modal.module';
import { FormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GerarWorkflowComponent } from './gerar-workflow.component';
import { WorkflowModule } from '../../shared/workflow/workflow.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';


@NgModule({
  declarations: [
    GerarWorkflowComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    WorkflowModule,
    ConfirmModalModule,
    TabsModule,
    TooltipModule.forRoot(),
    NgSelectModule,
    BsDropdownModule.forRoot()
  ]
})
export class GerarWorkflowModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConteudoService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  get(params) {
    return this.http.get<Conteudos>(environment.environment.nomeServiceIntegra + 'ConteudoPublicados/getAll.json', { params: params });
  }

  getId(id) {
    return this.http.get<Conteudo>(environment.environment.nomeServiceIntegra + `ConteudoPublicados/getId/${id}.json`)
    .pipe(map((res) => {
      if(res.conteudo_evento_saidas.length){
        res.conteudo_evento_saidas.forEach(c => {
          if(c.nome_plataforma){
            c.nomeCustom = c.nome_plataforma + ' - ' + c.nome_evento;
          } else {
            c.nomeCustom = c.nome_evento;
          }
        });
      }
      return res;
    }));;
  }

  salvar(params) {
    this.sessaoService.delete('ServiceCampanhaCarteiras');
    return this.http.post(environment.environment.nomeServiceIntegra + 'ConteudoPublicados/postSave.json', { ConteudoPublicados: params });
  }

  delete(id) {
    this.sessaoService.delete('ServiceCampanhaCarteiras');
    return this.http.post<ResponseDelete>(`${environment.environment.nomeServiceIntegra}ConteudoPublicados/delete/${id}.json`, {});
  }

  copiar(id) {
    return this.http.get(environment.environment.nomeServiceIntegra + `ConteudoPublicados/getDuplicar/${id}.json`);
  }

  getMensagensHistorico() {
    return this.http.get(environment.environment.nomeServiceIntegra + 'ConteudoFilas/getListMensagensHistorico.json');
  }

  reprocessarLeads(list) {
    return this.http.post(environment.environment.nomeServiceIntegra + 'ConteudoFilas/postReprocessarLeads.json', { ids: list });
  }

    executarNovoFluxo(params) {
        return this.http.post<any>(environment.environment.nomeServiceIntegra + 'ConteudoFilas/postExecutarNovoFluxo.json', params );
    }

  deleteList(list: any) {
    return this.http.post(environment.environment.nomeServiceIntegra + 'ConteudoFilas/deleteList.json', { ids: list });
  }

  excluirAtendimentoSasLeadsFalha(list: any) {
    return this.http.post(environment.environment.nomeServiceIntegra + 'ConteudoFilas/postExcluirAtendimentoSasLeadsFalha.json', { ids: list });
  }

  salvarFila(params) {
    return this.http.post(environment.environment.nomeServiceIntegra + 'ConteudoFilas/postSave.json', { ConteudoFilas: params });
  }

    addLead(params, endpoint) {
        return this.http.post<any>(environment.environment.nomeServiceIntegra + 'ConteudoFilas/' + endpoint + '.json', params );
    }

  getIdCamposText(id) {
    return this.http.get(`${environment.environment.nomeServiceIntegra}ConteudoFilas/getIdCamposText/${id}.json`);
  }

  listarTodasMensagensHistorico() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceMensagensHistorico')) {
        resolve(this.sessaoService.get('ServiceMensagensHistorico'));
      } else {
        this.http.get(environment.environment.nomeServiceIntegra + 'ConteudoFilas/getListMensagensHistorico.json')
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceMensagensHistorico', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

  listarTodasCampanhaCarteiras() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceCampanhaCarteiras')) {
        resolve(this.sessaoService.get('ServiceCampanhaCarteiras'));
      } else {
        const query: any = {mostrar_carteiras: 1, limit: 1000 };
        this.http.get<Conteudos>(environment.environment.nomeServiceIntegra + 'ConteudoPublicados/getAll.json', { params: query })
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceCampanhaCarteiras', res.ConteudoPublicados);
            resolve(res.ConteudoPublicados);
          }, err => {
            reject(err);
          });
      }
    });
  }

}

export class Conteudo {
  id: number;
  conteudo_publicado_id: number;
  conteudo_tipo_id: number;
  identificador: string;
  publico_cliente: string;
  nome_arquivo: string;
  arquivo: string;
  filetype: string;
  ativo: number;
  OrientacaoCliente: string;
  DescRealizacao: string;
  TipoRealizacao: string;
  DataRealizacao: string;
  Instrumento: string;
  Abordagem: string;
  CodProjeto: string;
  CodAcao: number;
  CpfResponsavel: string;
  UnidadeOrganizacionalID: any;
  CodMeioAtendimento: number;
  MacroeventoID: number;
  CodTipoRealizacao: string;
  conteudo_temas: any;
  conteudo_portes: any;
  conteudo_ignore_campos: any;
  conteudo_evento_saidas: any;
  PortfolioDisponibilizacaoID: number;
  EventoID: number;
  gerar_reserva: boolean;
  gerar_inscricao: boolean;
  responsavel_nome: string;
  responsavel_id: number;
}

export class Conteudos {
  TotalItems: number;
  ConteudoPublicados: object;
}

export class ResponseDelete {
  message: string;
}

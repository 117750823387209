
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthCheckComponent } from './health-check.component';
import { HealthCheckRoutingModule } from './health-check-routing.module';

import { NgSelectModule } from '@ng-select/ng-select';
import {NgbDateParserFormatter, NgbDatepickerConfig, NgbDatepickerI18n, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {CustomDatepickerI18n, I18n} from "../../shared/datepicker/CustomDatepickerI18n";
import {NgbDatePTParserFormatter} from "../../shared/datepicker/NgbDatePTParserFormatter";
import {CustomNgbDatepickerConfig} from "../../shared/datepicker/CustomNgbDatepickerConfig";
import {MyNgbDateParserFormatter} from "../../shared/datepicker/MyNgbDateParserFormatter";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ControlMessagesModule} from "../../shared/control-messages/control-messages.module";
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
        HealthCheckComponent,
  ],
  imports: [
        NgbNavModule,
        HealthCheckRoutingModule,
        CommonModule,
        NgbModule,
        NgSelectModule,
        CollapseModule.forRoot(),
        ReactiveFormsModule,
        ControlMessagesModule,
        FormsModule
  ],
    providers: [
        [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
        [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
        [{ provide: NgbDatepickerConfig, useClass: CustomNgbDatepickerConfig }],
        MyNgbDateParserFormatter
    ],
})
export class HealthCheckModule { }

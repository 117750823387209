import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private http: HttpClient) { }

  get(params) {
    return this.http.get<Leads>(environment.environment.nomeServiceIntegra + 'ConteudoFilas/getAll.json', {params : params});
  }
    // exportarLeads(filter, colunas) {
    //     console.log(filter);
    //     var arrStr = this.parameterizeArray('colunas', colunas);
    //     var link = environment.environment.nomeServiceIntegra + `Relatorios/exportarListaLeads.json` + filter + arrStr ;
    //     window.open(link);
    // }
    exportarLeads(selectedFields) {
      const params = this.buildQueryString(selectedFields);
      return `${environment.environment.nomeServiceIntegra}Relatorios/exportarListaLeads.json?${params}`;
      // return this.http.get<Blob>(`${environment.environment.nomeServiceIntegra}Relatorios/exportarListaLeads.json`, {
      //   params,
      //   responseType: 'blob' as 'json', // responseType é blob para lidar com arquivos
      // });
  }
  private buildQueryString(selectedFields: any): HttpParams {
    let queryString = '';
    for (const key in selectedFields) {
      if (key !="colunas") {
        queryString += `${key}=${selectedFields[key]}&`;
      }
    }
    selectedFields['colunas'].forEach(f => {
      queryString += `colunas[]=${f}&`;
    });
    return new HttpParams({ fromString: queryString });
  }
}

export class Leads{
  TotalItems: number;
  ConteudoFilas: object;
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GrupoPermissoesFormComponent } from './grupo-permissoes-form/grupo-permissoes-form.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Grupos de Permissões'
    },
    children: [      
      {
        path: 'inserir',
        component: GrupoPermissoesFormComponent,
        data: {
          title: 'Cadastrar Novo'
        }            
      },
      {
        path: 'alterar/:id',
        component: GrupoPermissoesFormComponent,
        data: {
          title: 'Alterar'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GruposPermissoesRoutingModule { }

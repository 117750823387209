import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SegmentoAtividadeService {

  constructor(private http: HttpClient) { }  

  get(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'SegmentoAtividades/getAll.json', { params: params});
  }  

  salvar(params) {
    return this.http.post(environment.environment.nomeServiceIntegra + 'SegmentoAtividades/postSave.json', { SegmentoAtividades: params });
  }

  excluir(id) {
    return this.http.delete(environment.environment.nomeServiceIntegra + `SegmentoAtividades/delete/${id}.json`);
  }
  
}

export class SegmentoAtividade {
  id: number;  
  segmento_id: number;
  CodAtivEcon: string;
  CodCnaeFiscal: string;
  DescCnaeFiscal: string;
  CodSetor: string;
  DescSetor: number;
}

<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">

      <div class="card">
        <div class="card-header">
          <i class="fa fa-search"></i>Filtros para consulta de clientes concluintes encaminhados para conversão
          <div class="card-header-actions">
            <button type="button" class="btn btn-minimize" (click)="toggleCollapse()">
              <i class={{iconCollapse}}></i>
            </button>
          </div>
        </div>
        <div [collapse]="isCollapsed" class="card-body">
          <form class="form-horizontal">

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nome ou CPF:</label>
                  <autocomplete-cliente [(ngModel)]="filter" name="filter"></autocomplete-cliente>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Razão Social / CNPJ:</label>
                  <autocomplete-empreendimento [(ngModel)]="filter" name="filter"></autocomplete-empreendimento>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="name">Cód. do Evento:</label>
                  <div class="input-group">
                    <input class="form-control" type="text" [(ngModel)]="filter.EventoID" name="EventoID" number-only>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="name">Período Ev. Início:</label>
                  <div class="input-group">
                    <input class="form-control" type="text" ngbDatepicker #d="ngbDatepicker"
                      [(ngModel)]="data_evento_ini" name="data_evento_ini">
                    <div class="input-group-append">
                      <div class="input-group-append">
                        <span class="input-group-text" (click)="d.toggle()" style="cursor: pointer;">
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="name">Período Ev. Fim:</label>
                  <div class="input-group">
                    <input class="form-control" type="text" ngbDatepicker #df="ngbDatepicker"
                      [(ngModel)]="data_evento_fim" name="data_evento_fim">
                    <div class="input-group-append">
                      <div class="input-group-append">
                        <span class="input-group-text" (click)="df.toggle()" style="cursor: pointer;">
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Tipo:</label>

                  <ng-select [items]="listTipos" bindLabel="nome" bindValue="value" placeholder="Selecione..."
                    [(ngModel)]="filter.tipo" name="tipo">
                  </ng-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Status:</label>

                  <ng-select [items]="listStatus" bindLabel="nome" bindValue="value" placeholder="Selecione..."
                    [(ngModel)]="filter.status" name="status">
                  </ng-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Registros por página:</label>

                  <ng-select [items]="listItemsPerPage" placeholder="Selecione..." [(ngModel)]="filter.limit"
                    name="limit">
                  </ng-select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group pull-right">
                  <button type="button" class="btn btn-primary" (click)="pesquisar()">
                    <i class="fa fa-search"></i>
                    Pesquisar/Visualizar
                  </button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                    <i class="fa fa-ban"></i>
                    Resetar Filtros
                  </button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn btn-success" (click)="exportar()">
                    <i class="fa fa-file-excel-o"></i>
                    Exportar para Excel
                  </button>
                </div>
              </div>
            </div>

            <div class="clearfix"></div>

          </form>
        </div>
      </div>

      <div class="row" *ngIf="listagem">
        <div class="col-md-12">

          <div class="card">
            <div class="card-header">
              <i class="fa fa-align-justify"></i> Resultados ({{totalItems}})

              <button *ngIf="listagem && listagem.length > 0" type="button" class="btn btn-primary active pull-right"
                (click)="imprimir()">
                <i class="fa fa-print"></i> Imprimir Listagem
              </button>
            </div>
            <div class="card-body" id="divImpressao">
              <table class="table table-bordered table-striped table-sm">
                <thead>
                  <tr style="background-color: #c8ced3">
                    <th colspan="3" class="text-center">
                      <h5><strong>Conversões enviadas para o RD Station de origem SAS</strong></h5>
                    </th>
                  </tr>
                  <tr>
                    <th class="text-center">Cliente / Empreendimento</th>
                    <th class="text-center">Dados do Evento</th>
                    <th class="text-center">Dados da Conversão</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let l of listagem; let i = index">
                    <td>
                      <div><strong>Cliente:&nbsp;</strong>{{l.cliente?.NomeRazaoSocial}}</div>
                      <div class="small text-muted">
                        <span><strong>CPF:&nbsp;</strong></span>{{l?.cliente?.CgcCpf | completeDigitoCpf | mask: '000.000.000-00'}}
                      </div>
                      <div class="small text-muted">
                        <span><strong>Telefone:&nbsp;</strong></span>{{l?.cliente?.Telefone}}
                      </div>
                      <div class="small text-muted">
                        <span><strong>E-mail:&nbsp;</strong></span>{{l?.cliente?.Email}}
                      </div>
                      <div class="small text-muted">
                        <span><strong>Empresa:&nbsp;</strong></span>{{l?.empreendimento?.NomeRazaoSocial}}
                      </div>
                      <div class="small text-muted">
                        <span><strong>CNPJ:&nbsp;</strong></span>{{l?.empreendimento?.CgcCpf | completeDigitoCnpj | mask: '00.000.000/0000-00'}}
                      </div>
                      <div class="small text-muted">
                        <span><strong>E-mail:&nbsp;</strong></span>{{l?.empreendimento?.Email}}
                      </div>
                    </td>
                    <td>
                      <div><strong>Evento:&nbsp;</strong> {{l?.EventoID }} - {{l?.evento?.Nome }}</div>
                      <div class="small text-muted">
                        <span><strong>Data Início:&nbsp;</strong></span>{{l?.evento?.DataInicio | date: 'dd/MM/yyyy'}}
                      </div>
                      <div class="small text-muted">
                        <span><strong>Data Fim:&nbsp;</strong></span>{{l?.evento?.DataFim | date: 'dd/MM/yyyy'}}
                      </div>
                    </td>
                    <td>
                      <div>
                        <strong>Status:&nbsp;</strong>
                        <span [ngClass]="l?.status == 1 ? 'badge badge-success' : 'badge badge-danger'">{{l?.status
                                            ? 'Sucesso' : 'Falha'}}</span>
                      </div>
                      <div class="small text-muted">
                        <span><strong>Tipo de Conversão:&nbsp;</strong></span>{{l?.tipo}}
                      </div>
                      <div class="small text-muted">
                        <span><strong>Data de
                            Processamento:&nbsp;</strong></span>{{l.created | date: 'dd/MM/yyyy HH:mm'}}
                      </div>
                      <div class="small text-muted"><span><strong>Mensagem:&nbsp;</strong></span>
                        {{l?.mensagem}}
                      </div>

                      <div class="small text-muted">
                        <span><strong>Envio WS RD:&nbsp;</strong></span>
                        <i style="cursor: pointer;" class="fa fa-code fa-lg mt-2" popover="{{l.json_request}}"
                          popoverTitle="Dados de Conversão RD Station" placement="bottom"
                          tooltip="Clique aqui para ver dados enviado para conversão no RD Station"></i>
                        &nbsp;&nbsp;&nbsp;
                        <span><strong>Resposta WS RD:&nbsp;</strong></span>
                        <i style="cursor: pointer;" class="fa fa-code fa-lg mt-2" popover="{{l.json_response}}"
                          popoverTitle="Dados de Resposta WebService RD Station" placement="bottom"
                          tooltip="{{l.json_response ? 'Clique aqui para ver dados de resposta do WebService RD Station' : 'Não enviado ou sem resposta do WebService RD'}}"></i>
                      </div>

                    </td>
                  </tr>
                  <tr *ngIf="listagem && listagem.length == 0">
                    <td colspan="10">Não existe registros encontrados com estes parâmetros</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-md-12 justify-content-center">
              <pagination [boundaryLinks]="true" [itemsPerPage]="filter.limit" [totalItems]="totalItems"
                class="text-center" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize"
                previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
import { WorkflowService } from './../../services/workflow.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewChild, ElementRef, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
//https://singhprabhanshu5.medium.com/flow-chart-using-mermaid-in-angular-501e53565075
//https://medium.com/blocksurvey/tutorial-how-to-make-a-dynamic-logic-flowchart-in-angularjs-a24ed3788d74
import { Component, OnInit } from '@angular/core';
import { TipoAcaoEnum } from '../../enum/TipoAcaoEnum';
import { SessaoService } from '../../services/sessao.service';
declare var flowchart: any;
declare var window: any;

@Component({
    selector: 'app-workflow',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent implements OnInit {
    @ViewChild('template') template: ElementRef;
    @Input() limpar;
    @Input() editar;
    @Output() atualizarObjeto: EventEmitter<any> = new EventEmitter();
    textoJson = "";
    modalRef?: BsModalRef;
    config = {
        backdrop: true,
        ignoreBackdropClick: true
    };
    declarar_fluxos: Array<String>;
    node_selected = null;
    sequenciaAcoes = [];

    private chart;
    public validacoes = {};
    public campos = {};
    public variaveis = {};
    public noSelecionadoObjeto = {};

    constructor(private modalService: BsModalService, private workflowService: WorkflowService, private sessaoService: SessaoService) {
        this.declararFluxos();

    }
    declararFluxos() {
        this.declarar_fluxos = ["st=>start: Inicio|approved:$myFunction"];

        this.workflowService.getPlataformas().then(data => {
            data.forEach(fluxos => {
                fluxos.acoes.forEach(acao => {
                    this.declarar_fluxos.push(acao.codigo_interno + '=>operation: ' + this.workflowService.quebrarTituloAcao(`[${fluxos.nome}]` + ' ' + acao.nome) + '|action:$myFunction');
                });
            });
            this.workflowService.getWorkflows().then((w: any) => {
                w.forEach(acao => {
                    this.declarar_fluxos.push(acao.codigo_interno + '=>operation: ' + this.workflowService.quebrarTituloAcao(`[${acao.nome}]`) + '|workflow:$myFunction');
                });
            });
            this.declarar_fluxos.push("e=>end: Fim");
            this.atualizar();
        });
    }
    async atualizarFluxosDeclarados() {
        const fluxos = [];
        const acoes = await this.workflowService.getPlataformas();
        acoes.forEach(a => {
            a.acoes.forEach(acao => {
                fluxos.push(acao.codigo_interno + '=>operation: ' + this.workflowService.quebrarTituloAcao(`[${a.nome}]` + ' ' + acao.nome) + '|action:$myFunction');
            });
        });
        const workflows = await this.workflowService.getWorkflows();
        workflows.forEach(acao => {
            fluxos.push(acao.codigo_interno + '=>operation: ' + this.workflowService.quebrarTituloAcao(`[${acao.nome}]`) + '|workflow:$myFunction');
        });
        if(this.editar){
            const objetos = typeof this.editar.objeto_json == 'string'? JSON.parse(this.editar.objeto_json) : [];
            this.setValidacoesCustonsVariaveis(objetos);
           
        }

        return fluxos;

    }
    private setValidacoesCustonsVariaveis(objetos){
        
        let custom = this.encontrarValidacoesCustonsVariaveis(objetos,TipoAcaoEnum.CUSTOMIZADO);
        let variavel = this.encontrarValidacoesCustonsVariaveis(objetos,TipoAcaoEnum.ACAO);
        let validacoes = this.encontrarValidacoesCustonsVariaveis(objetos,TipoAcaoEnum.VALIDACAO);
        if(validacoes != null && validacoes.length > 0){
            validacoes.forEach(f => {
                this.validacoes[f.codigo_interno] = f.validacoes;
            });   
            this.workflowService.setValidacoes(this.validacoes);        
        }
        if(variavel != null && variavel.length > 0){
            variavel.forEach(f => {
                this.variaveis[f.codigo_interno] = f.variaveis;
            }); 
            this.workflowService.setVariaveis(this.variaveis);            
        }
        if(custom != null && custom.length > 0){
            custom.forEach(f => {
                this.campos[f.codigo_interno] = f.campos;
            });
            this.workflowService.setCampos(this.campos);            
        }
        
    }
    private  encontrarValidacoesCustonsVariaveis(objetos, tipo) {
        const resultado = [];
      
        objetos.filter(s => s.tipo_condicao_id == tipo).forEach(objeto => {

            if(objeto.validacoes && objeto.validacoes.length >0 ){
                resultado.push({
                codigo_interno: objeto.codigo_interno,
                validacoes: objeto.validacoes
                });
            }else if(objeto.campos && objeto.campos.length >0 ){
                resultado.push({
                    codigo_interno: objeto.codigo_interno,
                    campos: objeto.campos
                });
            }else if(objeto.variaveis && objeto.variaveis.length >0 ){
                resultado.push({
                    codigo_interno: objeto.codigo_interno,
                    variaveis: objeto.variaveis
                    });
            }
              
      
          if (objeto.completou && objeto.completou.true) {
            const validacoesCompletou = this.encontrarValidacoesCustonsVariaveis([objeto.completou.true], tipo);
            resultado.push(...validacoesCompletou);
          }
      
          if (objeto.completou && objeto.completou.false) {
            const validacoesCompletou = this.encontrarValidacoesCustonsVariaveis([objeto.completou.false], tipo);
            resultado.push(...validacoesCompletou);
          }
        });
      
        return resultado;
      }
    async ngOnChanges(changes: any) {
        if (this.limpar) {
            this.declararFluxos();
            this.limparChar();
            this.editar = null;
            this.limpar = false;
        }

        if (this.editar) {

            var content = Array.isArray(this.editar.chart_content) ? this.editar.chart_content : String(this.editar.chart_content.replace(/(\r\n|\n|\r)/gm, ""));//wanderson: o update do seeds as vezes cria quebra de linha no banco
            let chart_content = Array.isArray(content) ? content : JSON.parse(content.replaceAll("\\","").replaceAll("\"[","[").replaceAll("]\"","]")); // this.editar.chart_content.toString().replaceAll('[',"").replaceAll(']','').replaceAll("'","").split(",");
            this.declarar_fluxos = chart_content.filter(element => element.search("=>") >= 0);
            // if(this.declarar_fluxos.length ==0){
            //     this.declararFluxos();
            // }

            let fluxos = await this.atualizarFluxosDeclarados();
            if(this.declarar_fluxos.length == 0){
                this.declarar_fluxos.push("st=>start: Inicio|approved:$myFunction");
                this.declarar_fluxos.push("e=>end: Fim");
            }else if(this.declarar_fluxos.every(f => f.search('st=>start:') < 0)){
                this.declarar_fluxos.push("st=>start: Inicio|approved:$myFunction");
                this.declarar_fluxos.push("e=>end: Fim");
            }
            fluxos.forEach(f => {
                if(!f.includes(this.declarar_fluxos)){
                    this.declarar_fluxos.push(f);
                }
            });
            this.sequenciaAcoes = chart_content.filter(element => element.search("=>") < 0);
            this.atualizar();
        }
        this.atualizar();

    }
    ngAfterViewInit(): void {
        window.myFunction = (event, node) => {
            this.node_selected = node;
            this.openModal(this.template);
        }
    }

    adicionarAcao(obj) {


        if(obj.tipo == TipoAcaoEnum.VALIDACAO){
           obj = this.validacao(obj);
        }else if(obj.tipo == TipoAcaoEnum.CUSTOMIZADO){
           obj = this.camposCustomizados(obj);
        }else if(obj.tipo == TipoAcaoEnum.ACAO && obj['regras']){
           this.variaveis[obj.codigo_interno] = obj['regras'];
           this.workflowService.setVariaveis(this.variaveis);
        }
        if(obj.edit && Object.keys(obj.edit).length > 0){
            this.atualizar();
            this.modalRef?.hide();
            return;
        }

        if (this.node_selected.key == 'st') {
            this.sequenciaAcoes.splice(0, 0, obj.codigo_interno);
        } else if (this.node_selected.key.includes('cond-')) {
            let posicao = this.sequenciaAcoes.indexOf(this.node_selected.key) + 1
            let jaTemPosicaoSim = this.sequenciaAcoes.find(f => f == this.node_selected.key + '(yes)');
            if (jaTemPosicaoSim) {
                this.sequenciaAcoes.splice(posicao, 0, this.node_selected.key + '(no)');
            } else {
                this.sequenciaAcoes.splice(posicao, 0, this.node_selected.key + '(yes)');
            }
            this.sequenciaAcoes.splice(posicao + 1, 0, obj.codigo_interno);

        } else {

            let posicao = this.sequenciaAcoes.indexOf(this.node_selected.key) + 1
            this.sequenciaAcoes.splice(posicao, 0, obj.codigo_interno);
        }


        this.atualizar();
        this.modalRef?.hide();
    }

    removerAcao() {
        if (this.node_selected.key == 'st') {
            return;
        }
        let indice = this.sequenciaAcoes.findIndex(f => {
            return f.includes(this.node_selected.key);
        });
        if (indice >= 0) {
            if (this.workflowService.removerNumeros(this.sequenciaAcoes[indice - 1]) == 'cond()') {
                this.sequenciaAcoes.splice(indice - 1, 2);
            } else if (this.workflowService.removerNumeros(this.sequenciaAcoes[indice]) == 'cond') {
                this.sequenciaAcoes.splice(indice, this.sequenciaAcoes.length);
            } else {
                this.sequenciaAcoes.splice(indice,  this.sequenciaAcoes.length);
            }
        }

        this.atualizar();
        this.modalRef?.hide();

    }


    condicao() {
        let indexCondicao = this.declarar_fluxos.filter(x => x.includes('cond-')).length + 1;
        this.declarar_fluxos.push('cond-' + indexCondicao.toString() + '=>condition: Processou?|condition:$myFunction');
        let posicao = this.sequenciaAcoes.indexOf(this.node_selected.key) + 1;
        this.sequenciaAcoes.splice(posicao, 0, 'cond-' + indexCondicao.toString());
        this.atualizar();
        this.modalRef?.hide();
    }
    validacao(regras){
        if(regras.edit && Object.keys(regras.edit).length > 0){
            this.validacoes[regras.edit.codigo_interno] = regras.edit.validacoes;   
            this.workflowService.setValidacoes(this.validacoes);
            return {codigo_interno: regras.edit.codigo_interno, tipo:TipoAcaoEnum.VALIDACAO, edit: regras.edit};
        }

        let indexCondicao = this.declarar_fluxos.filter(x => x.includes('validacao-')).length + 1;
        let codigo_interno = 'validacao-' + indexCondicao.toString();
        this.declarar_fluxos.push( codigo_interno+ '=>operation: Critérios de Aceite|validacao:$myFunction');
        this.validacoes[codigo_interno] = regras.regras;    
        this.workflowService.setValidacoes(this.validacoes);
        return {codigo_interno: codigo_interno, tipo:TipoAcaoEnum.VALIDACAO};
       // this.adicionarAcao(obj);

    }
    camposCustomizados(campos){
        if(campos.edit && Object.keys(campos.edit).length > 0){
            this.campos[campos.edit.codigo_interno] = campos.edit.campos;   
            this.workflowService.setCampos(this.campos);
            return {codigo_interno: campos.edit.codigo_interno, tipo:TipoAcaoEnum.CUSTOMIZADO, edit: campos.edit};
        }
        let indexCondicao = this.declarar_fluxos.filter(x => x.includes('custom-')).length + 1;
        let codigo_interno = 'custom-' + indexCondicao.toString();
        this.declarar_fluxos.push(codigo_interno+ '=>operation: Campos de Entrada|custom:$myFunction');
        this.campos[codigo_interno] = campos.campos;
        this.workflowService.setCampos(this.campos);
        return {codigo_interno: codigo_interno, tipo:TipoAcaoEnum.CUSTOMIZADO};
    }


    ngOnInit(): void { this.atualizar();}


    treino(){
         let teste = ["INICIAR_MAPEAMENTO_CAMPOS_LEAD",
         "cond-1",
         "cond-1(no)",
         "FINALIZAR_APENAS_FLUXO_ATUAL",
         "cond-1(yes)",
         "MANTER_CADASTRO_PF",
         "cond-2",
         "cond-2(no)",
         "RECUPERAR_PF_COMPARA_NOME_VINCULOS_PJ",
         "MANTER_CADASTRO_PJ",
         "cond-2(yes)",
         "MANTER_CADASTRO_PJ",
         "cond-3",
         "cond-3(no)",
         "REFERENCIAR_OUTRA_PJ_SAS",
         "FINALIZAR_APENAS_FLUXO_ATUAL",
         "cond-3(yes)",
         "MANTER_VINCULO_ATIVO_PF_PJ",
         "cond-5",
         "cond-5(no)",
         "REFERENCIAR_OUTRA_PJ_SAS",
         "SETAR_ACAO_PARA_SUCESSO",
         "FINALIZAR_APENAS_FLUXO_ATUAL",
         "cond-5(yes)",
         "FINALIZAR_APENAS_FLUXO_ATUAL"].filter(element => element.search("=>") < 0);
         let sequencia = this.workflowService.montarSequencia(teste);
         this.workflowService.convertToJson(sequencia).then(f => {
            console.log(f);
         });
        // this.workflowService.convertToJson(sequencia.filter(f => f != null)).then(t => {
        //     this.textoJson = t;
        // });
    }
    findAndReplaceInList(list, searchString, replaceString) {
        let acoes = this.sessaoService.get('getAcoesWorkflow');
        const lowerAcoes = acoes == null ? [] : acoes.filter(f => f.entradas_json != null && f.entradas_json != "").map((acao) => acao.codigo_interno);

        const updatedList = list.map((item) => {        
            if (lowerAcoes.some((acao) => item.includes(acao))) {
                return item.replace(searchString, replaceString);
            }
            return item;
        });

        return updatedList;
    }
    // findAndReplaceInList(list, searchString, replaceString) {
    //     let acoes = this.sessaoService.get('getAcoesWorkflow');
    //     const updatedList = list.map((item) => {
    //     if (acoes.some((acao) => item.includes(acao))) {
    //         return item.replace(searchString, replaceString);
    //       }
    //       return item;
    //     });
      
    //     return updatedList;
    //   }
    atualizar() {
        let sequencia = this.workflowService.montarSequencia(this.sequenciaAcoes);
        //console.log(sequencia);
        let content = this.declarar_fluxos.concat(sequencia).filter(element => {
            return element !== undefined && this.workflowService.removerNumeros(element) != 'cond->cond()';
        });
        let content2 = this.findAndReplaceInList(content, "|action:$myFunction", "|variavel:$myFunction") ;

        if (this.chart) {
            this.chart.clean();
        }

        this.chart = flowchart.parse(content2.join('\n'));
        this.workflowService.convertToJson(sequencia.filter(f => f != null)).then(t => {
            this.textoJson = t;
            this.atualizarObjeto.emit({ chart_content: this.declarar_fluxos.concat(this.sequenciaAcoes), acoes: this.textoJson });
        });

        this.chart.drawSVG('canvas',
            {
                //  'x': 30,
                //  'y': 50,
                'line-width': 2,

                'text-margin': 10,
                'font-size': 12,
                'font': 'normal',
                'font-family': 'Helvetica',
                'font-weight': 'normal',
                'font-color': 'black',
                'line-color': 'black',
                'element-color': 'black',
                'fill': 'white',
                'yes-text': 'sim',
                'no-text': 'não',
                'stroke-width':6,
                'arrow-end':'long',
                'scale': 1,
                'symbols': {
                    'start': {
                        'class': 'end-element',
                        'font-color': '#000',
                        'element-color': '#f0fff0',
                        'fill': '#b6d3a8'
                    },
                    'end': {
                        'class': 'end-element',
                        'font-color': '#000',
                        'element-color': '#D8BFD8',
                        'fill': '#D8BFD8'
                    }
                },
                'flowstate': {

                    'action': {
                        'font-size': 12,
                        'font-color': '#000',
                        'element-color': '#FFF0F5',
                        'fill': '#E6E6FA'
                    },
                    'workflow': {
                        'font-size': 12,
                        'font-color': '#000',
                        'element-color': '#90ee90',
                        'fill': '#bdecb6',
                        'text-align': 'justify'
                    },
                    'condition': {
                        'fill': '#f5deb3',
                        'element-color':"#f5deb3"
                    },
                    'validacao': {
                        'element-color': '#ff9e8e',
                        'fill': '#ff9e8e',
                    },
                    'custom': {
                        'fill': '#fff2bc',
                        'element-color':"#fff2bc"
                    },
                    'variavel': {
                        'fill': '#D8BFD8',
                        'element-color':"#D8BFD8"
                    }

                }
            });


    }


    openModal(template: any) {
        let listaAcoesJson = [];
        if(this.editar != null){
             listaAcoesJson = JSON.parse(this.editar.objeto_json);
        }else if((this.textoJson != null && JSON.parse(this.textoJson).length >= 0)){
             listaAcoesJson = JSON.parse(this.textoJson);
        }
        this.noSelecionadoObjeto =  listaAcoesJson.find(f => this.node_selected.key == f.codigo_interno);
        this.config['class'] = 'modal-xl';
        this.config['scrollable'] = true;
        this.modalRef = this.modalService.show(template, this.config);
    }
    limparChar() {
        this.node_selected = null;
        this.sequenciaAcoes = [];

        this.validacoes = {};
        this.campos = {};
        this.variaveis = {};
        this.noSelecionadoObjeto = {};

        this.atualizar();
    }

}
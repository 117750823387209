import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { SessaoService } from './sessao.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { KeycloakService } from 'keycloak-angular';


@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {

  constructor(private ngxService: NgxUiLoaderService, private toastr: ToastrService, private sessao: SessaoService, private router: Router, private readonly keycloak: KeycloakService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let loaderId = this.sessao.getLoaderId() ? this.sessao.getLoaderId() : 'loader-default';

    if(!req.url.includes("Processos/getAll.json") && !req.url.includes("dashboard.json") && !req.url.includes("getQueues.json") && !req.url.includes("/metabase/api/session")){
      this.ngxService.startLoader(loaderId, req.url);
    }

    const url = req.url.includes('http') ? req.url : environment.environment.urlBase + req.url;
    const api_key = this.sessao.get('Login') ? this.sessao.get('Login').api_key : '';
    const cloneReq = req.clone({
      url: url,
      headers: req.headers.set('Authorization', `Basic ${api_key}`)
    });

    return next
      .handle(cloneReq)
      .pipe(tap(event => {
        if (event.type > 0) {
          this.ngxService.stopLoader(loaderId, req.url);
        }
      }, e => {
        this.ngxService.stopLoader(loaderId, req.url);
        if (e instanceof HttpErrorResponse) {
          if (e.status === 401) {
            this.sessao.limparSessao();
            this.toastr.warning(e.error.message);
            if(environment.environment.autenticarPorAmei){
              this.keycloak.logout();
            } else {
              this.router.navigate(['/login']);
            }
          }else if (e.status === 400) {
            let url : string = e.error.url ? e.error.url : '';
            let messsage : string = e.error.message ? e.error.message + ' '+url : '';
            if(!messsage){
              messsage = e.error[0] && e.error[0].Descricao ? e.error[0].Descricao : ''; //padrão SAS
            }
            this.toastr.warning('Não foi possível realizar essa operação. '+messsage);

          } else {
            let url : string = e.error.url ? e.error.url : '';
            let messsage : string = e.error.message ? e.error.message + ' '+url : '';
            if(!messsage){
              messsage = e.error[0] && e.error[0].Descricao ? e.error[0].Descricao : ''; //padrão SAS
            }
            this.toastr.error(messsage ? messsage : 'Falha ao processar, por favor verifique!');
          }
        }
      }));

  }
}

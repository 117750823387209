import { WorkflowService } from './../../../services/workflow.service';
import { PlataformaService } from './../../../services/plataforma.service';
import { PlataformaEventoSaidaService } from './../../../services/plataforma-evento-saida.service';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TipoAcaoEnum } from '../../../enum/TipoAcaoEnum';


@Component({
    selector: 'app-workflow-acoes',
    templateUrl: './workflow-acoes.component.html',
    styleUrls: ['./workflow-acoes.component.css'],
    providers: [NgbAccordionConfig]
})
export class WorkflowAcoesComponent implements OnInit {
    @Output() addAcao: EventEmitter<any> = new EventEmitter();
    @Input() nodeSelecionado = null;
    active: any = null;
    active2: any = null;
    plataformas: any;
    acoes: any;
    workflows: any;
    buscar = {workflow:'', plataforma: '', acao:''};
    mostrarVariavies = false;
    acao = null;
    acaoEdit = { 1: {}, 3: {}, 4: {} };
    

    constructor(config: NgbAccordionConfig, private plataformaEventoSaidaService: PlataformaEventoSaidaService, private plataformaService:PlataformaService, private workflowService:WorkflowService ) {
        // customize default values of accordions used by this component tree
        config.closeOthers = true;
        config.type = 'info';
        this.plataformaEventoSaidaService.listarTodosEventoSaidas().then(a => {
            let evSaidas: any = a;
            let eventoSaidas: any = [];
            evSaidas.forEach(i => {
                if(i.metodo){
                    eventoSaidas.push(i);
                }
            });
            this.acoes = eventoSaidas;

            this.plataformaService.listarTodasPlataformas().then(p => {
                this.plataformas =p;
                this.plataformas = this.plataformas.filter(f => this.acoes.map(m => m.plataforma_id).some(e => e === f.id));
              //  this.active = this.plataformas[0].id;
            });
        });

    }
    ngOnChanges(changes: any) {
        if(this.nodeSelecionado){
            this.mostrarVariavies = false;
            this.active = null;
            this.active2 = null;
            if(this.nodeSelecionado.tipo_condicao_id == TipoAcaoEnum.ACAO && this.nodeSelecionado.variaveis && this.nodeSelecionado.variaveis.length > 0){
                this.mostrarVariavies = true;
                this.active = 1;
                if(this.acao == null){
                    this.acao = this.nodeSelecionado;
                }
               
            }else  if(this.nodeSelecionado.tipo_condicao_id == TipoAcaoEnum.CUSTOMIZADO){
                this.active2 = "customizados";
                
            }else if(this.nodeSelecionado.tipo_condicao_id == TipoAcaoEnum.VALIDACAO){
                this.active2 = "validacao";
            }
           this.acaoEdit[this.nodeSelecionado.tipo_condicao_id] = this.nodeSelecionado;    
            console.log(this.nodeSelecionado);
        }
        
    }

    ngOnInit(): void {
         this.workflowService.getWorkflows({ativo: 1}).then(w => this.workflows = w);
        //  this.mostrarVariavies = false;
        //  this.acao = null;
    }
    //TODO: saber quall é o objeto que vai vir as acoes
    checkAcao(acao) {
        
        //Limpar o campo seleção, pq vai adicionar um novo
        // if(this.nodeSelecionado != null && this.nodeSelecionado.codigo_interno != acao.codigo_interno){
        //     this.nodeSelecionado = null;
        // }
        //acao.variaveis = [{campo: 'variavel1'}, {campo: 'variavel2'},{campo: 'variavel3'}];
        if((acao.entradas_json == null || acao.entradas_json == "")){
            this.mostrarVariavies = false;
            this.adicionarAcao(acao.codigo_interno,TipoAcaoEnum.ACAO);
            
        }else{
            this.mostrarVariavies = true
            acao.entradas_json  = JSON.parse(acao.entradas_json);
            this.acao = acao;
        }
    }

    adicionarAcao(slug, tipo) {
        this.addAcao.emit({codigo_interno:slug, tipo: tipo});        
    }
    addVariaveis(regras) {
        if(regras.edit && Object.keys(regras.edit).length > 0 && regras.edit.codigo_interno == this.acao?.codigo_interno){
            let variaveis = regras.campos;
            if(regras.edit.variaveis != null){
                variaveis = regras.edit.variaveis;
            }
            this.addAcao.emit({codigo_interno: regras.edit.codigo_interno, tipo: TipoAcaoEnum.ACAO, edit:regras.campos, regras: variaveis});
        }else{
            this.addAcao.emit({codigo_interno: this.acao.codigo_interno, tipo: TipoAcaoEnum.ACAO, regras: regras.campos , edit: null});
        }
        this.mostrarVariavies = false;
        this.acao = null;
    }
    addValidacao(regras) {
        if(regras.edit && Object.keys(regras.edit).length > 0){
            this.addAcao.emit({codigo_interno: regras.edit.codigo_interno, tipo: TipoAcaoEnum.VALIDACAO, regras: regras.edit.regras, edit: regras.edit});
        }else{
            this.addAcao.emit({tipo: TipoAcaoEnum.VALIDACAO, regras: regras.regras, edit: regras.edit});
        }
        
    }
    addCustom(regras) {
        if(regras.edit && Object.keys(regras.edit).length > 0){
            this.addAcao.emit({codigo_interno: regras.edit.codigo_interno, tipo: TipoAcaoEnum.CUSTOMIZADO, campos: regras.edit.campos, edit: regras.edit});
        }else{
            this.addAcao.emit({tipo: TipoAcaoEnum.CUSTOMIZADO, campos: regras.campos, edit: regras.edit});
        }
    }


}

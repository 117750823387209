import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetabaseService {

  constructor(private http: HttpClient) { }

  get(params) {
    return this.http.get<Metabases>(environment.environment.nomeServiceIntegra + 'metabases/getAll.json', {params : params});
  }

  getKeyUserLeitura() {
    return this.http.get(environment.environment.nomeServiceIntegra + 'metabases/getKeyUserLeitura.json');
  }

  login(params) {
    return this.http.post<any>(environment.environment.metabase + 'api/session', params);
  }

  logout() {
    return this.http.delete(environment.environment.metabase + 'api/session');
  }

}

export class Metabases{
  TotalItems: number;
  Metabases: object;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConteudoTipoService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  get(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'ConteudoTipos/getAll.json', { params: params });
  }

  getId(id) {
    return this.http.get(environment.environment.nomeServiceIntegra + `ConteudoTipos/getId/${id}.json`);
  }

  salvar(params) {
    this.sessaoService.delete('ServiceTiposConteudo');
    return this.http.post(environment.environment.nomeServiceIntegra + 'ConteudoTipos/postSave.json', { ConteudoTipos: params });
  }  

  listarTodosTiposConteudo() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceTiposConteudo')) {
        resolve(this.sessaoService.get('ServiceTiposConteudo'));
      } else {
        this.http.get(environment.environment.nomeServiceIntegra + 'ConteudoTipos/getAll.json')
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceTiposConteudo', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

}
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { GrupoService } from '../../../../services/grupo.service';
import { UsuarioService } from '../../../../services/usuario.service';
import { ConfirmService } from '../../../../shared/confirm-modal/confirm-modal-and-service';
import { environment } from '../../../../../environments/environment';
import { CustomServiceValidateService } from '../../../../shared/control-messages/custom-service-validate.service';
import {ProjetoService} from "../../../../services/projeto.service";


@Component({
  selector: 'app-usuario-grupo-form',
  templateUrl: './usuario-grupo-form.component.html',
  styleUrls: ['./usuario-grupo-form.component.scss']
})
export class UsuarioGrupoFormComponent implements OnInit {

  form: FormGroup;
  grupos: any;
  projetoId: any = null;
  @Input() usuarioGrupos: any;

  constructor(private fb: FormBuilder
    , private grupoService: GrupoService
    , private usuarioService: UsuarioService
    , private toastr: ToastrService
    , private route: ActivatedRoute
      , private projetoService: ProjetoService

      , private confirmService: ConfirmService) {
  }

  async ngOnInit() {
      this.initForm();

      await this.projetoService.getPorNome(environment.environment.projeto).then(data => {
          this.projetoId = data['id'];
      });
      this.grupos = await this.grupoService.get({ativo : 1, projeto_id: this.projetoId}).toPromise();
  }

  initForm() {
    this.form = this.fb.group({
      usuario_id: [this.route.snapshot.params.id, Validators.required],
      grupo_id: [null, Validators.required],
    });
  }

  vincularGrupo() {
    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
      return false;
    }

    this.usuarioService.vincularGrupoUsuario(this.form.value).subscribe(response => {
      this.toastr.success('Grupo vinculado com sucesso.');
      this.initForm();
      this.getUsuarioGrupos(this.route.snapshot.params.id);
    }, e => {
      CustomServiceValidateService.customMensageService(e.error, this.form);
    });
  }

  getUsuarioGrupos(usuario_id: number) {
    this.usuarioService.getId(usuario_id).toPromise().then(response => {
      this.usuarioGrupos = response.usuario_grupos;
    });
  }

  desvincularGrupo(ug) {
    this.confirmService.confirm({
      title: 'Desvincular', message: 'Você tem certeza que deseja desvincular o grupo ' + ug.grupo.nome + ' do usuário?'
    }).then(
      () => {
        this.usuarioService.desvincularGrupoUsuario({
          usuario_id : ug.usuario_id,
          grupo_id: ug.grupo_id
       }).subscribe(response => {
          this.getUsuarioGrupos(this.route.snapshot.params.id);
          this.toastr.success('Grupo desvinculado com sucesso.');
        });
      },
      () => { });
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TipoAtividadeFormComponent } from './tipo-atividade-form/tipo-atividade-form.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Tipo de Atividades'
    },
    children: [
      {
        path: 'form/:id',
        component: TipoAtividadeFormComponent,
        data: {
          title: 'Cadastro de Tipo de Atividades'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TipoAtividadesRoutingModule { }

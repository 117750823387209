declare var window: any;

export class DynamicEnvironment {
    public get environment(): Environment {
        return window.config.environment;
    }
}
export class Environment{
        production: boolean;
        urlBase: string;
        projeto: string;
        versao : string;
        dataLiberacao : string;
        autenticarPorAmei: boolean;
        uf: string;
        prefixStorage: string;
        nomeServiceApi: string;
        nomeServiceIntegra:string;
        nomeServiceSas: string;
        nomeServiceCheckCadastro: string;
        nomeServiceEmail: string;
        nomeServiceCadCli: string;
        sse: string;
        metabase: string;
        KEYCLOAK_URL: string;
        KEYCLOAK_REALM: string;
        KEYCLOAK_CLIENT_ID: string;


}

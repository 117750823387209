import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class CampanhaFormSaveService {
    private saveFormSubject = new Subject<string>();

    // Observable que os componentes filhos vão escutar
    saveForm$ = this.saveFormSubject.asObservable();

    // Método para acionar o salvamento de um componente específico
    triggerSaveForComponent(viewChild: string) {
      this.saveFormSubject.next(viewChild);
    }
}

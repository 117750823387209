import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CamposComponent } from './campos.component';
import { CampoFormComponent } from './campo-form/campo-form.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ControlMessagesModule } from '../../shared/control-messages/control-messages.module';
import { ConfirmModalModule } from '../../shared/confirm-modal/confirm-modal.module';
import { CamposListasComponent } from './campos-listas/campos-listas.component';
import { CamposRoutingModule } from './campos-routing.module';
import { OrderModule } from 'ngx-order-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    CamposRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ControlMessagesModule,
    ConfirmModalModule,
    FilterPipeModule,
    OrderModule,
    NgSelectModule
  ],
  declarations: [CamposComponent, CampoFormComponent, CamposListasComponent]
})
export class CamposModule { }

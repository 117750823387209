<div class="animated fadeIn">

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-paperclip"></i>
          Alteração da Parametrização do <strong>Campo - {{form.controls.nome.value}}</strong>

          <button type="button" class="btn btn-success pull-right" (click)="salvar()">
            <i class="fa fa-check"></i>
            Salvar
          </button>
        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="name">Tipo do Campo:</label>
                  <div class="input-group">
                    <input class="form-control" type="text" name="tipo" formControlName="tipo" disabled>
                  </div>
                  <app-control-messages [control]="form.controls.tipo"></app-control-messages>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="name">Nome Integra:</label>
                  <div class="input-group">
                    <input class="form-control" type="text" name="nome" formControlName="nome" disabled>
                  </div>
                  <app-control-messages [control]="form.controls.nome"></app-control-messages>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">Descrição do campo:</label>
                  <div class="input-group">
                    <textarea class="form-control" name="descricao" formControlName="descricao" rows="1"></textarea>
                  </div>
                  <app-control-messages [control]="form.controls.descricao"></app-control-messages>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="name">Obrigatório no SAS:</label>
                  <div class="input-group">
                    <label class="switch switch-label switch-pill switch-primary">
                      <input type="checkbox" class="switch-input" checked value="1" formControlName="obrigatorio">
                      <span class="switch-slider" data-checked="Sim" data-unchecked="Não"></span>
                    </label>
                  </div>
                  <app-control-messages [control]="form.controls.obrigatorio"></app-control-messages>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="name">Valor Padrão global:</label>
                  <div class="input-group">
                    <input class="form-control" type="text" name="valor_padrao" formControlName="valor_padrao">
                  </div>
                  <app-control-messages [control]="form.controls.valor_padrao"></app-control-messages>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="name">Exemplo:</label>
                  <div class="input-group">
                    <input class="form-control" type="text" name="exemplo" formControlName="exemplo" />
                  </div>
                  <app-control-messages [control]="form.controls.exemplo"></app-control-messages>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
          </form>

          <div class="row">
            <div class="col-md-12">
              <h5>Integrações que utiliza este campo:</h5>
              <table class="table">
                <tbody>
                  <tr>
                    <th>Integrador</th>
                    <th>Nome do campo</th>
                    <th>Valor Padrão</th>
                  </tr>
                  <tr *ngFor="let p of plataformaCampos; let i = index">
                    <td>{{p?.plataforma?.nome}}</td>
                    <td>{{p?.nome}}</td>
                    <td>{{p?.valor_padrao}}</td>
                  </tr>
                  <tr *ngIf="plataformaCampos && plataformaCampos.length == 0">
                    <td colspan="3">Esse campo não está sendo utilizado por nenhuma integração</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <app-campos-listas [camposListas]="campos_listas.value" *ngIf="form.value.id"></app-campos-listas>

</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  getInstrumentos(params) {
    return this.http.get(environment.environment.nomeServiceSas + 'Instrumentos/consultarInstrumento.json', { params: params });
  }

  listarTodosInstrumentos() {
    let params = { Ativo: "S" };

    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('SasInstrumentosAtivos')) {
        resolve(this.sessaoService.get('SasInstrumentosAtivos'));
      } else {
        this.http.get(environment.environment.nomeServiceSas + 'Instrumentos/consultarInstrumento.json', { params: params })
          .toPromise().then((res: any) => {
            this.sessaoService.set('SasInstrumentosAtivos', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }
}

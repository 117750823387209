import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class PlataformaService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  listarTodasPlataformas() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServicePlataformas')) {
        resolve(this.sessaoService.get('ServicePlataformas'));
      } else {
        let params : any = { ativo: 1 };
        this.http.get(environment.environment.nomeServiceIntegra + 'Plataformas/getAll.json', { params: params })
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServicePlataformas', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

  get() {
    return this.http.get(environment.environment.nomeServiceIntegra + 'Plataformas/getAll.json');
  }

  getId(id) {
    return this.http.get<Plataforma>(environment.environment.nomeServiceIntegra + `Plataformas/getId/${id}.json`);
  }

  salvar(params) {
    this.sessaoService.delete('ServicePlataformas');
    this.sessaoService.delete('ServiceTodosEventoSaidas');
    return this.http.post(environment.environment.nomeServiceIntegra + 'Plataformas/postSave.json', { Plataformas: params });
  }

  postRequisicaoExterna(params) {
    return this.http.post(environment.environment.nomeServiceIntegra + 'Plataformas/postRequisicaoExterna.json', params);
  }

}

export class Plataforma {
  id: number;
  nome: string;
  descricao: string;
  ativo: number;
}

<div>
    <form class="form-horizontal">
        <div class="row">
            <div class="col-md-5">&nbsp;</div>
            <div class="col-md-2">
                <div class="form-group">
                    <div class="input-group">
                        <input class="form-control" type="text" ngbDatepicker #d="ngbDatepicker" name="data_inicial" [(ngModel)]="data_inicial">
                        <div class="input-group-append">
                            <span class="input-group-text" (click)="d.toggle()" style="cursor: pointer;">
                                <i class="fa fa-calendar"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <div class="input-group">
                        <input class="form-control" type="text" ngbDatepicker #df="ngbDatepicker" name="data_final" [(ngModel)]="data_final">
                        <div class="input-group-append">
                            <span class="input-group-text" (click)="df.toggle()" style="cursor: pointer;">
                                <i class="fa fa-calendar"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 text-right">
                <small class="text-muted">{{msgCarregando}}</small>
                <button type="button" class="btn btn-primary" (click)="atualizar()">
                    <i class="fa fa-search"></i>
                    Atualizar
                </button>
            </div>
        </div>
        <div class="clearfix"></div>
    </form>
</div>

<div class="animated fadeIn">
    <div class="row">
        <div class="col-sm-4 col-lg-2">
            <div class="card text-white bg-warning card-link">
                <div tooltip="Clique para visualizar os leads deste status" class="card-body pb-0" (click)="setFilter({status:'Aguardando'})">
                    <div class="text-value"><strong>{{dashData?.geral?.PF?.Aguardando?.total + dashData?.geral?.PJ?.Aguardando?.total || 0}}</strong></div>
                    <div><strong>Leads Aguardando</strong></div>
                </div>
                <div class="chart-wrapper mt-3 mx-3 card-link" style="height:30px;">
                    <div class="text-center"><strong><span tooltip="Clique para visualizar os leads PF" (click)="setFilter({status:'Aguardando', tipo_cliente:'PF'})">PF: {{dashData?.geral?.PF?.Aguardando?.total || 0}}</span> | <span tooltip="Clique para visualizar os leads PJ" (click)="setFilter({status:'Aguardando', tipo_cliente:'PJ'})">PJ: {{dashData?.geral?.PJ?.Aguardando?.total || 0}}</span></strong></div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-lg-2">
            <div class="card text-white bg-danger card-link">
                <div tooltip="Clique para visualizar os leads deste status" class="card-body pb-0" (click)="setFilter({status:'Falha'})">
                    <div class="text-value"><strong>{{dashData?.geral?.PF?.Falha?.total + dashData?.geral?.PJ?.Falha?.total || 0}}</strong></div>
                    <div><strong>Leads Falha</strong></div>
                </div>
                <div class="chart-wrapper mt-3" style="height:30px;">
                    <div class="text-center">
                        <strong><span tooltip="Clique para visualizar os leads PF" (click)="setFilter({status:'Falha', tipo_cliente:'PF'})">PF: {{dashData?.geral?.PF?.Falha?.total|| 0}}</span>  | <span tooltip="Clique para visualizar os leads PJ" (click)="setFilter({status:'Falha', tipo_cliente:'PJ'})">PJ: {{dashData?.geral?.PJ?.Falha?.total || 0}}</span></strong>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-lg-2">
            <div class="card text-white bg-success card-link">
                <div tooltip="Clique para visualizar os leads deste status" class="card-body pb-0" (click)="setFilter({status:'Sucesso'})">
                    <div class="text-value"><strong>{{dashData?.geral?.PF?.Sucesso?.total + dashData?.geral?.PJ?.Sucesso?.total || 0}}</strong></div>
                    <div><strong>Leads Sucesso</strong></div>
                </div>
                <div class="chart-wrapper mt-3" style="height:30px;">
                    <div class="text-center"><strong><span tooltip="Clique para visualizar os leads PF" (click)="setFilter({status:'Sucesso', tipo_cliente:'PF'})">PF: {{dashData?.geral?.PF?.Sucesso?.total || 0}}</span> | <span tooltip="Clique para visualizar os leads PJ" (click)="setFilter({status:'Sucesso', tipo_cliente:'PJ'})">PJ: {{dashData?.geral?.PJ?.Sucesso?.total || 0}}</span></strong></div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-lg-2">
            <div class="card text-white bg-primary card-link">
                <div tooltip="Clique para visualizar os leads deste status" class="card-body pb-0" (click)="setFilter({status: null})">
                    <div class="text-value"><strong>{{dashData?.geral?.PF?.Aguardando?.total + dashData?.geral?.PF?.Falha?.total + dashData?.geral?.PF?.Sucesso?.total + dashData?.geral?.PJ?.Aguardando?.total + dashData?.geral?.PJ?.Falha?.total + dashData?.geral?.PJ?.Sucesso?.total || 0}}</strong></div>
                    <div><strong>Leads Total</strong></div>
                </div>
                <div class="chart-wrapper mt-3" style="height:30px;">
                    <div class="text-center"><strong><span tooltip="Clique para visualizar os leads PF" (click)="setFilter({status:null, tipo_cliente:'PF'})">PF: {{dashData?.geral?.PF?.Aguardando?.total + dashData?.geral?.PF?.Falha?.total + dashData?.geral?.PF?.Sucesso?.total || 0}}</span> | <span tooltip="Clique para visualizar os leads PJ" (click)="setFilter({status:null, tipo_cliente:'PJ'})">PJ: {{dashData?.geral?.PJ?.Aguardando?.total + dashData?.geral?.PJ?.Falha?.total + dashData?.geral?.PJ?.Sucesso?.total || 0}}</span></strong></div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-lg-4">
            <div class="card text-white bg-info card-link">
                <div tooltip="Clique para visualizar os leads deste status" class="card-body pb-0" (click)="setFilter({atendimento:true})">
                    <div class="text-value"><strong>{{dashData?.atendimentos?.PF?.total + dashData?.atendimentos?.PJ?.total || 0}}</strong></div>
                    <div><strong>Total de atendimentos gerados</strong></div>
                </div>
                <div class="chart-wrapper mt-3" style="height:30px;">
                    <div class="text-center"><strong><span tooltip="Clique para visualizar os leads PF" (click)="setFilter({atendimento:true, tipo_cliente:'PF'})">PF: {{dashData?.atendimentos?.PF?.total || 0}}</span> | <span tooltip="Clique para visualizar os leads PJ" (click)="setFilter({atendimento:true, tipo_cliente:'PJ'})">PJ: {{dashData?.atendimentos?.PJ?.total || 0}}</span></strong></div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-4 col-lg-3" *ngFor="let item of plataformas">
            <div class="brand-card callout callout-info">
                <div tooltip="Clique para visualizar os leads deste status" class="card-body pb-0 card-link" (click)="setFilter({plataforma_id: item.plataforma_id})">
                    <div class="text-value text-primary"><strong>{{somar([item?.Aguardando?.total || 0, item?.Falha?.total || 0, item?.Sucesso?.total ])}}</strong></div>
                    <div class="text-info"><strong>{{item.plataforma}}</strong></div>
                </div>
                <div class="brand-card-body">
                    <div tooltip="Clique para visualizar os leads deste status" class="text-warning card-link" (click)="setFilter({plataforma_id: item.plataforma_id, status: 'Aguardando'})">
                        <div class="text-value">{{item?.Aguardando?.total || 0 }}</div>
                        <div class="text-uppercase text-muted small">Aguardando</div>
                    </div>
                    <div tooltip="Clique para visualizar os leads deste status" class="text-danger card-link" (click)="setFilter({plataforma_id: item.plataforma_id, status: 'Falha'})">
                        <div class="text-value">{{item?.Falha?.total || 0 }}</div>
                        <div class="text-uppercase text-muted small">Falha</div>
                    </div>
                    <div tooltip="Clique para visualizar os leads deste status" class="text-success card-link" (click)="setFilter({plataforma_id: item.plataforma_id, status: 'Sucesso'})">
                        <div class="text-value">{{item?.Sucesso?.total || 0 }}</div>
                        <div class="text-uppercase text-muted small">Sucesso</div>
                    </div>
                    <div tooltip="Clique para visualizar os leads deste status" class="text-info card-link" (click)="setFilter({plataforma_id: item.plataforma_id, status: null, atendimento: true})">
                        <div class="text-value">{{item?.Aguardando?.atendimentos + item?.Falha?.atendimentos + item?.Sucesso?.atendimentos || 0 }}</div>
                        <div class="text-uppercase text-muted small">Atendimentos</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="card card-integrador">
        <div class="card-body">
            <h4 class="card-title mb-0">Integradores e Campanhas/Eventos</h4>
            <small class="text-muted">Indicadores das campanhas ativas</small>
            <hr />
            <div class="row">
                <div class="col-sm-4"></div>
                <div class="col-sm-8"></div>
            </div>
            <table class="table table-responsive-sm table-hover table-outline mb-0">
                <thead class="thead-light">
                    <tr>
                        <th class="card-link">
                            <span tooltip="Clique para ordenar" [class.active]="order === 'plataforma'" (click)="setOrder('plataforma')">Nome do Integrador <span [hidden]="reverse">▼</span><span [hidden]="!reverse">▲</span></span>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <span tooltip="Clique para ordenar" [class.active]="order === 'identificador'" (click)="setOrder('identificador')">Campanha<span [hidden]="reverse">▼</span><span [hidden]="!reverse">▲</span></span>
                        </th>
                        <th nowrap>
                            <div class="brand-card-body">
                                <div tooltip="Clique para ordenar" class="text-warning card-link">
                                    <span [class.active]="order === 'Aguardando.total'" (click)="setOrder('Aguardando.total')">
                                        <div class="text-uppercase text-muted small"><strong>Aguardando</strong><span [hidden]="reverse">▼</span><span [hidden]="!reverse">▲</span></div>
                                    </span>
                                </div>
                                <div tooltip="Clique para ordenar" class="text-danger card-link">
                                    <span [class.active]="order === 'Falha.total'" (click)="setOrder('Falha.total')">
                                        <div class="text-uppercase text-muted small"><strong>Falha</strong><span [hidden]="reverse">▼</span><span [hidden]="!reverse">▲</span></div>
                                    </span>
                                </div>
                                <div class="text-success card-link">
                                    <span tooltip="Clique para ordenar" [class.active]="order === 'Sucesso.total'" (click)="setOrder('Sucesso.total')">
                                        <div class="text-uppercase text-muted small"><strong>Sucesso</strong><span [hidden]="reverse">▼</span><span [hidden]="!reverse">▲</span></div>
                                    </span>
                                </div>
                                <div tooltip="Clique para ordenar" class="text-info card-link">
                                    <span [class.active]="order === 'atendimentos'" (click)="setOrder('atendimentos')">
                                        <div class="text-uppercase text-muted small"><strong>Atendimentos</strong><span [hidden]="reverse">▼</span><span [hidden]="!reverse">▲</span></div>
                                    </span>
                                </div>
                                <div tooltip="Clique para ordenar" class="text-primary card-link">
                                    <span><div class="text-uppercase text-muted small"><strong>Total</strong></div></span>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="identificadores">
                    <tr *ngFor="let item of identificadores | orderBy:order:reverse:false">
                        <td tooltip="Clique para visualizar os leads" class="card-link" (click)="setFilter({plataforma_id: item.plataforma_id, identificador: item.identificador})">
                            <strong>{{item.identificador}}</strong>
                            <div>
                                <small class="text-muted">
                                    <span class="info-label">Integrador:</span> {{item?.plataforma}} |
                                    <span class="info-label">Tipo Campanha:</span> {{item?.campanha_tipo_nome}}
                                </small>
                            </div>
                            <div>
                                <small class="text-muted">
                                    <span class="info-label">Responsável:</span> {{item?.responsavel_nome}} |
                                    <span class="info-label">Tipo Atividade:</span> {{item?.conteudo_tipo_nome}}
                                </small>
                            </div>
                        </td>
                        <td nowrap>
                            <div class="brand-card-body">
                                <div tooltip="Clique para visualizar os leads deste status" class="text-warning card-link" (click)="setFilter({plataforma_id: item.plataforma_id,identificador: item.identificador, status: 'Aguardando'})">
                                    <div class="text-value">{{item?.Aguardando?.total || 0}}</div>
                                    <div class="text-uppercase text-muted small">Aguardando</div>
                                </div>
                                <div tooltip="Clique para visualizar os leads deste status" class="text-danger card-link" (click)="setFilter({plataforma_id: item.plataforma_id,identificador: item.identificador, status: 'Falha'})">
                                    <div class="text-value">{{item?.Falha?.total || 0}}</div>
                                    <div class="text-uppercase text-muted small">Falha</div>
                                </div>
                                <div tooltip="Clique para visualizar os leads deste status" class="text-success card-link" (click)="setFilter({plataforma_id: item.plataforma_id,identificador: item.identificador, status: 'Sucesso'})">
                                    <div class="text-value">{{item?.Sucesso?.total || 0}}</div>
                                    <div class="text-uppercase text-muted small">Sucesso</div>
                                </div>
                                <div tooltip="Clique para visualizar os leads deste status" class="text-info card-link" (click)="setFilter({plataforma_id: item.plataforma_id,identificador: item.identificador, status: null, atendimento: true})">
                                    <div class="text-value">{{somar([item?.Aguardando?.atendimentos || 0, item?.Falha?.atendimentos || 0, item?.Sucesso?.atendimentos || 0]) }}</div>
                                    <div class="text-uppercase text-muted small">Atendimentos</div>
                                </div>
                                <div class="text-primary card-link">
                                    <div class="text-value">{{somar([item?.Aguardando?.total || 0, item?.Falha?.total || 0, item?.Sucesso?.total || 0])}}</div>
                                    <div class="text-uppercase text-muted small">Total</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>




<ng-template #content let-modal>
    <div class="modal-header text-white bg-primary">
        <h4 class="card-title mb-0">Total de Leads ({{totalDetalhes}})</h4>
        <small class="text-muted">(A lista mostra apenas {{filter.limit}} registros para detalhes)</small>
    </div>
    <div class="modal-body">
        <div class="">
            <table class="table table-responsive-sm table-hover table-outline mb-0">
                <thead class="thead-light">
                    <tr>
                        <th scope="col"><span [class.active]="orderDetalhes === 'Plataformas.nome'"
                                (click)="setOrderDetalhes('Plataformas.nome')">Integrador <span
                                    [hidden]="reverseDetalhes">▼</span><span
                                    [hidden]="!reverseDetalhes">▲</span></span>|
                            <span [class.active]="orderDetalhes === 'ConteudoPublicados.identificador'"
                                (click)="setOrderDetalhes('ConteudoPublicados.identificador')">Campanha <span
                                    [hidden]="reverseDetalhes">▼</span><span [hidden]="!reverseDetalhes">▲</span></span>
                        </th>
                        <th scope="col"><span [class.active]="orderDetalhes === 'nome_cliente'"
                                (click)="setOrderDetalhes('nome_cliente')">Cliente <span
                                    [hidden]="reverseDetalhes">▼</span><span
                                    [hidden]="!reverseDetalhes">▲</span></span>|
                            <span [class.active]="orderDetalhes === 'nome_empreendimento'"
                                (click)="setOrderDetalhes('nome_empreendimento')">Empresa <span
                                    [hidden]="reverseDetalhes">▼</span><span [hidden]="!reverseDetalhes">▲</span></span>
                        </th>
                        <th scope="col" style="width:400px;" class="text-left"> <span
                                [class.active]="orderDetalhes === 'status'" (click)="setOrderDetalhes('status')">Status/Mensagens de Processamento
                                <span [hidden]="reverseDetalhes">▼</span><span
                                    [hidden]="!reverseDetalhes">▲</span></span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let detalhe of detalhes | orderBy:orderDetalhes:reverseDetalhes:false">
                        <td>
                            <span class="badge badge-secondary">{{detalhe.Plataformas.nome}}</span>
                            &nbsp;|&nbsp;
                            <span class="badge badge-secondary">{{detalhe?.ConteudoTipos?.nome}}</span>
                            <div><span><strong>{{detalhe.ConteudoPublicados?.identificador}}</strong></span></div>
                            <div class="small text-muted">
                                <span><strong>ID INTEGRA:&nbsp;</strong></span>{{detalhe?.id}}
                            </div>
                            <div class="small text-muted">
                                <span><strong>ID ORIGEM:&nbsp;</strong></span>{{detalhe?.registro_id}}
                            </div>
                            <div class="small text-muted" *ngIf="detalhe?.protocolo_atendimento">
                                <span><strong>PROTOCOLO
                                        ATENDIMENTO:&nbsp;</strong></span>{{detalhe?.protocolo_atendimento}}
                            </div>
                            <div class="small text-muted">
                                <span><strong>INCLUÍDO:&nbsp;</strong></span>{{detalhe.created | date:
                                'dd/MM/yyyy H:mm'}}
                            </div>

                        </td>
                        <td>
                            <div><strong>{{detalhe?.nome_cliente}}</strong></div>
                            <div class="small text-muted">
                                <span><strong>E-MAIL:&nbsp;</strong></span>{{detalhe?.email_cliente}}
                            </div>
                            <div class="small text-muted" *ngIf="detalhe?.cpf_origem">
                                <span><strong>CPF:&nbsp;</strong></span>{{detalhe?.cpf_origem |
                                completeDigitoCpf | mask: '000.000.000-00'}}
                            </div>
                            <div class="small text-muted" *ngIf="detalhe?.cpf_encontrado">
                                <span><strong>CPF REF.:&nbsp;</strong></span>{{detalhe?.cpf_encontrado |
                                completeDigitoCpf | mask: '000.000.000-00'}}
                            </div>

                            <div class="small text-muted"
                                *ngIf="detalhe?.nome_empreendimento && detalhe?.nome_empreendimento !=''">
                                <span><strong>EMPRESA: &nbsp;</strong></span>{{detalhe?.nome_empreendimento}}
                            </div>

                            <div class="small text-muted" *ngIf="detalhe?.cnpj_origem ">
                                <span><strong>CNPJ:&nbsp;</strong></span>{{detalhe?.cnpj_origem |
                                completeDigitoCnpj | mask: '00.000.000/0000-00'}}
                            </div>
                            <div class="small text-muted" *ngIf="detalhe?.cnpj_encontrado">
                                <span><strong>CNPJ REF.:&nbsp;</strong></span>{{detalhe?.cnpj_encontrado |
                                completeDigitoCnpj | mask: '00.000.000/0000-00'}}
                            </div>
                            <div class="small text-muted" *ngIf="detalhe?.inscricao_estadual">
                                <span><strong>INSCRIÇÃO
                                        ESTADUAL:&nbsp;</strong></span>{{detalhe?.inscricao_estadual}}
                            </div>
                            <div class="small text-muted" *ngIf="detalhe?.dap">
                                <span><strong>DAP:&nbsp;</strong></span>{{detalhe?.dap}}
                            </div>
                            <div class="small text-muted" *ngIf="detalhe?.registro_pescador">
                                <span><strong>REGISTRO DE
                                        PESCADOR:&nbsp;</strong></span>{{detalhe?.registro_pescador}}
                            </div>
                            <div class="small text-muted" *ngIf="detalhe?.nirf">
                                <span><strong>NIRF:&nbsp;</strong></span>{{detalhe?.nirf}}
                            </div>
                            <div class="small text-muted" *ngIf="detalhe?.cod_sicab">
                                <span><strong>CÓD. SICAB:&nbsp;</strong></span>{{detalhe?.cod_sicab}}
                            </div>
                        </td>
                        <td class="text-left">
                            <span [ngClass]="getClassStatus(detalhe.status)">{{detalhe.status}}</span>
                            &nbsp;|&nbsp;
                            <span class="badge"
                                [ngClass]="detalhe?.tipo_cliente == 'PJ' ? 'badge-warning' : 'badge-info'">{{detalhe?.tipo_cliente}}</span>
                            <div class="small text-muted"><span> {{detalhe?.modified | date: 'dd/MM/yyyy HH:mm'}}</span>
                            </div>
                            <div class="small text-muted"><span>Reprocessado</span> |
                                {{detalhe?.qtd_reprocessado || 0}}</div>
                            <div class="small text-muted" innerHTML="{{detalhe?.historico}}"></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="close()">Fechar</button>
    </div>
</ng-template>

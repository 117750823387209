import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService, ModalState, ModalTemplateDirective, ModalModalComponent } from './modal-and-service';
import { RouterModule } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
@NgModule({
    declarations: [ModalTemplateDirective, ModalModalComponent],
    imports: [RouterModule, CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule, TooltipModule.forRoot()],
    exports: [CommonModule, ModalTemplateDirective, ModalModalComponent],

    providers: [ModalService, ModalState],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalModule { }

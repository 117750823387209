import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.css']
})
export class HealthCheckComponent implements OnInit {
    paramsRoute: any;
    links = [];
    constructor(public activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.paramsRoute = this.activatedRoute.snapshot.params;

        this.links = [
            { title: 'Dashboard', fragment: 'checkDashboard', url: 'dashboard' },
            { title: 'Cadastro Geral', fragment: 'checkGeral', url: 'geral' },
        ];
    }

}

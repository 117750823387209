<div class="animated fadeIn">

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-search"></i>
            Parametrizações de <strong>Campos Integra X Integrador</strong>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Nome do Campo</th>
                  <th>Descrição</th>
                  <th>Valor Padrão Global</th>
                  <th>Obrigatório SAS</th>
                </tr>
                <tr>
                  <th scope="col">
                    <input type="text" class="form-control" [(ngModel)]="Response.tipo" placeholder="Buscar..."/>
                  </th>
                  <th scope="col">
                    <input type="text" class="form-control" [(ngModel)]="Response.nome" placeholder="Buscar..."/>
                  </th>
                  <th scope="col">
                    <input type="text" class="form-control" [(ngModel)]="Response.descricao" placeholder="Buscar..."/>
                  </th>
                  <th scope="col">&nbsp;</th>
                  <th scope="col">&nbsp;</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let c of campos | filterBy: Response" (click)="editarRegistro(c.id)" style="cursor: pointer;"
                  tooltip="Clique para editar a parametrização" placement="left">
                  <td>{{c.tipo}}</td>
                  <td>{{c.nome}}<br>
                    <div class="small text-muted">
                      <span><strong>Exemplo:&nbsp;</strong></span> {{c.exemplo}}
                    </div>
                  </td>
                  <td>{{c.descricao}}</td>
                  <td>{{c.valor_padrao}}</td>
                  <td class="text-center">
                      <span [ngClass]="c.obrigatorio == 1 ? 'badge badge-success' : 'badge badge-danger'">{{c.obrigatorio ? 'Sim' : 'Não'}}</span>
                  </td>
                </tr>
                <tr *ngIf="campos && campos.length == 0">
                  <td colspan="4">Não existe campos cadastrados</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>

  </div>

<div class="animated fadeIn">
    <div class="row">
        <div class="col-lg-12">

            <div class="card">
                <div class="card-header">
                    <i class="fa fa-search"></i>Pesquisa de <strong>Campanhas/Eventos</strong>
                    <div class="card-header-actions">
                        <button type="button" class="btn btn-minimize" (click)="toggleCollapse()">
                            <i class={{iconCollapse}}></i>
                        </button>
                    </div>
                </div>
                <div [collapse]="isCollapsed" class="card-body">
                    <form class="form-horizontal">

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Integrador:</label>

                                    <ng-select [items]="plataformas" bindLabel="nome" bindValue="id"
                                        placeholder="Selecione" [(ngModel)]="filter.plataforma_id" name="plataforma_id">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tipo de Campanha:</label>

                                    <ng-select [items]="tiposCampanhas" bindLabel="nome" bindValue="id"
                                        placeholder="Selecione um tipo de campanha"
                                        [(ngModel)]="filter.campanha_tipo_id" name="campanha_tipo_id">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tipo de Atividade:</label>

                                    <ng-select [items]="tiposConteudos" bindLabel="nome" bindValue="id"
                                        placeholder="Selecione um tipo de atividade"
                                        [(ngModel)]="filter.conteudo_tipo_id" name="conteudo_tipo_id">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Identificador/Evento:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" [(ngModel)]="filter.identificador_desc"
                                            name="identificador_desc">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Nome do Responsável:</label>
                                    <autocomplete-usuario [(ngModel)]="filter" name="filter"></autocomplete-usuario>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Associar CPF:</label>

                                    <ng-select [items]="listPublicados" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.associar_cpf"
                                        name="associar_cpf">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Substituir PJ:</label>

                                    <ng-select [items]="listPublicados" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.vincular_nova_pj"
                                        name="vincular_nova_pj">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="evento_saidas">WorkFlow:</label>

                                    <ng-select [items]="workflows" bindLabel="nome" bindValue="id"
                                        placeholder="Selecione um WorkFlow" [(ngModel)]="filter.workflow_id"
                                        name="workflows">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Gerar Atendimento:</label>

                                    <ng-select [items]="listPublicados" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.gerar_atendimento"
                                        name="gerar_atendimento">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Gerar Reserva Evento:</label>

                                    <ng-select [items]="listPublicados" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.gerar_reserva"
                                        name="gerar_reserva">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Gerar Inscrição Evento:</label>

                                    <ng-select [items]="listPublicados" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.gerar_inscricao"
                                        name="gerar_inscricao">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Atualizar PF SAS:</label>

                                    <ng-select [items]="listPublicados" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.atualizar_pf"
                                        name="atualizar_pf">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Atualizar PJ SAS:</label>

                                    <ng-select [items]="listPublicados" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.atualizar_pj"
                                        name="atualizar_pj">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Gerar Conversão RD:</label>

                                    <ng-select [items]="listPublicados" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.gerar_conversao"
                                        name="gerar_conversao">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Publicado:</label>

                                    <ng-select [items]="listPublicados" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.ativo" name="ativo">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Mostrar Base/Leads:</label>

                                    <ng-select [items]="listPublicados" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.mostrar_base_leads"
                                        name="mostrar_base_leads">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Mostrar na Carteiras:</label>

                                    <ng-select [items]="listPublicados" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.mostrar_carteiras"
                                        name="mostrar_carteiras">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Notificar Erros:</label>

                                    <ng-select [items]="listPublicados" bindLabel="nome" bindValue="value"
                                        placeholder="Selecione..." [(ngModel)]="filter.notificar_erros"
                                        name="notificar_erros">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Registros por página:</label>

                                    <ng-select [items]="listItemsPerPage" placeholder="Selecione..."
                                        [(ngModel)]="filter.limit" name="limit">
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group pull-right">
                                    <button type="button" class="btn btn-primary" (click)="pesquisar()">
                                        <i class="fa fa-search"></i>
                                        Pesquisar
                                    </button>
                                    &nbsp;&nbsp;
                                    <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                                        <i class="fa fa-ban"></i>
                                        Limpar Filtros
                                    </button>
                                    &nbsp;&nbsp;
                                    <a class="btn btn-success" href="#" [routerLink]="['/campanha/0/principal']"
                                        fragment="campEve">
                                        <i class="fa fa-plus"></i> Cadastrar Novo</a>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                    </form>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <i class="fa fa-align-justify"></i> Resultados
                            ({{conteudos && conteudos.length ? conteudos.length : 0 }})
                        </div>
                        <div class="card-body">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th [class.active]="order === 'identificador'"
                                            (click)="setOrder('identificador')"
                                            class="mdl-data-table__cell--non-numeric">

                                            Dados/Configurações da Campanha <span [hidden]="reverse">▼</span><span
                                                [hidden]="!reverse">▲</span>
                                        </th>
                                        <th [class.active]="order === 'modified'" (click)="setOrder('modified')">
                                            &nbsp; <span [hidden]="reverse">▼</span><span [hidden]="!reverse">▲</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="conteudos">
                                    <tr *ngFor="let c of conteudos | orderBy: order:reverse">
                                        <td width="90%">
                                            <div class="row mb-4">
                                                <div class="col-sm-6">
                                                    <div><strong><i *ngIf="c?.descricao_campanha"
                                                                class="fa fa-info-circle fa-sm"
                                                                tooltip="{{c?.descricao_campanha}}"></i>
                                                            {{c.identificador}}</strong></div>
                                                    <div class="small text-muted">
                                                        <span><strong>Integrador:&nbsp;</strong></span>{{c.plataforma?.nome}}
                                                    </div>
                                                    <div class="small text-muted">
                                                        <span><strong>Tipo de
                                                                Campanha:&nbsp;</strong></span>{{c.campanha_tipo?.nome}}
                                                    </div>
                                                    <div class="small text-muted">
                                                        <span><strong>Tipo de
                                                                Atividade:&nbsp;</strong></span>{{c.conteudo_tipo?.nome}}
                                                    </div>
                                                    <div class="small text-muted">
                                                        <span><strong>Tipo de Público do
                                                                Cliente:&nbsp;</strong></span>{{c?.publico_cliente}}
                                                    </div>
                                                    <div class="small text-muted">
                                                        <span><strong>Responsável:&nbsp;</strong></span>{{c.responsavel_nome}}
                                                    </div>
                                                    <div class="small text-muted">
                                                        <span><strong>Incluído:&nbsp;</strong></span>{{c.created | date:
                                                        'dd/MM/yyyy H:mm'}}
                                                    </div>
                                                    <div class="small text-muted">
                                                        <span><strong>Alterado:&nbsp;</strong></span>{{c.modified |
                                                        date: 'dd/MM/yyyy H:mm'}}
                                                    </div>
                                                </div>

                                                <div class="col-sm-6" *ngIf="c.workflow_id != null">
                                                    <div><strong>{{c?.workflow?.nome}}</strong></div>
                                                    <div tooltip="Se marcado como publicado “Não”, o integra deixa de receber novos leads da integração por plataforma ou importações de novos leads por arquivo .csv"> Publicado: <span
                                                        [ngClass]="c.ativo ? 'badge badge-success' : 'badge badge-danger'">{{c.ativo
                                                        ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                    <div>Mostrar Base/Leads: <span
                                                            [ngClass]="c.mostrar_base_leads ? 'badge badge-success' : 'badge badge-danger'">{{c?.mostrar_base_leads
                                                            ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                    <div>Mostrar Carteiras: <span
                                                        [ngClass]="c.mostrar_carteiras ? 'badge badge-success' : 'badge badge-danger'">{{c?.mostrar_carteiras
                                                        ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                </div>

                                                <div class="col-sm-3" *ngIf="c.workflow_id == null">
                                                    <div>Publicado: <span
                                                            [ngClass]="c.ativo ? 'badge badge-success' : 'badge badge-danger'">{{c.ativo
                                                            ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                    <div>Mostrar Base/Leads: <span
                                                            [ngClass]="c.mostrar_base_leads ? 'badge badge-success' : 'badge badge-danger'">{{c?.mostrar_base_leads
                                                            ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                    <div>Mostrar Carteiras: <span
                                                        [ngClass]="c.mostrar_carteiras ? 'badge badge-success' : 'badge badge-danger'">{{c?.mostrar_carteiras
                                                        ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                    <div>Conversão RD: <span
                                                            [ngClass]="c.gerar_conversao ? 'badge badge-success' : 'badge badge-danger'">{{c.gerar_conversao
                                                            ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                    <div>Notificar Erros: <span
                                                            [ngClass]="c.notificar_erros ? 'badge badge-success' : 'badge badge-danger'">{{c.notificar_erros
                                                            ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3" *ngIf="c.workflow_id == null">
                                                    <div>Atendimento SAS: <span
                                                            [ngClass]="c.gerar_atendimento ? 'badge badge-success' : 'badge badge-danger'">{{c.gerar_atendimento
                                                            ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                    <div>Reserva Evento SAS: <span
                                                        [ngClass]="c.gerar_reserva ? 'badge badge-success' : 'badge badge-danger'">{{c.gerar_reserva
                                                        ? 'Sim' : 'Não'}}</span>
                                                </div>
                                                    <div>Inscrição Evento SAS: <span
                                                            [ngClass]="c.gerar_inscricao ? 'badge badge-success' : 'badge badge-danger'">{{c.gerar_inscricao
                                                            ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                    <div tooltip="Atualiza o cadastro completo do cliente PF no SAS">
                                                        Atualizar PF SAS: <span
                                                            [ngClass]="c.atualizar_pf ? 'badge badge-success' : 'badge badge-danger'">{{c.atualizar_pf
                                                            ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                    <div tooltip="Atualiza o cadastro completo do cliente PJ no SAS">
                                                        Atualizar PJ SAS: <span
                                                            [ngClass]="c.atualizar_pj ? 'badge badge-success' : 'badge badge-danger'">{{c.atualizar_pj
                                                            ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                    <div
                                                        tooltip="Integra associa automaticamente o CPF do proprietário e/ou sócio/representante através da consulta da PJ na ausência de um CPF informado">
                                                        Associar CPF Automaticamente: <span
                                                            [ngClass]="c.associar_cpf ? 'badge badge-success' : 'badge badge-danger'">{{c.associar_cpf
                                                            ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                    <div
                                                        tooltip="Integra vincula uma nova empresa existente no SAS para a pessoa, se CNPJ inválido, não informado ou não encontrado/cadastrado no SAS">
                                                        Substituir SAS PJ: <span
                                                            [ngClass]="c.vincular_nova_pj ? 'badge badge-success' : 'badge badge-danger'">{{c.vincular_nova_pj
                                                            ? 'Sim' : 'Não'}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td width="10%">
                                            <div class="btn-group" dropdown placement="bottom right">
                                                <button dropdownToggle type="button" class="btn btn-sm dropdown-toggle">
                                                    Ações <span class="caret"></span>
                                                </button>
                                                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                                                    <li role="menuitem">
                                                        <a href="#" [routerLink]="['/campanha', c?.id, 'principal']"
                                                            fragment="campEve" class="dropdown-item"><i
                                                                class="fa fa-pencil"></i> Editar Campanha/Eventos</a>
                                                    </li>
                                                    <li role="menuitem">
                                                        <a href="#" [routerLink]="['/campanha', c?.id, 'moskit']"
                                                            fragment="campEveMoskCrm" class="dropdown-item"><i
                                                                class="fa fa-pencil"></i> Editar Configurações
                                                            Moskit</a>
                                                    </li>
                                                    <li role="menuitem">
                                                        <a href="#" [routerLink]="['/campanha', c?.id, 'sas']"
                                                            fragment="campEveSas" class="dropdown-item"><i
                                                                class="fa fa-pencil"></i> Editar Configurações SAS</a>
                                                    </li>
                                                    <li role="menuitem">
                                                        <button type="button" class="dropdown-item" (click)="copiar(c)"
                                                        tooltip="Copiar">
                                                            <i class="fa fa-copy mt-1 "></i> Copiar Campanha
                                                        </button>
                                                    </li>
                                                    <li role="menuitem">
                                                        <a href="#"
                                                            [routerLink]="['/campanha', c?.id, 'importacoes-csv']"
                                                            fragment="campImportCsv" class="dropdown-item"
                                                            *ngIf="c?.conteudo_tipo_id == tipoAtividadeEnum.PLANILHAS"><i
                                                                class="fa fa-file-excel-o"></i> Importar Planilha
                                                            CSV</a>
                                                    </li>
                                                    <li role="menuitem">
                                                        <button type="button" class="dropdown-item" (click)="historico(c)"
                                                                tooltip="Historico Alterações">
                                                            <i class="fa fa-eye fa-lg mt-1 "></i> Histórico Alterações
                                                        </button>
                                                    </li>
                                                    <li class="divider dropdown-divider"></li>
                                                    <li role="menuitem">
                                                        <button type="button" class="dropdown-item" (click)="deletar(c)"
                                                            tooltip="Excluir">
                                                            <i class="fa fa-times fa-lg mt-1 "></i> Excluir Campanha
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr *ngIf="conteudos && conteudos.length == 0">
                                        <td colspan="12">Não existe campanhas cadastradas com estes parâmetros, verifique os filtros.</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="col-md-12 justify-content-center">
                                <pagination [boundaryLinks]="true" [itemsPerPage]="filter.limit"
                                    [totalItems]="totalItems" class="text-center" [(ngModel)]="currentPage"
                                    (pageChanged)="pageChanged($event)" [maxSize]="maxSize" previousText="&lsaquo;"
                                    nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template confirm>
         <confirm-modal-component></confirm-modal-component>
    </ng-template>
    <ng-template historico>
        <historico-modal-component></historico-modal-component>
    </ng-template>

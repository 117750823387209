import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardRabbitmqComponent } from './dashboard-rabbitmq.component';

const routes: Routes = [
    {
      path: '',
      component: DashboardRabbitmqComponent,
      data: {
        title: 'RabbitMQ Filas'
      }
    }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRabbitmqRoutingModule { }

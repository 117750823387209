import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class CheckCadastroService {

  constructor(private http: HttpClient, private sessaoService: SessaoService) { }

  get(params) {
    return this.http.get<Consultas>(environment.environment.nomeServiceCheckCadastro + 'Consultas/getAll.json', {params : params});
  }

  getListaOrigensConsulta() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceCheckCadastroOrigens')) {
        resolve(this.sessaoService.get('ServiceCheckCadastroOrigens'));
      } else {        
        this.http.get<any>(environment.environment.nomeServiceCheckCadastro + 'Consultas/getListaOrigensConsulta.json')
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceCheckCadastroOrigens', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });    
  }

  getIndicadoresContratoDataminer(params) {
    return this.http.get<Consultas>(environment.environment.nomeServiceCheckCadastro + 'Consultas/getIndicadoresContratoDataminer.json', {params : params});
  }

}

export class Consultas{
  TotalItems: number;
  Consultas: object;
}
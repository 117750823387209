import { Component, OnInit } from "@angular/core";
import { MetabaseService } from "../../../services/metabase.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "app-rel-exploracao-leads",
    templateUrl: "./rel-exploracao-leads.component.html",
    styleUrls: ["./rel-exploracao-leads.component.css"],
})
export class RelExploracaoLeadsComponent implements OnInit {
    url: SafeResourceUrl | null = null;
    relSelecionado: string = "";
    relatorios: any;
    token: any = "";
    linkApp: string | null;

    constructor(
        private metabaseService: MetabaseService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(){
        this.loginMetabase();
        this.getRelatorios("EXPLORACAO-LEADS");
    }

    carregarIframe(link: string, nome: string, linkApp: string) {
        this.linkApp = linkApp;
        this.relSelecionado = nome;
        const unsafeUrl = link;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
        //console.log(nome);
    }

    getRelatorios(slug: string) {
        this.metabaseService.get({ slug }).subscribe((m) => {
            this.relatorios = m.Metabases;
            if (this.relatorios[0]) {
                this.carregarIframe(
                    this.relatorios[0].link,
                    this.relatorios[0].nome,
                    this.relatorios[0].link_app
                );
            }
        });
    }

    async loginMetabase() {
        if (!this.isLocalEnvironment()) {
            this.token = await this.metabaseService
                .getKeyUserLeitura()
                .toPromise();

            const toke = atob(this.token);
            const [username, password] = toke.split(":");
            this.metabaseService
                .login({
                    username: username,
                    password: password,
                    remember: true,
                })
                .subscribe((response) => {
                    console.log(response);
                    return true;
                });
            return false;
        }
    }

    abrirNovaAba(url: string): void {
        window.open(url, "_blank");
    }

    isLocalEnvironment(): boolean {
        return (
            window.location.hostname === "localhost" ||
            window.location.hostname === "127.0.0.1"
        );
    }
}

import { Component, OnInit } from '@angular/core';
import { ProcessoService } from '../../services/processo.service';

@Component({
  selector: 'app-dashboard-rabbitmq',
  templateUrl: './dashboard-rabbitmq.component.html',
  styleUrls: ['./dashboard-rabbitmq.component.css']
})
export class DashboardRabbitmqComponent implements OnInit {
    controlAtualizaFilas: any;
    filas: any = [];
    filaImportacaoCsv: any = [];
    filaAcoesAssincrona: any = [];
    filaPreprocessarHook : any = [];
    filaPreprocessarAuto : any = [];
    totalMensagensReady: number = 0;
    totalMensagensUnacknowledged: number = 0;
    totalMensagensPersistent: number = 0;
    order: string = 'name';
    reverse: boolean = false;

    constructor(private processoService: ProcessoService) {
    }

    ngOnInit() {
        this.getQueues().then(() => {
            this.calculateTotalMessages();
            this.filterAndSeparateQueues();

            this.controlAtualizaFilas = setInterval(() => {
            this.getQueues().then(() => {
                this.calculateTotalMessages();
                this.filterAndSeparateQueues();
            });
            }, 10000);
        });
    }

    ngOnDestroy() {
      if (this.controlAtualizaFilas) {
        clearInterval(this.controlAtualizaFilas);
      }
    }

    async getQueues() {
      this.filas = await this.processoService.getQueues().toPromise();
    }

    filterAndSeparateQueues() {
        const filasIgnoradasNames = ['PRE-PROCESSAR-ENTRADA-LEAD', 'PRE-PROCESSAR-AUTOMACOES', 'IMPORTAR-LEADS-CONVERSOES-CSV', 'ACOES-ASSINCRONA'];

        // Filtra as filas ignoradas
        const filaImportacaoCsv = this.filas.find(fila =>
          fila.name === 'IMPORTAR-LEADS-CONVERSOES-CSV'
        );

        const filaAcoesAssincrona = this.filas.find(fila =>
            fila.name === 'ACOES-ASSINCRONA'
        );

        const filaPreprocessarHook = this.filas.find(fila =>
          fila.name === 'PRE-PROCESSAR-ENTRADA-LEAD'
        );

        const filaPreprocessarAuto = this.filas.find(fila =>
            fila.name === 'PRE-PROCESSAR-AUTOMACOES'
          );

        // Atualiza as variáveis do componente
        this.filaImportacaoCsv = filaImportacaoCsv;
        this.filaAcoesAssincrona = filaAcoesAssincrona;
        this.filaPreprocessarHook = filaPreprocessarHook;
        this.filaPreprocessarAuto = filaPreprocessarAuto;

        // Filtra as filas principais e ignora as filas específicas
        this.filas = this.filas.filter(fila =>
            !filasIgnoradasNames.includes(fila.name)
          );
      }

      calculateTotalMessages() {
        // Inicializa as variáveis
        let totalReady = 0;
        let totalUnacknowledged = 0;
        let totalPersistent = 0;

        // Soma os valores de cada chave para todas as filas
        this.filas.forEach(fila => {
          totalReady += fila.messages_ready;
          totalUnacknowledged += fila.messages_unacknowledged;
          totalPersistent += fila.messages_persistent;
        });

        // Atualiza as variáveis do componente
        this.totalMensagensReady = totalReady;
        this.totalMensagensUnacknowledged = totalUnacknowledged;
        this.totalMensagensPersistent = totalPersistent;
      }

    setOrder(value: string) {
        if (this.order === value) {
          this.reverse = !this.reverse;
        }
        this.order = value;
      }

}

import { Component, Injectable, Directive, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
/**
 * Options passed when opening a confirmation modal
 */
interface ConfirmOptions {
    /**
     * The title of the confirmation modal
     */
    title: string,

    /**
     * The message in the confirmation modal
     */
    message: string
}

/**
 * An internal service allowing to access, from the confirm modal component, the options and the modal reference.
 * It also allows registering the TemplateRef containing the confirm modal component.
 *
 * It must be declared in the providers of the NgModule, but is not supposed to be used in application code
 */
@Injectable()
export class ConfirmState {
    /**
     * The last options passed ConfirmService.confirm()
     */
    options: ConfirmOptions;

    /**
     * The last opened confirmation modal
     */
    modal: NgbModalRef;

    /**
     * The template containing the confirmation modal component
     */
    template: TemplateRef<any>;
}

/**
 * A confirmation service, allowing to open a confirmation modal from anywhere and get back a promise.
 */
@Injectable()
export class ConfirmService {

    constructor(private modalService: NgbModal, private state: ConfirmState, private ngxService: NgxUiLoaderService) { }

    /**
     * Opens a confirmation modal
     * @param options the options for the modal (title and message)
     * @returns {Promise<any>} a promise that is fulfilled when the user chooses to confirm, and rejected when
     * the user chooses not to confirm, or closes the modal
     */
    confirm(options: ConfirmOptions): Promise<any> {
        this.state.options = options;
        this.state.modal = this.modalService.open(this.state.template);
        this.ngxService.stopAll();//Wanderson: foi acrescentado esse stop aqui devido estar interferindo na chamada CampanhaUnsavedChanges
        return this.state.modal.result;
    }
}

/**
 * The component displayed in the confirmation modal opened by the ConfirmService.
 */
@Component({
    selector: 'confirm-modal-component',
    templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
    dynamicContent: SafeHtml;
    options: ConfirmOptions;
    constructor(private state: ConfirmState, private sanitizer: DomSanitizer) {
        this.options = state.options;
        this.dynamicContent = this.sanitizer.bypassSecurityTrustHtml(this.options.message);
    }

    yes() {
        this.state.modal.close('confirmed');
    }

    no() {
        this.state.modal.dismiss('not confirmed');
    }
}

/**
 * Directive allowing to get a reference to the template containing the confirmation modal component,
 * and to store it into the internal confirm state service. Somewhere in the view, there must be
 *
 * ```
 * <template confirm>
 *   <confirm-modal-component></confirm-modal-component>
 * </template>
 * ```
 *
 * in order to register the confirm template to the internal confirm state
 */
@Directive({
    selector: "[confirm]"
})
export class ConfirmTemplateDirective {
    constructor(confirmTemplate: TemplateRef<any>, state: ConfirmState) {
        state.template = confirmTemplate;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {isObject} from "util";

@Injectable({
  providedIn: 'root'
})
export class SegmentacaoLeadsService {

  constructor(private http: HttpClient) { }

  postVisualizacaoLeadsPorQuery(params) {
    return this.http.post<Leads>(environment.environment.nomeServiceIntegra + 'ConteudoCondicoes/postVisualizacaoLeadsPorQuery.json', params);
  }

  get(params) {
    return this.http.get<SegmentacoesLeads>(environment.environment.nomeServiceIntegra + 'ConteudoCondicoes/getAll.json', { params: params });
  }

  getId(id) {
    return this.http.get(environment.environment.nomeServiceIntegra + `ConteudoCondicoes/getId/${id}.json`);
  }

  salvar(params) {
    return this.http.post(environment.environment.nomeServiceIntegra + 'ConteudoCondicoes/postSave.json', { ConteudoCondicoes: params });
  }

  delete(id) {
    return this.http.delete(environment.environment.nomeServiceIntegra + `ConteudoCondicoes/delete/${id}.json`);
  }

    excluirLeads(id) {
        return this.http.get(environment.environment.nomeServiceIntegra + `ConteudoCondicoes/excluirLeads/${id}.json`);
    }

    reprocessarLeads(id) {
        return this.http.get(environment.environment.nomeServiceIntegra + `ConteudoCondicoes/reprocessarLeads/${id}.json`);
    }
    reenviarLeadsTravadosParaFila(id) {
        return this.http.get(environment.environment.nomeServiceIntegra + `ConteudoCondicoes/reenviarLeadsTravadosParaFila/${id}.json`);
    }
    executarNovoFluxo(params) {
        return this.http.post<any>(environment.environment.nomeServiceIntegra + `ConteudoCondicoes/postExecutarNovoFluxo.json`, params);
    }
    exportarLeads(id,colunas) {
        var arrStr = this.parameterizeArray('colunas',colunas);
        let url = environment.environment.nomeServiceIntegra + `ConteudoCondicoes/exportarLeads/${id}.json` + arrStr;

        window.open(url);
    }
    parameterizeArray(key, arr) {
        arr = arr.map(encodeURIComponent);
        return '?'+key+'[]=' + arr.join('&'+key+'[]=');
    }

}

export class Leads{
  TotalItems: number;
  Leads: [];
}

export class SegmentacoesLeads{
  TotalItems: number;
  ConteudoCondicoes: [];
}



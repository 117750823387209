import { FilterPipe } from './../../pipe/filter.pipe';
import { NgbAccordionModule, NgbNavModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkflowComponent } from './workflow.component';
import { WorkflowAcoesComponent } from './workflow-acoes/workflow-acoes.component';
import { WorkflowService } from '../../services/workflow.service';
import { WorkflowViewComponent } from './workflow-view/workflow-view.component';

import { FormatarFrasePipe } from '../../pipe/formatar-frase.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidacaoComponent } from './validacao/validacao.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CamposCustomizadosComponent } from './campos-customizados/campos-customizados.component';
import { VariaveisComponent } from './variaveis/variaveis.component';





@NgModule({
  declarations: [
    WorkflowComponent,
    WorkflowAcoesComponent,
    FilterPipe,
    FormatarFrasePipe,
    WorkflowViewComponent,
    ValidacaoComponent,
    CamposCustomizadosComponent,
    VariaveisComponent
  ],
  imports: [
    CommonModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgbNavModule,
    NgbPopoverModule
  ],
  exports: [WorkflowComponent, WorkflowAcoesComponent, WorkflowViewComponent, ValidacaoComponent, CamposCustomizadosComponent],
  providers: [WorkflowService]
})
export class WorkflowModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CriteriosCarteiraComponent } from './criterios-carteira.component';

const routes: Routes = [
    {
      path: '',
      component: CriteriosCarteiraComponent,
      data: {
        title: 'Critérios de Consulta da Carteira'
      }
    }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CriteriosCarteiraRoutingModule { }

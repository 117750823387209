<div class="input-group">
    <ng-template #rtCli let-r="result" let-t="term">
        {{ r.NomeRazaoSocial}} - {{ r.CgcCpf}}
    </ng-template>

    <input type="text" class="form-control" [ngModel]="value?.cliente" (ngModelChange)="handleInput('cliente', $event)" [class.is-invalid]="searchFailedCli"
        name="cliente" [ngbTypeahead]="searchCli" [inputFormatter]="formatterCli" [resultTemplate]="rtCli" placeholder="Digite o Nome ou CPF"
    />
    <div class="input-group-append">
        <span class="input-group-text">
            <i class="fa fa-search"></i>
        </span>
    </div>
    <div class="invalid-feedback" *ngIf="searchFailedCli">Desculpe, não conseguimos buscar este cliente.</div>
</div>
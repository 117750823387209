<div *ngIf="colunas">
    <div class="form-check mb-3">
        <button type="button" class="btn btn-primary" (click)="toggleAllSelected()">Marcar/Desmarcar Todos</button>
    </div>
    <div class="row mb-3" style="padding-left: 30px;">
        <div *ngFor="let coluna of colunas" class="form-check mb-3 col-4">
            <label class="form-check-label">
                <input class="form-check-input" type="checkbox" [(ngModel)]="coluna.checked" [value]="coluna.value" (change)="exportar.emit(colunas);" />
                {{ coluna.name }}</label>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatService {

  constructor() { }

  public cpf_mask(cpf: string) {
    let v = ("0000" + cpf).slice(-11);
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  cnpj_mask(v: string) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
    return v;
  }

  ctt_custo_mask(cttCusto: string) {    
    //cttCusto: 00682.000004.990
    const parte1 = cttCusto.slice(0,5);
    const parte2 = cttCusto.slice(5,11);
    const parte3 = cttCusto.slice(11);
    return `${parte1}.${parte2}.${parte3}`
  }

  getClassBadgeStatus(status: string) {
    if (status.trim() == 'Aguardando') {
      return 'badge badge-warning';
    } else if (status.trim() == 'Processando') {
      return 'badge badge-processing';
    } else if (status.trim() == 'Sucesso') {
      return 'badge badge-success';
    } else {
      return 'badge badge-danger';
    }
  }

}

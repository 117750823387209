import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampanhaFormStateService {
  private isDirtySubject = new BehaviorSubject<boolean>(false);
  isDirty$ = this.isDirtySubject.asObservable();

  setFormDirty(isDirty: boolean) {
    this.isDirtySubject.next(isDirty);
  }
}

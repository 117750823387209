import { Component, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { ValueAccessorBase } from '../value-accessor';
import { UsuarioService } from '../../../services/usuario.service';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError } from 'rxjs/operators';


@Component({
    selector: 'autocomplete-usuario',
    templateUrl: './autocomplete-usuario.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: AutocompleteUsuarioComponent,
        multi: true,
    }],
})
export class AutocompleteUsuarioComponent extends ValueAccessorBase<DadosEnvio> {
    @ViewChild(NgModel) dadosEnvio: NgModel;

    public searchFailedUser = false;

    constructor(private usuarioService: UsuarioService) {
        super();
    }

    handleInput(prop, value) {
        if (!this.value) {
            this.value = new DadosEnvio();
        }
        this.value[prop] = value;
        this.value = { ...this.value };
    }

    searchUser = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap(term =>
                term.length < 3 ? of([])
                    :
                    this.usuarioService.getUsuariosAutoComplete(term).pipe(
                        tap(() => this.searchFailedUser = false),
                        catchError(() => {
                            this.searchFailedUser = true;
                            return of([]);
                        }))
            )
        );

    formatterUser = (usuario: {
        nome: string
    }) => (usuario.nome);

}
export class DadosEnvio {
    usuario: any;

    constructor() { }
}

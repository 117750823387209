import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlaybookService {

  constructor(private http: HttpClient) { }

  get(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'Playbooks/getAll.json', { params: params });
  }

  getPaginate(params) {
    return this.http.get<PlaybookPaginate>(environment.environment.nomeServiceIntegra + 'Playbooks/getAll.json', { params: params });
  }

  getId(id) {
    return this.http.get<Playbooks>(environment.environment.nomeServiceIntegra + `Playbooks/getId/${id}.json`);
  }

  salvar(params) {
    return this.http.post(environment.environment.nomeServiceIntegra + 'Playbooks/postSave.json', { Playbooks: params });
  }

  delete(id) {
    return this.http.delete<ResponseDelete>(environment.environment.nomeServiceIntegra + `Playbooks/delete/${id}.json`);
  }

}

export class Playbooks {
  id: number;
  nome: string;
  descricao: string;
  ativo: number;
  playbook_acoes:any;
}

export class PlaybookPaginate {
  Playbooks: any;
  TotalItems: number;

  constructor() { }
}

export class ResponseDelete {
    message: string;
  }

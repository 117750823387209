import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'completeDigitoCnpj'
})
export class CompleteDigitoCnpjPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      return ("00000000000000" + value).slice(-14);
    }
    return value;
  }

}

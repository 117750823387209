import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-campos-customizados',
  templateUrl: './campos-customizados.component.html',
  styleUrls: ['./campos-customizados.component.css']
})
export class CamposCustomizadosComponent implements OnInit {
  @Output() addAcao: EventEmitter<any> = new EventEmitter();
  @Input() edit;
  formCampo: FormGroup;
  campos;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if(this.campos == null || this.campos.length == 0){
      this.campos = [];
    }
    this.createForm();
  }
  ngOnChanges(changes: any) {
    if(this.edit && this.edit.campos){
        this.campos = this.edit.campos;
    }
  }

  createForm() {
    this.formCampo = this.formBuilder.group({
      id: [],
      campo: ['', [Validators.required]],
      valor: ['', [Validators.required]]
    });

  }
  adicionarAcao() {
    this.addAcao.emit({campos: this.campos, edit: this.edit});
    this.campos = [];
    this.formCampo.reset();

  }
  remover(index: number) {
    this.campos.splice(index, 1);
  }

  editForm(form: any, index) {
    let campo = this.campos[index];
    form['id'] = campo.id;
    this.formCampo.setValue(form);

  }

  onSubmit() {
    this.addRegras();
    this.formCampo.reset();
  }

  private addRegras() {
    let form = this.createObjeto();
    if(form.id == null){
      if(this.campos == null || this.campos.length == 0) {
        form.id = 1;
      }else if(this.campos.length > 0){
        form.id = (Math.max(...this.campos.map(m => m.id)) + 1);
      }
      this.campos.push(form);
    }else{
      let index = this.campos.findIndex(f => f.id == form.id);
      this.campos[index] = form;
    }
    
  }

  private createObjeto() {
    let form = this.formCampo.value;
    return {
      id: form.id,
      campo: form.campo,
      valor: form.valor
    };

  }

}

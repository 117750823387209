import { Component, OnInit } from '@angular/core';
import { PlataformaService } from '../../services/plataforma.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-integracoes',
  templateUrl: './integracoes.component.html',
  styleUrls: ['./integracoes.component.scss']
})
export class IntegracoesComponent implements OnInit {
  integracoes: any = [];

  constructor(private plataformaService: PlataformaService, private router: Router) { }

  async ngOnInit() {
    this.integracoes = await this.plataformaService.get().toPromise();
  }

  editar(id: number) {
    this.router.navigate(['/integracoes/',id,'cadastro']);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  get(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'Produtos/getAll.json', { params: params });
  }

  getPaginate(params) {
    return this.http.get<ProdutosPaginate>(environment.environment.nomeServiceIntegra + 'Produtos/getAll.json', { params: params });
  }

  getId(id) {
    return this.http.get<Produto>(environment.environment.nomeServiceIntegra + `Produtos/getId/${id}.json`);
  }

  salvar(params) {
    this.sessaoService.delete('ServiceProdutos');
    return this.http.post(environment.environment.nomeServiceIntegra + 'Produtos/postSave.json', { Produtos: params });
  }

  delete(id) {
    this.sessaoService.delete('ServiceProdutos');
    return this.http.post<ResponseDelete>(`${environment.environment.nomeServiceIntegra}Produtos/delete/${id}.json`, {});
  }

  listarTodosProdutosAtivo() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceProdutos')) {
        resolve(this.sessaoService.get('ServiceProdutos'));
      } else {
        let params: any = { limit: 1000, ativo: 1 };
        this.http.get<ProdutosPaginate>(environment.environment.nomeServiceIntegra + 'Produtos/getAll.json', { params: params })
          .toPromise().then((res: any) => {
            res.Produtos.map((p) => {
              if (p.cod_externo) {
                p.nomeCustom = p.cod_externo + ' - ' + p.nome;
              } else {
                p.nomeCustom = p.nome;
              }
              return p;
            });
            this.sessaoService.set('ServiceProdutos', res.Produtos);
            resolve(res.Produtos);
          }, err => {
            reject(err);
          });
      }
    });
  }

}

export class Produto {
  id: number;
  nome: string;
  descricao: string;
  cod_crm: string;
  cod_externo: string;
  valor: number;
  ativo: number;
}

export class ProdutosPaginate {
  Produtos: any;
  TotalItems: number;

  constructor() { }
}

export class ResponseDelete {
  message: string;
}
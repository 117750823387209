<div class="card">
    <div class="card-header">
        <i class="fa fa-paperclip"></i>Cadastro de <strong>Web Services para Enriquecimento de Dados PF/PJ</strong>

        <div class="pull-right">
            <button type="button" class="btn btn-primary" (click)="initForm()">
                <i class="fa fa-plus"></i>
                Novo
            </button>
            &nbsp;|&nbsp;
            <button type="button" class="btn btn-success" (click)="salvar()">
                <i class="fa fa-check"></i>
                Salvar
            </button>
        </div>
    </div>
    <div class="card-body" *ngIf="form">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="name">Tipo do Serviço:</label>

                        <ng-select [items]="listTiposServicos" bindLabel="nome" bindValue="value"
                            placeholder="Selecione..." formControlName="ambiente" name="ambiente">
                        </ng-select>
                        <app-control-messages [control]="form.controls.ambiente"></app-control-messages>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="form.controls.ambiente.value!='DIRECTD'">
                    <div class="form-group">
                        <label for="name">Usuário:</label>
                        <div class="input-group">
                            <input class="form-control" name="usuario" formControlName="usuario" />
                        </div>
                        <app-control-messages [control]="form.controls.usuario"></app-control-messages>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="name" *ngIf="form.controls.ambiente.value!='DIRECTD'">Senha:</label>
                        <label for="name" *ngIf="form.controls.ambiente.value=='DIRECTD'">Token:</label>
                        <div class="input-group">
                            <input class="form-control" type="password" name="senha" formControlName="senha"
                                autocomplete="off">
                        </div>
                        <app-control-messages [control]="form.controls.senha"></app-control-messages>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="name">URL base do serviço:</label>
                        <div class="input-group">
                            <input class="form-control" type="text" name="url_base" formControlName="url_base">
                        </div>
                        <app-control-messages [control]="form.controls.url_base"></app-control-messages>
                    </div>
                </div>

            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <i class="fa fa-search"></i>
        Lista de <strong>Web Services</strong>
    </div>
    <div class="card-body">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Tipo do Serviço</th>
                    <th>Usuário</th>
                    <th>URL base do serviço</th>
                    <th class="text-left">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let c of configuracoes; let i = index">

                    <td class="text-center">{{c.id}}</td>
                    <td>{{getDescricaoTipoServico(c.ambiente)}}</td>
                    <td>{{c.usuario}}</td>
                    <td>{{c.url_base}}</td>
                    <td class="td-actions text-left">

                        <div class="btn-group" dropdown placement="bottom left">
                            <button dropdownToggle type="button" class="btn btn-light dropdown-toggle">
                                Ações
                                <span class="caret"></span>
                            </button>
                            <ul *dropdownMenu class="dropdown-menu" role="menu">
                                <li role="menuitem">
                                    <button type="button" class="dropdown-item" (click)="editarRegistro(c)"
                                        tooltip="Editar">
                                        <i class="fa fa-pencil fa-lg mt-1 "></i> Editar
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button type="button" class="dropdown-item" (click)="deletar(c.id)"
                                        tooltip="Excluir">
                                        <i class="fa fa-times fa-lg mt-1 "></i> Excluir
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ng-template confirm>
    <confirm-modal-component></confirm-modal-component>
</ng-template>
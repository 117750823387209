import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SegmentoService } from '../../../services/segmento.service';
import { CustomServiceValidateService } from '../../../shared/control-messages/custom-service-validate.service';

@Component({
  selector: 'app-segmento-form',
  templateUrl: './segmento-form.component.html',
  styleUrls: ['./segmento-form.component.scss']
})
export class SegmentoFormComponent implements OnInit {
  
  form: FormGroup;  

  constructor(private fb: FormBuilder
    , private segmentoService: SegmentoService    
    , private toastr: ToastrService
    , private router: Router
    , private route: ActivatedRoute) { }

  ngOnInit() {
    this.initForm();    

    this.route.params.subscribe(routeParams => {
      if (routeParams.id && routeParams.id != 0) {
        this.getId(routeParams.id);
      }
    });
  }

  initForm() {
    this.form = this.fb.group({
      id: [''],
      nome: ['', Validators.required],
      descricao: [''],      
      ativo: [1]
    });
  }

  // facilitar o acesso no html
  get f() { return this.form.controls; }

  salvar() {
    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
      return false;
    }
    this.form.value.ativo = this.form.value.ativo ? 1 : 0;    

    this.segmentoService.salvar(this.form.value).subscribe(response => {
      this.toastr.success('Segmento salvo com sucesso!');
      this.router.navigate(['/segmentos/form/' + response['id']]);
    }, e => {
      CustomServiceValidateService.customMensageService(e.error, this.form);
    });
  }

  getId(id: number) {
    this.segmentoService.getId(id).toPromise().then(response => {      
      this.form.patchValue(response);
    });
  }

  novo() {    
    this.initForm();
  }  

}

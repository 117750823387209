import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampanhasComponent } from './campanhas.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CampanhasRoutingModule } from './campanhas-routing.module';
import { OrderModule } from 'ngx-order-pipe';
import { ConfirmModalModule } from '../../shared/confirm-modal/confirm-modal.module';
import { HistoricoModalModule } from '../../shared/historico-modal/historico-modal.module';
import { AutocompleteUsuarioModule } from '../../shared/form-component/autocomplete-usuario/autocomplete-usuario.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    CommonModule,
    CampanhasRoutingModule,
    FormsModule,
    NgSelectModule,
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    PaginationModule.forRoot(),
    OrderModule,
    NgbModule,
    ConfirmModalModule,
    HistoricoModalModule,
    AutocompleteUsuarioModule,
    BsDropdownModule.forRoot()
  ],
  declarations: [CampanhasComponent],
})
export class CampanhasModule { }

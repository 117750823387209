import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampoListaService {

  constructor(private http: HttpClient) { }
  
  addCampoLista(params) {
    return this.http.post(environment.environment.nomeServiceIntegra + 'CamposListas/postAdd.json', { CamposListas: params });
  }

  removerCampoLista(id) {
    return this.http.delete(environment.environment.nomeServiceIntegra + `CamposListas/delete/${id}.json`);
  }
}

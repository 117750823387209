<ngx-ui-loader [loaderId]="'loader-default'" pbColor="#1e88e5" fgsColor="#1e88e5" fgsType="ball-spin"></ngx-ui-loader>
<div class="app-body" *ngIf="!isLoggedInAmei ">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <h1>Login</h1>
                <p class="text-muted">Informe os dados de acesso:</p>
                <form [formGroup]="formLogin">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-user"></i>
                      </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Login" formControlName="login" autofocus>
                    <app-control-messages [control]="formLogin.controls.login"></app-control-messages>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-lock"></i>
                      </span>
                    </div>
                    <input type="password" class="form-control" placeholder="Senha" formControlName="senha" (keyup.enter)="login()">
                    <app-control-messages [control]="formLogin.controls.senha"></app-control-messages>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button type="button" class="btn btn-primary px-4" (click)="login()">Entrar</button>
                    </div>
                    <div class="col-6 text-right">
                      <!-- <button type="button" class="btn btn-link px-0">Forgot password?</button> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <div class="card-body text-center">
                <p>Integra {{uf}} - Integrações Sebrae</p>
                <img src="assets//img/brand/logo.png " alt="... " />
                <p>
                  <small>Versão {{versao}}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
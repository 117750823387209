import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TreeviewItem } from 'ngx-treeview';

@Injectable({
  providedIn: 'root'
})
export class GrupoService {

  constructor(private http: HttpClient) { }

  get(params) {
    return this.http.get(environment.environment.nomeServiceApi + 'Grupos/getAll.json', { params: params });
  }

  getPaginate(params) {
    return this.http.get<GruposPaginate>(environment.environment.nomeServiceApi + 'Grupos/getAll.json', { params: params });
  }

  getId(id) {
    return this.http.get<Grupo>(environment.environment.nomeServiceApi + `Grupos/getId/${id}.json`);
  }

  salvar(params) {
    return this.http.post(environment.environment.nomeServiceApi + 'Grupos/postSave.json', params);
  }
  
  getTreeviewFuncionalidadesPorProjetos(params) {
    return this.http.get(environment.environment.nomeServiceApi + 'Grupos/getTreeviewFuncionalidadesPorProjetos.json', { params: params });
  }
}

export class Grupo {
  id: number;
  nome: string;
  descricao: string;
  ativo: number;
}

export class GruposPaginate {
  Grupos: any;
  TotalItems: number;

  constructor() { }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProdutoService } from '../../services/produto.service';
import { ConfirmService } from '../../shared/confirm-modal/confirm-modal-and-service';
import {WorkflowService} from '../../services/workflow.service';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss']
})
export class ProdutosComponent implements OnInit {

  produtos: any;
  filter: any;
  totalItems: number;
  currentPage: number = 1;
  maxSize: number;
    workflows: any;
  listItemsPerPage = [10, 20, 30, 40, 50, 100, 200, 300];
  listStatus = [{ name: 'Sim', value: 1 }, { name: 'Não', value: 0 }, { name: 'Todos', value: '' }];

  constructor(
    private produtoService: ProdutoService
    , private confirmService: ConfirmService
    , private toastr: ToastrService
      , private workflowService: WorkflowService
    , private router: Router) { }

  async ngOnInit() {
    this.initFiltros();
      this.workflowService.getWorkflows().then((w: any) => {
          this.workflows = w;
      });
    this.getProdutos();
  }

  initFiltros() {
    this.filter = {
      nome: '',
      cod_crm: '',
        workflow_id: '',
      cod_externo: '',
      cod_sas: '',
      ativo: '',
      page: 1,
      limit: 30
    };
  }

  pageChanged(event: any): void {
    this.filter.page = event.page;
    this.filter.limit = event.itemsPerPage;
    this.getProdutos();
  }

  async getProdutos(): Promise<void> {
    let response = await this.produtoService.getPaginate(this.filter).toPromise();
    this.produtos = response.Produtos;
    this.totalItems = response.TotalItems;

    let maxSize = Math.ceil(this.totalItems / this.filter.limit);
    this.maxSize = maxSize <= 15 ? maxSize : 15;
  }

  pesquisar() {
    this.filter.page = 1;
    this.currentPage = 1;

    this.getProdutos();
  }

  editarRegistro(id: number) {
    this.router.navigate(['/produtos/form', id]);
  }

  limparFiltros() {
    this.initFiltros();
  }

  deletar(produto: any = []) {
    this.confirmService.confirm({
      title: 'Exclusão', message: 'Você tem certeza que deseja excluir o produto de nome ' + produto.nome + ' ?'
    }).then(
      () => {
        this.produtoService.delete(produto.id).subscribe(response => {
          this.pesquisar();
          this.toastr.success('Produto excluído com sucesso!');
          if (response.message) {
            this.toastr.info(response.message);
          }
        });
      },
      () => { });
  }

}

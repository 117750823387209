import {Component, Injectable, Directive, TemplateRef, OnInit} from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {AuditoriaService} from "../../services/auditoria.service";
/**
 * Options passed when opening a Historicoation modal
 */
interface HistoricoOptions {

    projeto: string,
    modulo: string,
    registro_id: string
}

/**
 * An internal service allowing to access, from the Historico modal component, the options and the modal reference.
 * It also allows registering the TemplateRef containing the Historico modal component.
 *
 * It must be declared in the providers of the NgModule, but is not supposed to be used in application code
 */
@Injectable()
export class HistoricoState {
    /**
     * The last options passed HistoricoService.Historico()
     */
    options: HistoricoOptions;

    /**
     * The last opened Historicoation modal
     */
    modal: NgbModalRef;

    /**
     * The template containing the Historicoation modal component
     */
    template: TemplateRef<any>;
}

/**
 * A Historicoation service, allowing to open a Historicoation modal from anywhere and get back a promise.
 */
@Injectable()
export class HistoricoService {

    constructor(private modalService: NgbModal, private state: HistoricoState) { }

    /**
     * Opens a Historicoation modal
     * @param options the options for the modal (title and message)
     * @returns {Promise<any>} a promise that is fulfilled when the user chooses to Historico, and rejected when
     * the user chooses not to Historico, or closes the modal
     */
    modal(options: HistoricoOptions): Promise<any> {
        this.state.options = options;
        this.state.modal = this.modalService.open(this.state.template, { size: 'xl' });
        return this.state.modal.result;
    }

}

/**
 * The component displayed in the Historicoation modal opened by the HistoricoService.
 */
@Component({
    selector: 'historico-modal-component',
    templateUrl: 'historico-modal.component.html'
})
export class HistoricoModalComponent implements OnInit {
    dynamicContent: SafeHtml;
    options: HistoricoOptions;
    listaDados: any = [];
    totalItems: number;
    constructor(
        private state: HistoricoState,
        private sanitizer: DomSanitizer,
        private auditoriaService: AuditoriaService
    ) {
        this.options = state.options;
    }

    fechar() {
        this.state.modal.close('');
    }
    async ngOnInit() {
        this.getHistorico();
    }

    async getHistorico(): Promise<void> {
        let response = await this.auditoriaService.get(this.options).toPromise();
        let res: any = [];
        // @ts-ignore
        response.Auditorias.forEach(dados => {
            if (dados.auditoria_campos.length > 0) {
                dados.auditoria_campos.forEach(campos => {
                    res['created'] = campos.created;
                    res['usuario'] = dados.usuario.nome;
                    res['campo'] = campos.nome;
                    res['old_value'] = campos.old_value;
                    res['new_value'] = campos.new_value;
                    this.listaDados.push(res);
                    res = [];
                });
            }
        });
        this.totalItems = response.TotalItems;
    }
}

/**
 * Directive allowing to get a reference to the template containing the Historicoation modal component,
 * and to store it into the internal Historico state service. Somewhere in the view, there must be
 *
 * ```
 * <template historico>
 *   <historico-modal-component></historico-modal-component>
 * </template>
 * ```
 *
 * in order to register the Historico template to the internal Historico state
 */
@Directive({
    selector: "[historico]"
})
export class HistoricoTemplateDirective {
    constructor(HistoricoTemplate: TemplateRef<any>, state: HistoricoState) {
        state.template = HistoricoTemplate;
    }
}

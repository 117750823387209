import { SessaoService } from '../../../services/sessao.service';
import { WorkflowService } from './../../../services/workflow.service';
import { Component, Input, OnInit } from '@angular/core';
declare var flowchart: any;
declare var window: any;
@Component({
    selector: 'app-workflow-view',
    templateUrl: './workflow-view.component.html',
    styleUrls: ['./workflow-view.component.css']
})
export class WorkflowViewComponent implements OnInit {
    @Input() content;
    declarar_fluxos: Array<String>;
    sequenciaAcoes = [];
    public validacoes = {};
    public campos = {};
    public variaveis = {};

    private chart;
    constructor(private workflowService: WorkflowService, private sessaoService: SessaoService) { }

    ngOnInit(): void {
        this.atualizar();
    }
   
    private encontrarValidacoesCustonsVariaveis(objetos, tipo) {
        const resultado = [];

        objetos.forEach(objeto => {

            if (objeto.validacoes && objeto.validacoes.length > 0) {
                resultado.push({
                    codigo_interno: objeto.codigo_interno,
                    validacoes: objeto.validacoes
                });
            } else if (objeto.campos && objeto.campos.length > 0) {
                resultado.push({
                    codigo_interno: objeto.codigo_interno,
                    campos: objeto.campos
                });
            } else if (objeto.variaveis && objeto.variaveis.length > 0) {
                resultado.push({
                    codigo_interno: objeto.codigo_interno,
                    variaveis: objeto.variaveis
                });
            }


            if (objeto.completou && objeto.completou.true) {
                const validacoesCompletou = this.encontrarValidacoesCustonsVariaveis([objeto.completou.true], tipo);
                resultado.push(...validacoesCompletou);
            }

            if (objeto.completou && objeto.completou.false) {
                const validacoesCompletou = this.encontrarValidacoesCustonsVariaveis([objeto.completou.false], tipo);
                resultado.push(...validacoesCompletou);
            }
        });

        return resultado;
    }
    async atualizarFluxosDeclarados() {
        const fluxos = ["st=>start: Inicio|approved:$myFunction"];
        const acoes = await this.workflowService.getPlataformas();
        acoes.forEach(a => {
            a.acoes.forEach(acao => {
                fluxos.push(acao.codigo_interno + '=>operation: ' + this.workflowService.quebrarTituloAcao(`[${a.nome}]` + ' ' + acao.nome) + '|action:$myFunction');
            });
        });
        const workflows = await this.workflowService.getWorkflows();
        workflows.forEach(acao => {
            fluxos.push(acao.codigo_interno + '=>operation: ' + this.workflowService.quebrarTituloAcao(`[${acao.nome}]`) + '|workflow:$myFunction');
        });

        return fluxos;

    }
    findAndReplaceInList(list, searchString, replaceString) {
        let acoes = this.sessaoService.get('getAcoesWorkflow');
        const lowerAcoes = acoes == null ? [] : acoes.filter(f => f.entradas_json != null && f.entradas_json != "").map((acao) => acao.codigo_interno);

        const updatedList = list.map((item) => {        
            if (lowerAcoes.some((acao) => item.includes(acao))) {
                return item.replace(searchString, replaceString);
            }
            return item;
        });

        return updatedList;
    }
    async ngOnChanges(changes: any) {
        let content = Array.isArray(this.content) ? this.content : (this.content? JSON.parse(this.content) : []);

        const fluxos = await this.atualizarFluxosDeclarados();
        this.declarar_fluxos = content.filter(element => element.search("=>") >= 0);
        if (this.declarar_fluxos.every(f => f.search('st=>start:') < 0)) {
            this.declarar_fluxos.push("st=>start: Inicio|approved:$myFunction");
            this.declarar_fluxos.push("e=>end: Fim");
        }
        fluxos.forEach(f => {
            let df = this.declarar_fluxos;
            if (!df.includes(f)) {
                this.declarar_fluxos.push(f);
            }
        });
        console.log(this.declarar_fluxos);
        this.sequenciaAcoes = content.filter(element => element.search("=>") < 0);
        this.atualizar();
    }

    atualizar() {

        if (this.content == null) {
            if (this.chart) {
                this.chart.clean();
            }
            return false;
        }
        let sequencia = this.workflowService.montarSequencia(this.sequenciaAcoes);
        //console.log(sequencia);
        let content = this.declarar_fluxos.concat(sequencia).filter(element => {
            return element !== undefined && this.workflowService.removerNumeros(element) != 'cond->cond()';
        });

        let content2 = this.findAndReplaceInList(content, "|action:$myFunction", "|variavel:$myFunction") ;
        if (this.chart) {
            this.chart.clean();
        }

        this.chart = flowchart.parse(content2.join('\n'));


        this.chart.drawSVG('canvas',
            {
                // 'x': 30,
                // 'y': 50,
                'line-width': 1,

                'text-margin': 10,
                'font-size': 12,
                'font': 'normal',
                'font-family': 'Helvetica',
                'font-weight': 'normal',
                'font-color': 'black',
                'line-color': 'black',
                'element-color': 'black',
                'fill': 'white',
                'yes-text': 'sim',
                'no-text': 'não',
                'stroke-width': 3,
                'arrow-end': 'block',
                'symbols': {
                    'start': {
                        'fill': 'red'
                    },
                    'end': {
                        'class': 'end-element',
                        'font-color': '#000',
                        'element-color': '#D8BFD8',
                        'fill': '#D8BFD8'
                    }
                },
                'flowstate': {
                    'past': {
                        'font-size': 12,
                        'font-color': '#000',
                        'element-color': '#FFF0F5',
                        'fill': '#E6E6FA',
                    },
                    'action': {
                        'font-size': 12,
                        'font-color': '#000',
                        'element-color': '#FFF0F5',
                        'fill': '#E6E6FA',
                    },
                    'workflow': {
                        'font-size': 12,
                        'font-color': '#000',
                        'element-color': '#90ee90',
                        'fill': '#bdecb6',
                    },
                    'condition': {
                        'font-size': 10,
                        'fill': '#f5deb3',
                    },
                    'approved': {
                        'font-size': 12,
                        'fill': '#E0FFFF',
                        'element-color': '#B0E0E6',
                        'padding': '50px'
                    },
                    'validacao': {
                        'element-color': '#ff9e8e',
                        'fill': '#ff9e8e',
                    },
                    'custom': {
                        'fill': '#fff2bc',
                        'element-color':"#fff2bc"
                    },
                    'variavel': {
                        'fill': '#D8BFD8',
                        'element-color':"#D8BFD8"
                    }
                }
            });


    }

}

import { Component, OnInit} from '@angular/core';
import { ProcessoService } from '../../services/processo.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  controlAtualizaProcessos: any;
  processos: any;
  public filter = { nome: '', plataforma: { nome: ''} };

  constructor(private processoService: ProcessoService) {
  }

  ngOnInit() {
    this.getProcessos();

    this.controlAtualizaProcessos = setInterval(() => {
      this.getProcessos();
    }, 10000);
  }

  ngOnDestroy() {
    if (this.controlAtualizaProcessos) {
      clearInterval(this.controlAtualizaProcessos);
    }
  }

  async getProcessos() {
    this.processos = await this.processoService.get({ ativo: 1, possui_plataforma: 1 }).toPromise();
  }

  getClassStatus(processo) {
    if (processo.nome == 'Entrada de Lead') {

      if (processo.pausado) {
        return { class: 'badge badge-danger', status: 'Entrada de lead fechada' };
      } else {
        return { class: 'badge badge-success', status: 'Entrada de lead aberta' };
      }

    } else if (!processo.iniciado && !processo.pausado) {
      return { class: 'badge badge-warning', status: 'Aguardando próxima execução' };
    } else if (processo.iniciado) {
      return { class: 'badge badge-success', status: 'Processo em execução' };
    } else if (processo.pausado) {
      return { class: 'badge badge-danger', status: 'Processamento pausado' };
    } else {
      return { class: '', status: '' };
    }
  }

  getNomeStatus(processo) {
    return this.getClassStatus(processo).status;
  }
  getNomeClass(processo) {
    return this.getClassStatus(processo).class;
  }

}

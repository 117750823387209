import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Leads} from "./lead.service";

@Injectable({
  providedIn: 'root'
})
export class AuditoriaService {

  constructor(private http: HttpClient) { }

  get(params) {
    return this.http.get<Auditoria>(environment.environment.nomeServiceApi + 'auditorias/getAll.json', {params : params});
  }

}

export class Auditoria {
    TotalItems: number;
    Auditorias: object;
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from '../../services/usuario.service';
import { TipoUsuarioService } from '../../services/tipo-usuario.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  usuarios: any;  

  filter: any;

  public tipoUsuarios: any;
  public totalItems: number;
  public currentPage: number = 1;
  public maxSize: number;
  public listItemsPerPage = [10, 20, 30, 40, 50, 100, 200, 300];
  public listStatus = [{ name: 'Sim', value: 1 }, { name: 'Não', value: 0 }, { name: 'Todos', value: '' }]
  public listSimNao = [{ nome: 'Sim', value: 1 }, { nome: 'Não', value: 0 }, { nome: 'Todos', value: '' }];

  constructor(private usuarioService: UsuarioService,
    private TipoUsuarioService: TipoUsuarioService,
    private router: Router) { }

  async ngOnInit() {
    this.initFiltros();
    this.getUsuarios();
    this.getTipoUsuarios();
  }

  initFiltros(){
    this.filter = { 
      nome: '', 
      email: '', 
      login: '', 
      ativo: 1, 
      cpf: '', 
      usuario_ad : '', 
      tipo_usuario_id : '',
      admin : '',     
      page: 1, 
      limit: 100 
    };
  }

  pageChanged(event: any): void {
    this.filter.page = event.page;
    this.filter.limit = event.itemsPerPage;
    this.getUsuarios();
  }

  async getUsuarios(): Promise<void> {    

    let response = await this.usuarioService.get(this.filter).toPromise();
    this.usuarios = response.Usuarios;
    this.totalItems = response.TotalItems;    

    let maxSize = Math.ceil(this.totalItems / this.filter.limit);
    this.maxSize = maxSize <= 15 ? maxSize : 15;
  }

  getTipoUsuarios() {
    this.TipoUsuarioService.get().subscribe(response => {
      this.tipoUsuarios = response;
    });
  }

  pesquisar() {
    this.filter.page = 1;
    this.currentPage = 1;

    this.getUsuarios();
  }

  editarRegistro(id: number) {
    this.router.navigate(['/usuarios/alterar', id]);
  }

  limparFiltros() {
    this.initFiltros();
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class RegionalService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  get(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'Regionais/getAll.json', { params: params });
  }

  getPaginate(params) {
    return this.http.get<RegionaisPaginate>(environment.environment.nomeServiceIntegra + 'Regionais/getAll.json', { params: params });
  }

  getId(id) {
    return this.http.get<Regional>(environment.environment.nomeServiceIntegra + `Regionais/getId/${id}.json`);
  }

  salvar(params) {
    this.sessaoService.delete('ServiceRegionais');
    return this.http.post(environment.environment.nomeServiceIntegra + 'Regionais/postSave.json', params);
  }

  delete(id) {
    this.sessaoService.delete('ServiceRegionais');
    return this.http.post<ResponseDelete>(`${environment.environment.nomeServiceIntegra}Regionais/delete/${id}.json`, {});
  }

  getTreeviewCidades(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'Regionais/getTreeviewCidades.json', { params: params });
  }

  listarTodasCidadesUf() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceCidadesUf')) {
        resolve(this.sessaoService.get('ServiceCidadesUf'));
      } else {        
        this.http.get(environment.environment.nomeServiceIntegra + 'Regionais/getCidadesUf.json')
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceCidadesUf', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

  listarTodasRegionais() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceRegionais')) {
        resolve(this.sessaoService.get('ServiceRegionais'));
      } else {
        this.http.get(environment.environment.nomeServiceIntegra + 'Regionais/getListaRegionais.json')
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceRegionais', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

}

export class Regional {
  id: number;
  nome: string;
  ativo: number;
}

export class RegionaisPaginate {
  Regionais: any;
  TotalItems: number;

  constructor() { }
}

export class ResponseDelete {
  message: string;
}
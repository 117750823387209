import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PlataformaService } from "../../../services/plataforma.service";
@Component({
    selector: "app-integracao-form-abas",
    templateUrl: "./integracao-form-abas.component.html",
    styleUrls: ["./integracao-form-abas.component.scss"],
})
export class IntegracaoFormAbasComponent implements OnInit {
    paramsRoute: any;
    form: any;
    plataforma_id: number = 0;
    abaAtiva: any = 'processos';
    constructor(
        private router: Router,
        private plataformaService: PlataformaService,
        private route: ActivatedRoute,
        public activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        var arrayUrl = this.router.url.split('/');
        this.abaAtiva = arrayUrl[3] ? arrayUrl[3] : '';

        this.paramsRoute = this.activatedRoute.snapshot.params;
        this.plataforma_id = this.paramsRoute.plataforma_id != undefined && parseInt(this.paramsRoute.plataforma_id) != 0 ? parseInt(this.paramsRoute.plataforma_id) : 0;

        this.route.params.subscribe((routeParams) => {
            if (routeParams.plataforma_id && routeParams.plataforma_id != 0) {
                this.getId(routeParams.plataforma_id);
            }
        });
    }

    getId(id: number) {
        this.plataformaService
            .getId(id)
            .toPromise()
            .then(async (response) => {
                this.form = response;
            });
    }

    carregarAba(aba: String) {
        this.abaAtiva = aba;
        var url = `/integracoes/${this.plataforma_id}/${aba}`;
        this.router.navigate([url]);
    }


}

import { Component, OnInit } from '@angular/core';
import { ConteudoService } from '../../services/conteudo.service';
import { PlataformaService } from '../../services/plataforma.service';
import { Router } from '@angular/router';
import { OrderPipe } from 'ngx-order-pipe';
import { CampanhaTipoService } from '../../services/campanha-tipo.service';
import { ConteudoTipoService } from '../../services/conteudo-tipo.service';
import { ConfirmService } from '../../shared/confirm-modal/confirm-modal-and-service';
import { ToastrService } from 'ngx-toastr';
import { TipoAtividadeEnum } from '../../enum/TipoAtividadeEnum';
import { WorkflowService } from '../../services/workflow.service';
import { SessaoService } from '../../services/sessao.service';
import {HistoricoService} from "../../shared/historico-modal/historico-modal-and-service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-campanhas',
  templateUrl: './campanhas.component.html',
  styleUrls: ['./campanhas.component.scss']
})
export class CampanhasComponent implements OnInit {

  isCollapsed: boolean = false;
  iconCollapse: string = "icon-arrow-up";

  conteudos: any = [];
  tiposConteudos: any;
  tiposCampanhas: any;
  plataformas: any;
  workflows: any;
  filter: any;
  totalItems: number;
  currentPage: number = 1;
  maxSize: number;
  listItemsPerPage = [10, 20, 30, 40, 50, 100, 200, 300];
  listPublicados = [{ nome: 'Sim', value: 1 }, { nome: 'Não', value: 0 }, { nome: 'Todos', value: '' }];

  order: string = 'modified';
  reverse: boolean = true;
  sortedCollection: any[];

  tipoAtividadeEnum: any = TipoAtividadeEnum;

  constructor(private conteudoService: ConteudoService
    , private campanhaTipoService: CampanhaTipoService
    , private conteudoTipoService: ConteudoTipoService
    , private PlataformaService: PlataformaService
    , private router: Router
    , private orderPipe: OrderPipe
    , private confirmService: ConfirmService
    , private historicoService: HistoricoService
    , private toastr: ToastrService
    , private workflowService: WorkflowService
    , private sessaoService: SessaoService) {
   }

  async ngOnInit() {
    this.initFiltros();

    this.PlataformaService.listarTodasPlataformas().then(response => {
      this.plataformas = response;
    });

    this.campanhaTipoService.listarTodosTiposCampanha().then(response => {
      this.tiposCampanhas = response;
    });

    this.conteudoTipoService.listarTodosTiposConteudo().then(response => {
      this.tiposConteudos = response;
    });

    this.workflowService.getWorkflows().then((w: any) => {
        this.workflows = w;
    });

    this.getConteudos();

    //iniciar ordenação com identificador
    this.sortedCollection = this.orderPipe.transform(this.conteudos, 'identificador');
  }

  initFiltros() {
    let FiltrosCampanhas: any = this.sessaoService.get('FiltrosCampanhas');
    if(FiltrosCampanhas){
      this.filter = FiltrosCampanhas;
    } else {
    this.filter = {
      identificador_desc: '',
      campanha_tipo_id: '',
      conteudo_tipo_id: '',
      plataforma_id: '',
      workflow_id: '',
      gerar_atendimento: '',
      gerar_conversao: '',
      gerar_inscricao: '',
      gerar_reserva: '',
      atualizar_pf: '',
      atualizar_pj: '',
      notificar_erros: '',
      vincular_nova_pj: '',
      associar_cpf: '',
      mostrar_base_leads: '',
      mostrar_carteiras: '',
      ativo: 1,
      page: 1,
      limit: 30
    };
  }
  }

  pageChanged(event: any): void {
    this.filter.page = event.page;
    this.filter.limit = event.itemsPerPage;
    this.getConteudos();
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.iconCollapse = this.isCollapsed ? "icon-arrow-down" : "icon-arrow-up";
  }

  async getConteudos(): Promise<void> {
    //Parser
    this.filter.campanha_tipo_id = this.filter.campanha_tipo_id ? this.filter.campanha_tipo_id : '';
    this.filter.conteudo_tipo_id = this.filter.conteudo_tipo_id ? this.filter.conteudo_tipo_id : '';
    this.filter.plataforma_id = this.filter.plataforma_id ? this.filter.plataforma_id : '';
    this.filter.responsavel_id = this.filter.usuario && this.filter.usuario.id ? this.filter.usuario.id : '';
    this.sessaoService.set('FiltrosCampanhas', this.filter);
    let response = await this.conteudoService.get(this.filter).toPromise();
    this.conteudos = response.ConteudoPublicados;
    this.totalItems = response.TotalItems;

    let maxSize = Math.ceil(this.totalItems / this.filter.limit);
    this.maxSize = maxSize <= 15 ? maxSize : 15;
  }

  pesquisar() {
    this.filter.page = 1;
    this.currentPage = 1;
    this.getConteudos();
  }

  limparFiltros() {
    this.sessaoService.delete('FiltrosCampanhas');
    this.initFiltros();
    this.getConteudos();
  }

  editarRegistro(id: number) {
    this.router.navigate(['campanha', id, 'principal'], { fragment: 'campEve' });
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  deletar(campanha: any = []) {
    this.confirmService.confirm({
      title: 'Exclusão', message: 'Você tem certeza que deseja excluir a campanha de nome ' + campanha.identificador + ' ?'
    }).then(
      () => {
        this.conteudoService.delete(campanha.id).subscribe(response => {
          this.toastr.success('Campanha excluída com sucesso!');
          if (response.message) {
            this.toastr.info(response.message);
          }
          this.getConteudos();
        });
      },
      () => { });
  }

  copiar(campanha: any = []) {
    this.confirmService.confirm({
      title: 'Cópia', message: 'Você tem certeza que deseja copiar esta campanha de nome ' + campanha.identificador + ' ?'
    }).then(
      () => {
        this.conteudoService.copiar(campanha.id).subscribe(response => {
          this.toastr.success('Campanha copiada com sucesso!');
          this.getConteudos();
        });
      },
      () => { });
  }

  historico(campanha: any = []) {
        this.historicoService.modal({
            projeto: environment.environment.projeto,
            modulo: "ConteudoPublicados" ,
            registro_id: campanha.id
        });
    }

}

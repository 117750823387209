import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TreeviewItem } from 'ngx-treeview';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class ProjetoService {

  constructor(private http: HttpClient, private sessaoService: SessaoService) { }

  getPorNome(nome: String) {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceProjetoPorNome')) {
        resolve(this.sessaoService.get('ServiceProjetoPorNome'));
      } else {
        let params : any = {nome: nome};
        this.http.get(environment.environment.nomeServiceApi + 'projetos/getName.json', { params: params })
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceProjetoPorNome', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }
  getAll() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceProjetos')) {
        resolve(this.sessaoService.get('ServiceProjetos'));
      } else {
        this.http.get(environment.environment.nomeServiceApi + 'projetos/getAll.json')
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceProjetos', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

}

import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import ReconnectingEventSource from "reconnecting-eventsource";

@Injectable({
    providedIn: 'root'
})

export class SseService {

    constructor() { }

    createEventSource(metodo: String): Observable<any> {
        //new EventSource('http://localhost:3000/api/' + metodo); //new EventSource('https://integra-sse-develop.hml.ms.sebrae.com.br/api/' + metodo); // new EventSource(environment.sse_url);
        return new Observable(observer => {
            let eventSource = new ReconnectingEventSource(environment.environment.sse + metodo);
            eventSource.onmessage = event => {
                const messageData = JSON.parse(event.data);
                observer.next(messageData);
            };
            // eventSource.onerror = error => {
            //     console.log("error", error);
            //     eventSource.close();
            //   //  observer.error(error);

            // };
            // let reconnecting = false;
            // setInterval(() => {
            //     if (eventSource.readyState == EventSource.CLOSED) {
            //         reconnecting = true;
            //         console.log("reconnecting...");
            //         eventSource = new EventSource(environment.environment.sse + metodo);
            //         eventSource.onmessage = event => {
            //             const messageData = JSON.parse(event.data);
            //             observer.next(messageData);
            //         };
            //         eventSource.onerror = error => {
            //             console.log("error", error);
            //             eventSource.close();
            //           //  observer.error(error);

            //         };
            //     } else if (reconnecting) {
            //         reconnecting = false
            //         console.log("reconnected!");
            //     }
            // }, 3000);
        });
    }
}

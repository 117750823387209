import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class DorService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  get(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'Dores/getAll.json', { params: params });
  }

  getPaginate(params) {
    return this.http.get<DoresPaginate>(environment.environment.nomeServiceIntegra + 'Dores/getAll.json', { params: params });
  }

  getId(id) {
    return this.http.get<Dor>(environment.environment.nomeServiceIntegra + `Dores/getId/${id}.json`);
  }

  salvar(params) {
    this.sessaoService.delete('ServiceDores');
    return this.http.post(environment.environment.nomeServiceIntegra + 'Dores/postSave.json', { Dores: params });
  }

  delete(id) {
    this.sessaoService.delete('ServiceDores');
    return this.http.post<ResponseDelete>(`${environment.environment.nomeServiceIntegra}Dores/delete/${id}.json`, {});
  }

  getSincronizarDoresSol() {
    return this.http.get(environment.environment.nomeServiceIntegra + `Dores/getSincronizarDoresSol.json`);
  }

  listarTodasDoresAtivo() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceDores')) {
        resolve(this.sessaoService.get('ServiceDores'));
      } else {
        let params: any = {limit: 1000, ativo: 1 };
        this.http.get<DoresPaginate>(environment.environment.nomeServiceIntegra + 'Dores/getAll.json', { params: params })
          .toPromise().then((res: any) => {
            res.Dores.map((p) => {
              if (p.cod_externo) {
                p.nomeCustom = p.cod_externo + ' - ' + p.nome;
              } else {
                p.nomeCustom = p.nome;
              }
              return p;
            });
            this.sessaoService.set('ServiceDores', res.Dores);
            resolve(res.Dores);
          }, err => {
            reject(err);
          });
      }
    });
  }

}

export class Dor {
  id: number;
  nome: string;
  descricao: string;
  cod_externo: string;
  ativo: number;
}

export class DoresPaginate {
  Dores: any;
  TotalItems: number;

  constructor() { }
}

export class ResponseDelete {
  message: string;
}
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SegmentosRoutingModule } from './segmentos-routing.module';
import { SegmentosComponent } from './segmentos.component';
import { SegmentoFormComponent } from './segmento-form/segmento-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlMessagesModule } from '../../shared/control-messages/control-messages.module';
import { NgSelectModule } from '@ng-select/ng-select';

 
import { ConfirmModalModule } from '../../shared/confirm-modal/confirm-modal.module';
import { SegmentoAtividadeFormComponent } from './segmento-atividade-form/segmento-atividade-form.component';
import { SegmentoProdutoFormComponent } from './segmento-produto-form/segmento-produto-form.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';

@NgModule({
  imports: [
    CommonModule,
    SegmentosRoutingModule,
    ReactiveFormsModule,    
    ControlMessagesModule,                             
    NgSelectModule,
    FormsModule,
    NgbModule,
    PaginationModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),    
    ConfirmModalModule,
    TabsModule,
  ],
  declarations: [SegmentosComponent, SegmentoFormComponent, SegmentoAtividadeFormComponent, SegmentoProdutoFormComponent]
})
export class SegmentosModule { }

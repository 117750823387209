<div class="modal-header">
    <h4 class="modal-title">Histórico de Alterações</h4>
    <button type="button" class="close" aria-label="Close" (click)="fechar()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th>Feita em</th>
                        <th>Feita Por</th>
                        <th>Campo</th>
                        <th>Dado Anterior</th>
                        <th>Alterado Para</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let c of listaDados let x = index">
                        <td>{{c.created | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                        <td>{{c.usuario}}</td>
                        <td>{{c.campo}}</td>
                        <td>{{c.old_value}}</td>
                        <td>{{c.new_value}}</td>
                    </tr>
                    <tr *ngIf="listaDados && listaDados.length == 0">
                        <td colspan="10">Nenhum dado registrado.</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntegracoesRoutingModule } from './integracoes-routing.module';
import { IntegracoesComponent } from './integracoes.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ControlMessagesModule } from '../../shared/control-messages/control-messages.module';
import { ConfirmModalModule } from '../../shared/confirm-modal/confirm-modal.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from "../../shared/modal/modal.module";
import { IntegracaoFormAbasModule } from './integracao-form-abas/integracao-form-abas.module';

@NgModule({
    imports: [
        CommonModule,
        IntegracoesRoutingModule,
        ReactiveFormsModule,
        NgbModule,
        FormsModule,
        ControlMessagesModule,
        ConfirmModalModule,
        NgSelectModule,
        AlertModule.forRoot(),
        TabsModule.forRoot(),
        ModalModule,
        IntegracaoFormAbasModule
    ],
    declarations: [
        IntegracoesComponent
    ]
})
export class IntegracoesModule { }

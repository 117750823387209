import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CampoService } from '../../../services/campo.service';
import { CustomServiceValidateService } from '../../../shared/control-messages/custom-service-validate.service';

@Component({
  selector: 'app-campo-form',
  templateUrl: './campo-form.component.html',
  styleUrls: ['./campo-form.component.scss']
})
export class CampoFormComponent implements OnInit {

  public form: FormGroup;
  public plataformaCampos: any = [];

  constructor(private route: ActivatedRoute
    , private fb: FormBuilder
    , private toastr: ToastrService
    , private campoService: CampoService) { }

  ngOnInit() {
    this.initForm();
    if (this.route.snapshot.params.id) {
      this.getId(this.route.snapshot.params.id);
    }
  }

  initForm() {
    this.form = this.fb.group({
      id: ['', Validators.required],
      nome: ['', Validators.required],
      tipo: ['', Validators.required],
      exemplo: [''],
      valor_padrao: [''],
      obrigatorio: [''],      
      descricao: ['', Validators.required],
      campos_listas : this.fb.array([{
        id: [''],
        campo_id: [''],
        valor: [''],
        codigo_referente: [''],
      }])
    });
  }

  getId(id: number) {
    this.campoService.getId(id).toPromise().then(async response => {
      const arrayCamposListas = [];
      this.plataformaCampos = response.plataforma_campos;

      response.campos_listas.forEach(element => {
        arrayCamposListas.push(element);
      });      
      this.form.controls.campos_listas = this.fb.array(arrayCamposListas);
      
      this.form.patchValue(response);
    });
  }

  salvar() {
    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
      return false;
    }    
    
    this.form.value.obrigatorio = this.form.value.obrigatorio ? 1 : 0;    

    //Formulário back-end não está preparado para salvar com estas listas    
    delete this.form.value.campos_listas;

    this.campoService.salvar(this.form.value).subscribe(response => {
      this.toastr.success('Campo salvo com sucesso.');
    }, e => {
      CustomServiceValidateService.customMensageService(e.error, this.form);
    });
  }

  get campos_listas(): FormArray {
    return this.form.get('campos_listas') as FormArray;
  }

}

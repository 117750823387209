import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SegmentacaoLeadsFormComponent } from './segmentacao-leads-form/segmentacao-leads-form.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Listas de Segmentações de Leads'
    },
    children: [      
      {
        path: 'form/:id',
        component: SegmentacaoLeadsFormComponent,
        data: {
          title: 'Cadastro de Segmentação de Leads'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SegmentacaoLeadsRoutingModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ControlMessagesModule } from '../../../../shared/control-messages/control-messages.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfirmModalModule } from '../../../../shared/confirm-modal/confirm-modal.module';
import { UsuarioGrupoFormComponent } from './usuario-grupo-form.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ControlMessagesModule,
    NgSelectModule,    
    TooltipModule.forRoot(),
    ConfirmModalModule
  ],
  declarations: [UsuarioGrupoFormComponent],
  exports: [UsuarioGrupoFormComponent],
})
export class UsuarioGrupoFormModule { }

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoricoService, HistoricoState, HistoricoTemplateDirective, HistoricoModalComponent } from './historico-modal-and-service';
import { RouterModule } from '@angular/router';
@NgModule({
    declarations: [HistoricoTemplateDirective, HistoricoModalComponent],
    imports: [RouterModule, CommonModule],
    exports: [CommonModule, HistoricoTemplateDirective, HistoricoModalComponent],

    providers: [HistoricoService, HistoricoState],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA],
})
export class HistoricoModalModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompleteDigitoCnpjPipe } from './complete-digito-cnpj.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CompleteDigitoCnpjPipe],
  exports: [CompleteDigitoCnpjPipe]
})
export class CompleteDigitoCnpjModule { }

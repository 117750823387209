<div class="animated fadeIn">
    <div class="row">

        <div class="col-md-12">
            <ul ngbNav [activeId]="activatedRoute.fragment | async" class="nav-tabs">
                <li [ngbNavItem]="link.fragment" *ngFor="let link of links">
                    <a ngbNavLink
                       [routerLink]="link.url"
                       [fragment]="link.fragment"
                    >
                        {{ link.title }}
                    </a>
                </li>
            </ul>
        </div>

    </div>
</div>

<router-outlet></router-outlet>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SegmentacaoLeadsAcoesComponent } from './segmentacao-leads-acoes.component';
import { ExportarLeadsModule } from '../exportar-leads/exportar-leads.module';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ConfirmModalModule } from '../../shared/confirm-modal/confirm-modal.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AutocompleteUsuarioModule } from '../form-component/autocomplete-usuario/autocomplete-usuario.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    SegmentacaoLeadsAcoesComponent
  ],
  imports: [
    CommonModule,
    ExportarLeadsModule,
    BsDropdownModule.forRoot(),
    ConfirmModalModule,
    AutocompleteUsuarioModule,
    ModalModule.forRoot(),
    AlertModule,
    NgSelectModule,
    NgbModule,


  ],
  exports: [
      SegmentacaoLeadsAcoesComponent
  ]
})
export class SegmentacaoLeadsAcoesModule { }

// import { Injectable } from '@angular/core';
// import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

// @Injectable()
// export class CustomNgbDatepickerConfig extends NgbDatepickerConfig {
//   constructor() {
//     super();
//     this.firstDayOfWeek = 7;
//    }
// }
import { Injectable } from '@angular/core';
//import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomNgbDatepickerConfig {
  constructor() {
    // super();
    // this.firstDayOfWeek = 7;
   }
}

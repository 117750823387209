import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class PlataformaEventoSaidaService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  get(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'PlataformaEventoSaidas/getAll.json', { params: params });
  }

  getId(id) {
    return this.http.get<PlataformaEventoSaidas>(environment.environment.nomeServiceIntegra + `PlataformaEventoSaidas/getId/${id}.json`);
  }

  salvar(params) {
    this.sessaoService.delete('ServiceTodosEventoSaidas');
    return this.http.post(environment.environment.nomeServiceIntegra + 'PlataformaEventoSaidas/postSave.json', { PlataformaEventoEntradas: params });
  }

  excluir(id) {
    this.sessaoService.delete('ServiceTodosEventoSaidas');
    return this.http.delete(environment.environment.nomeServiceIntegra + `PlataformaEventoSaidas/delete/${id}.json`);
  }

  listarTodosEventoSaidas() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceTodosEventoSaidas')) {
        resolve(this.sessaoService.get('ServiceTodosEventoSaidas'));
      } else {
        let params : any = { ativo: 1, plataforma_ativo: 1};
        this.http.get(environment.environment.nomeServiceIntegra + 'PlataformaEventoSaidas/getAll.json', { params: params })
          .toPromise().then((res: any) => {
            res.map((c) => {
              if(c.plataforma && c.plataforma.nome){
                c.nomeCustom = c.plataforma.nome + ' - ' + c.nome;
              } else {
                c.nomeCustom = c.nome;
              }
              return c;
            });
            this.sessaoService.set('ServiceTodosEventoSaidas', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

  listarTodosEventoSaidasComCampanha() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceTodosEventoSaidasCampanha')) {
        resolve(this.sessaoService.get('ServiceTodosEventoSaidasCampanha'));
      } else {
        let params : any = { ativo: 1, campanha: 1, plataforma_ativo: 1};
        this.http.get(environment.environment.nomeServiceIntegra + 'PlataformaEventoSaidas/getAll.json', { params: params })
          .toPromise().then((res: any) => {
            res.map((c) => {
              if(c.plataforma && c.plataforma.nome){
                c.nomeCustom = c.plataforma.nome + ' - ' + c.nome;
              } else {
                c.nomeCustom = c.nome;
              }
              return c;
            });
            this.sessaoService.set('ServiceTodosEventoSaidasCampanha', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }
}

export class PlataformaEventoSaidas {
  id: number;
  plataforma_id: number;
  codigo_interno: string;
  nome: string;
  descricao: string;
  ativo: boolean;
  usuario_id: number;
  plataforma: {
    nome: string;
  };
}

<div class="animated fadeIn">

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-paperclip"></i>
                    <strong>Cadastro - Segmentação de Leads</strong>&nbsp;
                    <small class="text-muted">(Garanta que a lista esteja salva antes de enviá-la para uma ação. Ao editar o formulário e enviar para uma ação, aguarde o processamento antes de alterar a consulta e reenviá-la para outra ação.)</small>

                    <div class="pull-right">
                        <app-segmentacao-leads-acoes *ngIf="s" [s]="s" [isEdit]="true" (atualizarSegmentos)="novo($event)"></app-segmentacao-leads-acoes>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <button type="button" class="btn btn-primary" (click)="novo({salvar: false})">
                            <i class="fa fa-plus"></i>
                            Cadastrar Novo
                        </button>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <button type="button" class="btn btn-success pull-right" (click)="salvar()">
                            <i class="fa fa-check"></i>
                            Salvar
                        </button>
                    </div>
                </div>
                <div class="card-body">

                    <div class="row">
                        <div class="col-sm-3 col-lg-9">

                            <form [formGroup]="form">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="name">Nome da Lista:</label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" name="nome"
                                                    formControlName="nome">
                                            </div>
                                            <app-control-messages [control]="form.controls.nome"></app-control-messages>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="name">Usuário responsável por essa segmentação:</label>
                                            <div class="input-group">
                                                <ng-template #rtUser let-r="result" let-t="term">
                                                    {{ r.nome }}
                                                </ng-template>

                                                <input type="text" class="form-control" formControlName="usuario_resp"
                                                    [class.is-invalid]="searchFailedUser" [ngbTypeahead]="searchUser"
                                                    [inputFormatter]="formatterUser" [resultTemplate]="rtUser"
                                                    placeholder="Digite o nome do responsável" />
                                                <div class="invalid-feedback" *ngIf="searchFailedUser">Desculpe, não
                                                    conseguimos buscar este responsável.</div>
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="fa fa-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <app-control-messages [control]="form.controls.responsavel_id">
                                            </app-control-messages>
                                            <app-control-messages [control]="form.controls.usuario_resp">
                                            </app-control-messages>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </form>

                            <div class="row" *ngFor="let g of query; let iGrupo=index">
                                <div class="col-sm-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="form-group row">
                                                <label for="instrucao" class="col-sm-4 col-form-label"><strong>Grupo
                                                        {{getNumSequencia(iGrupo)}}</strong> Quero Leads que
                                                    atendam</label>
                                                <div class="col-sm-3">
                                                    <ng-select [items]="listInstrucoes" bindLabel="nome"
                                                        bindValue="value" placeholder="Selecione" name="instrucao"
                                                        [(ngModel)]="g.instrucao">
                                                    </ng-select>
                                                </div>
                                                <div class="col-sm-2">
                                                    <button type="button" class="btn btn-danger"
                                                        (click)="removerGrupo(iGrupo)">
                                                        <i class="fa fa-times fa-sm"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row" *ngFor="let c of g.condicoes; let iCondicao=index">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="name">Campo:</label>
                                                        <ng-select [items]="listCampos" bindLabel="nome"
                                                            bindValue="value" placeholder="Selecione um campo"
                                                            name="campo" [(ngModel)]="c.campo"
                                                            (change)="mudarCondicao($event,iGrupo,iCondicao)"
                                                        >
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="name">Condição:</label>
                                                        <ng-select [items]="query[iGrupo].condicoes[iCondicao].condicoes" bindLabel="nome"
                                                            bindValue="operador" placeholder="Selecione uma condição"
                                                            name="operador" [(ngModel)]="c.operador"
                                                            (change)="setControleCondicao(iGrupo, iCondicao, $event)">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group" [hidden]="c.esconder_valor || getCampo(c.campo)?.tipo =='select'">
                                                        <label for="name">Valor:</label>
                                                        <div class="input-group">
                                                            <input class="form-control" type="text" name="valor"
                                                                [placeholder]="c.dica" [(ngModel)]="c.valor">
                                                        </div>
                                                    </div>
                                                    <div class="form-group" [hidden]="c.esconder_valor" *ngIf="getCampo(c.campo)?.tipo =='select'">
                                                        <label for="CodSelect">{{getCampo(c.campo).nome}}:</label>
                                                        <ng-select [items]="getCampo(c.campo).items" [bindLabel]="getCampo(c.campo).bindLabel" [bindValue]="getCampo(c.campo).bindValue"
                                                            placeholder="Selecione" [(ngModel)]="c.valor"
                                                            name="name" (change)="getCampo(c.campo)?.change($event)">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group pull-left">
                                                        <label for="name">Ação:</label>
                                                        <button type="button" class="btn btn-danger"
                                                            (click)="removerCondicao(iGrupo, iCondicao)">
                                                            <i class="fa fa-times fa-xs"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 pull-left">
                                                    <button type="button" class="btn btn-primary"
                                                        (click)="novaCondicao(iGrupo)">
                                                        <i class="fa fa-plus fa-sm"></i>
                                                        Criar outra condição
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="card-footer">
                                        <button type="button" class="btn btn-primary" (click)="novoGrupo()">
                                            <i class="fa fa-plus fa-sm"></i>
                                            Criar outro grupo
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-3 col-lg-3">


                            <div class="row">
                                <div class="col-sm-3 col-lg-12">
                                    <div class="input-group">
                                        <h5 class="pull-center">TOTAL DE LEADS</h5>&nbsp;<button type="button"
                                            class="btn btn-warning btn-sm" (click)="carregarLeads()" tooltip="Clique aqui para atualizar o contador">
                                            <i class="fa fa-refresh fa-sm"></i></button>
                                    </div>
                                    <div class="row">&nbsp;</div>
                                    <div class="card text-white badge-success">
                                        <div class="card-body pb-0 text-center">
                                            <div class="text-value">{{totalItems}}</div>
                                            <div>&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="leads && leads.length == 0">
                                <div class="col-lg-12">
                                    <div><span><strong>Não encontrado leads com estes parâmetros, clique para atualizar.</strong></span></div>
                                </div>
                            </div>
                            <div class="row" *ngFor="let l of leads; let i = index">
                                <div class="col-lg-12">
                                    <div>
                                        <span class="badge badge-secondary">{{l?.Plataformas?.nome}}</span>
                                        &nbsp;|&nbsp;
                                        <span class="badge badge-secondary">{{l?.ConteudoTipos?.nome}}</span>
                                    </div>
                                    <div><strong>{{l?.ConteudoPublicados?.identificador}}</strong></div>
                                    <div class="small text-muted">
                                        <span><strong>TIPO CAMPANHA:&nbsp;</strong></span>{{l?.CampanhaTipos?.nome}}
                                    </div>
                                    <div class="small text-muted">
                                        <span><strong>RESP. CAMPANHA:&nbsp;</strong></span>{{l?.ConteudoPublicados?.responsavel_nome}}
                                    </div>
                                    <div class="small text-muted">
                                        <span><strong>ID INTEGRA:&nbsp;</strong></span>{{l?.id}}
                                    </div>
                                    <div class="small text-muted">
                                        <span><strong>ID IMPORTAÇÃO CSV:&nbsp;</strong></span>{{l?.ConteudoFilaImportacoes?.conteudo_importacao_id}}
                                    </div>
                                    <div class="small text-muted text-uppercase">
                                        <span><strong>STATUS:&nbsp;</strong></span>{{l?.status}} | {{l?.tipo_cliente}} |
                                        {{l?.modified | date:
                                        'dd/MM/yyyy H:mm'}}
                                    </div>
                                    <div><strong>{{l?.nome_cliente}}</strong></div>
                                    <div class="small text-muted">
                                        <span><strong>CPF:&nbsp;</strong></span>{{(l?.cpf_origem ? l?.cpf_origem :
                                        l?.cpf_encontrado) |
                                        completeDigitoCpf | mask: '000.000.000-00'}}
                                    </div>
                                    <div class="small text-muted">
                                        <span><strong>E-MAIL:&nbsp;</strong></span>{{l?.email_cliente}}
                                    </div>
                                    <div><strong>{{l?.nome_empreendimento}}</strong></div>
                                    <div class="small text-muted">
                                        <span><strong>CNPJ:&nbsp;</strong></span>{{(l?.cnpj_origem ? l?.cnpj_origem :
                                        l?.cnpj_encontrado) |
                                        completeDigitoCnpj | mask: '000.000.000-00'}}
                                    </div>
                                    <div class="small text-muted" *ngIf="l?.email_responsavel">
                                        <span><strong>E-MAIL RESP.:&nbsp;</strong></span>{{l?.email_responsavel}}
                                    </div>
                                    <div class="small text-muted">
                                        <span><strong>INCLUÍDO:&nbsp;</strong></span>{{l?.created | date:
                                        'dd/MM/yyyy H:mm'}}
                                    </div>
                                    <div class="small text-muted">
                                        <span><strong>ALTERADO:&nbsp;</strong></span>{{l?.modified | date:
                                        'dd/MM/yyyy H:mm'}}
                                    </div>
                                    <div class="small text-muted">
                                        <span><strong>REPROCESSADO:&nbsp;</strong></span>{{l?.qtd_reprocessado}}
                                    </div>
                                    <hr style="width: 100%; color: black; height: 1px; background-color:black;" />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="animated fadeIn">
  <div class="row">

    <div class="col-md-12 mb-12">
      <!-- Nav tabs USUÁRIO / GRUPO DE ACESSO -->
      <tabset>
        <tab>
          <ng-template tabHeading><i class="icon-user"></i> Dados do Usuário</ng-template>

          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-paperclip"></i>
                <strong>Cadastro de Usuário</strong>


                <div class="pull-right">
                  <button type="button" class="btn btn-primary" (click)="novo()">
                    <i class="fa fa-plus"></i>
                    Cadastrar Novo
                  </button>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <button type="button" class="btn btn-success pull-right" (click)="salvar()">
                    <i class="fa fa-check"></i>
                    Salvar
                  </button>
                </div>
              </div>
              <div class="card-body">
                <app-control-list-error-messages [errors]="listErros"></app-control-list-error-messages>

                <form [formGroup]="form" autocomplete="off">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Tipo de Usuário:</label>
                        <ng-select [items]="tipoUsuarios" bindLabel="nome" bindValue="id"
                          placeholder="Selecione um tipo de usuário" formControlName="tipo_usuario_id"
                          name="tipo_usuario_id">
                        </ng-select>
                        <app-control-messages [control]="form.controls.tipo_usuario_id"></app-control-messages>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="name">Login:</label>
                        <div class="input-group">
                          <input class="form-control" type="text" name="login" formControlName="login" autocomplete="new">
                        </div>
                        <app-control-messages [control]="form.controls.login"></app-control-messages>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="name">Senha Local:</label>
                        <div class="input-group">
                          <input class="form-control" type="password" name="senha" formControlName="senha" autocomplete="new-password">
                        </div>
                        <app-control-messages [control]="form.controls.senha"></app-control-messages>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="name">Nome Completo:</label>
                        <div class="input-group">
                          <input class="form-control" type="text" name="nome" formControlName="nome">
                        </div>
                        <app-control-messages [control]="form.controls.nome"></app-control-messages>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="name">CPF:</label>
                        <div class="input-group">
                          <input class="form-control" type="text" name="cpf" formControlName="cpf"
                            mask="000.000.000-00">
                        </div>
                        <app-control-messages [control]="form.controls.cpf"></app-control-messages>
                      </div>
                    </div>
                      <div class="col-md-4">
                          <div class="form-group">
                              <label for="celular">Celular:</label>
                              <div class="input-group">
                                  <input class="form-control" type="text" name="celular" formControlName="celular"
                                         mask="(00) 00000-0000">
                              </div>
                              <app-control-messages [control]="form.controls.celular"></app-control-messages>
                          </div>
                      </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="name">E-mail:</label>
                        <div class="input-group">
                          <input class="form-control" type="text" name="email" formControlName="email">
                        </div>
                        <app-control-messages [control]="form.controls.email"></app-control-messages>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label for="name">Ativo:</label>
                        <div class="input-group">
                          <label class="switch switch-label switch-pill switch-primary">
                            <input type="checkbox" class="switch-input" checked value="1" formControlName="ativo">
                            <span class="switch-slider" data-checked="Sim" data-unchecked="Não"></span>
                          </label>
                        </div>
                        <app-control-messages [control]="form.controls.ativo"></app-control-messages>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label for="name">Usuário AD:</label>
                        <div class="input-group">
                          <label class="switch switch-label switch-pill switch-primary">
                            <input type="checkbox" class="switch-input" checked value="1" formControlName="usuario_ad">
                            <span class="switch-slider" data-checked="Sim" data-unchecked="Não"></span>
                          </label>
                        </div>
                        <app-control-messages [control]="form.controls.usuario_ad"></app-control-messages>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label for="name">Administrador:</label>
                        <div class="input-group">
                          <label class="switch switch-label switch-pill switch-primary">
                            <input type="checkbox" class="switch-input" checked value="1" formControlName="admin">
                            <span class="switch-slider" data-checked="Sim" data-unchecked="Não"></span>
                          </label>
                        </div>
                        <app-control-messages [control]="form.controls.admin"></app-control-messages>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="name">Chave SAS:</label>
                        <div class="input-group">
                          <input class="form-control" type="text" name="chave_sas" formControlName="chave_sas" (change)="carregarDadosChaveSas()">
                        </div>
                        <app-control-messages [control]="form.controls.chave_sas"></app-control-messages>
                      </div>
                    </div>
                  </div>

                  <div class="row" *ngIf="infoUsuarioSas">
                    <div class="col-md-5">
                      <table class="table table-bordered table-striped table-sm">
                        <tbody>
                          <tr>
                            <th colspan="2">Dados da Chave deste Usuário no SAS</th>
                          </tr>
                          <tr>
                            <th>Código SAS</th>
                            <td>{{infoUsuarioSas?.ID}}</td>
                          </tr>
                          <tr>
                            <th>Nome</th>
                            <td>{{infoUsuarioSas?.Nome}}</td>
                          </tr>
                          <tr>
                            <th>CPF</th>
                            <td>{{infoUsuarioSas?.CgcCpf | mask: '000.000.000-00'}}</td>
                          </tr>
                          <tr>
                            <th>E-mail</th>
                            <td>{{infoUsuarioSas?.Email}}</td>
                          </tr>
                          <tr>
                            <th>Telefone</th>
                            <td>{{infoUsuarioSas?.Telefone}}</td>
                          </tr>
                          <tr>
                            <th>Login</th>
                            <td>{{infoUsuarioSas?.Login}}</td>
                          </tr>
                          <tr>
                            <th>Tipo</th>
                            <td>{{infoUsuarioSas?.tipo_usuario ? infoUsuarioSas.tipo_usuario?.Descricao : ''}}
                            </td>
                          </tr>
                          <tr>
                            <th>Unidade</th>
                            <td>{{infoUsuarioSas?.Unidade ? infoUsuarioSas.Unidade : ''}}
                            </td>
                          </tr>
                          <tr>
                            <th>Usuário AD</th>
                            <td>{{infoUsuarioSas?.UsuarioAD ? 'SIM' : 'NÃO'}}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>
                                <span [ngClass]="infoUsuarioSas?.Ativo ? 'badge badge-success' : 'badge badge-danger'">{{infoUsuarioSas?.Ativo ? 'ATIVO' : 'INATIVO'}}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="col-md-7">
                      <alert type="danger" *ngIf="infoUsuarioSas?.wsSemPermissoes && infoUsuarioSas.wsSemPermissoes.length">
                        <strong>* WebServices</strong> destacados em vermelho necessita de permissão no SAS para total funcionamento do sistema.
                      </alert>

                      <table class="table table-bordered table-striped table-sm">
                        <thead>
                          <th>Métodos</th>
                          <th>Validade</th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let wsP of infoUsuarioSas?.wsSemPermissoes;" style="color:red">
                            <td>{{wsP?.metodo}}</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr *ngFor="let ws of infoUsuarioSas?.webservices;">
                            <td>{{ws?.Metodo}}</td>
                            <td>{{ws?.DataValidade | date: 'dd/MM/yyyy'}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="clearfix"></div>
                </form>
              </div>
            </div>
          </div>

        </tab>
        <tab *ngIf="form.value.id">
          <ng-template tabHeading><i class="icon-settings"></i> Grupos de Acesso</ng-template>
          <app-usuario-grupo-form [usuarioGrupos]="usuarioGrupos"></app-usuario-grupo-form>
        </tab>
      </tabset>
    </div>
    <!--/.col-- FIM USUÁRIO / GRUPO DE ACESSO -->

  </div>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AtendimentoConfiguracoesFormComponent } from './atendimento-configuracoes-form/atendimento-configuracoes-form.component';


const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Lista de Configurações'
    },
    children: [      
      {
        path: 'form/:id',
        component: AtendimentoConfiguracoesFormComponent,
        data: {
          title: 'Alterar Configuração'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AtendimentoConfiguracoesRoutingModule { }

import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CampanhaFormStateService } from '../services/campanha-form-state.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfirmService } from '../shared/confirm-modal/confirm-modal-and-service';
import { CampanhaFormSaveService } from '../services/campanha-form-save.service';

@Injectable({
  providedIn: 'root'
})
export class CampanhaUnsavedChanges implements CanDeactivate<any> {
  links: any = [];

  constructor(
    private campanhaFormStateService: CampanhaFormStateService,
    private confirmService: ConfirmService,
    private campanhaFormSaveService: CampanhaFormSaveService
  ) {

    this.links = [
      { title: 'Campanha/Eventos', fragment: 'campEve', url: 'principal', viewChild: 'campAbaPrincipalForm' },
      { title: 'Moskit CRM', fragment: 'campEveMoskCrm', url: 'moskit', viewChild: 'campAbaMoskitForm' },
      { title: 'SAS', fragment: 'campEveSas', url: 'sas', viewChild: 'campAbaSasForm' },
      { title: 'Importações CSV', fragment: 'campImportCsv', viewChild: '' },
    ];
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    const fragment = currentRoute && currentRoute.fragment ? currentRoute.fragment : null;
    const nextUrl = nextState && nextState.url ? nextState.url : null;
    console.log('Tentando navegar para:', nextUrl);

    // Verifica se o formulário está sujo
    return this.campanhaFormStateService.isDirty$.pipe(
      switchMap(isDirty => {
        if (isDirty) {

          // Exibe o modal de confirmação se houver alterações não salvas
          return this.confirmService.confirm({
            title: 'Alterações Não Salvas',
            message: 'Você tem alterações não salvas. Deseja salvar as alterações primeiro e, em seguida, acessar a rota ' + nextUrl + '?'
          }).then(() => {
            // Marca o formulário como limpo
            this.campanhaFormStateService.setFormDirty(false);

            const link = this.links.find(item => item.fragment === fragment);
            if (link && link.viewChild) {
              // Salva o formulário
              this.campanhaFormSaveService.triggerSaveForComponent(link.viewChild);
              // Marca o formulário como limpo
              this.campanhaFormStateService.setFormDirty(false);
            }

            return true;
            // Timeout de 3 segundos antes de permitir a navegação
            // return new Promise<boolean>(resolve => {
            //   setTimeout(() => {
            //     resolve(true);  // Permite a navegação após o timeout de 3 segundos
            //   }, 3000);  // Atraso de 3 segundos
            // });
          },
          () => {
            // Marca o formulário como limpo
            this.campanhaFormStateService.setFormDirty(false);
            return true;
          });
        } else {
            return of(true);  // Permite a navegação se o formulário não estiver sujo
        }
      })
    );
  }
}

<div class="animated fadeIn">
    <div class="row"><div class="col-md-12"><h4>Processamento de Leads em Tempo Real</h4></div></div>

    <div class="row">
        <div class="col-sm-4 col-lg-3">
            <div class="brand-card callout callout-primary">
                <div class="card-body pb-0 card-link ">
                    <div class="text-value text-primary"><strong>{{filaImportacaoCsv?.messages_persistent || 0}}</strong>
                    </div>
                    <div class="text-primary text-uppercase"><strong>Importação Leads Arquivos .csv</strong></div>
                </div>

                <div class="brand-card-body">
                    <div class="text-warning card-link">
                        <div class="text-value">{{filaImportacaoCsv?.messages_ready || 0}}</div>
                        <div class="text-muted small">Aguardando</div>
                    </div>
                    <div class="text-primary card-link">
                        <div class="text-value">{{filaImportacaoCsv?.messages_unacknowledged  || 0}}</div>
                        <div class="text-muted small ">Entregue</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-4 col-lg-3">
            <div class="brand-card callout callout-primary">
                <div class="card-body pb-0 card-link ">
                    <div class="text-value text-primary"><strong>{{filaAcoesAssincrona?.messages_persistent || 0}}</strong>
                    </div>
                    <div class="text-primary text-uppercase"><strong>Ações Assíncrona do Sistema</strong></div>
                </div>

                <div class="brand-card-body">
                    <div class="text-warning card-link">
                        <div class="text-value">{{filaAcoesAssincrona?.messages_ready || 0}}</div>
                        <div class="text-muted small">Aguardando</div>
                    </div>
                    <div class="text-primary card-link">
                        <div class="text-value">{{filaAcoesAssincrona?.messages_unacknowledged  || 0}}</div>
                        <div class="text-muted small ">Entregue</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-4 col-lg-3">
            <div class="brand-card callout callout-info">
                <div class="card-body pb-0 card-link ">
                    <div class="text-value text-primary"><strong>{{filaPreprocessarHook?.messages_persistent || 0}}</strong>
                    </div>
                    <div class="text-primary text-uppercase"><strong>Pré-Processamento Webhook</strong></div>
                </div>

                <div class="brand-card-body">
                    <div class="text-warning card-link">
                        <div class="text-value">{{filaPreprocessarHook?.messages_ready || 0}}</div>
                        <div class="text-muted small">Aguardando</div>
                    </div>
                    <div class="text-primary card-link">
                        <div class="text-value">{{filaPreprocessarHook?.messages_unacknowledged || 0}}</div>
                        <div class="text-muted small ">Entregue</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-4 col-lg-3">
            <div class="brand-card callout callout-info">
                <div class="card-body pb-0 card-link ">
                    <div class="text-value text-primary"><strong>{{filaPreprocessarAuto?.messages_persistent || 0}}</strong>
                    </div>
                    <div class="text-primary text-uppercase"><strong>Pré-Processamento Automações</strong></div>
                </div>

                <div class="brand-card-body">
                    <div class="text-warning card-link">
                        <div class="text-value">{{filaPreprocessarAuto?.messages_ready || 0}}</div>
                        <div class="text-muted small">Aguardando</div>
                    </div>
                    <div class="text-primary card-link">
                        <div class="text-value">{{filaPreprocessarAuto?.messages_unacknowledged || 0}}</div>
                        <div class="text-muted small ">Entregue</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-lg-12">
            <div class="brand-card callout callout-success">
                <div class="card-body pb-0 card-link ">
                    <div class="text-value text-success"><strong>{{totalMensagensPersistent || 0}}</strong></div>
                    <div class="text-success text-uppercase"><strong>Total de Mensagens na Fila ({{filas[0]?.vhost}})</strong></div>
                    <div class="text-success text-uppercase"><strong>Consumers em Execução ({{filas[0]?.consumers}})</strong></div>
                </div>

                <div class="brand-card-body">
                    <div class="text-warning card-link">
                        <div class="text-value">{{totalMensagensReady || 0}}</div>
                        <div class="text-muted small">Aguardando</div>
                    </div>
                    <div class="text-primary card-link">
                        <div class="text-value">{{totalMensagensUnacknowledged || 0}}</div>
                        <div class="text-muted small ">Entregue</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <!-- <h4 class="card-title mb-0">Listagem das Filas</h4> -->

            <table class="table table-hover">
                <thead class="thead-light">
                    <tr>
                        <th style="cursor:pointer">
                            <span [class.active]="order === 'name'" (click)="setOrder('name')">Nome do Integrador<span
                                    [hidden]="reverse" >▼</span><span [hidden]="!reverse">▲</span></span>
                        </th>
                        <th>
                            <div class="brand-card-body">
                                <div class="text-secondary card-link" style="cursor:pointer">
                                    <span [class.active]="order === 'messages_ready'"
                                        (click)="setOrder('messages_ready')">
                                        <div class="text-muted small"><strong>Aguardando</strong><span
                                                [hidden]="reverse">▼</span><span [hidden]="!reverse">▲</span></div>
                                    </span>
                                </div>
                                <div class="text-danger card-link" style="cursor:pointer">
                                    <span [class.active]="order === 'messages_unacknowledged'"
                                        (click)="setOrder('messages_unacknowledged')">
                                        <div class="text-muted small"><strong>Entregue</strong><span
                                                [hidden]="reverse">▼</span><span [hidden]="!reverse">▲</span></div>
                                    </span>
                                </div>
                                <div class="text-success card-link" style="cursor:pointer">
                                    <span [class.active]="order === 'messages_persistent'"
                                        (click)="setOrder('messages_persistent')">
                                        <div class="text-muted small"><strong>Total</strong><span
                                                [hidden]="reverse">▼</span><span [hidden]="!reverse">▲</span></div>
                                    </span>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="filas">
                    <tr *ngFor="let item of filas | orderBy:order:reverse:false">
                        <td class="card-link">
                            <strong>{{item.name.replace('-PROCESSAMENTO-DE-LEADS', '')}}</strong>
                            <div>
                                <small class="text-muted">Prioridade ({{item?.arguments?.hasOwnProperty('x-max-priority') ? item.arguments['x-max-priority'] : 'N/A'}})</small>
                            </div>
                        </td>
                        <td nowrap>
                            <div class="brand-card-body">
                                <div class="text-warning card-link">
                                    <div class="text-value">{{item?.messages_ready || 0}}</div>
                                    <!-- <div class="text-uppercase text-muted small">Aguardando</div> -->
                                </div>
                                <div class="text-primary card-link">
                                    <div class="text-value">{{item?.messages_unacknowledged || 0}}</div>
                                    <!-- <div class="text-uppercase text-muted small">Entregue</div> -->
                                </div>
                                <div class="text-success card-link">
                                    <div class="text-value">{{item?.messages_persistent || 0}}</div>
                                    <!-- <div class="text-uppercase text-muted small">Total</div> -->
                                </div>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>

</div>

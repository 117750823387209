<div class="animated fadeIn">
    <div class="row">

        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-paperclip"></i>
                    <strong>Cadastro de Produto</strong>

                    <div class="pull-right">
                        <button type="button" class="btn btn-primary" (click)="novo()">
                            <i class="fa fa-plus"></i>
                            Cadastrar Novo
                        </button>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <button type="button" class="btn btn-success pull-right" (click)="salvar()">
                            <i class="fa fa-check"></i>
                            Salvar
                        </button>
                    </div>
                </div>
                <div class="card-body">

                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label for="name">Cód. CRM:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" name="cod_crm"
                                            formControlName="cod_crm">
                                    </div>
                                    <app-control-messages [control]="form.controls.cod_crm"></app-control-messages>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label for="name">Cód. Externo:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" name="cod_externo"
                                            formControlName="cod_externo">
                                    </div>
                                    <app-control-messages [control]="form.controls.cod_externo"></app-control-messages>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label for="name">Cód. SAS:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" name="cod_sas"
                                            formControlName="cod_sas">
                                    </div>
                                    <app-control-messages [control]="form.controls.cod_sas"></app-control-messages>
                                </div>
                            </div>
                            <div class="col-sm-10">
                                <div class="form-group">
                                    <label for="name">Nome do Produto:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" name="nome" formControlName="nome">
                                    </div>
                                    <app-control-messages [control]="form.controls.nome"></app-control-messages>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label for="name">Valor:</label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" formControlName="valor" name="valor"
                                            currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
                                    </div>
                                    <app-control-messages [control]="form.controls.valor"></app-control-messages>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="name">Descrição do Produto:</label>
                                    <div class="input-group">
                                        <textarea class="form-control" name="descricao"
                                            formControlName="descricao"></textarea>
                                    </div>
                                    <app-control-messages [control]="form.controls.descricao"></app-control-messages>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="form-group">
                                    <label for="name">Ativo:</label>
                                    <div class="input-group">
                                        <label class="switch switch-label switch-pill switch-primary">
                                            <input type="checkbox" class="switch-input" checked value="1"
                                                formControlName="ativo">
                                            <span class="switch-slider" data-checked="Sim" data-unchecked="Não"></span>
                                        </label>
                                    </div>
                                    <app-control-messages [control]="form.controls.ativo"></app-control-messages>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="workflows">WorkFlow: </label>

                                    <ng-select [items]="workflows" bindLabel="nome" bindValue="id" (change)="atualizarWorkflow()"
                                               placeholder="Selecione um WorkFlow" formControlName="workflow_id"
                                               name="workflow_id">
                                    </ng-select>
                                    <app-control-messages [control]="form.controls.workflow_id"></app-control-messages>

                                </div>
                            </div>
                            <div class="col-md-12"  *ngIf="workflow.nome">
                                <alert type="info">
                                    <strong>{{workflow.nome}}:</strong> {{workflow.descricao}}
                                </alert>
                            </div>
                            <app-workflow-view  [content]="contentChart" *ngIf="form.controls.workflow_id.value != null" ></app-workflow-view>
                        </div>

                        <div class="clearfix"></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProdutoService } from '../../../services/produto.service';
import { ToastrService } from 'ngx-toastr';
import { CustomServiceValidateService } from '../../../shared/control-messages/custom-service-validate.service';
import {WorkflowService} from "../../../services/workflow.service";

@Component({
  selector: 'app-produto-form',
  templateUrl: './produto-form.component.html',
  styleUrls: ['./produto-form.component.scss']
})
export class ProdutoFormComponent implements OnInit {

  form: FormGroup;
    workflows: any;
    contentChart: any;
    workflow: any = { nome: null , descricao: null };
  constructor(private fb: FormBuilder
    , private produtoService: ProdutoService
    , private toastr: ToastrService
    , private router: Router
      , private workflowService: WorkflowService
    , private route: ActivatedRoute) { }

  ngOnInit() {
      this.initForm();
      this.workflowService.getWorkflows({ativo : 1}).then((w: any) => {
          this.workflows = w;
          this.route.params.subscribe(routeParams => {
              if (routeParams.id && routeParams.id != 0) {
                  this.getId(routeParams.id);
              }
          });
      });

  }

  initForm() {
    this.form = this.fb.group({
        id: [''],
        nome: ['', Validators.required],
        descricao: [''],
        workflow_id:   [''],
        cod_crm: [''],
        cod_externo: [''],
        cod_sas: [''],
        valor: [''],
        ativo: [1]
    });
  }

  // facilitar o acesso no html
  get f() { return this.form.controls; }

  salvar() {
    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
      return false;
    }
    this.form.value.ativo = this.form.value.ativo ? 1 : 0;

    this.produtoService.salvar(this.form.value).subscribe(response => {
      this.toastr.success('Produto salvo com sucesso!');
      this.router.navigate(['/produtos/form/' + response['id']]);
    }, e => {
      CustomServiceValidateService.customMensageService(e.error, this.form);
    });
  }

  getId(id: number) {
    this.produtoService.getId(id).toPromise().then(response => {
      this.form.patchValue(response);
        this.atualizarWorkflow();
    });

  }

  novo() {
    this.initForm();
  }
    atualizarWorkflow(){
        if(this.form.controls.workflow_id.value != null){
            let workflow = this.workflows.find(f => f.id == this.form.controls.workflow_id.value);
            var content = null;
            if(workflow && workflow.chart_content){
                content = Array.isArray(workflow.chart_content) ? workflow.chart_content : String(workflow.chart_content.replace(/(\r\n|\n|\r)/gm, ""));//wanderson: o update do seeds as vezes cria quebra de linha no banco
            }
            this.contentChart = content ? JSON.parse(content) : null;
            this.workflow.nome = workflow.nome;
            this.workflow.descricao = workflow.descricao;
        }
    }
}

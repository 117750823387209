<div class="animated fadeIn">

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-search"></i>
          Pesquisa de <strong>Playbooks</strong>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Nome do Playbook:</label>
                <input class="form-control" type="text" [(ngModel)]="filter.nome" name="nome">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Ativo:</label>
                <ng-select [items]="listStatus" bindLabel="name" bindValue="value" placeholder="Selecione um status"
                  name="ativo" [(ngModel)]="filter.ativo">
                </ng-select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Registros por página:</label>

                <ng-select [items]="listItemsPerPage" placeholder="Selecione..." [(ngModel)]="filter.limit"
                  name="limit">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group pull-left">
              <br>
              <button type="button" class="btn btn-primary" (click)="pesquisar()">
                <i class="fa fa-search"></i>
                Pesquisar
              </button>
              &nbsp;&nbsp;
              <a class="btn btn-success" href="#" [routerLink]="['/playbooks/form/0']">
                <i class="fa fa-plus"></i> Cadastrar Playbook</a>
              &nbsp;&nbsp;
              <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                <i class="fa fa-ban"></i>
                Limpar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i>
          Resultados ({{totalItems}})
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="text-center">Código</th>
                <th>Nome do Playbook</th>
                <th>Responsável</th>
                <th>Campanha Associada</th>
                <th class="text-center">Automações</th>
                <th class="text-center">Ativo</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let s of playbooks; let i = index" >
                <td class="text-center">{{s?.id}}</td>
                <td>{{s?.nome}}</td>
                <td>{{s?.responsavel?.nome}}</td>
                <td>{{s?.campanha}}</td>
                <td class="text-center">{{s?.playbook_acoes?.length}}</td>
                <td class="text-center"><span [ngClass]="s?.ativo == 1 ? 'badge badge-success' : 'badge badge-danger'">{{s?.ativo
                  == 1 ? 'Sim' : 'Não'}}</span>
                </td>
                <td width="10%">
                    <div class="btn-group" dropdown placement="bottom right">
                        <button dropdownToggle type="button" class="btn btn-sm dropdown-toggle">
                            Ações <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="menuitem">
                                <a href="#" [routerLink]="['/playbooks/form', s?.id]" class="dropdown-item"><i class="fa fa-pencil"></i> Editar</a>
                            </li>
                            <li role="menuitem">
                                <button type="button" class="dropdown-item" (click)="deletar(s)"
                                    tooltip="Excluir">
                                    <i class="fa fa-times fa-lg mt-1 "></i> Excluir
                                </button>
                            </li>
                            <li class="divider dropdown-divider"></li>
                            <li role="menuitem">
                                <a href="#" [routerLink]="['/playbooks/negocios-moskit', s?.funil_id]" class="dropdown-item"><i class="fa fa-briefcase"></i> Visualizar Negócios Moskit</a>
                            </li>
                        </ul>
                    </div>
                </td>
              </tr>
              <tr *ngIf="playbooks && playbooks.length == 0">
                <td colspan="6">Não existe playbooks com estes parâmetros</td>
              </tr>
            </tbody>
          </table>

          <div class="col-md-12 justify-content-center">
            <pagination [boundaryLinks]="true" [itemsPerPage]="filter.limit" [totalItems]="totalItems"
              class="text-center" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template confirm>
    <confirm-modal-component></confirm-modal-component>
</ng-template>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rel-chechagem-externa',
  templateUrl: './rel-chechagem-externa.component.html',
  styleUrls: ['./rel-chechagem-externa.component.scss']
})
export class RelChechagemExternaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

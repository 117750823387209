import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], searchText: string, fieldName: string, equal: boolean = false): any[] {

        // return empty array if array is falsy
        if (!items) { return []; }

        // return the original array if search text is empty
        if (!searchText) { return items; }

        searchText = searchText.toLowerCase();

        return items.filter(item => {
          if (item && item[fieldName]) {
              if (equal) {
                  return item[fieldName].toString().toLowerCase() === searchText;
              } else {
                  return item[fieldName].toString().toLowerCase().includes(searchText);
              }
          }
          return false;
        });
       }
    }

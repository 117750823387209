import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { RegionalService } from '../../../services/regional.service';
import { CustomServiceValidateService } from '../../../shared/control-messages/custom-service-validate.service';

@Component({
  selector: 'app-regional-form',
  templateUrl: './regional-form.component.html',
  styleUrls: ['./regional-form.component.scss']
})
export class RegionalFormComponent implements OnInit {

  itemsCidades: TreeviewItem[];  
  checkCidades: string[];  
  form: FormGroup;
  jsonTreeCidades: any = [];  

  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 600
  });

  constructor(private fb: FormBuilder
    , private regionalService: RegionalService    
    , private toastr: ToastrService
    , private router: Router
    , private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.initForm();
    const regional_id = this.form.value.id ? this.form.value.id : (this.route.snapshot.params.id ? this.route.snapshot.params.id : '');

    this.jsonTreeCidades = await this.regionalService.getTreeviewCidades({ regional_id: regional_id }).toPromise();
    this.itemsCidades = this.getFormatTreeview(this.jsonTreeCidades);    

    if (regional_id && regional_id != 0) {
      this.getId(regional_id);
    }
  }

  initForm() {
    this.form = this.fb.group({
      id: [''],
      nome: ['', Validators.required],      
      ativo: [1]
    });
  }

  // facilitar o acesso no html
  get f() { return this.form.controls; }

  onFilterChangeCidade(value: string) {
    console.log('filter:', value);
  }  

  getFormatTreeview(jsonTree): TreeviewItem[] {
    let list: any = [];
    jsonTree.forEach((item) => {
      list.push(new TreeviewItem(item));
    });
    return list;
  }

  async salvar() {
    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
      return false;
    } else if (this.checkCidades.length <= 0) {
      this.toastr.warning('Por favor vincule ao menos uma cidade na regional!', 'Atenção');
      return false;
    }

    let regional_cidades: any = [];
    let listCidades: any = [];
    this.checkCidades.forEach((values) => {
      const obj = values.split(";");
      if (obj && obj[0]) {
        listCidades.push(obj[0]);
        regional_cidades.push({          
          CodCid: obj[0],
          id: obj[1]
        });
      }
    });    

    this.form.value.ativo = this.form.value.ativo ? 1 : 0;    
    this.form.value.regional_cidades = regional_cidades;
    this.regionalService.salvar(this.form.value).subscribe(response => {
      this.toastr.success('Regional salva com sucesso!');
      this.router.navigate(['/regionais/form/' + response['id']]);
    }, e => {
      CustomServiceValidateService.customMensageService(e.error, this.form);
    });
  }

  getId(id) {
    this.regionalService.getId(id).toPromise().then(response => {
      this.form.patchValue(response);
    });
  }

  async novo() {
    this.jsonTreeCidades = await this.regionalService.getTreeviewCidades({ regional_id: '' }).toPromise();
    this.itemsCidades = this.getFormatTreeview(this.jsonTreeCidades);    

    this.initForm();
  }

}

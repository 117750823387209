import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LeadsComponent } from './leads.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DateFormatModule } from '../../pipe/date-format/date-format.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { I18n, CustomDatepickerI18n } from '../../shared/datepicker/CustomDatepickerI18n';
import { NgbDatepickerI18n, NgbDateParserFormatter, NgbDatepickerConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatePTParserFormatter } from '../../shared/datepicker/NgbDatePTParserFormatter';
import { CustomNgbDatepickerConfig } from '../../shared/datepicker/CustomNgbDatepickerConfig';
import { MyNgbDateParserFormatter } from '../../shared/datepicker/MyNgbDateParserFormatter';
import { NgxMaskModule} from 'ngx-mask';

import { ConfirmModalModule } from '../../shared/confirm-modal/confirm-modal.module';
import { CompleteDigitoCpfModule } from '../../pipe/complete-digito-cpf/complete-digito-cpf.module';
import { CompleteDigitoCnpjModule } from '../../pipe/complete-digito-cnpj/complete-digito-cnpj.module';
import { PrettyJsonModule } from 'angular2-prettyjson';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ExportarLeadsModule } from '../../shared/exportar-leads/exportar-leads.module';
import { AutocompleteUsuarioModule } from '../../shared/form-component/autocomplete-usuario/autocomplete-usuario.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    PaginationModule.forRoot(),
    DateFormatModule,
    BsDropdownModule.forRoot(),
    NgxMaskModule.forRoot(),
    PopoverModule.forRoot(),
    ExportarLeadsModule,
    ConfirmModalModule,
    CompleteDigitoCpfModule,
    CompleteDigitoCnpjModule,
    PrettyJsonModule,
    AlertModule,
    AutocompleteUsuarioModule,
    ModalModule.forRoot()
  ],
  declarations: [LeadsComponent],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
    [{ provide: NgbDatepickerConfig, useClass: CustomNgbDatepickerConfig }],
    MyNgbDateParserFormatter
  ],
})
export class LeadsModule { }

import { Component, OnInit } from '@angular/core';
import { CampoService } from '../../services/campo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-campos',
  templateUrl: './campos.component.html',
  styleUrls: ['./campos.component.scss']
})
export class CamposComponent implements OnInit {

  public campos: any;
  public Response = { nome: '', tipo: '', descricao : '' };

  constructor(private campoService: CampoService
    , private router: Router) { }

  async ngOnInit() {
    this.campos = await this.campoService.get().toPromise();    
  }

  editarRegistro(id: number) {
    this.router.navigate(['/campos/form', id]);
  }

}

<ngx-ui-loader [loaderId]="'loader-default'" pbColor="#1e88e5" fgsColor="#1e88e5" fgsType="ball-spin"></ngx-ui-loader>
<ngx-ui-loader [loaderId]="'importacao-leads-csv'" pbColor="#1e88e5" fgsColor="#1e88e5" fgsType="ball-spin" text="Por favor aguarde, esse processo pode demorar alguns minutos, se houver algum erro verifique na base de leads os registros que já foram inseridos antes de tentar novamente..." textColor="#f7f8fa" textPosition="center-center"></ngx-ui-loader>

<app-header [fixed]="true"
[navbarBrandRouterLink]="['/dashboard']"
 [navbarBrandFull]="{src: 'assets/img/brand/logo-sebrae.png', alt: 'Sebrae Logo'}"
 [navbarBrandMinimized]="{src: 'assets/img/brand/logo-sebrae.png', width: 30, height: 30, alt: 'Sebrae Logo'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]=false
>
  <h5 style="color:#fff">Integra {{uf}} - Integrações Sebrae</h5>
  <form action="" method="post" class="form-inline my-2 my-lg-0">
    <div class="form-group">
      <div class="col-md-12">
        <div class="input-group">
          <span class="input-group-prepend">
              <select [(ngModel)]="searchOption" class="custom-select" name="searchOption" (change)="selecionarMaskSearch()">
                <option value="id" selected>ID Integra</option>
                <option value="cpf">CPF</option>
                <option value="cnpj">CNPJ</option>
                <option value="identificador">Identificador</option>
              </select>
          </span>

          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
            </div>
            <input type="text" [(ngModel)]="search" [mask]="searchOptionMask" name="search" class="form-control" placeholder="Pesquisa de Leads" (change)="buscarLeads()"
            (keyup.enter)="buscarLeads()">
          </div>
        </div>
      </div>
    </div>
  </form>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item"></li>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle
        (click)="false">
        <i class="fa fa-user-circle"></i>
        {{usuario.nome}}
        <i class="fa fa-angle-down pull-right"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <a class="dropdown-item" href="#" (click)="logout()">
          <i class="fa fa-sign-out"></i> Sair</a>
      </div>
    </li>
    <li class="nav-item">&nbsp;</li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar  [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
  <cui-breadcrumb></cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
    <!-- /.container-fluid -->

</main>
</div>
<app-footer>
  <span>Desenvolvido por <a href="http://www.wises.com.br" target="_blank">Wise</a> / Sebrae</span>
  <span class="ml-auto">Versão {{versao}}</span>
</app-footer>

<ng-template confirm>
    <confirm-modal-component></confirm-modal-component>
</ng-template>

<!-- <button type="button" class="btn btn-success" (click)="treino()">Teste</button> -->
<div id="canvas"></div>
<ng-template #template #lgModal>
    <div class="modal-header bg-primary">
        <h4 class="modal-title pull-left">Escolha uma ação</h4>
        <div class="pull-right">
            <button type="button" class="btn btn-primary" (click)="condicao()">Adicionar Condição</button>
            <button type="button" class="btn btn-primary" (click)="removerAcao()">Remover Ação</button>
            <button type="button" class="btn btn-primary" (click)="modalRef?.hide()">Cancelar</button>
        </div>
    </div>
    <div class="modal-body">
        <app-workflow-acoes (addAcao)="adicionarAcao($event)" [nodeSelecionado]="noSelecionadoObjeto" ></app-workflow-acoes>
    </div>
    <div class="modal-footer bg-primary">
        <button type="button" class="btn btn-primary" (click)="condicao()">Adicionar Condição</button>
        <button type="button" class="btn btn-primary" (click)="removerAcao()">Remover Ação</button>
        <button type="button" class="btn btn-primary" (click)="modalRef?.hide()">Cancelar</button>
    </div>
</ng-template>

<div class="animated fadeIn">
  <div class="row">
    <div class="col-md-12 mb-12">

      <tabset>
        <tab>
          <ng-template tabHeading> Segmento</ng-template>

          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-paperclip"></i>
                <strong>Cadastro de Segmento</strong>

                <div class="pull-right">
                  <button type="button" class="btn btn-primary" (click)="novo()">
                    <i class="fa fa-plus"></i>
                    Cadastrar Novo
                  </button>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <button type="button" class="btn btn-success pull-right" (click)="salvar()">
                    <i class="fa fa-check"></i>
                    Salvar
                  </button>
                </div>
              </div>
              <div class="card-body">

                <form [formGroup]="form">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="name">Nome do Segmento:</label>
                        <div class="input-group">
                          <input class="form-control" type="text" name="nome" formControlName="nome">
                        </div>
                        <app-control-messages [control]="form.controls.nome"></app-control-messages>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="name">Descrição do Segmento:</label>
                        <div class="input-group">
                          <textarea class="form-control" name="descricao" formControlName="descricao"></textarea>
                        </div>
                        <app-control-messages [control]="form.controls.descricao"></app-control-messages>
                      </div>
                    </div>
                    <div class="col-sm-1">
                      <div class="form-group">
                        <label for="name">Ativo:</label>
                        <div class="input-group">
                          <label class="switch switch-label switch-pill switch-primary">
                            <input type="checkbox" class="switch-input" checked value="1" formControlName="ativo">
                            <span class="switch-slider" data-checked="Sim" data-unchecked="Não"></span>
                          </label>
                        </div>
                        <app-control-messages [control]="form.controls.ativo"></app-control-messages>
                      </div>
                    </div>
                  </div>

                  <div class="clearfix"></div>
                </form>

              </div>
            </div>
          </div>

        </tab>
        <tab *ngIf="form.value.id">
          <ng-template tabHeading> Atividades Econômicas</ng-template>
          <app-segmento-atividade-form *ngIf="f.id.value" [SegmentoId]="f.id.value" [SegmentoNome]="f.nome.value"></app-segmento-atividade-form>
        </tab>
        <tab *ngIf="form.value.id">
          <ng-template tabHeading> Produtos </ng-template>
          <app-segmento-produto-form *ngIf="f.id.value" [SegmentoId]="f.id.value" [SegmentoNome]="f.nome.value"></app-segmento-produto-form>
        </tab>
      </tabset>

    </div>
  </div>
</div>
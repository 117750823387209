<form [formGroup]="formCampo" novalidate>

    <div class="row">
        <div class="col-4">
            <div class="form-group">
                <input type="text" class="form-control" name="campo1" id="campo" formControlName="campo" required
                    placeholder="Campo">
                <div *ngIf="formCampo.controls['campo'].invalid && (formCampo.controls['campo'].dirty || formCampo.controls['campo'].touched)"
                    class="text-danger">
                    <div *ngIf="formCampo.controls['campo'].errors.required">
                        Informe um campo
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <input type="text" class="form-control" name="valor" id="valor" formControlName="valor" required
                    placeholder="Valor">
                <div *ngIf="formCampo.controls['valor'].invalid && (formCampo.controls['valor'].dirty || formCampo.controls['valor'].touched)"
                    class="text-danger">
                    <div *ngIf="formCampo.controls['valor'].errors.required">
                        Informe um valor
                    </div>
                </div>
            </div>

        </div>
        <div class="col-2">
            <div class="form-group">
                <button class="btn btn-primary btn-sm" (click)="onSubmit()" [disabled]="formCampo.invalid"><i
                        class="fa fa-pencil"></i>Atualizar</button>
            </div>
        </div>
        <div class="col-2">
            <div class="form-group">
                <button class="btn btn-success btn-sm" (click)="adicionarAcao()" [disabled]="(campos == null || campos.length==0)"><i
                        class="fa fa-pencil"></i>Finalizar</button>
            </div>
        </div>
    </div>
</form>
<div class="table table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th>Campo</th>
                <th>Valor</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let campo of campos; let i = index">
                <td>{{campo.campo}}</td>
                <td>{{campo.valor}}</td>
                <td><button class="btn btn-danger btn-sm" (click)="remover(i)"><i
                            class="fa fa-times fa-lg mt-1 "></i>
                    </button>
                    <button class="btn btn-primary btn-sm" (click)="editForm(campo, i)"><i class="fa fa-pencil"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProcessoService {

  constructor(private http: HttpClient) { }

  get(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'Processos/getAll.json', {params: params});
  }

  salvar(params) {
    return this.http.post(environment.environment.nomeServiceIntegra + 'Processos/postSave.json', { Processos: params });
  }

  getQueues() {
    return this.http.get(environment.environment.nomeServiceIntegra + 'RabbitMq/getQueues.json');
  }

}

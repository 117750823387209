<div class="animated fadeIn">
    <div class="col-md-12 mb-12" *ngIf="relatorios">
        <ul class="nav nav-pills custom-nav-pills">
            <li class="nav-item" *ngFor="let r of relatorios; let i = index">
                <a class="nav-link"
                   [ngClass]="relSelecionado == r?.nome ? 'active' : ''"
                   href="javascript:void(0)"
                   (click)="carregarIframe(r?.link, r?.nome, r?.link_app)">
                   {{r?.nome}}
                </a>
            </li>
        </ul>
        <br />
        <div class="row mb-3">
            <div class="col-12 d-flex justify-content-end" *ngIf="linkApp">
                <button type="button" class="btn btn-success" (click)="abrirNovaAba(linkApp)">
                    <i class="fa fa-external-link mr-2"></i>
                    Visualizar no Metabase
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="iframe-container" *ngIf="url !== undefined">
                    <iframe
                        *ngIf="url !== null; else loadingTemplate"
                        [src]="url"
                        frameborder="0"
                        width="100%"
                        height="800px"
                    ></iframe>
                    <ng-template #loadingTemplate>
                        <p>Aguarde, carregando relatório...</p>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
    providedIn: 'root'
})
export class CenariosIaService {

    constructor(private http: HttpClient, private sessaoService: SessaoService) { }

    getId(id) {
        return this.http.get(`${environment.environment.nomeServiceIntegra}CenariosIa/getId/${id}.json`);
    }

    getCenarioSemSolucao() {
        let params: any = { slug: 'solucao-nao-encontrada', 'tipo_cenario': 'Problema'};

        return new Promise((resolve, reject) => {
          if (this.sessaoService.get('CenarioSemSolucao')) {
            resolve(this.sessaoService.get('CenarioSemSolucao'));
          } else {
            this.http.get(environment.environment.nomeServiceIntegra + 'CenariosIa/getAll.json', { params: params })
              .toPromise().then((res: any) => {
                this.sessaoService.set('CenarioSemSolucao', res.CenariosIa[0]);
                resolve(res.CenariosIa[0]);
              }, err => {
                reject(err);
              });
          }
        });
      }
}

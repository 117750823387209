<div class="animated fadeIn">

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-search"></i>
          Critérios de <strong>Consulta da Carteira</strong>

          <button *ngIf="criterios && criterios.length > 0" type="button" class="btn btn-success pull-right"
            (click)="salvar()">
            <i class="fa fa-check"></i>
            Salvar
          </button>
        </div>
        <div class="card-body">

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="name"><strong>Critérios escolhido nas configurações gerais do integra:</strong></label>
                <div class="input-group">
                  <input class="form-control" type="text" value="{{IntegraCarteiraCriterios?.valor}}" disabled>
                </div>                
              </div>
            </div>            
          </div>


          <table class="table table-striped">
            <thead>
              <tr>
                <th class="text-center">Ordem</th>
                <th>Descrição do Critério</th>
                <th>Ativo</th>
                <th class="text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of criterios; let i = index">
                <td class="text-center">{{(i + 1)}}</td>
                <td>
                  <div><strong>{{c.nome}}</strong></div>
                  <div class="small text-muted">
                    <span><strong>Código:&nbsp;</strong></span>{{c.id}}
                  </div>
                  <!-- <div class="small text-muted">
                    <span><strong>Ordem Banco de Dados:&nbsp;</strong></span>{{c.ordem}}
                  </div>                   -->
                  <div class="small text-muted">
                    <span><strong>Slug:&nbsp;</strong></span>{{c.codigo_interno}}
                  </div>
                </td>
                <td>
                  
                  <div class="input-group">
                    <label class="switch switch-label switch-pill switch-primary">
                      <input type="checkbox" class="switch-input" [checked]="c.ativo == 1 ? 'checked' : ''"
                        [(ngModel)]="c.ativo">
                      <span class="switch-slider" data-checked="Sim" data-unchecked="Não"></span>
                    </label>
                  </div>

                </td>
                <td class="td-actions text-center">

                  <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic">
                    <label ngbButtonLabel class="btn-info" tooltip="Mover para cima">
                      <input ngbButton type="radio" value="up" (click)="order(i,true)"><i class="fa fa-level-up"></i>
                    </label>
                    <label ngbButtonLabel class="btn-primary" tooltip="Mover para baixo">
                      <input ngbButton type="radio" value="down" (click)="order(i,false)"><i
                        class="fa fa-level-down"></i>
                    </label>
                  </div>

                </td>
              </tr>
              <tr *ngIf="criterios && criterios.length == 0">
                <td colspan="4">Não existe critérios de carteira cadastrado no sistema.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
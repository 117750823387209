import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracoesWsService {

    constructor(private http: HttpClient, private sessaoService: SessaoService) { }

    get(params) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(environment.environment.nomeServiceCheckCadastro + 'ConfiguracoesWs/getAll.json', {params : params});
    }
    getPaginate(params) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<ConfiguracoesWsPaginate>(environment.environment.nomeServiceCheckCadastro + 'ConfiguracoesWs/getAll.json', { params: params });
    }
    getId(id) {
        return this.http.get<ConfiguracoesWs>(environment.environment.nomeServiceCheckCadastro + `ConfiguracoesWs/getId/${id}.json`);
    }
    salvar(params) {
        return this.http.post(environment.environment.nomeServiceCheckCadastro + 'ConfiguracoesWs/postSave.json', params);
    }
    delete(id) {
        return this.http.post<ResponseDelete>(`${environment.environment.nomeServiceCheckCadastro}ConfiguracoesWs/delete/${id}.json`, {});
    }
}


export class ConfiguracoesWs {
    id: number;
    nome: string;
    descricao: string;
    cod_crm: string;
    cod_externo: string;
    valor: number;
    ativo: number;
}

export class ConfiguracoesWsPaginate {
    ConfiguracoesWs: any;
    TotalItems: number;

    constructor() { }
}

export class ResponseDelete {
    message: string;
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlaybookService } from '../../services/playbook.service';
import { ConfirmService } from '../../shared/confirm-modal/confirm-modal-and-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-playbooks',
  templateUrl: './playbooks.component.html',
  styleUrls: ['./playbooks.component.scss']
})
export class PlaybooksComponent implements OnInit {

  playbooks: any;
  filter: any;
  totalItems: number;
  currentPage: number = 1;
  maxSize: number;
  listItemsPerPage = [10, 20, 30, 40, 50, 100, 200, 300];
  listStatus = [{ name: 'Sim', value: 1 }, { name: 'Não', value: 0 }, { name: 'Todos', value: '' }];

  constructor(private playbookService: PlaybookService
    , private confirmService: ConfirmService
    , private toastr: ToastrService
    ) { }

  async ngOnInit() {
    this.initFiltros();
    this.getPlaybooks();
  }

  initFiltros() {
    this.filter = {
      nome: '',
      ativo: '',
      page: 1,
      limit: 30
    };
  }

  pageChanged(event: any): void {
    this.filter.page = event.page;
    this.filter.limit = event.itemsPerPage;
    this.getPlaybooks();
  }

  async getPlaybooks(): Promise<void> {
    let response = await this.playbookService.getPaginate(this.filter).toPromise();
    this.playbooks = response.Playbooks;
    this.totalItems = response.TotalItems;

    let maxSize = Math.ceil(this.totalItems / this.filter.limit);
    this.maxSize = maxSize <= 15 ? maxSize : 15;
  }

  pesquisar() {
    this.filter.page = 1;
    this.currentPage = 1;

    this.getPlaybooks();
  }

  limparFiltros() {
    this.initFiltros();
  }

  deletar(p: any = []) {
    this.confirmService.confirm({
      title: 'Exclusão', message: 'Você tem certeza que deseja excluir o playbook de nome ' + p.nome + ' ?'
    }).then(
      () => {
        this.playbookService.delete(p.id).subscribe(response => {
          this.toastr.success('Playbook excluído com sucesso!');
          if (response.message) {
            this.toastr.info(response.message);
          }
          this.getPlaybooks();
        });
      },
      () => { });
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { DorService } from '../../services/dor.service';
import { ConfirmService } from '../../shared/confirm-modal/confirm-modal-and-service';

@Component({
  selector: 'app-dores',
  templateUrl: './dores.component.html',
  styleUrls: ['./dores.component.scss']
})
export class DoresComponent implements OnInit {

  dores: any;
  filter: any;
  totalItems: number;
  currentPage: number = 1;
  maxSize: number;
  listItemsPerPage = [10, 20, 30, 40, 50, 100, 200, 300];
  listStatus = [{ name: 'Sim', value: 1 }, { name: 'Não', value: 0 }, { name: 'Todos', value: '' }];
  uf: String = '';

  constructor(
    private dorService: DorService
    , private confirmService: ConfirmService
    , private toastr: ToastrService
    , private router: Router) {
    this.uf = environment.environment.uf;
  }

  async ngOnInit() {
    this.initFiltros();

    this.getDores();
  }

  initFiltros() {
    this.filter = {
      nome: '',
      cod_externo: '',
      ativo: '',
      page: 1,
      limit: 30
    };
  }

  pageChanged(event: any): void {
    this.filter.page = event.page;
    this.filter.limit = event.itemsPerPage;
    this.getDores();
  }

  async getDores(): Promise<void> {
    let response = await this.dorService.getPaginate(this.filter).toPromise();
    this.dores = response.Dores;
    this.totalItems = response.TotalItems;

    let maxSize = Math.ceil(this.totalItems / this.filter.limit);
    this.maxSize = maxSize <= 15 ? maxSize : 15;
  }

  pesquisar() {
    this.filter.page = 1;
    this.currentPage = 1;

    this.getDores();
  }

  editarRegistro(id: number) {
    this.router.navigate(['/dores/form', id]);
  }

  limparFiltros() {
    this.initFiltros();
  }

  deletar(dor: any = []) {
    this.confirmService.confirm({
      title: 'Exclusão', message: 'Você tem certeza que deseja excluir a dor de nome ' + dor.nome + ' ?'
    }).then(
      () => {
        this.dorService.delete(dor.id).subscribe(response => {
          this.pesquisar();
          this.toastr.success('Dor excluída com sucesso!');
          if (response.message) {
            this.toastr.info(response.message);
          }
        });
      },
      () => { });
  }

  sincronizarDores() {
    this.confirmService.confirm({
      title: 'Sincronizar Dores Sistema Sol AL', message: 'Confirma a Sincronização?'
    }).then(
      () => {
        this.dorService.getSincronizarDoresSol().subscribe(response => {
          this.toastr.success(response['message']);
        });
      },
      () => { });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ValueAccessorBase } from '../value-accessor';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'select-registros-por-pagina',
  templateUrl: './select-registros-por-pagina.component.html',
  styleUrls: ['./select-registros-por-pagina.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SelectRegistrosPorPaginaComponent,
    multi: true,
  }],
})
export class SelectRegistrosPorPaginaComponent extends ValueAccessorBase<DadosEnvio> {
  @ViewChild(NgModel) dadosEnvio: NgModel;

  public listItemsPerPage = [10, 20, 30, 40, 50, 100, 200, 300];

  constructor() {
    super();
  }

  handleInput(prop, value) {
    if (!this.value) {
      this.value = new DadosEnvio();
    }
    this.value[prop] = value;
    this.value = { ...this.value };
  }

  ngOnInit() {
  }

}

export class DadosEnvio {
  limit: any;

  constructor() { }
}
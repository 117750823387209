import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class CanalAtendimentoService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  getCanalAtendimento() {
    return this.http.get(environment.environment.nomeServiceSas + 'CanalAtendimentos/consultarCanal.json');
  }

  listarTodosCanaisAtendimento() {
    let params: any = { Ativo: 1 };

    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceCanaisAtendimento')) {
        resolve(this.sessaoService.get('ServiceCanaisAtendimento'));
      } else {
        this.http.get(environment.environment.nomeServiceSas + 'CanalAtendimentos/consultarCanal.json', { params: params })
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceCanaisAtendimento', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

}

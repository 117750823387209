import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExportarLeadsService } from './exportar-leads.service';

@Component({
  selector: 'app-exportar-leads',
  templateUrl: './exportar-leads.component.html',
  styleUrls: ['./exportar-leads.component.css']
})
export class ExportarLeadsComponent implements OnInit {

  @Output() exportar = new EventEmitter<any>();
  colunas: any;
    //colunasExport = [];
  selectedFields = {'colunas': []};
  allSelected = true;

  constructor(private exportarService: ExportarLeadsService) { }

  ngOnInit(): void {
    this.colunas = this.exportarService.getColunasExport();
    this.exportar.emit(this.colunas);
  }

  toggleAllSelected(): void {
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      this.colunas.forEach(f => f.checked = true);
    } else {
      this.colunas.forEach(f => f.checked = false);
    }
    this.exportar.emit(this.colunas);
  }


}

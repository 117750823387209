import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class CampoService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  get() {
    return this.http.get(environment.environment.nomeServiceIntegra + 'Campos/getAll.json');
  }

  getId(id) {
    return this.http.get<Campo>(environment.environment.nomeServiceIntegra + `Campos/getId/${id}.json`);
  }

  salvar(params) {
    this.sessaoService.delete('ServiceCampos');
    //método não é preparado para salvar formulário com estas listas.
    if(params.campos_esperados != undefined){
      delete params.campos_esperados;
    }
    if(params.campos_listas != undefined){
      delete params.campos_listas;
    }    
    return this.http.post(environment.environment.nomeServiceIntegra + 'Campos/postSave.json', { Campos: params });
  }

  listarTodosCampos() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceCampos')) {
        resolve(this.sessaoService.get('ServiceCampos'));
      } else {
        this.http.get(environment.environment.nomeServiceIntegra + 'Campos/getAll.json')
          .toPromise().then((res: any) => {
            res.map((c) => {
              c.nomeCustom = c.nome + (c.obrigatorio ? ' (Obrigatório)' : '');
              return c;
            });
            this.sessaoService.set('ServiceCampos', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

  listarTodosTipos() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceCamposTipos')) {
        resolve(this.sessaoService.get('ServiceCamposTipos'));
      } else {
        this.http.get(environment.environment.nomeServiceIntegra + 'Campos/getTipos.json')
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceCamposTipos', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

}

export class Campo {
  id: number;
  tipo: string;
  nome: string;
  valor_padrao: string;
  descricao: string;
  obrigatorio: number;
  ordem: number;
  plataforma_campos: [{
    id: number;
    campo_id: number;
    plataforma_id: number;
    nome: number;
    plataforma: {
      id: number;
      nome: string;
    }
  }];
  campos_listas: [{
    id: number;
    campo_id: string;
    valor: string;
    codigo_referente: string;
  }];
}
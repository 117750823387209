<div class="input-group">
    <ng-template #rtUser let-r="result" let-t="term">
        {{ r.nome_cpf }}
    </ng-template>

    <input type="text" class="form-control" [ngModel]="value?.usuario" (ngModelChange)="handleInput('usuario', $event)"
        [class.is-invalid]="searchFailedUser" [ngbTypeahead]="searchUser" [inputFormatter]="formatterUser" [resultTemplate]="rtUser"
        placeholder="Digite o nome do responsável" />
    <div class="invalid-feedback" *ngIf="searchFailedUser">Desculpe, não conseguimos buscar este responsável.</div>
    <div class="input-group-append">
        <span class="input-group-text">
            <i class="fa fa-search"></i>
        </span>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SegmentoService } from '../../services/segmento.service';

@Component({
  selector: 'app-segmentos',
  templateUrl: './segmentos.component.html',
  styleUrls: ['./segmentos.component.scss']
})
export class SegmentosComponent implements OnInit {
  
  segmentos: any;
  filter: any;
  totalItems: number;
  currentPage: number = 1;
  maxSize: number;
  listItemsPerPage = [10, 20, 30, 40, 50, 100, 200, 300];
  listStatus = [{ name: 'Sim', value: 1 }, { name: 'Não', value: 0 }, { name: 'Todos', value: '' }];

  constructor(
    private segmentoService: SegmentoService        
    , private router: Router) { }

  async ngOnInit() {
    this.initFiltros();
    this.getSegmentos();
  }

  initFiltros() {
    this.filter = {      
      nome: '',      
      ativo: '',
      page: 1,
      limit: 30
    };
  }

  pageChanged(event: any): void {
    this.filter.page = event.page;
    this.filter.limit = event.itemsPerPage;
    this.getSegmentos();
  }

  async getSegmentos(): Promise<void> {    
    let response = await this.segmentoService.get(this.filter).toPromise();
    this.segmentos = response.Segmentos;
    this.totalItems = response.TotalItems;

    let maxSize = Math.ceil(this.totalItems / this.filter.limit);
    this.maxSize = maxSize <= 15 ? maxSize : 15;
  }

  pesquisar() {
    this.filter.page = 1;
    this.currentPage = 1;

    this.getSegmentos();
  }

  editarRegistro(id: number) {
    this.router.navigate(['/segmentos/form', id]);
  }

  limparFiltros() {
    this.initFiltros();
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegionalFormComponent } from './regional-form/regional-form.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Regionais/Cidades'
    },
    children: [      
      {
        path: 'form/:id',
        component: RegionalFormComponent,
        data: {
          title: 'Cadastro de Regional/Cidades'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegionaisRoutingModule { }

<div class="input-group">
    <ng-template #rtEmp let-r="result" let-t="term">
        {{ r.NomeRazaoSocial ? r.NomeRazaoSocial : r.NomeAbrevFantasia}} - {{ r.CgcCpf ? r.CgcCpf : r.CodParceiro}}
    </ng-template>

    <input type="text" class="form-control" [ngModel]="value?.empreendimento" (ngModelChange)="handleInput('empreendimento', $event)"
        [class.is-invalid]="searchFailedEmp" [ngbTypeahead]="searchEmp" [inputFormatter]="formatterEmp" [resultTemplate]="rtEmp"
        placeholder="Digite a Razão Social / CNPJ, se não encontrar, tente o fantasia" />
    <div class="invalid-feedback" *ngIf="searchFailedEmp">Desculpe, não conseguimos buscar este empreendimento.</div>
    <div class="input-group-append">
        <span class="input-group-text">
            <i class="fa fa-search"></i>
        </span>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AtendimentoConfiguracaoService {

  constructor(private http: HttpClient) { }

  get(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'AtendimentoConfiguracoes/getAll.json', {params : params});
  }

  getId(id) {    
    return this.http.get<AtendimentoConfiguracao>(environment.environment.nomeServiceIntegra + `AtendimentoConfiguracoes/getId/${id}.json`);
  }

  salvar(params) {
    return this.http.post(environment.environment.nomeServiceIntegra + 'AtendimentoConfiguracoes/postSave.json', { AtendimentoConfiguracoes: params });
  }

}

export class AtendimentoConfiguracao {
  id: number;
  nome: string;
  conteudo_tipo_id: number;
  NomeRealizacao: string;
  DescRealizacao: string;
  TipoRealizacao: string;
  Instrumento: string;
  Abordagem: string;
  CodProjeto: string;
  CodAcao: number;
  CpfResponsavel: number;
  conteudo_tipo: {
    id: number;
    nome: string;
    ativo: number;
  };
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsuariosRoutingModule } from './usuarios-routing.module';
import { UsuariosComponent } from './usuarios.component';
import { UsuarioFormComponent } from './usuario-form/usuario-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlMessagesModule } from '../../shared/control-messages/control-messages.module';
import { NgSelectModule } from '@ng-select/ng-select';
//import { AlertModule, BsDropdownModule, PaginationModule, TabsModule, TooltipModule } from 'ngx-bootstrap';
import { UsuarioGrupoFormModule } from './usuario-form/usuario-grupo-form/usuario-grupo-form.module';
import { NgxMaskModule } from 'ngx-mask';
import { ControlListErrorMessagesModule } from '../../shared/control-list-error-messages/control-list-error-messages.module';
import { CompleteDigitoCpfModule } from '../../pipe/complete-digito-cpf/complete-digito-cpf.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';

@NgModule({
  imports: [
    CommonModule,
    UsuariosRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ControlMessagesModule,
    NgSelectModule,
    TooltipModule.forRoot(),
    UsuarioGrupoFormModule,
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxMaskModule.forRoot(),
    TabsModule,
    ControlListErrorMessagesModule,
    AlertModule.forRoot(),
    CompleteDigitoCpfModule
  ],
  declarations: [UsuariosComponent, UsuarioFormComponent]
})
export class UsuariosModule { }

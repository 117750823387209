import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmService, ConfirmState, ConfirmTemplateDirective, ConfirmModalComponent } from './confirm-modal-and-service';
import { RouterModule } from '@angular/router';
@NgModule({
    declarations: [ConfirmTemplateDirective, ConfirmModalComponent],
    imports: [RouterModule, CommonModule],
    exports: [CommonModule, ConfirmTemplateDirective, ConfirmModalComponent],

    providers: [ConfirmService, ConfirmState],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA],
})
export class ConfirmModalModule { }

<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
        <i class="fa fa-search"></i>Pesquisa de Grupos de Permissionamentos no Sistema
          <div class="card-header-actions">
            <button type="button" class="btn btn-minimize" (click)="toggleCollapse()">
              <i class={{iconCollapse}}></i>
            </button>
          </div>
        </div>
        <div [collapse]="isCollapsed" class="card-body">
          <form class="form-horizontal">            

            <div class="row">              
              <div class="col-md-8">
                <div class="form-group">
                  <label>Nome do grupo:</label>
                  <input class="form-control" type="text" [(ngModel)]="filter.nome" name="nome">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Ativo:</label>
                  <ng-select [items]="listStatus" bindLabel="name" bindValue="value" placeholder="Selecione um status"
                    name="ativo" [(ngModel)]="filter.ativo">
                  </ng-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Registros por página:</label>
                  <select-registros-por-pagina [(ngModel)]="filter" name="filter"></select-registros-por-pagina>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group pull-right">
                  <button type="button" class="btn btn-primary" (click)="pesquisar()">
                    <i class="fa fa-search"></i>
                    Pesquisar
                  </button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                    <i class="fa fa-ban"></i>
                    Limpar
                  </button>
                  &nbsp;&nbsp;
                  <a class="btn btn-success" href="#" [routerLink]="['/grupos-permissoes/inserir']">
                    <i class="fa fa-plus"></i> Cadastrar Novo Grupo de Acesso</a>
                </div>
              </div>
            </div>

            <div class="clearfix"></div>
          </form>
        </div>
      </div>
    </div>
    <!--/.col-->
  </div>
  <!--/.row-->


  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Resultados ({{totalItems}})
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="text-center">Código</th>
                <th>Nome do Grupo</th>
                <th>Descrição</th>
                <th class="text-center">Ativo</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let g of grupos; let i = index" (click)="editarRegistro(g.id)" style="cursor: pointer;"
              tooltip="Clique para editar o cadastro" placement="left">
                <td class="text-center">{{g?.id}}</td>
                <td>{{g?.nome}}</td>
                <td>{{g?.descricao}}</td>                
                <td class="text-center"><span [ngClass]="g.ativo == 1 ? 'badge badge-success' : 'badge badge-danger'">{{g.ativo
                  == 1 ? 'Sim' : 'Não'}}</span></td>
              </tr>
              <tr *ngIf="grupos && grupos.length == 0">
                <td colspan="4">Não existe grupos de acesso com estes parâmetros</td>
              </tr>
            </tbody>
          </table>

          <div class="col-md-12 justify-content-center">
            <pagination [boundaryLinks]="true" [itemsPerPage]="filter.limit" [totalItems]="totalItems"
              class="text-center" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
          </div>

        </div>

      </div>
    </div>
  </div>


</div>
<div class="animated fadeIn">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-refresh"></i>
                    <strong>Execuções de Processos Ativos no Integra</strong>
                </div>
                <div class="card-body">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Integrador</th>
                                <th>Nome do Processo</th>
                                <th class="text-center">Status</th>
                                <th>Contadores</th>
                                <th>Última Execução</th>
                            </tr>
                            <tr>
                                <th scope="col">
                                  <input type="text" class="form-control" [(ngModel)]="filter.plataforma.nome" placeholder="Buscar..."/>
                                </th>
                                <th scope="col">
                                  <input type="text" class="form-control" [(ngModel)]="filter.nome" placeholder="Buscar..."/>
                                </th>
                                <th scope="col">&nbsp;</th>
                                <th scope="col">&nbsp;</th>
                                <th scope="col">&nbsp;</th>
                              </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of processos | filterBy: filter; let i = index">
                                <td>{{p?.plataforma?.nome ? p?.plataforma?.nome : 'Todos'}}</td>
                                <td>{{p?.nome}}</td>
                                <td class="text-center">
                                    <div><span [ngClass]="getNomeClass(p)">{{getNomeStatus(p)}}</span></div>
                                </td>
                                <td>
                                    <div *ngIf="p?.qtd_registros">
                                        <span class="small text-muted"><strong>Qtd. Lote:&nbsp;</strong></span>
                                        <span class="badge badge-dark badge-pill" style="position: static;">{{p?.qtd_registros}}</span>
                                    </div>
                                    <div *ngIf="p?.qtd_sucesso">
                                        <span class="small text-muted"><strong>Qtd. Sucesso:&nbsp;</strong></span>
                                        <span class="badge badge-success badge-pill" style="position: static;">{{p?.qtd_sucesso}}</span>
                                    </div>
                                    <div *ngIf="p?.qtd_falha">
                                        <span class="small text-muted" ><strong>Qtd. Falha:&nbsp;</strong></span>
                                        <span class="badge badge-danger badge-pill" style="position: static;">{{p?.qtd_falha}}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="small text-muted" *ngIf="p?.data_hora_inicio">
                                        <span><strong>Iniciado em:&nbsp;</strong></span>{{p?.data_hora_inicio | date: 'dd/MM/yyyy H:mm'}}
                                    </div>
                                    <div class="small text-muted" *ngIf="p?.registro_id">
                                        <span><strong>Último registro:&nbsp;</strong></span>{{p?.registro_id}}
                                    </div>
                                    <div class="small text-muted" *ngIf="p?.data_hora_registro">
                                        <span><strong>Registro alterado em:&nbsp;</strong></span>{{p?.data_hora_registro |
                                        date: 'dd/MM/yyyy H:mm'}}
                                    </div>
                                    <div class="small text-muted" *ngIf="p?.data_hora_fim">
                                        <span><strong>Finalizado em:&nbsp;</strong></span>{{p?.data_hora_fim | date: 'dd/MM/yyyy H:mm'}}
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="processos && processos.length == 0">
                                <td colspan="10">Não existe processos criados ou ativos para execução.</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</div>

import { GerarWorkflowComponent } from './views/gerar-workflow/gerar-workflow.component';
import { HealthCheckComponent } from './views/health-check/health-check.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './shared/guard';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { CampanhasComponent } from './views/campanhas/campanhas.component';
import { AtendimentoConfiguracoesComponent } from './views/atendimento-configuracoes/atendimento-configuracoes.component';
import { CamposComponent } from './views/campos/campos.component';
import { LeadsComponent } from './views/leads/leads.component';
import { IntegracoesComponent } from './views/integracoes/integracoes.component';
import { CarteirasComponent } from './views/carteiras/carteiras.component';
import { SegmentosComponent } from './views/segmentos/segmentos.component';
import { GruposPermissoesComponent } from './views/grupos-permissoes/grupos-permissoes.component';
import { UsuariosComponent } from './views/usuarios/usuarios.component';
import { RegionaisComponent } from './views/regionais/regionais.component';
import { TipoAtividadesComponent } from './views/tipo-atividades/tipo-atividades.component';
import { TipoCampanhasComponent } from './views/tipo-campanhas/tipo-campanhas.component';
import { ProdutosComponent } from './views/produtos/produtos.component';
import { CriteriosCarteiraComponent } from './views/carteiras/criterios-carteira/criterios-carteira.component';
import { DoresComponent } from './views/dores/dores.component';
import { PlaybooksComponent } from './views/playbooks/playbooks.component';
import { SegmentacaoLeadsComponent } from './views/segmentacao-leads/segmentacao-leads.component';
import { APP_BASE_HREF, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CampanhaUnsavedChanges } from './guard/campanha-unsaved-changes.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },


  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  // {
  //   path: 'register',
  //   component: RegisterComponent,
  //   data: {
  //     title: 'Register Page'
  //   }
  // },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [

      {
        path: 'processos',
        component: DashboardComponent,
        canDeactivate: [CampanhaUnsavedChanges],
        data: {
          title: 'Processos'
        },
      },
      {
        path: 'dashboard',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/dashboard2/dashboard2.module').then(m => m.Dashboard2Module),
        data: {
          title: 'Dashboard'
        },
      },
      {
        path: 'rabbitmq',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/dashboard-rabbitmq/dashboard-rabbitmq.module').then(m => m.DashboardRabbitmqModule),
        data: {
          title: 'RabbitMQ Filas'
        },
      },
      {
        path: 'workflows',
        canDeactivate: [CampanhaUnsavedChanges],
        component: GerarWorkflowComponent,
        data: {
          title: 'WorkFlow'
        },
      },
      {
        path: 'tipo-atividades',
        canDeactivate: [CampanhaUnsavedChanges],
        component: TipoAtividadesComponent,
        data: {
          title: 'Tipo de Atividades'
        },
      },
      {
        path: 'tipo-campanhas',
        canDeactivate: [CampanhaUnsavedChanges],
        component: TipoCampanhasComponent,
        data: {
          title: 'Tipo de Campanhas'
        },
      },
      {
        path: 'campanhas',
        canDeactivate: [CampanhaUnsavedChanges],
        component: CampanhasComponent,
        data: {
          title: 'Lista de Campanhas/Eventos'
        },
      },
      {
        path: 'campos',
        canDeactivate: [CampanhaUnsavedChanges],
        component: CamposComponent,
        data: {
          title: 'Lista de Campos Parametrizados'
        },
      },
      {
        path: 'leads',
        canDeactivate: [CampanhaUnsavedChanges],
        component: LeadsComponent,
        data: {
          title: 'Listas de Leads'
        },
      },
      {
        path: 'segmentacao-leads',
        canDeactivate: [CampanhaUnsavedChanges],
        component: SegmentacaoLeadsComponent,
        data: {
          title: 'Lista de Segmentações de Leads'
        },
      },
      {
        path: 'integracoes',
        canDeactivate: [CampanhaUnsavedChanges],
        component: IntegracoesComponent,
        data: {
          title: 'Integrações Conectadas'
        },
      },
      {
        path: 'criterios-carteira',
        canDeactivate: [CampanhaUnsavedChanges],
        component: CriteriosCarteiraComponent,
        data: {
          title: 'Critérios de Consulta da Carteria'
        },
      },
      {
        path: 'carteiras',
        canDeactivate: [CampanhaUnsavedChanges],
        redirectTo: 'carteiras/0/pesquisa',
        pathMatch: 'full',
        component: CarteirasComponent,
        data: {
          title: 'Carteiras'
        },
      },
      {
        path: 'produtos',
        canDeactivate: [CampanhaUnsavedChanges],
        component: ProdutosComponent,
        data: {
          title: 'Produtos'
        },
      },
      {
        path: 'health-check',
        canDeactivate: [CampanhaUnsavedChanges],
        component: HealthCheckComponent,
        data: {
          title: 'Saúde da Aplicação'
        },
      },
      {
        path: 'segmentos',
        canDeactivate: [CampanhaUnsavedChanges],
        component: SegmentosComponent,
        data: {
          title: 'Segmentos'
        },
      },
      {
        path: 'dores',
        canDeactivate: [CampanhaUnsavedChanges],
        component: DoresComponent,
        data: {
          title: 'Dores'
        },
      },
      {
        path: 'dores',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/dores/dores.module').then(m => m.DoresModule)
      },
    {
        path: 'playbooks',
        canDeactivate: [CampanhaUnsavedChanges],
        component: PlaybooksComponent,
        data: {
            title: 'Playbooks'
        },
    },
    {
        path: 'playbooks',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/playbooks/playbooks.module').then(m => m.PlaybooksModule)
    },
      {
        path: 'regionais',
        canDeactivate: [CampanhaUnsavedChanges],
        component: RegionaisComponent,
        data: {
          title: 'Regionais/Cidades'
        },
      },
      {
        path: 'grupos-permissoes',
        canDeactivate: [CampanhaUnsavedChanges],
        component: GruposPermissoesComponent,
        data: {
          title: 'Grupos de Permissões'
        }
      },
      {
        path: 'usuarios',
        canDeactivate: [CampanhaUnsavedChanges],
        component: UsuariosComponent,
        data: {
          title: 'Usuários'
        }
      },
      {
        path: 'usuarios',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/usuarios/usuarios.module').then(m => m.UsuariosModule)
      },
      {
        path: 'grupos-permissoes',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/grupos-permissoes/grupos-permissoes.module').then(m => m.GruposPermissoesModule)
      },
      {
        path: 'regionais',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/regionais/regionais.module').then(m => m.RegionaisModule)
      },
      {
        path: 'produtos',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/produtos/produtos.module').then(m => m.ProdutosModule)
      },
      {
        path: 'health-check',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/health-check/health-check.module').then(m => m.HealthCheckModule)
      },
      {
        path: 'segmentos',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/segmentos/segmentos.module').then(m => m.SegmentosModule)
      },
      {
        path: 'carteiras',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/carteiras/carteiras.module').then(m => m.CarteirasModule)
      },
      {
        path: 'criterios-carteira',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/carteiras/criterios-carteira/criterios-carteira.module').then(m => m.CriteriosCarteiraModule)
      },
      {
        path: 'tipo-atividades',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/tipo-atividades/tipo-atividades.module').then(m => m.TipoAtividadesModule)
      },
      {
        path: 'tipo-campanhas',
        loadChildren: () => import('./views/tipo-campanhas/tipo-campanhas.module').then(m => m.TipoCampanhasModule)
      },
      {
        path: 'campanha',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/campanhas/campanha-abas-form/campanha-abas-form.module').then(m => m.CampanhaAbasFormModule)
      },
      {
        path: 'atendimento-configuracoes',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/atendimento-configuracoes/atendimento-configuracoes.module').then(m => m.AtendimentoConfiguracoesModule)
      },
      {
        path: 'campos',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/campos/campos.module').then(m => m.CamposModule)
      },
      {
        path: 'relatorios',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/relatorios/relatorios.module').then(m => m.RelatoriosModule)
      },
      {
        path: 'integracoes',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/integracoes/integracoes.module').then(m => m.IntegracoesModule)
      },
      {
        path: 'integracoes',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/integracoes/integracao-form-abas/integracao-form-abas.module').then(m => m.IntegracaoFormAbasModule)
      },
      {
        path: 'segmentacao-leads',
        canDeactivate: [CampanhaUnsavedChanges],
        loadChildren: () => import('./views/segmentacao-leads/segmentacao-leads.module').then(m => m.SegmentacaoLeadsModule)
      },
      {
        path: 'emails',
        loadChildren: () => import('./views/emails/emails.module').then(m => m.EmailsModule)
      },
      {
        path: '',
        canDeactivate: [CampanhaUnsavedChanges],
        children: [{
          path: '**',
          loadChildren: () => import('./spa-host/spa-host.module').then(m => m.SpaHostModule),
          data: {
            title: 'Cadastro Cliente',
            app: 'micro-cadastro-cliente'
          }
        }
        ]
      }
    ]
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
  exports: [RouterModule]
})
export class AppRoutingModule { }

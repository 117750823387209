import { ConfiguracoesWsService } from './../../../../services/configuracoes-ws.service';
import { CustomServiceValidateService } from '../../../../shared/control-messages/custom-service-validate.service';
import { ConfirmService } from './../../../../shared/confirm-modal/confirm-modal-and-service';
import { ToastrService } from 'ngx-toastr';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-tab-configuracoes',
    templateUrl: './tab-configuracoes.component.html',
    styleUrls: ['./tab-configuracoes.component.css']
})
export class TabConfiguracoesComponent implements OnInit {
    form: FormGroup;
    configuracoes: any;
    listTiposServicos = [{ nome: 'CPE Sebrae Nacional', value: 'CPE_SEBRAE_NA' }, { nome: 'Direct Data', value: 'DIRECTD' }, { nome: 'DW On-Line', value: 'DWONLINE' }];
    constructor(private fb: FormBuilder, private toastr: ToastrService
        , private configuracoesWsService: ConfiguracoesWsService
        , private confirmService: ConfirmService) { }

    ngOnInit(): void {
        this.initForm();
        this.buscarConfiguracoes();
    }
    buscarConfiguracoes() {
        this.configuracoesWsService.getPaginate({}).subscribe(p => {
            this.configuracoes = p.ConfiguracoesWs;
        });
    }
    initForm() {
        this.form = this.fb.group({
            id: ['', Validators.required],
            url_base: ['', Validators.required],
            ambiente: ['', Validators.required],
            usuario: [''],
            usuario_id: [-1],
            senha: [''],

        });
    }
    salvar() {
        if (this.form.errors) {
            Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
            this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
            return false;
        }
        this.configuracoesWsService.salvar(this.form.value).subscribe(response => {
            this.toastr.success('Configuração salvo com sucesso.');
            this.initForm();
            this.buscarConfiguracoes();
        }, e => {
            CustomServiceValidateService.customMensageService(e.error, this.form);
        });
    }
    deletar(id) {
        this.confirmService.confirm({
            title: 'Exclusão', message: 'Você tem certeza que deseja excluir essa Configuração ?'
          }).then(
            () => {
                this.configuracoesWsService.delete(id) .subscribe(d => {
                    this.buscarConfiguracoes();
                    this.toastr.success('Configuração de WS excluída com sucesso!');
                });
            },
            () => { });
    }
    editarRegistro(c){
        delete c.modified;
        delete c.created;
        this.form.setValue(c);
        window.scroll(0,0);
    }
    getDescricaoTipoServico(tipo){
        const tipos = this.listTiposServicos.filter((item: any) => item.value == tipo);
        return tipos[0] && tipos[0].nome ? tipos[0].nome : '';
    }
}

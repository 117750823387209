import { SseService } from '../../services/sse.service';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { LeadService } from '../../services/lead.service';
import { ConteudoService } from '../../services/conteudo.service';
import { CategoriaService } from '../../services/categoria.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MyNgbDateParserFormatter } from '../../shared/datepicker/MyNgbDateParserFormatter';
import { ToastrService } from 'ngx-toastr';
import { ConfirmService } from '../../shared/confirm-modal/confirm-modal-and-service';
import { PlataformaService } from '../../services/plataforma.service';

import { CampanhaTipoService } from '../../services/campanha-tipo.service';
import { ConteudoTipoService } from '../../services/conteudo-tipo.service';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { TipoAtividadeEnum } from '../../enum/TipoAtividadeEnum';
import { WorkflowService } from '../../services/workflow.service';
import { SessaoService } from '../../services/sessao.service';
import { UsuarioService } from '../../services/usuario.service';
import moment from 'moment';
import { FormatService } from '../../services/format.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PlataformaEnum } from '../../enum/PlataformaEnum';
import { ProjetoAcaoService } from '../../services/projeto-acao.service';
import { CadastroClienteService } from '../../services/cadastro-cliente.service';
import { CenariosIaService } from '../../services/cenarios-ia.service';
@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss'],
  styles: [`:host /deep/ .string {color:blue} ...`]
})
export class LeadsComponent implements OnInit {
  @ViewChild('modalAlterarJson') public modalAlterarJson: ModalDirective;
  @ViewChild('modalExecutarFluxo') public modalExecutarFluxo: ModalDirective;
  @ViewChild('modalDetalhesSolucao') public modalDetalhesSolucao: ModalDirective;
  dataModalJson: any;
  dataModalFilaId: number;

  dataCenarioSemSolucao: any;
  dataModalSolucao: any;
  dataModalCenarioDesc: any;

  isCollapsed: boolean = false;
  iconCollapse: string = "icon-arrow-up";
  isButtonJson: boolean = false;
  isButtonMsgHistorico: boolean = true;
  faButtonMsgHistorico: string = "";

  mostrarJsonInputs: boolean = false;
  mostrarFormInputs: boolean = true;

  divInputs: Array<{ nkey: string, nvalue: string }> = [];
  inputsForm: any = {};
  plataformaSelecaoModal;
  alterarDadosInputados: boolean = true;

  modalRef?: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  leads: any = [];
  isPesquisado:boolean = false;
  tiposCampanhas: any;
  tiposConteudos: any;
  plataformas: any;
  workflows: any;
  mensagensStatus: any;
  mensagens_status_sel;
  filter: any;
  data_ini: NgbDateStruct = { day: null, month: null, year: null };
  data_fim: NgbDateStruct = { day: null, month: null, year: null };
  selectedAll: any;
  categoriasInstrumento: any = [];
  fluxo: number;
  executarNovoFluxo: boolean = false;
  workflow: any = { nome: null, descricao: null };
  qtdeItem: number;
  lead_id: number;
  colexport: any;
  totalItems: number = 0;
  currentPage: number = 1;
  maxSize: number;
  listItemsPerPage = [10, 20, 30, 40, 50, 100, 200, 300, 400, 500];
  listStatus = [{ nome: 'Aguardando', value: 'Aguardando' }, { nome: 'Processando', value: 'Processando' }, { nome: 'Sucesso', value: 'Sucesso' }, { nome: 'Falha', value: 'Falha' }, { nome: 'Todos', value: '' }];
  listTiposCliente = [{ nome: 'PF', value: 'PF' }, { nome: 'PJ', value: 'PJ' }, { nome: 'Todos', value: '' }];
  colunasExport = [];
  tipoAtividadeEnum: any = TipoAtividadeEnum;
  queryParams: any;
  queryParamsSubscription: Subscription;
  atualizacaoTelaTempoReal: boolean = true;

  selectedFields = { 'colunas': [] };
  allSelected = true;
  projetos: any = [];
  acoes: any = [];
  acoesAll: any = [];
  portes: any = [];

  constructor(private leadService: LeadService
    , private modalService: BsModalService
    , private conteudoService: ConteudoService
    , private campanhaTipoService: CampanhaTipoService
    , private conteudoTipoService: ConteudoTipoService
    , private customFormat: MyNgbDateParserFormatter
    , private toastr: ToastrService
    , private PlataformaService: PlataformaService
    , private confirmService: ConfirmService
    , private CategoriaService: CategoriaService
    , private workflowService: WorkflowService
    , private sessaoService: SessaoService
    , private usuarioService: UsuarioService
    , private sseService: SseService
    , private formatService: FormatService
    , private route: ActivatedRoute
    , private projetoAcaoService: ProjetoAcaoService
    , private cadastroClienteService: CadastroClienteService
    , private cenariosIa: CenariosIaService
    , private ngZone: NgZone) {


    this.sseService.createEventSource('events/1').subscribe(
      (e: any) => {
        if (this.atualizacaoTelaTempoReal) {
          console.log('Message received: ', e);
          const i = this.leads.findIndex(f => f.id == e.fila_id);
          if (i >= 0) {
            this.ngZone.run(() => {
              if (e['fila'] == null) {
                this.leads[i].status = e['status'];
              } else {
                this.leads[i] = e['fila']['ConteudoFilas'][0];
              }
            });
          }
        }
      }
    );

    this.queryParams = this.route.snapshot.queryParams;
  }

  async ngOnInit() {
    this.faButtonMsgHistorico = this.isButtonMsgHistorico ? "fa-eye" : "fa-eye-slash";

    this.permissaoExecutarNovoFluxo();
    this.initFiltros();

    this.projetoAcaoService.listarTodosProjetos().then(response => {
      this.projetos = response;
    });
    this.projetoAcaoService.listarTodasAcoes().then(response => {
      this.acoesAll = response;
    });
    this.CategoriaService.listarTodosInstrumentos().then(response => {
      this.categoriasInstrumento = response;
    });

    this.conteudoService.listarTodasMensagensHistorico().then(response => {
      this.mensagensStatus = response;
    });

    this.PlataformaService.listarTodasPlataformas().then(response => {
      this.plataformas = response;
    });

    this.campanhaTipoService.listarTodosTiposCampanha().then(response => {
      this.tiposCampanhas = response;
    });

    this.conteudoTipoService.listarTodosTiposConteudo().then(response => {
      this.tiposConteudos = response;
    });

    this.workflowService.getWorkflows().then((w: any) => {
      this.workflows = w;
    });

    this.cadastroClienteService.listarPortesAtivo().then((response: any) => {
        this.portes = response;
    });

    this.cenariosIa.getCenarioSemSolucao().then((response: any) => {
        this.dataCenarioSemSolucao = response;
    });

    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      this.queryParams = params
      this.setFilterQueryParams();
    });
  }

  initFiltrosObject() {
    this.data_ini = { day: null, month: null, year: null };
    this.data_fim = { day: null, month: null, year: null };

    this.filter = {
      id: '',
      registro_id: '',
      identificador: '',
      plataforma_id: '',
      workflow_id: '',
      campanha_tipo_id: '',
      conteudo_tipo_id: '',
      conteudo_importacao_id: '',
      status: '',
      cpf: '',
      cnpj: '',
      nome_cliente: '',
      nome_empreendimento: '',
      porte: '',
      email_cliente: '',
      email_responsavel: '',
      historico_keys: '',
      historico: '',
      msg_impeditiva: '',
      data_ini_processo: '',
      data_fim_processo: '',
      qtd_reprocessado_de: '',
      qtd_reprocessado_ate: '',
      tipo_cliente: '',
      mostrar_base_leads: 1,
      dados_formatado_json: '',
      cod_projeto:'',
      cod_acao:'',
      cod_categoria: '',
      protocolo_atendimento:'',
      page: 1,
      limit: 30,
      exportar_rel: 0
    };

    this.parseFilters();
    this.sessaoService.set('FiltrosLeadsInit', this.jsonToBase64(this.filter));
  }

  jsonToBase64(obj){
    const jsonString = JSON.stringify(obj);
    return btoa(jsonString);
  }

  initFiltros() {
    this.mensagens_status_sel = [];
    let FiltrosLeads: any = this.sessaoService.get('FiltrosLeads');
    if (FiltrosLeads) {
      this.filter = FiltrosLeads;

      if(this.sessaoService.get('FiltrosLeadsInit') != this.jsonToBase64(this.filter)){
        this.getLeads();
      }
    } else {
      this.initFiltrosObject();
    }
  }
  getAcoesPorProjeto() {
    this.acoes = [];
    if (this.filter.cod_projeto) {
      this.projetoAcaoService.getAcoesPorProjeto(this.filter.cod_projeto).toPromise().then(response => {
        this.acoes = response;
      });
    }

  }
  public setFilterQueryParams() {
    let fields = Object.keys(this.queryParams);

    if (fields.length && fields[0] != 'identificador') {
      this.initFiltrosObject();
    } else if (fields.length && fields[0] == 'identificador') {
      this.initFiltrosObject();
      this.initPeriodo();
    } else {
      this.initPeriodo();
    }

    fields.forEach(field => {
      this.filter[field] = this.queryParams[field];
    });

    if(fields.length > 0){
        this.getLeads();
    }
  }

  ngOnDestroy() {
    this.queryParamsSubscription.unsubscribe();
  }

  initPeriodo() {
    if (this.filter.data_ini_processo && this.filter.data_fim_processo) {
      const dtInicio = moment(this.filter.data_ini_processo);
      const dtFim = moment(this.filter.data_fim_processo);
      this.data_ini = { year: dtInicio.year(), month: dtInicio.month() + 1, day: dtInicio.date() };
      this.data_fim = { year: dtFim.year(), month: dtFim.month() + 1, day: dtFim.date() };
    } else {

      //Voltar dias (operador -)
      const now = new Date();
      var date = new Date();
      const dtInicio = new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000));
      this.data_ini = { year: dtInicio.getFullYear(), month: dtInicio.getMonth() + 1, day: dtInicio.getDate() };
      this.data_fim = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };

      this.parseFilters();
      this.sessaoService.set('FiltrosLeadsInit', this.jsonToBase64(this.filter));
    }
  }

  selectAll() {
    for (var i = 0; i < this.leads.length; i++) {
      this.leads[i].selected = this.selectedAll;
    }
  }

  desmarcarAll() {
    for (var i = 0; i < this.leads.length; i++) {
      this.leads[i].selected = false;
    }
  }

  rowClicked(obj: any) {
    if (obj.status.trim() == 'Falha') {
      obj.selected = !obj.selected;
    }
  };
  selectAtualizacaoTelaTempoReal() {
    this.atualizacaoTelaTempoReal = this.atualizacaoTelaTempoReal ? false : true;
  }
  checkClicked() {
    this.qtdeItem = 0;
    this.leads.forEach(lead => {
      if (lead.selected === true) {
        this.qtdeItem++;
      }
    });
  };
  pageChanged(event: any): void {
    this.filter.page = event.page;
    this.filter.limit = event.itemsPerPage;
    this.getLeads();
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.iconCollapse = this.isCollapsed ? "icon-arrow-down" : "icon-arrow-up";
  }

  toggleButtonJson(): void {
    this.isButtonJson = !this.isButtonJson;
  }

  toggleButtonMsgHistorico(): void {
    this.isButtonMsgHistorico = !this.isButtonMsgHistorico;
    this.faButtonMsgHistorico = this.isButtonMsgHistorico ? "fa-eye" : "fa-eye-slash";
  }

  parseFilters(){
    this.filter.campanha_tipo_id = this.filter.campanha_tipo_id ? this.filter.campanha_tipo_id : '';
    this.filter.conteudo_tipo_id = this.filter.conteudo_tipo_id ? this.filter.conteudo_tipo_id : '';
    this.filter.plataforma_id = this.filter.plataforma_id ? this.filter.plataforma_id : '';
    this.filter.cod_projeto = this.filter.cod_projeto ? this.filter.cod_projeto : '';
    this.filter.cod_acao = this.filter.cod_acao ? this.filter.cod_acao : '';
    this.filter.cod_categoria = this.filter.cod_categoria ? this.filter.cod_categoria : '';
    this.filter.workflow_id = this.filter.workflow_id ? this.filter.workflow_id : '';
    this.filter.status = this.filter.status ? this.filter.status : '';
    this.filter.tipo_cliente = this.filter.tipo_cliente ? this.filter.tipo_cliente : '';
    this.filter.responsavel_id = this.filter.usuario && this.filter.usuario.id ? this.filter.usuario.id : '';
    this.filter.data_ini_processo = this.data_ini && this.data_ini.day ? this.customFormat.formatForServer(this.data_ini) : '';
    this.filter.data_fim_processo = this.data_fim && this.data_fim.day ? this.customFormat.formatForServer(this.data_fim) : '';
    this.filter.historico_keys = this.mensagens_status_sel.length > 0 ? this.mensagens_status_sel.join(';') : '';
    this.filter.limit = this.filter.limit ? this.filter.limit : 30;
  }

  async getLeads(): Promise<void> {
    this.isPesquisado = true;
    //Parser

    this.parseFilters();
    this.sessaoService.set('FiltrosLeads', this.filter);
    let response = await this.leadService.get(this.filter).toPromise();
    this.leads = response.ConteudoFilas;
    this.totalItems = response.TotalItems;

    let maxSize = Math.ceil(this.totalItems / this.filter.limit);
    this.maxSize = maxSize <= 15 ? maxSize : 15;

    //window.pageYOffset;//ver posição atual
    window.scroll(739, 739);
  }

  pesquisar() {
    this.filter.page = 1;
    this.currentPage = 1;
    this.getLeads();
  }

  limparFiltros() {
    this.sessaoService.delete('FiltrosLeads');
    this.initFiltros();
    this.initPeriodo();
    this.getLeads();
  }

  getClassStatus(status: string) {
    return this.formatService.getClassBadgeStatus(status);
  }

  reprocessarLista(lead_id = null) {
    let ids = [];

    if (lead_id) {
      ids.push(lead_id);
    } else {
      this.leads.forEach(lead => {
        if (lead.selected === true) {
          ids.push(lead.id);
        }
      });
    }

    if (!ids.length) {
      this.toastr.warning('Por favor selecione ao menos um registro para reprocessar!', 'Atenção');
      return false;
    }

    this.conteudoService.reprocessarLeads(ids).subscribe(response => {
      this.toastr.success('Registros reinicializados com sucesso.');
      this.pesquisar();
      this.desmarcarAll();
      this.selectedAll = false;
    }, e => {
      console.log(e.error);
    });
  }
  exportar(template: any): void {

    this.config['class'] = 'modal-xl';
    this.config['scrollable'] = true;
    this.modalRef = this.modalService.show(template, this.config);
  }

  setColunasExport($event) {
    this.colunasExport = $event;
  }

  exportCSV(modalRef: any): void {
    this.filter.exportar_rel = 1;
    this.selectedFields.colunas = this.colunasExport.filter(f => f.checked == true).map(m => m.value);
    window.open(this.leadService.exportarLeads({ ...this.filter, ...this.selectedFields }));
    modalRef.hide();
  }

  excluirLeads(lead_id = null) {
    let ids = [];

    if (lead_id) {
      ids.push(lead_id);
    } else {
      this.leads.forEach(lead => {
        if (lead.selected === true) {
          ids.push(lead.id);
        }
      });
    }

    if (!ids.length) {
      this.toastr.warning('Por favor selecione ao menos um registro para excluir!', 'Atenção');
      return false;
    }


    this.confirmService.confirm({
      title: 'Excluir da Base de Leads', message: 'Essa exclusão não desfaz algum processamento já realizado, você tem certeza que deseja excluir o(s) ' + ids.length + ' iten(s)?'
    }).then(
      () => {
        this.conteudoService.deleteList(ids).subscribe(response => {
          this.toastr.success('Registros excluídos com sucesso!');
          this.getLeads();
          this.desmarcarAll();
          this.selectedAll = false;
        }, e => {
          console.log(e);
        });
      },
      () => { });
  }

  getNomeInstrumento(cod_categoria) {
    let instrumentos = this.categoriasInstrumento.filter((item: any) => item.CodCategoria == cod_categoria);
    return instrumentos.length > 0 ? instrumentos[0].DescCategoria : '';
  }

  getNomeProjeto(cod_projeto) {
    let projetos = this.projetos.filter((item: any) => item.CodPRATIF == cod_projeto);
    return projetos.length > 0 ? projetos[0].NomePRATIF : '';
  }

  getNomeAcao(cod_acao) {
    let acoes = this.acoesAll.filter((item: any) => item.CodAcaoSeq == cod_acao);
    return acoes.length > 0 ? acoes[0].NomeAcao : '';
  }

  openModalAlterarJson(lead: any, alterar: boolean) {
    this.alterarDadosInputados = alterar;

    this.dataModalFilaId = lead.id;
    if (lead && lead.id && !lead.camposJson) {
      this.conteudoService.getIdCamposText(lead.id).subscribe(response => {
        lead.camposJson = response;
        this.dataModalJson = JSON.parse(lead.camposJson.objeto_json);
        this.dataModalJson = JSON.stringify(this.dataModalJson, undefined, 4);
        this.plataformaSelecaoModal = lead.conteudo_publicado.plataforma.codigo_interno;
        this.montaInputs(this, lead.camposJson.objeto_json);
        this.modalAlterarJson.show();
      });
    } else if (lead && lead.id && lead.camposJson) {
      this.dataModalJson = JSON.parse(lead.camposJson.objeto_json);
      this.dataModalJson = JSON.stringify(this.dataModalJson, undefined, 4);
      this.modalAlterarJson.show();
    }
    return lead;
  }

  salvarJsonModal() {
    this.conteudoService.salvarFila({ id: this.dataModalFilaId, objeto_json: this.dataModalJson }).subscribe(response => {
      this.toastr.success('Parâmetros JSON salvo com sucesso!');
      this.modalAlterarJson.hide();
      this.pesquisar();
    }, e => {
      console.log(e.error);
    });
  }



  getCamposJson(lead: any) {
    if (lead && lead.id && !lead.camposJson) {
      this.conteudoService.getIdCamposText(lead.id).subscribe(response => {
        lead.camposJson = response;
        return lead;
      });
    }
    return lead;
  }



  getLeadsFalhaComProtocoloAtendimentoSas(lead_id = null) {
    let ids = [];

    if (lead_id) {
      ids.push(lead_id);
    } else if (this.leads && this.leads.length > 0) {
      this.leads.forEach(lead => {
        if (lead.selected === true && (lead.protocolo_atendimento != null && lead.protocolo_atendimento != '')) {
          ids.push(lead.id);
        }
      });
    }
    return ids;
  }

  countLeadsFalhaComProtocoloAtendimentoSas() {
    return this.getLeadsFalhaComProtocoloAtendimentoSas().length;
  }

  excluirAtendimentosSasLeadsFalha(lead_id = null) {
    let ids = this.getLeadsFalhaComProtocoloAtendimentoSas(lead_id);

    if (!ids.length) {
      this.toastr.warning('Não encontrado nenhum lead com protocolo de atendimento gerado no SAS!', 'Atenção');
      return false;
    }

    this.confirmService.confirm({
      title: 'Desfazer Atendimento SAS - Leads com Falha', message: 'Essa ação exclui o protocolo de atendimento gerado no SAS, confirmar a exclusão de ' + ids.length + ' protocolo(s)?'
    }).then(
      () => {
        this.conteudoService.excluirAtendimentoSasLeadsFalha(ids).subscribe(response => {
          this.toastr.success('Aguarde o processamento do workflow para desfazer o atendimento no SAS!');
          this.pesquisar();
          this.desmarcarAll();
          this.selectedAll = false;
        }, e => {
          console.log(e);
        });
      },
      () => { });
  }
  mostrarInputs(tipo) { // right now: ['1','3']
    if (tipo === 'FORM') {
      this.mostrarFormInputs = false;
      this.mostrarJsonInputs = true;
    } else {
      this.mostrarFormInputs = true;
      this.mostrarJsonInputs = false;
    }
  }
  montaInputs(element, json) {
    let jsonData = JSON.parse(json);
    if (element.plataformaSelecaoModal == PlataformaEnum.RDSTATION_MARKETING) {
      jsonData = jsonData.leads[0].last_conversion.content;
    }
    this.divInputs = [];
    Object.keys(jsonData).forEach(function (key) {
      let value = jsonData[key];
      let type = 'text';
      if (Array.isArray(value)) {
        value = JSON.stringify(value);
        type = 'textarea';
      }
      if (key.search("assword") >= 0) {
        type = 'password';
      }
      element.divInputs.push({ nkey: key, nvalue: value, type: type });
      element.inputsForm[key] = value;
    });
  }
  editInput(key, nkey, value) {
    this.divInputs[key].nvalue = value.target.value;
    this.inputsForm[nkey] = value.target.value;
    if (this.plataformaSelecaoModal == PlataformaEnum.RDSTATION_MARKETING) {
      this.dataModalJson = JSON.parse(this.dataModalJson);
      this.dataModalJson.leads[0].last_conversion.content = this.inputsForm;
      this.dataModalJson = JSON.stringify(this.dataModalJson, undefined, 4);
    } else {
      this.dataModalJson = JSON.stringify(this.inputsForm, undefined, 4);
    }

  }
  editJson() {

    this.montaInputs(this, this.dataModalJson);
  }

  openModalExecutarFluxo(obj: any = null) {
    this.lead_id = null;
    if (obj) {
      this.lead_id = obj.id;
    }
    this.checkClicked();
    this.modalExecutarFluxo.show();
  }

  openModalDetalhesSolucao(cenario_id:number, cenario: string) {
    this.dataModalCenarioDesc = cenario;
    if(cenario_id){
        this.cenariosIa.getId(cenario_id).subscribe(response => {
            this.dataModalSolucao = response;
          });
    } else {
        this.dataModalSolucao = this.dataCenarioSemSolucao;
    }

    this.modalDetalhesSolucao.show();
  }

  salvarExecutarNovoFluxo(lead_id = this.lead_id) {

    if (!this.fluxo) {
      this.toastr.warning('Selecione um Fluxo!', 'Atenção');
      return false;
    }
    let ids = [];

    if (lead_id) {
      ids.push(lead_id);
    } else {
      this.leads.forEach(lead => {
        if (lead.selected === true) {
          ids.push(lead.id);
        }
      });
    }
    if (!ids.length) {
      this.toastr.warning('Por favor selecione ao menos um registro!', 'Atenção');
      return false;
    }
    let params = { ids: ids, workflow_id: this.fluxo };

    this.conteudoService.executarNovoFluxo(params).subscribe(response => {
      this.toastr.success('Registros alterados com sucesso.');
      this.pesquisar();
      this.desmarcarAll();
      this.selectedAll = false;
    }, e => {
      console.log(e.error);
    });
    this.modalExecutarFluxo.hide();
  }

  async permissaoExecutarNovoFluxo() {
    const dados = {
      usuario_id: this.sessaoService.getUsuarioId(),
      projeto: environment.environment.projeto,
      metodo: 'postExecutarNovoFluxo',
      modulo: 'ConteudoFilas',
      verifica_front: true
    };
    this.executarNovoFluxo = await this.usuarioService.getIsAuthorized(dados).toPromise();
  }

  public mostraWorkflow(obj) {
    this.workflow.nome = obj.nome;
    this.workflow.descricao = obj.descricao;
  }

  getTituloSolucao(lead){
    if(lead && lead.CenarioIaTitSolucao){
        return lead.CenarioIaTitSolucao;
    } else if(lead && lead.status != 'Processando'){
        return this.dataCenarioSemSolucao.titulo_solucao;
    } else {
        return '';
    }
  }
}

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-search"></i>
        Pesquisa de pessoas físicas e jurídicas realizadas
        <div class="card-header-actions">
          <button type="button" class="btn btn-minimize" (click)="toggleCollapse()">
            <i class={{iconCollapse}}></i>
          </button>
        </div>
      </div>
      <div [collapse]="isCollapsed" class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">Tipo de Documento:</label>

              <ng-select [items]="listTiposDocumento" bindLabel="nome" bindValue="value" placeholder="Selecione..."
                [(ngModel)]="filter.tipo_documento" name="tipo_documento">
              </ng-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">Somente pesquisas:</label>

              <ng-select [items]="listLocalizados" bindLabel="nome" bindValue="value" placeholder="Selecione..."
                [(ngModel)]="filter.encontrado" name="encontrado">
              </ng-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">CPF/CNPJ:</label>
              <div class="input-group">
                <input class="form-control" type="text" [(ngModel)]="filter.numero_documento" name="numero_documento">
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">Cód. Parceiro SAS:</label>
              <div class="input-group">
                <input class="form-control" type="text" [(ngModel)]="filter.CodParceiro" name="CodParceiro">
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">Pesquisa do dia:</label>
              <div class="input-group">
                <input class="form-control" type="text" ngbDatepicker #d="ngbDatepicker" name="data_ini"
                  [(ngModel)]="data_ini">
                <div class="input-group-append">
                  <div class="input-group-append">
                    <span class="input-group-text" (click)="d.toggle()" style="cursor: pointer;">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">Pesquisa até dia:</label>
              <div class="input-group">
                <input class="form-control" type="text" ngbDatepicker #df="ngbDatepicker" name="data_fim"
                  [(ngModel)]="data_fim">
                <div class="input-group-append">
                  <div class="input-group-append">
                    <span class="input-group-text" (click)="df.toggle()" style="cursor: pointer;">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">Origem da base de dados:</label>

              <ng-select [items]="listOrigensConsulta" placeholder="Selecione..."
                [(ngModel)]="filter.origem_consulta" name="origem_consulta">
              </ng-select>
              <!-- <div class="input-group">
                <input class="form-control" type="text" [(ngModel)]="filter.origem_consulta" name="origem_consulta">
              </div> -->
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">Somente pesquisas indisponíveis:</label>

              <ng-select [items]="listSimNao" bindLabel="nome" bindValue="value" placeholder="Selecione..."
                [(ngModel)]="filter.status_code_indisponivel" name="status_code_indisponivel">
              </ng-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">Somente pesquisas com e-mail:</label>

              <ng-select [items]="listSimNao" bindLabel="nome" bindValue="value" placeholder="Selecione..."
                [(ngModel)]="filter.cadastro_com_email" name="cadastro_com_email">
              </ng-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Registros por página:</label>

              <ng-select [items]="listItemsPerPage" placeholder="Selecione..." [(ngModel)]="filter.limit" name="limit">
              </ng-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group pull-right">
              <button type="button" class="btn btn-primary" (click)="pesquisar()">
                <i class="fa fa-search"></i>
                Pesquisar/Visualizar
              </button>
              &nbsp;&nbsp;
              <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                <i class="fa fa-ban"></i>
                Resetar Filtros
              </button>
              &nbsp;&nbsp;
              <button type="button" class="btn btn-success" (click)="exportar()">
                <i class="fa fa-file-excel-o"></i>
                Exportar para Excel
              </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-align-justify"></i>
        Resultados ({{totalItems}})
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Dados do Requisitante</th>
              <th>Cliente / Empreendimento</th>
              <th>Dados da Checagem / Indicadores</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of consultas; let i = index">
              <td>
                <div><strong>{{c?.usuario?.nome}}</strong></div>
                <div class="small text-muted">
                  <span><strong>Aplicação de origem:&nbsp;</strong></span>{{c?.projeto?.nome}}
                </div>
                <div class="small text-muted">
                  <span><strong>Primeira
                      consulta:&nbsp;</strong></span>{{c.created | date: 'dd/MM/yyyy HH:mm'}}
                </div>
                <div class="small text-muted">
                  <span><strong>Última
                      consulta:&nbsp;</strong></span>{{c.modified | date: 'dd/MM/yyyy HH:mm'}}
                </div>
                <div class="small text-muted"><span><strong>Reconsulta:</strong></span>
                  {{c.numero_reconsulta}}</div>
              </td>
              <td>
                <div><strong>{{c?.nome}}</strong></div>
                <div class="small text-muted">
                  <span><strong>Cód. Parceiro SAS:&nbsp;</strong></span>{{c?.CodParceiro}}
                </div>
                <div class="small text-muted" *ngIf="c?.tipo_documento =='PF'">
                  <span><strong>CPF:&nbsp;</strong></span>{{c?.numero_documento | completeDigitoCpf | mask:
                  '000.000.000-00'}}
                </div>
                <div class="small text-muted" *ngIf="c?.tipo_documento =='PJ'">
                  <span><strong>CNPJ:&nbsp;</strong></span>{{c?.numero_documento | completeDigitoCnpj | mask:
                  '00.000.000/0000-00'}}
                </div>
                <div class="small text-muted">
                  <span><strong>E-mail:&nbsp;</strong></span>{{c?.email}}
                </div>

                <div class="row text-center" *ngIf="c.json_sas">
                  <button class="btn btn-sm btn-ghost-primary" type="button"
                    (click)="openModalVisualizarJson(c.json_sas, 'Dados no formato JSON convertido no padrão SAS')">Visualizar
                    dados JSON convertido no padrão SAS</button>
                </div>

              </td>
              <td>
                <div>
                  <span [ngClass]="c?.encontrado == 1 ? 'badge badge-success' : 'badge badge-danger'">{{c?.encontrado
                    ? 'Localizado' : 'Não localizado'}}</span>
                </div>
                <div class="small text-muted">
                  <span><strong>Origem da checagem:&nbsp;</strong></span>{{c?.origem_consulta}}
                </div>


                <div class="small text-muted">
                  <span><strong>Status do
                      serviço:&nbsp;</strong></span>{{c?.status_code == 200 ? 'Disponível' : 'Indisponível'}}
                </div>

                <div class="small text-muted"><strong>Obs.:&nbsp;</strong>{{c?.mensagens}}</div>

                <div class="row text-center" *ngIf="c.json_retorno">
                  <button class="btn btn-sm btn-ghost-success" type="button"
                    (click)="openModalVisualizarJson(c.json_retorno, 'Dados no formato JSON originados da pesquisa')">Visualizar
                    dados no formato JSON originados da pesquisa</button>
                </div>
              </td>
            </tr>
            <tr *ngIf="consultas && consultas.length == 0">
              <td colspan="9">Não existe consultas realizadas com estes parâmetros</td>
            </tr>
          </tbody>
        </table>

        <div class="col-md-12 justify-content-center">
          <pagination [boundaryLinks]="true" [itemsPerPage]="filter.limit" [totalItems]="totalItems" class="text-center"
            [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" previousText="&lsaquo;"
            nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
        </div>
      </div>
    </div>
  </div>
</div>


<div bsModal #modalVisualizarJson="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{tituloModalJson}}</h4>
        <button type="button" class="close" (click)="modalVisualizarJson.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <pre [innerHtml]="dataModalJson | json"></pre>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modalVisualizarJson.hide()">Fechar</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsuarioFormComponent } from './usuario-form/usuario-form.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Usuários'
    },
    children: [
      {
        path: 'inserir',
        component: UsuarioFormComponent,
        data: {
          title: 'Inserir Usuário'
        }            
      },
      {
        path: 'alterar/:id',
        component: UsuarioFormComponent,
        data: {
          title: 'Alterar Usuário'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsuariosRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CanalAtendimentoService } from '../../../services/canal-atendimento.service';
import { CategoriaService } from '../../../services/categoria.service';
import { ConteudoTipoService } from '../../../services/conteudo-tipo.service';
import { CustomServiceValidateService } from '../../../shared/control-messages/custom-service-validate.service';

@Component({
  selector: 'app-tipo-atividade-form',
  templateUrl: './tipo-atividade-form.component.html',
  styleUrls: ['./tipo-atividade-form.component.scss']
})
export class TipoAtividadeFormComponent implements OnInit {
  form: FormGroup;
  canaisAtendimento: any = [];
  categoriasInstrumento: any = [];

  constructor(private fb: FormBuilder
    , private conteudoTipoService: ConteudoTipoService
    , private canalAtendimentoService: CanalAtendimentoService
    , private categoriaService: CategoriaService
    , private toastr: ToastrService
    , private router: Router
    , private route: ActivatedRoute) {
  }

  async ngOnInit() {
    this.initForm();

    this.canalAtendimentoService.listarTodosCanaisAtendimento().then(response => {
      this.canaisAtendimento = response;
    });

    this.categoriaService.listarTodosInstrumentos().then(response => {
      this.categoriasInstrumento = response;
    });

    const tipo_atividade_id = this.form.value.id ? this.form.value.id : (this.route.snapshot.params.id ? this.route.snapshot.params.id : '');

    if (tipo_atividade_id && tipo_atividade_id != 0) {
      this.getId(tipo_atividade_id);
    }
  }

  initForm() {
    this.form = this.fb.group({
      id: [''],
      nome: ['', Validators.required],
      CodMeioAtendimento: [''],
      CodCategoria: [''],
      ativo: [1]
    });
  }

  salvar() {
    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
      return false;
    }

    this.form.value.ativo = this.form.value.ativo ? 1 : 0;

    this.conteudoTipoService.salvar(this.form.value).subscribe(response => {
      this.toastr.success('Tipo de atividade salvo com sucesso.');
      this.router.navigate(['/tipo-atividades/form/' + response['id']]);
    }, e => {
      CustomServiceValidateService.customMensageService(e.error, this.form);
    });
  }

  getId(id) {
    this.conteudoTipoService.getId(id).toPromise().then(async response => {
      this.form.patchValue(response);
    });
  }

  novo() {
    this.initForm();
  }
}

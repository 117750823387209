import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectRegistrosPorPaginaComponent } from './select-registros-por-pagina.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgbModule
  ],
  exports: [CommonModule, FormsModule, SelectRegistrosPorPaginaComponent],
  declarations: [SelectRegistrosPorPaginaComponent]  
})
export class SelectRegistrosPorPaginaModule { }

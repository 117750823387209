import {  NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModalComponent } from './sidebar-modal.component';



@NgModule({
  declarations: [
    SidebarModalComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
  ],
  exports: [SidebarModalComponent],
})
export class SidebarModalModule { }

<div class="animated fadeIn">

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-search"></i>
          Pesquisa de <strong>Tipo de Campanhas</strong>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Nome da Campanha:</label>
                <input class="form-control" type="text" [(ngModel)]="filter.nome" name="nome">
              </div>
            </div>            
            <div class="col-md-2">
              <div class="form-group">
                <label>Ativo:</label>
                <ng-select [items]="listStatus" bindLabel="name" bindValue="value" placeholder="Selecione um status"
                  name="ativo" [(ngModel)]="filter.ativo">
                </ng-select>
              </div>
            </div>           
          </div>
          <div class="col-md-8">
            <div class="form-group pull-left">
              <br>
              <button type="button" class="btn btn-primary" (click)="pesquisar()">
                <i class="fa fa-search"></i>
                Pesquisar
              </button>
              &nbsp;&nbsp;
              <a class="btn btn-success" href="#" [routerLink]="['/tipo-campanhas/form/0']">
                <i class="fa fa-plus"></i> Cadastrar Tipo de Campanha</a>
              &nbsp;&nbsp;
              <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                <i class="fa fa-ban"></i>
                Limpar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i>
          Resultados ({{tipoCampanhas && tipoCampanhas.length ? tipoCampanhas.length : 0}})
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th width="10%" class="text-center">Código</th>
                <th width="60%">Nome da Campanha</th>
                <th width="30%" class="text-center">Ativo</th>                
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ta of tipoCampanhas; let i = index" (click)="editarRegistro(ta.id)" style="cursor: pointer;">
                <td class="text-center">{{ta.id}}</td>
                <td>{{ta.nome}}</td>                
                <td class="text-center"><span
                    [ngClass]="ta.ativo == 1 ? 'badge badge-success' : 'badge badge-danger'">{{ta.ativo
                    == 1 ? 'Sim' : 'Não'}}</span>
                </td>            
              </tr>
              <tr *ngIf="tipoCampanhas && tipoCampanhas.length == 0">
                <td colspan="3">Não existe tipo de atividades com estes parâmetros</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
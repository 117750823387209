import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatarFrase'
})
export class FormatarFrasePipe implements PipeTransform {

    transform(value: string): string {
        const palavras = value.toLocaleLowerCase().split(" ");
        palavras[0] = palavras[0][0].toUpperCase() + palavras[0].substr(1);
        return palavras.join(" ");

    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DoresRoutingModule } from './dores-routing.module';
import { DoresComponent } from './dores.component';
import { DorFormComponent } from './dor-form/dor-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlMessagesModule } from '../../shared/control-messages/control-messages.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmModalModule } from '../../shared/confirm-modal/confirm-modal.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';

@NgModule({
  imports: [
    CommonModule,
    DoresRoutingModule,
    ReactiveFormsModule,    
    ControlMessagesModule,                             
    NgSelectModule,        
    NgbModule,    
    FormsModule,    
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),    
    PaginationModule.forRoot(),
    ConfirmModalModule
  ],
  declarations: [DoresComponent, DorFormComponent]
})
export class DoresModule { }

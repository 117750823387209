import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'completeDigitoCpf'
})
export class CompleteDigitoCpfPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      return ("00000000000" + value).slice(-11);
    }
    return value;
  }

}

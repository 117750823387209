<div class="animated fadeIn">

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-search"></i>
          Pesquisa de <strong>Regionais/Cidades</strong>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="name">Nome da Regional:</label>
                <input class="form-control" type="text" [(ngModel)]="filter.nome" name="nome">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="name">Nome da Cidade:</label>

                <ng-select [items]="cidades" bindLabel="DescCid" bindValue="CodCid" placeholder="Selecione uma cidade"
                  [(ngModel)]="filter.CodCid" name="CodCid">
                </ng-select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Ativo:</label>
                <ng-select [items]="listStatus" bindLabel="name" bindValue="value" placeholder="Selecione um status"
                  name="ativo" [(ngModel)]="filter.ativo">
                </ng-select>
              </div>
            </div>
            <!-- <div class="col-md-2">
              <div class="form-group">
                  <label>Registros por página:</label>

                  <ng-select [items]="listItemsPerPage" placeholder="Selecione..."
                      [(ngModel)]="filter.limit" name="limit">
                  </ng-select>
              </div>
          </div> -->
          </div>
          <div class="col-md-8">
            <div class="form-group pull-left">
              <br>
              <button type="button" class="btn btn-primary" (click)="pesquisar()">
                <i class="fa fa-search"></i>
                Pesquisar
              </button>
              &nbsp;&nbsp;
              <a class="btn btn-success" href="#" [routerLink]="['/regionais/form/0']">
                <i class="fa fa-plus"></i> Cadastrar Regional/Cidades</a>
              &nbsp;&nbsp;
              <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                <i class="fa fa-ban"></i>
                Limpar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i>
          Resultados ({{regionais && regionais.length ? regionais.length : 0}})
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th width="15%" class="text-center">Código</th>
                <th width="75%">Nome da Regional</th>
                <th width="10%" class="text-center">Ativo</th>
                <th width="10%" class="text-left">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let r of regionais; let i = index">
                <td class="text-center">{{r.id}}</td>
                <td>{{r.nome}}</td>
                <td class="text-center"><span
                    [ngClass]="r.ativo == 1 ? 'badge badge-success' : 'badge badge-danger'">{{r.ativo
                    == 1 ? 'Sim' : 'Não'}}</span>
                </td>
                <td class="td-actions text-left">

                  <div class="btn-group" dropdown placement="bottom left">
                    <button dropdownToggle type="button" class="btn btn-light dropdown-toggle">
                      Ações
                      <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                      <li role="menuitem">
                        <button type="button" class="dropdown-item" (click)="editarRegistro(r.id)" tooltip="Editar">
                          <i class="fa fa-pencil fa-lg mt-1 "></i> Editar
                        </button>
                      </li>
                      <li role="menuitem">
                        <button type="button" class="dropdown-item" (click)="deletar(r)" tooltip="Excluir">
                          <i class="fa fa-times fa-lg mt-1 "></i> Excluir
                        </button>
                      </li>
                    </ul>
                  </div>

                </td>
              </tr>
              <tr *ngIf="regionais && regionais.length == 0">
                <td colspan="4">Não existe regionais com estes parâmetros</td>
              </tr>
            </tbody>
          </table>

          <!-- <div class="col-md-12 justify-content-center">
            <pagination [boundaryLinks]="true" [itemsPerPage]="filter.limit" [totalItems]="totalItems"
              class="text-center" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
          </div> -->

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template confirm>
   <confirm-modal-component></confirm-modal-component>
</ng-template>

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-paperclip"></i>
        <strong>Atividades Econômicas do Segmento [{{SegmentoNome}}]</strong>

        <div class="pull-right">
          <button type="button" class="btn btn-primary" (click)="adicionar()">
            <i class="fa fa-check"></i>
            Adicionar
          </button>
        </div>
      </div>
      <div class="card-body">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="name">Atividade Econômica:</label>
                <div class="input-group">
                  <ng-template #rtAtividade let-r="result" let-t="term">
                    {{ r.CodAtivEcon }}{{ r.CodCnaeFiscal }} - {{ r.DescCnaeFiscal }}
                  </ng-template>

                  <input type="text" class="form-control" formControlName="atividade_economica"
                      [class.is-invalid]="searchFailedAtividade" [ngbTypeahead]="searchAtividade" [inputFormatter]="formatterAtividade" [resultTemplate]="rtAtividade"
                      placeholder="Digite o nome da atividade econômica" />
                  <div class="invalid-feedback" *ngIf="searchFailedAtividade">Desculpe, não conseguimos encontrar esta atividade econômica.</div>
                  <div class="input-group-append">
                      <span class="input-group-text">
                          <i class="fa fa-search"></i>
                      </span>
                  </div>
                </div>
                <app-control-messages [control]="form.controls.atividade_economica"></app-control-messages>
                <app-control-messages [control]="form.controls.CodAtivEcon"></app-control-messages>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </form>

        <div class="row">&nbsp;</div>

        <table class="table">
          <tbody>
            <tr>
              <th width="15%">Cód. Atividade</th>
              <th width="60%">Atividade</th>
              <th width="15%">Setor</th>
              <th width="10%" class="text-center">Ações</th>
            </tr>
            <tr *ngFor="let s of segmentoAtividades; let i = index">
              <td>{{s?.CodAtivEcon}}{{s?.CodCnaeFiscal}}</td>
              <td>{{s?.DescCnaeFiscal}}</td>
              <td>{{s?.DescSetor}}</td>
              <td class="text-center">
                <button type="button " class="btn btn-sm btn-danger " title="Excluir" (click)="excluir(s) ">
                  <i class="fa fa-times fa-lg mt-1 "></i>
                </button>
              </td>
            </tr>
            <tr *ngIf="segmentoAtividades && segmentoAtividades.length == 0">
              <td colspan="4">Não existe atividades econômicas cadastradas para este segmento</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>

<ng-template confirm>
   <confirm-modal-component></confirm-modal-component> 
</ng-template>

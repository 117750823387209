<div class="animated fadeIn">

  <div class="col-md-12 mb-12">
    <tabset #staticTabs>
      <tab (selectTab)="carregarAba('cadastro')" [active]="abaAtiva === 'cadastro'">
        <ng-template tabHeading><i class="icon-note"></i> Integrador</ng-template>
      </tab>
      <tab (selectTab)="carregarAba('reprocessamentos')" [active]="abaAtiva === 'reprocessamentos'">
        <ng-template tabHeading><i class="icon-note"></i> Reprocessamentos Automático</ng-template>
      </tab>
      <tab *ngIf="form?.id" (selectTab)="carregarAba('configuracoes-customizadas')" [active]="abaAtiva === 'configuracoes-customizadas'">
        <ng-template tabHeading><i class="icon-note"></i> Configurações Customizadas</ng-template>
      </tab>
      <tab *ngIf="form?.id && form?.eventos_entrada == 1" (selectTab)="carregarAba('evento-entradas')" [active]="abaAtiva === 'evento-entradas'">
        <ng-template tabHeading><i class="icon-note"></i> Eventos de Entrada (Webhook)</ng-template>
      </tab>
      <tab *ngIf="form?.id" (selectTab)="carregarAba('campos')" [active]="abaAtiva === 'campos'">
        <ng-template tabHeading><i class="icon-note"></i> Campos (Base de Leads)</ng-template>
      </tab>
      <tab *ngIf="form?.id && form?.codigo_interno == 'SALESFORCE'" (selectTab)="carregarAba('extensoes')" [active]="abaAtiva === 'extensoes'">
        <ng-template tabHeading><i class="icon-note"></i> Extensões de Dados</ng-template>
      </tab>
      <tab *ngIf="form?.id && form?.codigo_interno == 'SOL'" (selectTab)="carregarAba('sol-sas')" [active]="abaAtiva === 'sol-sas'">
        <ng-template tabHeading ><i class="icon-note"></i> SOL x SAS</ng-template>
      </tab>
      <tab *ngIf="form?.id && form?.codigo_interno == 'PLANILHA_BRASIL_MAIS'" (selectTab)="carregarAba('brasil-mais')" [active]="abaAtiva === 'brasil-mais'">
        <ng-template tabHeading ><i class="icon-note"></i> Importações Brasil Mais</ng-template>
      </tab>
      <tab *ngIf="form?.id && form?.codigo_interno == 'RD_STATION'" (selectTab)="carregarAba('csv-rdstation')" [active]="abaAtiva === 'csv-rdstation'">
        <ng-template tabHeading ><i class="icon-note"></i> Importações CSV</ng-template>
      </tab>
      <tab *ngIf="form?.id" (selectTab)="carregarAba('processos')" [active]="abaAtiva === 'processos'">
        <ng-template tabHeading ><i class="icon-refresh"></i> Processos</ng-template>
      </tab>
      <router-outlet></router-outlet>
      <br>
    </tabset>
  </div>

</div>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DorService } from '../../../services/dor.service';
import { CustomServiceValidateService } from '../../../shared/control-messages/custom-service-validate.service';

@Component({
  selector: 'app-dor-form',
  templateUrl: './dor-form.component.html',
  styleUrls: ['./dor-form.component.scss']
})
export class DorFormComponent implements OnInit {

  form: FormGroup;

  constructor(private fb: FormBuilder
    , private dorService: DorService
    , private toastr: ToastrService
    , private router: Router
    , private route: ActivatedRoute) { }

  ngOnInit() {
    this.initForm();

    this.route.params.subscribe(routeParams => {
      if (routeParams.id && routeParams.id != 0) {
        this.getId(routeParams.id);
      }
    });
  }

  initForm() {
    this.form = this.fb.group({
      id: [''],
      nome: ['', Validators.required],
      descricao: [''],
      cod_externo: [''],
      ativo: [1]
    });
  }

  // facilitar o acesso no html
  get f() { return this.form.controls; }

  salvar() {
    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
      return false;
    }
    this.form.value.ativo = this.form.value.ativo ? 1 : 0;

    this.dorService.salvar(this.form.value).subscribe(response => {
      this.toastr.success('Dor salva com sucesso!');
      this.router.navigate(['/dores/form/' + response['id']]);
    }, e => {
      CustomServiceValidateService.customMensageService(e.error, this.form);
    });
  }

  getId(id: number) {
    this.dorService.getId(id).toPromise().then(response => {
      this.form.patchValue(response);
    });
  }

  novo() {
    this.initForm();
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { timeStamp } from 'console';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-validacao',
  templateUrl: './validacao.component.html',
  styleUrls: ['./validacao.component.css']
})



export class ValidacaoComponent implements OnInit {
  @Output() addAcao: EventEmitter<any> = new EventEmitter();
  @Input() edit;

  formValidade: FormGroup;
  regras;

  tipos = [
    { id: 1, operador: '>', descricao: '>' },
    { id: 2, operador: '>=', descricao: '>=' },
    { id: 3, operador: '<', descricao: '<' },
    { id: 4, operador: '<=', descricao: '<=' },
    { id: 5, operador: '!=', descricao: '<>' },
    { id: 5, operador: '==', descricao: '==' },
    { id: 6, operador: 'like', descricao: 'Contém' },
    { id: 7, operador: 'notlike', descricao: 'Não contém' },
    { id: 8, operador: 'pattern', descricao: 'Expressão regular' },    
    { id: 9, operador: 'cpf', descricao: 'CPF' },
    { id: 10, operador: 'cnpj', descricao: 'Cnpj' },
    { id: 11, operador: 'is_not_empty', descricao: 'Está preenchido' },
    { id: 12, operador: 'is_empty', descricao: 'Não está preenchido' },
  ];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if(this.regras == null || this.regras.length == 0){
      this.regras = [];
    }
    this.createForm();
  }
  ngOnChanges(changes: any) {
    if(this.edit && this.edit.validacoes){
        this.regras = this.edit.validacoes;
    }
  }

  createForm() {
    this.formValidade = this.formBuilder.group({
      id: [],
      criterio: ['Validacao', [Validators.required]],
      instrucao: ['And', [Validators.required]],
      regra_id: [],
      campo: ['', [Validators.required]],      
      operador: ['', [Validators.required]],      
      valor: ['']
    });

  }
  adicionarAcao() {
    this.addAcao.emit({regras: this.regras, edit: this.edit});
    this.regras = [];
    this.formValidade.reset();

  }
  deleteRegra(index: number) {
    this.regras.splice(index,1);
  }

  editForm(form: any, indexRegra) {
    let regra = this.regras[indexRegra];
    form['id'] = regra.id;
    form['criterio'] = regra.criterio ? regra.criterio : '';
    form['instrucao'] = regra.instrucao;    
    this.formValidade.setValue(form);

  }
  removerValidacao(index, indexRegra) {
    
    this.regras[indexRegra].validacoes.splice(index,1);
    //se não tiver mais validacoes nas regras, então removo a validacao
    if(this.regras[indexRegra].validacoes.length == 0){
      this.regras.splice(indexRegra,1);

    }
  }
  onSubmit() {
    this.addRegras();
    // aqui você pode implementar a logica para fazer seu formulário salvar
    this.formValidade.reset();
  }
  private addRegras(){
    let form = this.createObjeto();
    if(form.id == null && (this.regras == null || this.regras.length == 0))
    {
      form.id = 1;
      form.validacoes[0].regra_id = 1;
      this.regras.push(form);
    } 
    else if (form.id == null && (this.regras != null || this.regras.length > 0)) 
    {
      //verifico se a ultima posição do array é a mesma instrucao
      if(form.instrucao != this.regras[this.regras.length-1].instrucao){
        form.id = (Math.max(...this.regras.map(m => m.id)) + 1);
        form.validacoes[0].regra_id = 1;
        this.regras.push(form);
      }else{
          form.validacoes[0].regra_id = (Math.max(...this.regras[this.regras.length-1].validacoes.map(m => m.regra_id)) + 1);
          this.regras[this.regras.length-1].validacoes.push(form.validacoes[0]);
      }
     
      
    } else {
      let index = this.regras.findIndex(f => f.id == form.id);
      let validacao = form.validacoes[0];
      if(validacao != null && validacao.regra_id > 0){
        let index2 = this.regras[index].validacoes.findIndex(f => f.regra_id == validacao.regra_id);
        this.regras[index].validacoes[index2] =form.validacoes[0];
      }else{
        this.regras[index].validacoes.push(form.validacoes[0]);
      }

     
    }
  }
  private createObjeto(){
    let form = this.formValidade.value;

    return {
      id: form.id,
      criterio: form.criterio,
      instrucao: form.instrucao,
      validacoes: [{
        regra_id: form.regra_id,
        campo: form.campo,
        valor: form.valor,
        operador: form.operador
      }]
    };  
  }

  getDescOperador(operador){
    if(!operador){
      return '';
    }
    return this.tipos.filter((item: any) => item.operador == operador)[0].descricao;
  }

}

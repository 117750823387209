import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TipoAtividadesComponent } from './tipo-atividades.component';
import { TipoAtividadeFormComponent } from './tipo-atividade-form/tipo-atividade-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlMessagesModule } from '../../shared/control-messages/control-messages.module';
import { NgSelectModule } from '@ng-select/ng-select';


import { TipoAtividadesRoutingModule } from './tipo-atividades-routing.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    TipoAtividadesRoutingModule,
    ReactiveFormsModule,
    ControlMessagesModule,
    NgSelectModule,
    NgbModule,
    FormsModule,
    CollapseModule.forRoot(),
    TooltipModule.forRoot()
  ],
  declarations: [TipoAtividadesComponent, TipoAtividadeFormComponent]
})
export class TipoAtividadesModule { }

<div class="animated fadeIn">

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-search"></i>
                    Pesquisa de <strong>Produtos</strong>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="name">Cód. CRM:</label>
                                <input class="form-control" type="text" [(ngModel)]="filter.cod_crm" name="cod_crm">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="name">Cód. Externo:</label>
                                <input class="form-control" type="text" [(ngModel)]="filter.cod_externo" name="cod_externo">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="name">Cód. SAS:</label>
                                <input class="form-control" type="text" [(ngModel)]="filter.cod_sas" name="cod_sas">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name">Nome do Produto:</label>
                                <input class="form-control" type="text" [(ngModel)]="filter.nome" name="nome">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Ativo:</label>
                                <ng-select [items]="listStatus" bindLabel="name" bindValue="value"
                                    placeholder="Selecione um status" name="ativo" [(ngModel)]="filter.ativo">
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Registros por página:</label>

                                <ng-select [items]="listItemsPerPage" placeholder="Selecione..."
                                    [(ngModel)]="filter.limit" name="limit">
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="workflows">WorkFlow: </label>

                                <ng-select [items]="workflows" bindLabel="nome" bindValue="id"
                                           placeholder="Selecione um WorkFlow" [(ngModel)]="filter.workflow_id"
                                           name="workflows">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group pull-left">
                            <br>
                            <button type="button" class="btn btn-primary" (click)="pesquisar()">
                                <i class="fa fa-search"></i>
                                Pesquisar
                            </button>
                            &nbsp;&nbsp;
                            <a class="btn btn-success" href="#" [routerLink]="['/produtos/form/0']">
                                <i class="fa fa-plus"></i> Cadastrar Produto</a>
                            &nbsp;&nbsp;
                            <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                                <i class="fa fa-ban"></i>
                                Limpar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-align-justify"></i>
                    Resultados ({{totalItems ? totalItems : 0}})
                </div>
                <div class="card-body">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th width="12%">Códigos</th>
                                <th width="48%">Nome do Produto</th>
                                <th width="35%" class="text-left">&nbsp;</th>
                                <th width="10%" class="text-left">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of produtos; let i = index">
                                <td>
                                    <div class="small text-muted">
                                        <span><strong>Integra:&nbsp;</strong></span>{{p.id}}
                                    </div>
                                    <div class="small text-muted">
                                        <span><strong>CRM:&nbsp;</strong></span>{{p.cod_crm}}
                                    </div>
                                    <div class="small text-muted">
                                        <span><strong>Externo:&nbsp;</strong></span>{{p.cod_externo}}
                                    </div>
                                    <div class="small text-muted">
                                        <span><strong>SAS:&nbsp;</strong></span>{{p.cod_sas}}
                                    </div>
                                </td>
                                <td>{{p.nome}}</td>
                                <td>
                                    <div class="col-sm-12" >
                                        <div *ngIf="p.workflow_id != null"><strong>{{p?.workflow?.nome}}</strong></div>

                                        <div>
                                            Valor: {{p.valor | formatMoney }}
                                        </div>
                                        <div>Ativo: <span
                                            [ngClass]="p.ativo ? 'badge badge-success' : 'badge badge-danger'">{{p.ativo
                                            ? 'Sim' : 'Não'}}</span>
                                        </div>
                                    </div>

                                </td>
                                <td class="td-actions text-left">

                                    <div class="btn-group" dropdown placement="bottom left">
                                        <button dropdownToggle type="button" class="btn btn-light dropdown-toggle">
                                            Ações
                                            <span class="caret"></span>
                                        </button>
                                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                                            <li role="menuitem">
                                                <button type="button" class="dropdown-item"
                                                    (click)="editarRegistro(p.id)" tooltip="Editar">
                                                    <i class="fa fa-pencil fa-lg mt-1 "></i> Editar
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button type="button" class="dropdown-item" (click)="deletar(p)"
                                                    tooltip="Excluir">
                                                    <i class="fa fa-times fa-lg mt-1 "></i> Excluir
                                                </button>
                                            </li>
                                        </ul>
                                    </div>

                                </td>
                            </tr>
                            <tr *ngIf="produtos && produtos.length == 0">
                                <td colspan="6">Não existe produtos com estes parâmetros</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="col-md-12 justify-content-center">
                        <pagination [boundaryLinks]="true" [itemsPerPage]="filter.limit" [totalItems]="totalItems"
                            class="text-center" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
                            [maxSize]="maxSize" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
                            lastText="&raquo;"></pagination>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<ng-template confirm>
    <confirm-modal-component></confirm-modal-component>
</ng-template>

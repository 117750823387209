<div class="animated fadeIn">

  <div class="row">
      <div class="col-lg-12">
          <div class="card">
              <div class="card-header">
                  <i class="fa fa-search"></i>
                  Pesquisa de <strong>Dores</strong>
              </div>
              <div class="card-body">
                  <div class="row">
                      <div class="col-md-3">
                          <div class="form-group">
                              <label for="name">Cód. Externo:</label>
                              <input class="form-control" type="text" [(ngModel)]="filter.cod_externo" name="nome">
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="form-group">
                              <label for="name">Nome da dor:</label>
                              <input class="form-control" type="text" [(ngModel)]="filter.nome" name="nome">
                          </div>
                      </div>
                      <div class="col-md-2">
                          <div class="form-group">
                              <label>Ativo:</label>
                              <ng-select [items]="listStatus" bindLabel="name" bindValue="value"
                                  placeholder="Selecione um status" name="ativo" [(ngModel)]="filter.ativo">
                              </ng-select>
                          </div>
                      </div>
                      <div class="col-md-2">
                          <div class="form-group">
                              <label>Registros por página:</label>

                              <ng-select [items]="listItemsPerPage" placeholder="Selecione..."
                                  [(ngModel)]="filter.limit" name="limit">
                              </ng-select>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-8">
                      <div class="form-group pull-left">
                          <br>
                          <button type="button" class="btn btn-primary" (click)="pesquisar()">
                              <i class="fa fa-search"></i>
                              Pesquisar
                          </button>
                          &nbsp;&nbsp;
                          <a class="btn btn-success" href="#" [routerLink]="['/dores/form/0']">
                              <i class="fa fa-plus"></i> Cadastrar Dor</a>
                          &nbsp;&nbsp;
                          <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                              <i class="fa fa-ban"></i>
                              Limpar
                          </button>
                          &nbsp;&nbsp;
                          <button type="button" class="btn btn-warning" (click)="sincronizarDores()" *ngIf="uf == 'AL'">
                            <i class="fa fa-refresh"></i> Sincronizar Sol AL
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>


  <div class="row">
      <div class="col-lg-12">
          <div class="card">
              <div class="card-header">
                  <i class="fa fa-align-justify"></i>
                  Resultados ({{totalItems ? totalItems : 0}})
              </div>
              <div class="card-body">
                  <table class="table table-striped">
                      <thead>
                          <tr>
                              <th width="10%" class="text-center">Cód. Integra</th>
                              <th width="10%" class="text-center">Cód. Externo</th>
                              <th width="60%">Nome da Dor</th>
                              <th width="10%" class="text-center">Ativo</th>
                              <th width="10%" class="text-left">&nbsp;</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let d of dores; let i = index">
                              <td class="text-center">{{d.id}}</td>
                              <td class="text-center">{{d.cod_externo}}</td>
                              <td>{{d.nome}}</td>
                              <td class="text-center"><span
                                      [ngClass]="d.ativo == 1 ? 'badge badge-success' : 'badge badge-danger'">{{d.ativo
                                      == 1 ? 'Sim' : 'Não'}}</span>
                              </td>
                              <td class="td-actions text-left">

                                  <div class="btn-group" dropdown placement="bottom left">
                                      <button dropdownToggle type="button" class="btn btn-light dropdown-toggle">
                                          Ações
                                          <span class="caret"></span>
                                      </button>
                                      <ul *dropdownMenu class="dropdown-menu" role="menu">
                                          <li role="menuitem">
                                              <button type="button" class="dropdown-item"
                                                  (click)="editarRegistro(d.id)" tooltip="Editar">
                                                  <i class="fa fa-pencil fa-lg mt-1 "></i> Editar
                                              </button>
                                          </li>
                                          <li role="menuitem">
                                              <button type="button" class="dropdown-item" (click)="deletar(d)"
                                                  tooltip="Excluir">
                                                  <i class="fa fa-times fa-lg mt-1 "></i> Excluir
                                              </button>
                                          </li>
                                      </ul>
                                  </div>

                              </td>
                          </tr>
                          <tr *ngIf="dores && dores.length == 0">
                              <td colspan="5">Não existe dores com estes parâmetros</td>
                          </tr>
                      </tbody>
                  </table>

                  <div class="col-md-12 justify-content-center">
                      <pagination [boundaryLinks]="true" [itemsPerPage]="filter.limit" [totalItems]="totalItems"
                          class="text-center" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
                          [maxSize]="maxSize" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
                          lastText="&raquo;"></pagination>
                  </div>

              </div>
          </div>
      </div>
  </div>
</div>

<ng-template confirm>
   <confirm-modal-component></confirm-modal-component>
</ng-template>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarteirasRoutingModule } from './carteiras-routing.module';
import { CarteirasComponent } from './carteiras.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    CarteirasRoutingModule,
    NgbNavModule
  ],
  declarations: [CarteirasComponent]
})
export class CarteirasModule { }

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExportarLeadsService {

  constructor() { }

  getColunasExport(){
    return [
      { id: 0, name: 'Mostrar Dados Imputados', value: 'mostrar_dados_imputados', checked: false },
      { id: 10, name: 'ID Integra', value: 'id', checked: true },
      { id: 20, name: 'ID Origem', value: 'registro_id', checked: true },
      { id: 30, name: 'Integrador', value: 'integrador-nome', checked: true },
      { id: 40, name: 'Tipo de Campanha', value: 'campanha_tipo-nome', checked: true },
      { id: 50, name: 'Tipo de Atividade', value: 'conteudo_tipo-nome', checked: true },
      { id: 60, name: 'Identificador', value: 'conteudo_publicado-identificador', checked: true },
      { id: 70, name: 'Responsável da Campanha', value: 'conteudo_publicado-responsavel_nome', checked: true },
      { id: 80, name: 'E-mail do Responsável', value: 'email_responsavel', checked: true },
      { id: 90, name: 'Tipo de Cliente', value: 'tipo_cliente', checked: true },
      { id: 100, name: 'CPF', value: 'cpf_origem', checked: true },
      { id: 110, name: 'CPF Referenciado', value: 'cpf_encontrado', checked: true },
      { id: 120, name: 'Nome do cliente', value: 'nome_cliente', checked: true },
      { id: 130, name: 'E-mail do cliente', value: 'email_cliente', checked: true },
      { id: 140, name: 'CNPJ', value: 'cnpj_origem', checked: true },
      { id: 150, name: 'CNPJ Referenciado', value: 'cnpj_encontrado', checked: true },
      { id: 160, name: 'Nome da Empresa', value: 'nome_empreendimento', checked: true },
      { id: 165, name: 'Porte', value: 'porte', checked: true },
      { id: 180, name: 'Inscrição Estadual', value: 'inscricao_estadual', checked: false },
      { id: 190, name: 'DAP', value: 'dap', checked: false },
      { id: 200, name: 'Registro Pescador', value: 'registro_pescador', checked: false },
      { id: 210, name: 'NIRF', value: 'nirf', checked: false },
      { id: 220, name: 'Código SICAB', value: 'cod_sicab', checked: false },
      { id: 230, name: 'Código Evento', value: 'codigo_evento', checked: true },
      { id: 240, name: 'Protocolo de Atendimento', value: 'protocolo_atendimento', checked: true },
      { id: 250, name: 'Projeto Atendimento ', value: 'cod_projeto', checked: false },
      { id: 260, name: 'Ação Atendimento', value: 'cod_acao', checked: false },
      { id: 270, name: 'Instrumento de Atendimento', value: 'cod_categoria', checked: false },
      { id: 280, name: 'Protocolo de Inscrição Consultoria', value: 'protocolo_insc_consultoria', checked: false },
      { id: 290, name: 'Status', value: 'status', checked: true },
      { id: 300, name: 'Qtd. Reprocessado', value: 'qtd_reprocessado', checked: true },
      { id: 310, name: 'Data de Entrada', value: 'created', checked: true },
      { id: 320, name: 'Data de Processamento', value: 'modified', checked: true },
      { id: 340, name: 'Mensagem Impeditiva', value: 'msg_impeditiva', checked: true },
      { id: 341, name: 'Workflow', value: 'workflow', checked: false },
      { id: 360, name: 'EmpresaSAS.Cidade', value: 'sas_empresa_cidade', checked: false },
      { id: 370, name: 'EmpresaSAS.Estado', value: 'sas_empresa_estado', checked: false },
      { id: 380, name: 'EmpresaSAS.CNAE', value: 'sas_empresa_cnae', checked: false },
      { id: 390, name: 'EmpresaSAS.Email', value: 'sas_empresa_email', checked: false },
      { id: 400, name: 'EmpresaSAS.Telefone', value: 'sas_empresa_telefone', checked: false },
      { id: 410, name: 'EmpresaSAS.Situacao', value: 'sas_empresa_situacao', checked: false },
      { id: 420, name: 'EmpresaSAS.Segmento', value: 'sas_empresa_segmento', checked: false },
      { id: 440, name: 'ClienteSAS.Telefone', value: 'sas_cliente_telefone', checked: false },
      { id: 450, name: 'ClienteSAS.Cidade', value: 'sas_cliente_cidade', checked: false },
      { id: 460, name: 'ClienteSAS.Email', value: 'sas_cliente_email', checked: false },
      { id: 490, name: 'CarteiraIntegra.Nome', value: 'carteira_nome', checked: false },
      { id: 500, name: 'CarteiraIntegra.ResponsavelNome', value: 'carteira_resp_nome', checked: false },
      { id: 510, name: 'CarteiraIntegra.ResponsavelEmail', value: 'carteira_resp_email', checked: false },
      { id: 520, name: 'CarteiraIntegra.Segmento', value: 'carteira_segmento', checked: false },
      { id: 530, name: 'CarteiraIntegra.Regional', value: 'carteira_regional', checked: false },
      { id: 540, name: 'EmpresaSAS.EnderecoCompleto', value: 'sas_empresa_endereco_completo', checked: false },
      { id: 550, name: 'EmpresaSAS.Celular', value: 'sas_empresa_celular', checked: false },
      { id: 560, name: 'EmpresaSAS.AceiteEmail', value: 'sas_empresa_aceite_email', checked: false },
      { id: 570, name: 'EmpresaSAS.AceiteTelefone', value: 'sas_empresa_aceite_telefone', checked: false },
      { id: 580, name: 'EmpresaSAS.AceiteCelular', value: 'sas_empresa_aceite_celular', checked: false },
      { id: 590, name: 'ClienteSAS.EnderecoCompleto', value: 'sas_cliente_endereco_completo', checked: false },
      { id: 600, name: 'ClienteSAS.Celular', value: 'sas_cliente_celular', checked: false },
      { id: 610, name: 'ClienteSAS.AceiteEmail', value: 'sas_cliente_aceite_email', checked: false },
      { id: 620, name: 'ClienteSAS.AceiteTelefone', value: 'sas_cliente_aceite_telefone', checked: false },
      { id: 630, name: 'ClienteSAS.AceiteCelular', value: 'sas_cliente_aceite_celular', checked: false },
      { id: 640, name: 'EmpresaSAS.AceiteSMS', value: 'sas_empresa_aceite_sms', checked: false },
      { id: 650, name: 'ClienteSAS.AceiteSMS', value: 'sas_cliente_aceite_sms', checked: false },
    ];
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CampoListaService } from '../../../services/campo-lista.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmService } from '../../../shared/confirm-modal/confirm-modal-and-service';
import { CustomServiceValidateService } from '../../../shared/control-messages/custom-service-validate.service';
import { CampoService } from '../../../services/campo.service';

@Component({
  selector: 'app-campos-listas',
  templateUrl: './campos-listas.component.html',
  styleUrls: ['./campos-listas.component.scss']
})
export class CamposListasComponent implements OnInit {

  public form: FormGroup;  
  @Input() camposListas: any;
  public Response = { valor: '', codigo_referente : '' };

  constructor(private fb: FormBuilder    
    , private toastr: ToastrService    
    , private route: ActivatedRoute
    , private confirmService: ConfirmService
    , private campoService: CampoService
    , private campoListaService: CampoListaService) {      
  }

  async ngOnInit() {
    this.initForm();    
  }

  initForm() {
    this.form = this.fb.group({      
      campo_id: [this.route.snapshot.params.id, Validators.required],
      valor: [null, Validators.required],
      codigo_referente: [null, Validators.required],
    });
  }

  getCamposListas(campo_id: number) {
    this.campoService.getId(campo_id).toPromise().then(response => {      
      this.camposListas = response.campos_listas;
    });
  }

  addCampoLista() {
    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
      return false;
    }   

    this.campoListaService.addCampoLista(this.form.value).subscribe(response => {
      this.toastr.success('Item salvo com sucesso.');
      this.initForm();
      this.getCamposListas(this.route.snapshot.params.id);
    }, e => {
      CustomServiceValidateService.customMensageService(e.error, this.form);
    });
  }  

  removerCampoLista(cl) {
    this.confirmService.confirm({
      title: 'Excluir', message: 'Você tem certeza que deseja excluir o item ' + cl.valor + ' da lista deste campo?'
    }).then(
      () => {
        this.campoListaService.removerCampoLista(cl.id).subscribe(response => {
          this.getCamposListas(this.route.snapshot.params.id);
          this.toastr.success('Item da lista removido com sucesso.');
        });
      },
      () => { });
  }

}
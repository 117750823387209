<div class="card">
    <div class="card-header" (click)="limpar = limpar+1" style="cursor: pointer;">
        <i class="fa fa-code-fork"></i>
        <strong>WorkFlow</strong>
    </div>
    <div class="card-body">
        <tabset #staticTabs>
            <tab heading="Listagem">

                <div class="card-body">
                    <form class="form-horizontal">

                        <div class="row">
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input class="form-control" type="text" placeholder="Pesquisar workflow por nome"
                                        [(ngModel)]="filter.nome" name="identificador" (ngModelChange)="pesquisar()">
                                    <div class="input-group-append">
                                        <ng-select [items]="listAtivos" bindLabel="nome" bindValue="value"
                                            placeholder="Ativo/Inativo" [(ngModel)]="filter.ativo" name="ativo"
                                            (change)="pesquisar()"></ng-select>
                                    </div>
                                    <!-- <div class="input-group-append">
                                        <button type="submit" class="btn btn-primary" (click)="pesquisar()">
                                            <i class="fa fa-search"></i>
                                            Pesquisar
                                        </button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </form>
                </div>

                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Descrição</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of classes; let i = index">
                            <td>
                                <div class="small text-muted">
                                    <span><strong>Criado por:&nbsp;</strong></span> {{item?.usuario?.nome}} |
                                    {{item.created | date:'dd/MM/yyyy H:mm'}}
                                </div>
                                <div>
                                    <span class="badge badge-pill"
                                        [ngClass]="item?.ativo ? 'badge-success' : 'badge-danger'"
                                        style="position: static;"
                                        [tooltip]="item?.ativo ? 'Ativo' : 'Inativo'">&nbsp;</span>
                                        <span class="badge badge-pill badge-info" *ngFor="let tag of getTags(item.tags)" style="margin:2px">{{tag}}</span>
                                        <br>
                                    <strong>{{item.nome}}</strong>
                                    <div>{{item.descricao}}</div>
                                </div>
                            </td>
                            <td>
                                <div class="btn-group" dropdown placement="bottom right">
                                    <button dropdownToggle type="button" class="btn btn-sm dropdown-toggle">
                                        Ações <span class="caret"></span>
                                    </button>
                                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                                        <li role="menuitem">
                                            <button type="button" tooltip="Editar" class="dropdown-item"
                                                (click)="editar(item)">
                                                <span class="fa fa-edit"></span> Editar
                                            </button>
                                        </li>
                                        <li role="menuitem">
                                            <button type="button" tooltip="Copiar" class="dropdown-item"
                                                (click)="copiar(item)">
                                                <span class="fa fa-copy"></span> Copiar
                                            </button>
                                        </li>
                                        <li class="divider dropdown-divider"></li>
                                        <li role="menuitem">
                                            <button type="button" tooltip="Inativar" class="dropdown-item"
                                                (click)="remover(item, i)">
                                                <span class="fa fa-power-off"></span> Remover
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </tab>
            <tab heading="Gráfico">
                <div class="card">
                    <div class="card-header text-left" style="cursor: pointer;">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="input-group">
                                    <div class="input-group-append">
                                        <button (click)="novo()" class="btn btn-warning" tooltip="Criar novo Workflow">
                                            <i class="fa fa-plus"></i> Novo</button>
                                    </div>&nbsp;
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" (click)="salvar()" tooltip="Salvar Workflow">
                                            <i class="fa fa-save"></i> Salvar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <input class="form-control" type="text" name="nome" style="margin: 0 15px 0 17px;" required [(ngModel)]="classe.nome" placeholder="Nome do workflow">-->
                        <div class="row">
                            <div class="col-sm-12">
                                <input class="form-control" type="text" name="nome" required [(ngModel)]="classe.nome"
                                    placeholder="Nome do workflow" style="font-weight: bold;">
                            </div>
                            <div class="col-sm-12">
                                <textarea class="form-control" type="text" name="descricao" required
                                    [(ngModel)]="classe.descricao" placeholder="Descrição" style="height: 123px;"></textarea>
                            </div>
                        </div>



                        <!--  <div class="row">
                            <div class="col-sm-11 offset-sm-1">
                                <input class="form-control" type="text" name="nome" style="margin: 0 15px 0 17px;" required [(ngModel)]="classe.nome" placeholder="Nome do workflow">
                            </div>
                            <div class="col-sm-11 offset-sm-1">
                                <textarea class="form-control" type="text" name="descricao" required [(ngModel)]="classe.descricao" placeholder="Descrição"></textarea>
                            </div>
                        </div>-->
                    </div>
                    <div class="card-body text-center">
                        <app-workflow [limpar]="limpar" [editar]="classe"
                            (atualizarObjeto)="atualizarObjeto($event)"></app-workflow>
                    </div>
                </div>
            </tab>
        </tabset>
    </div>
</div>
<ng-template confirm>
    <confirm-modal-component></confirm-modal-component>
</ng-template>
import { JsonPipe } from './../../pipe/json.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelatoriosRoutingModule } from './relatorios-routing.module';
import { RelConversoesRdSasComponent } from './rel-conversoes-rd-sas/rel-conversoes-rd-sas.component';
import { AutocompleteClienteModule } from '../../shared/form-component/autocomplete-cliente/autocomplete-cliente.module';
import { AutocompleteEmpreendimentoModule } from '../../shared/form-component/autocomplete-empreendimento/autocomplete-empreendimento.module';
import { CompleteDigitoCpfModule } from '../../pipe/complete-digito-cpf/complete-digito-cpf.module';
import { CompleteDigitoCnpjModule } from '../../pipe/complete-digito-cnpj/complete-digito-cnpj.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DateFormatModule } from '../../pipe/date-format/date-format.module';
import { NgbTypeaheadModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RelChechagemExternaComponent } from './rel-chechagem-externa/rel-chechagem-externa.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TabCheckConsultasComponent } from './rel-chechagem-externa/tab-check-consultas/tab-check-consultas.component';
//import { PrettyJsonModule } from 'angular2-prettyjson';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabIndicadoresComponent } from './rel-chechagem-externa/tab-indicadores/tab-indicadores.component';
import { TabConfiguracoesComponent } from './rel-chechagem-externa/tab-configuracoes/tab-configuracoes.component';
import {ConfirmModalModule} from '../../shared/confirm-modal/confirm-modal.module';
import {ControlMessagesModule} from "../../shared/control-messages/control-messages.module";
import { RelExploracaoLeadsComponent } from './rel-exploracao-leads/rel-exploracao-leads.component';

@NgModule({
    imports: [
        CommonModule,
        RelatoriosRoutingModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        FormsModule,
        NgSelectModule,
        DateFormatModule,
        NgbTypeaheadModule,
        NgbModule,
        NgxMaskModule.forRoot(),
        AutocompleteClienteModule,
        AutocompleteEmpreendimentoModule,
        CompleteDigitoCpfModule,
        CompleteDigitoCnpjModule,
        PopoverModule,
        PaginationModule,
        TooltipModule,
        TabsModule,
        //   PrettyJsonModule,
        ModalModule.forRoot(),
        ConfirmModalModule,
        ControlMessagesModule,
        ReactiveFormsModule,
    ],
  declarations: [RelConversoesRdSasComponent, RelChechagemExternaComponent, TabCheckConsultasComponent, TabIndicadoresComponent, TabConfiguracoesComponent, JsonPipe, RelExploracaoLeadsComponent], //TabIndicadoresComponent esse cara deu erro na injeção
  exports: [TabCheckConsultasComponent, TabIndicadoresComponent, TabConfiguracoesComponent]
})
export class RelatoriosModule { }

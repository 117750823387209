import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CanalAtendimentoService } from '../../services/canal-atendimento.service';
import { CategoriaService } from '../../services/categoria.service';
import { ConteudoTipoService } from '../../services/conteudo-tipo.service';

@Component({
  selector: 'app-tipo-atividades',
  templateUrl: './tipo-atividades.component.html',
  styleUrls: ['./tipo-atividades.component.scss']
})
export class TipoAtividadesComponent implements OnInit {

  tipoAtividades: any;
  filter: any;
  canaisAtendimento: any = [];
  categoriasInstrumento: any = [];
  listStatus = [{ name: 'Sim', value: 1 }, { name: 'Não', value: 0 }, { name: 'Todos', value: '' }];

  constructor(private conteudoTipoService: ConteudoTipoService    
    , private canalAtendimentoService: CanalAtendimentoService
    , private categoriaService: CategoriaService
    , private router: Router) { }

  async ngOnInit() {
    this.initFiltros();

    this.canalAtendimentoService.listarTodosCanaisAtendimento().then(response => {
      this.canaisAtendimento = response;
    });

    this.categoriaService.listarTodosInstrumentos().then(response => {
      this.categoriasInstrumento = response;
    });

    this.pesquisar();
  }

  initFiltros() {
    this.filter = {
      nome: '',
      ativo: ''      
    };
  }


  async pesquisar() {
    this.filter.CodCid = this.filter.CodCid ? this.filter.CodCid : '';
    this.tipoAtividades = await this.conteudoTipoService.get(this.filter).toPromise();
  }

  editarRegistro(id: number) {
    this.router.navigate(['/tipo-atividades/form', id]);
  }

  limparFiltros() {
    this.initFiltros();
  }
  
  getNomeCanalAtendimento(CodMeioAtendimento){
    let canais = this.canaisAtendimento.filter((item: any) => item.CodMeioAtendimento == CodMeioAtendimento);
    return canais.length > 0 ? canais[0].DescMeioAtendimento : '';
  }

  getNomeInstrumento(CodCategoria){
    let instrumentos = this.categoriasInstrumento.filter((item: any) => item.CodCategoria == CodCategoria);
    return instrumentos.length > 0 ? instrumentos[0].DescCategoria : '';
  }

}

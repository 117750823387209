import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegionaisRoutingModule } from './regionais-routing.module';
import { RegionaisComponent } from './regionais.component';
import { RegionalFormComponent } from './regional-form/regional-form.component';
import { TreeviewModule } from 'ngx-treeview';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlMessagesModule } from '../../shared/control-messages/control-messages.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
 
import { AutocompleteUsuarioModule } from '../../shared/form-component/autocomplete-usuario/autocomplete-usuario.module';
import { ConfirmModalModule } from '../../shared/confirm-modal/confirm-modal.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    CommonModule,
    RegionaisRoutingModule,
    TreeviewModule.forRoot(),    
    ReactiveFormsModule,    
    ControlMessagesModule,                             
    NgSelectModule,        
    NgbModule,    
    FormsModule,    
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    AutocompleteUsuarioModule,
    ConfirmModalModule
  ],
  declarations: [RegionaisComponent, RegionalFormComponent]
})
export class RegionaisModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class SegmentoService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }  

  get(params) {
    return this.http.get<Segmentos>(environment.environment.nomeServiceIntegra + 'Segmentos/getAll.json', { params: params });
  }

  getId(id) {
    return this.http.get<Segmento>(environment.environment.nomeServiceIntegra + `Segmentos/getId/${id}.json`);
  }

  salvar(params) {
    this.sessaoService.delete('ServiceSegmentos');
    return this.http.post(environment.environment.nomeServiceIntegra + 'Segmentos/postSave.json', { Segmentos: params });
  } 
  
  listarTodosSegmentos() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceSegmentos')) {
        resolve(this.sessaoService.get('ServiceSegmentos'));
      } else {        
        const query: any = { limit: 1000 };
        this.http.get<Segmentos>(environment.environment.nomeServiceIntegra + 'Segmentos/getAll.json', { params: query })
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceSegmentos', res.Segmentos);
            resolve(res.Segmentos);
          }, err => {
            reject(err);
          });
      }
    });
  }
    
}

export class Segmento {
  id: number;
  nome: string;
  descricao: string;    
  ativo: number;  
}

export class Segmentos {
  Segmentos: any;
  TotalItems: number;

  constructor() { }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor() { }

  public printDiv(elementId: string) {
    var printContents = document.getElementById(elementId).innerHTML;
    var popup = window.open('', '_blank', 'width=900,height=700,fullscreen=yes');
    popup.document.open();
    popup.document.write('<!DOCTYPE html><html><head>'
      + '<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous"'
      + 'media="screen,print">'
      + '</head><body onload="window.print()"><div class="reward-body">'
      + printContents + '</div></html>');
    popup.document.close();
  }
}

import { Component, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { ValueAccessorBase } from '../value-accessor';
import { ClienteService } from '../../../services/cliente.service';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError } from 'rxjs/operators';

@Component({
    selector: 'autocomplete-cliente',
    templateUrl: './autocomplete-cliente.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: AutocompleteClienteComponent,
        multi: true,
    }],
})
export class AutocompleteClienteComponent extends ValueAccessorBase<DadosEnvio> {
    @ViewChild(NgModel) dadosEnvio: NgModel;

    public searchFailedCli = false;

    constructor(private clienteService: ClienteService) {
        super();
    }

    handleInput(prop, value) {
        if (!this.value) {
            this.value = new DadosEnvio();
        }
        this.value[prop] = value;
        this.value = { ...this.value };
    }

    searchCli = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap(term =>
                term.length < 3 ? of([])
                    :
                    this.clienteService.getClientesPfAutoComplete(term).pipe(
                        tap(() => this.searchFailedCli = false),
                        catchError(() => {
                            this.searchFailedCli = true;
                            return of([]);
                        }))
            )
        );

    //formatterCli = (cliente: { NomeRazaoSocial: string, CgcCpf: string }) => cliente.NomeRazaoSocial;
    formatterCli = (empreendimento: {
        NomeRazaoSocial: string,        
        CgcCpf: string
    }) => (empreendimento.NomeRazaoSocial ? empreendimento.NomeRazaoSocial + ' - ' : '') + (empreendimento.CgcCpf ? empreendimento.CgcCpf : '');

}
export class DadosEnvio {
    cliente: any;

    constructor() { }
}
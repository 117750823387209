import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class CadastroClienteService {

  constructor(private http: HttpClient, private sessaoService: SessaoService) { }

  getEmpresas(params: any) {
    return this.http.get<any>(environment.environment.nomeServiceCadCli + 'empresas.json', { params: params });
  }

  listarPortesAtivo() {
    let params: any = { ativo: 1 };

    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('CadCliPortes')) {
        resolve(this.sessaoService.get('CadCliPortes'));
      } else {
        this.http.get<any>(environment.environment.nomeServiceCadCli + 'portes.json', { params: params })
          .toPromise().then((res: any) => {
            this.sessaoService.set('CadCliPortes', res.portes);
            resolve(res.portes);
          }, err => {
            reject(err);
          });
      }
    });
  }



}

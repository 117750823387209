<form [formGroup]="formValidade" novalidate>

    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <div class="col-form-label">
                    <label class="form-check-label" for="inlineCheckbox1"><strong>Tipo de Critério:</strong>&nbsp;</label>
                    <div class="form-check form-check-inline mr-1" id="inline-radios-criterio">
                        <input class="form-check-input" type="radio" name="inline-radios-criterio" id="inlineRadioVal"
                            formControlName="criterio" value="Validacao">
                        <label class="form-check-label" for="inlineRadioVal">Validação</label>
                    </div>
                    <div class="form-check form-check-inline mr-1">
                        <input class="form-check-input" type="radio" name="inline-radios-criterio" id="inlineRadioDir"
                            formControlName="criterio" value="Direcionamento">
                        <label class="form-check-label" for="inlineRadioDir">Direcionamento</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <div class="form-group">
                <input type="text" class="form-control" name="campo" id="campo" formControlName="campo" required
                    placeholder="Campo">
                <div *ngIf="formValidade.controls['campo'].invalid && (formValidade.controls['campo'].dirty || formValidade.controls['campo'].touched)"
                    class="text-danger">
                    <div *ngIf="formValidade.controls['campo'].errors.required">
                        Informe um campo
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <ng-select [items]="tipos" bindLabel="descricao" bindValue="operador" placeholder="Operador"
                    formControlName="operador" name="operador" required>
                </ng-select>
                <div *ngIf="formValidade.controls['operador'].invalid && (formValidade.controls['operador'].dirty || formValidade.controls['operador'].touched)"
                    class="text-danger">
                    <div *ngIf="formValidade.controls['operador'].errors.required">
                        Informe uma ação
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <input type="text" class="form-control" name="valor" id="valor" formControlName="valor"
                    placeholder="Valor">
                <div *ngIf="formValidade.controls['valor'].invalid && (formValidade.controls['valor'].dirty || formValidade.controls['valor'].touched)"
                    class="text-danger">
                    <div *ngIf="formValidade.controls['valor'].errors.required">
                        Informe um valor
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <div class="col-form-label">
                    <div class="form-check form-check-inline mr-1" id="inline-radios">
                        <input class="form-check-input" type="radio" name="inline-radios" id="inlineRadio1"
                            formControlName="instrucao" value="And">
                        <label class="form-check-label" for="inlineRadio1">And</label>
                    </div>
                    <div class="form-check form-check-inline mr-1">
                        <input class="form-check-input" type="radio" name="inline-radios" id="inlineRadio2"
                            formControlName="instrucao" value="Or">
                        <label class="form-check-label" for="inlineRadio2">Or</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <button class="btn btn-primary btn-sm" (click)="onSubmit()" [disabled]="formValidade.invalid"><i
                        class="fa fa-pencil"></i> Atualizar</button>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <button class="btn btn-success btn-sm" (click)="adicionarAcao()"
                    [disabled]="(regras == null || regras.length==0)"><i class="fa fa-pencil"></i> Finalizar</button>
            </div>
        </div>
    </div>
</form>
<div class="table table-responsive">
    <table class="table" *ngFor="let instrucao of regras; let i = index">
        <thead>
            <tr>
                <th colspan="3">{{instrucao.id}} {{instrucao?.criterio}} {{instrucao?.instrucao}}</th>
                <th><button class="btn btn-primary btn-sm" (click)="deleteRegra(i);"><i
                            class="fa fa-times fa-lg mt-1 "></i>
                    </button></th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let validacao of instrucao.validacoes; let i2 = index">
                <td>{{validacao.regra_id}} {{validacao.campo}}</td>
                <td>{{getDescOperador(validacao.operador)}}</td>
                <td>{{validacao.valor}}</td>
                <td><button class="btn btn-danger btn-sm" (click)="removerValidacao(i2, i)"><i
                            class="fa fa-times fa-lg mt-1 "></i>
                    </button>
                    <button class="btn btn-primary btn-sm" (click)="editForm(validacao, i)"><i class="fa fa-pencil"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
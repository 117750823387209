  <div class="d-flex">
    <div class="container">
        <div class="row">
          <div class="col-3">
             <!-- <h2>{{active}} - {{active2}}</h2>  -->
            <h5>Ações</h5>
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical" style="min-width: 200px; max-height: 450px; overflow: auto;">
                <li><input [(ngModel)]="buscar.plataforma" class="form-control" placeholder="Buscar..." /></li>
                <li ngbNavItem="{{item.id}}" *ngFor="let item of plataformas | filter:buscar.plataforma:'nome'">
                  <a ngbNavLink (click)="active2=null">{{item.nome | formatarFrase}}</a>
                  <ng-template ngbNavContent>
                      <div class="list-group list-group-flush">
                        <div  class="list-group-item list-group-item-action"><input [(ngModel)]="buscar.acao" class="form-control" placeholder="Buscar..." /></div>
                          <button type="button" class="list-group-item list-group-item-action" *ngFor="let acao of acoes | filter : active : 'plataforma_id':true | filter:buscar.acao:'nome'" (click) = "checkAcao(acao)">{{acao.nome | formatarFrase}}</button>
                        </div>
                  </ng-template>
                </li>
              </ul>
          </div>
          <div class="col-6" >
            <ul ngbNav #nav2="ngbNav" [(activeId)]="active2" class="nav nav-pills nav-fill" >
              <li ngbNavItem="customizados" class="nav-item nav-link">
                <a ngbNavLink (click)="active=null"> Campos de entrada</a>
                <ng-template ngbNavContent>
                  <app-campos-customizados (addAcao)="addCustom($event)" [edit] = "acaoEdit['3']"></app-campos-customizados>
                </ng-template>
              </li>
              <li ngbNavItem="validacao" class="nav-item nav-link">
                <a ngbNavLink (click)="active=null">Critérios de Aceite</a>
                <ng-template ngbNavContent>
                  <app-validacao (addAcao)="addValidacao($event)" [edit] = "acaoEdit['4']"></app-validacao>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav2" [hidden]="active2==null" style="height: 450px; overflow: auto;" ></div>
            <div [ngbNavOutlet]="nav"  [hidden]="active==null" style="height: 450px; overflow: auto;"></div>
          </div>
          <div class="col-3" *ngIf="mostrarVariavies">
            <h5>Variáveis</h5>
            <app-variaveis (addAcao)="addVariaveis($event)" [variaveis]="acao?.entradas_json" [edit] = "acaoEdit['1']"></app-variaveis>
          </div>
          <div class="col-3" *ngIf="!mostrarVariavies">
            <h5>Workflows</h5>
            <div class="list-group list-group-flush" style="max-height: 450px; overflow: auto;">
                <div  class="list-group-item list-group-item-action"><input [(ngModel)]="buscar.workflow" class="form-control" placeholder="Buscar..." /></div>
                <button type="button" class="list-group-item list-group-item-action" *ngFor="let w of workflows | filter:buscar.workflow:'nome'" (click) = "adicionarAcao(w.codigo_interno, 2)">{{w.nome | formatarFrase}}</button>
              </div>
          </div>
        </div>
      </div>



  </div>



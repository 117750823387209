import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RelConversoesRdSasComponent } from './rel-conversoes-rd-sas/rel-conversoes-rd-sas.component';
import { RelChechagemExternaComponent } from './rel-chechagem-externa/rel-chechagem-externa.component';
import { RelExploracaoLeadsComponent } from './rel-exploracao-leads/rel-exploracao-leads.component';


const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Relatórios'
    },
    children: [
    {
        path: 'exploracao-leads',
        component: RelExploracaoLeadsComponent,
        data: {
            title: 'Exploração de Dados de Leads'
        }
      },
      {
        path: 'conversoes-rd-sas',
        component: RelConversoesRdSasComponent,
        data: {
          title: 'Lista de clientes concluintes encaminhados para conversão'
        }
      },
      {
        path: 'checagem-externa',
        component: RelChechagemExternaComponent,
        data: {
          title: 'Lista de pesquisas de checagem de cadastro de clientes'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RelatoriosRoutingModule { }

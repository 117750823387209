<button class="btn btn-success btn-sm btn-block" (click)="adicionarAcao()" [disabled]="(campos == null || campos.length==0)"><i
        class="fa fa-pencil"></i> Finalizar</button>

<div *ngFor="let campo of campos; let i = index">
    <div>
        <label type="button" class="label text-primary" triggers="mouseenter:mouseleave" ngbPopover="{{campo.info}}"
            popoverTitle="">{{campo.campo}}</label>
    </div>
    <div>
        <input type="text" class="form-control" name="campo" id="campo" [(ngModel)]="campo.valor" required
            placeholder="Campo">
    </div>
</div>
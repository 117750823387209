import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TipoCampanhasComponent } from './tipo-campanhas.component';
import { TipoCampanhaFormComponent } from './tipo-campanha-form/tipo-campanha-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlMessagesModule } from '../../shared/control-messages/control-messages.module';
import { NgSelectModule } from '@ng-select/ng-select';


import { TipoCampanhasRoutingModule } from './tipo-campanhas-routing.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    TipoCampanhasRoutingModule,
    ReactiveFormsModule,
    ControlMessagesModule,
    NgSelectModule,
    NgbModule,
    FormsModule,
    CollapseModule.forRoot(),
    TooltipModule.forRoot()
  ],
  declarations: [TipoCampanhasComponent, TipoCampanhaFormComponent]
})
export class TipoCampanhasModule { }

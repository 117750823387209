import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipoUsuarioService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get(environment.environment.nomeServiceApi + 'tipoUsuarios/getAll.json');
  }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { GrupoService } from '../../services/grupo.service';
import {ProjetoService} from "../../services/projeto.service";

@Component({
  selector: 'app-grupos-permissoes',
  templateUrl: './grupos-permissoes.component.html',
  styleUrls: ['./grupos-permissoes.component.scss']
})
export class GruposPermissoesComponent implements OnInit {

  grupos: any;
  filter: any;
  isCollapsed: boolean = false;
  iconCollapse: string = "icon-arrow-up";
  searchFailedProdutoSas = false;
  searchFailedProdutoRm = false;
    public projetoId: '';

  listStatus = [{ name: 'Sim', value: 1 }, { name: 'Não', value: 0 }, { name: 'Todos', value: '' }]

  totalItems: number = 0;
  currentPage: number = 1;
  maxSize: number;

  constructor(private grupoService: GrupoService
    , private router: Router
      , private projetoService: ProjetoService

  ) { }

  async ngOnInit() {
    await this.projetoService.getPorNome(environment.environment.projeto).then(data => {
        this.projetoId = data['id'];
    });
    this.initFiltros();
    this.carregar();
  }

  initFiltros() {
    this.filter = {
      nome: '',
      ativo : 1,
      projeto_id : this.projetoId,
      listar_apenas_grupos : 1,
      page: 1,
      limit: 30
    }
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.iconCollapse = this.isCollapsed ? "icon-arrow-down" : "icon-arrow-up";
  }

  pageChanged(event: any): void {
    this.filter.page = event.page;
    this.filter.limit = event.itemsPerPage;
    this.carregar();
  }

  async carregar() {
    this.filter.PortfolioIdSas = this.filter.produtoSas && this.filter.produtoSas.ID ? this.filter.produtoSas.ID : '';
    this.filter.ativo = this.filter.ativo ? this.filter.ativo : '';

    let response = await this.grupoService.getPaginate(this.filter).toPromise();
    this.grupos = response.Grupos;
    this.totalItems = response.TotalItems;

    let maxSize = Math.ceil(this.totalItems / this.filter.limit);
    this.maxSize = maxSize <= 15 ? maxSize : 15;
  }

  pesquisar() {
    this.filter.page = 1;
    this.currentPage = 1;
    this.carregar();
  }

  limparFiltros() {
    this.initFiltros();
    this.carregar();
  }

  editarRegistro(id: number) {
    this.router.navigate(['/grupos-permissoes/alterar', id]);
  }

}

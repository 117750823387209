import { HttpClient } from '@angular/common/http';
import { SessaoService } from './sessao.service';
import { PlataformaService } from './plataforma.service';
import { PlataformaEventoSaidaService } from './plataforma-evento-saida.service';
import { Injectable } from '@angular/core';
import { cloneDeep } from "lodash";
import { environment } from '../../environments/environment';
import { SlicePipe } from '@angular/common';
import { TipoAcaoEnum } from '../enum/TipoAcaoEnum';

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {

    private workflows;
    private validacoes = {};
    private campos = {};
    private variaveis = {};

    constructor(private http: HttpClient, private plataformaEventoSaidaService: PlataformaEventoSaidaService, private plataformaService: PlataformaService, private sessaoService: SessaoService) {
         this.getWorkflows({limit: 1000}).then(w => this.workflows = w);
    }

    getWorkflows(params: any = {}) {
        const filter = params;
        if (filter.ativo == null) {
            filter.ativo = true;
        }
        if (filter.nome == null) {
            filter.nome = "";
        }
        params = { limit: 1000 };
        return new Promise<Array<any>>((resolve, reject) => {
            if (this.sessaoService.get('getWorkflows')) {
                if (filter.nome == null || filter.nome == "") {
                    resolve(this.sessaoService.get('getWorkflows').filter(f => f.ativo == filter.ativo));
                } else {
                    let retorno = this.sessaoService.get('getWorkflows')
                    resolve(retorno.filter(f => f.nome.toUpperCase().indexOf(filter.nome.toUpperCase()) >= 0 && f.ativo == filter.ativo));
                }

            } else {
                this.http.get(environment.environment.nomeServiceIntegra + 'workflows/getAll.json', { params: params })
                    .toPromise().then((res: any) => {
                        let retorno = [];
                        res.forEach(element => {
                            if ((typeof element.chart_content) != "string") {
                                element.chart_content = JSON.stringify(element.chart_content.filter(f => f.indexOf('=>') < 0 || f.indexOf('cond-') >= 0));
                            }
                            retorno.push(element);
                        });

                        this.sessaoService.set('getWorkflows', retorno);
                        resolve(res.filter(f => f.nome.toUpperCase().indexOf(filter.nome.toUpperCase()) >= 0 && f.ativo == filter.ativo));
                    }, err => {
                        reject(err);
                    });
            }
        });

    }

    quebrarTituloAcao(str) {
        let _str = str.split(", ");
        return _str.join(", \n");

    }
    montarSequencia(sequenciaAcoes): Array<String> {
        const retorno = sequenciaAcoes.map((c, i) => {
            if (i == 0) {
                return 'st->' + c;
            } else {
                if (this.removerNumeros(sequenciaAcoes[i - 1]) == 'cond') {
                    return undefined;
                }
                if (this.removerNumeros(c) == 'cond()') {
                    return undefined;
                }
                return sequenciaAcoes[i - 1] + '->' + c;
            }
        });
        if (retorno.length == 0) {
            return ['st->e'];
        }
        retorno[retorno.length - 1] = retorno[retorno.length - 1];
        return retorno;
    }

    saveWorkflow(params) {
        this.sessaoService.delete('getWorkflows');
        this.sessaoService.delete('gePlataformas');
        this.sessaoService.delete('getAcoesWorkflow');
        return this.http.post(environment.environment.nomeServiceIntegra + 'workflows/postSave.json', params);
    }
    async getPlataformas() {

        if (!this.sessaoService.get('gePlataformas')) {
            let plataformas: any;
            let acoes: any;

            acoes = await this.plataformaEventoSaidaService.listarTodosEventoSaidas();
            plataformas = await this.plataformaService.get().toPromise();
            plataformas = plataformas.filter(f => acoes.map(m => m.plataforma_id).some(e => e == f.id));
            plataformas.forEach(element => {
                element['acoes'] = acoes.filter(a => a.plataforma_id == element.id);
            });
            this.sessaoService.set('gePlataformas', plataformas);
        }
        return new Promise<Array<any>>((resolve, reject) => {
            setTimeout(() => {
                resolve(this.sessaoService.get('gePlataformas')); // pass values

            }, 1);
        });
    }
    async getAcoes() {

        if (!this.sessaoService.get('getAcoesWorkflow')) {
            let acoes: any;

            acoes = await this.plataformaEventoSaidaService.listarTodosEventoSaidas();

            this.sessaoService.set('getAcoesWorkflow', acoes);
        }
        return new Promise<Array<any>>((resolve, reject) => {
            setTimeout(() => {
                resolve(this.sessaoService.get('getAcoesWorkflow')); // pass values

            }, 10);
        });
    }
    removerNumeros(str) {
        if (str == null) {
            return str;
        }
        //TODO: achar uma expressão regular pra fazer isso
        return str.replaceAll('-1', '').replaceAll('-2', '').replaceAll('-3', '').replaceAll('-4', '').replaceAll('-5', '').replaceAll('-6', '').replaceAll('-7', '').replaceAll('-8', '').replaceAll('-9', '').replaceAll('-0', '').replaceAll('yes', '').replaceAll('no', '');

    }



    private async findClasseAcoes(chave) {
        let classeAcoes: any;
        //TODO: verificar pq ta sempre null o this.worflow
        const workflow: any = this.workflows;
        if (workflow == null) {
            return null;
        }
        const retorno = { ordem: 0, codigo_interno: chave, tipo_condicao_id: null, irParaOrdem: null, condicao_indice: null, completou: { true: null, false: null }};

        classeAcoes = workflow.find(w => w.codigo_interno == chave);
        if (classeAcoes) {
            retorno.tipo_condicao_id = TipoAcaoEnum.WORKFLOW;
            return retorno ;
        }
        const acoes = await this.getAcoes();
        classeAcoes = acoes.find(w => w.codigo_interno == chave);
        if (classeAcoes) {
            retorno.tipo_condicao_id = TipoAcaoEnum.ACAO;
            if(this.variaveis != null && this.variaveis[retorno.codigo_interno]){
                let variavel = this.variaveis[retorno.codigo_interno];
                retorno['variaveis'] = variavel;
            }
            return retorno ;
        }
        if(chave.indexOf('validacao-') >= 0 ){
            let validacao = this.validacoes[chave];
            retorno.tipo_condicao_id = TipoAcaoEnum.VALIDACAO;
            retorno['validacoes'] = validacao;
            return retorno ;
        }
        if(chave.indexOf('custom-') >= 0 ){
            let campo = this.campos[chave];
            retorno.tipo_condicao_id = TipoAcaoEnum.CUSTOMIZADO;
            retorno['campos'] = campo;
            return retorno ;
        }

        return null;
    }

    setValidacoes(validacoes){
        this.validacoes = validacoes;
    }
    setCampos(campos){
        this.campos = campos;
    }
    setVariaveis(variaveis){
        this.variaveis = variaveis;
    }
    async convertToJson(_objeto) {

        if (_objeto == null) {
            return "";
        }
        let objeto = _objeto.filter(f => f != null);
        let acoes = [];

        if (_objeto.length == 1) {
            const posicaoAtual = objeto[0].split('->');
            let acao_atual = posicaoAtual[1];
            if(acao_atual.indexOf('custom-') >= 0) {
                acoes.push({ ordem: 0, codigo_interno: acao_atual, tipo_condicao_id: TipoAcaoEnum.CUSTOMIZADO, irParaOrdem: null, condicao_indice: null, campos: [], completou: { true: null, false: null }  });
            } else  if(acao_atual.indexOf('validacao-') >= 0) {
                acoes.push({ ordem: 0, codigo_interno: acao_atual, tipo_condicao_id: TipoAcaoEnum.VALIDACAO, irParaOrdem: null, condicao_indice: null, validacoes: [], completou: { true: null, false: null }  });
            }else{
                let atual = await this.findClasseAcoes(acao_atual);
                acoes.push(atual);
            }
        }

        for (let i = 1; i < objeto.length; i++) {
            const posicaoAtual = objeto[i].split('->');
            let acao_atual = posicaoAtual[0];
            let proxima_acao = posicaoAtual[1];
            let classeProxima;
            let classe;

            if (acao_atual.indexOf('(no)') >= 0) {
                let acao = acoes.find(f => (f.condicao_indice == acao_atual.replace("(no)", "")));
                let atual = await this.findClasseAcoes(proxima_acao);
                if(acao != null){
                    acao["completou"]["false"] =atual;
                }
                console.log("ação não ", acao_atual);
            } else if (acao_atual.indexOf('(yes)') >= 0) {
                let acao = acoes.find(f => (f.condicao_indice == acao_atual.replace("(yes)", "")));
                let atual = await this.findClasseAcoes(proxima_acao);
                if(acao != null){
                    acao["completou"]["true"] = atual;
                }
                console.log("ação yes ", acao_atual);
            } 
             else {

                if (!acoes.some(f => (f.codigo_interno == acao_atual))) {
                    classe = await this.findClasseAcoes(acao_atual);
                  //  let atual = await this.findClasseAcoes(acao_atual);
                  //  classe = { ordem: 0, codigo_interno: atual ? atual?.codigo_interno : '', tipo_condicao_id: atual ? atual?.tipo_condicao_id : '', irParaOrdem: null, condicao_indice: null, completou: { true: null, false: null } };
                }
                //verifico se a proxima é condição
                if (proxima_acao.indexOf('cond-') >= 0) {
                    //se ja existir então eu só adiciono a condição
                    if (classe == null) {
                        let acao = acoes.find(f => (f.codigo_interno == acao_atual));
                        acao.condicao_indice = proxima_acao;
                    } else {
                        classe.condicao_indice = proxima_acao;
                        //acoes.push(classe);
                    }
                } else {
                    if (!acoes.some(f => (f.codigo_interno == proxima_acao))) {
                        classeProxima = await this.findClasseAcoes(proxima_acao);
                       // let proximo = await this.findClasseAcoes(proxima_acao);
                       // classeProxima = { ordem: 0, codigo_interno: proximo?.codigo_interno, tipo_condicao_id: proximo?.tipo_condicao_id, irParaOrdem: null, condicao_indice: null, completou: { true: null, false: null } };
                    }

                }
                console.log("não é condição ", acao_atual);
                if (classe) {
                    acoes.push(classe);
                }

                if (classeProxima) {
                    acoes.push(classeProxima);
                }
            }

        }
        acoes = acoes.filter(f => f != null); //REmover null
        acoes.forEach((f, i) => { f.ordem = i + 1 });
        acoes.filter(f => f != null).forEach((f, i) => {
            if (f.completou == null || (f.completou.true == null && f.completou.false == null)) {
                delete acoes[i].completou;
            }
        });
        //tratar o irParaOrdem dos nós que não tem condição
        for (let i = 1; i < objeto.length; i++) {
            const posicaoAtual = objeto[i].split('->');
            //não pode ter condição
            if (this.removerNumeros(posicaoAtual[1]).indexOf('cond') < 0 && this.removerNumeros(posicaoAtual[0]).indexOf('cond') < 0) {
                const antes = await acoes.find(a => a.codigo_interno == posicaoAtual[0]);
                const depois = await acoes.find(a => a.codigo_interno == posicaoAtual[1]);
                if (antes != null && depois != null) {
                    antes.irParaOrdem = depois.ordem;
                }
            }
        }

        acoes.forEach(a => {
            if (a?.completou) {
                let completouSim = acoes.find(f => (f.codigo_interno == a.completou?.true?.codigo_interno));
                let completouNao = acoes.find(f => {
                    return (f.codigo_interno == a.completou?.false?.codigo_interno);
                });
                if (completouSim) {
                    a.completou.true.irParaOrdem = completouSim.ordem;
                    a.completou.true.repetir = 0
                }
                if (completouNao) {
                    a.completou.false.irParaOrdem = completouNao.ordem;
                    a.completou.false.repetir = 0
                }
            }
        });
        return JSON.stringify(acoes);;

    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProdutosRoutingModule } from './produtos-routing.module';
import { ProdutosComponent } from './produtos.component';
import { ProdutoFormComponent } from './produto-form/produto-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlMessagesModule } from '../../shared/control-messages/control-messages.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ConfirmModalModule } from '../../shared/confirm-modal/confirm-modal.module';
import { NgxCurrencyModule } from 'ngx-currency';
import { FormatMoneyModule } from '../../pipe/format-money/format-money.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {WorkflowModule} from "../../shared/workflow/workflow.module";
import { AlertModule } from 'ngx-bootstrap/alert';
@NgModule({
  declarations: [ProdutosComponent, ProdutoFormComponent],
  imports: [
    CommonModule,
    ProdutosRoutingModule,
    ReactiveFormsModule,
    ControlMessagesModule,
    NgSelectModule,
    NgbModule,
    FormsModule,
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    ConfirmModalModule,
    NgxCurrencyModule,
      WorkflowModule,
      AlertModule,
    FormatMoneyModule
  ]
})
export class ProdutosModule { }

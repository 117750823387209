import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';

import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ConfigInitService {



  constructor() { }

  public getConfig(): Observable<any> {
    return from(
      fetch('/assets/app-config.json').then(async response => {
        let env = await response.json();

        // HACK: Don't log to console in production environment.
        // TODO: This can be done in better way using logger service and logger factory.
        if (window  &&  env['production'] == true) {
          window.console.log = window.console.warn = window.console.info = function () {
            // Don't log anything.
          };
        }
        return env;
      })
    ).pipe(
      map((config) => {
        return config;
      }))
  }

}
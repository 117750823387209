import { Component, OnInit } from '@angular/core';
import { AtendimentoConfiguracaoService } from '../../../services/atendimento-configuracao.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CustomServiceValidateService } from '../../../shared/control-messages/custom-service-validate.service';
import { HistoricoRealizacaoService } from '../../../services/historico-realizacao.service';
import { ProjetoAcaoService } from '../../../services/projeto-acao.service';
import { CategoriaService } from '../../../services/categoria.service';

@Component({
  selector: 'app-atendimento-configuracoes-form',
  templateUrl: './atendimento-configuracoes-form.component.html',
  styleUrls: ['./atendimento-configuracoes-form.component.scss']
})
export class AtendimentoConfiguracoesFormComponent implements OnInit {
  public form: FormGroup;
  public tiposRealizacoes: any = [];
  public instrumentos: any = [];
  public projetos: any = [];
  public acoes: any = [];
  public categoriasInstrumento: any = [];
  public abordagens: any = [
    { value: 'I', descricao: 'Individual' },
    { value: 'G', descricao: 'Grupal' },
    { value: 'U', descricao: 'Universal' }
  ];

  constructor(private router: Router
    , private route: ActivatedRoute
    , private fb: FormBuilder
    , private toastr: ToastrService
    , private atendimentoConfiguracaoService: AtendimentoConfiguracaoService
    , private historicoRealizacaoService: HistoricoRealizacaoService
    , private projetoAcaoService: ProjetoAcaoService
    , private CategoriaService: CategoriaService
  ) { }

  async ngOnInit() {
    this.initForm();
    this.tiposRealizacoes = await this.historicoRealizacaoService.getTiposRealizacoes().toPromise();
    // this.instrumentos = await this.historicoRealizacaoService.getInstrumentos().toPromise();
    this.categoriasInstrumento = await this.CategoriaService.getInstrumentos({Ativo:"S"}).toPromise();
    this.projetos = await this.projetoAcaoService.getProjetos().toPromise();
    if (this.route.snapshot.params.id) {
      this.getId(this.route.snapshot.params.id);
    }
  }

  initForm() {
    this.form = this.fb.group({
      id: ['', Validators.required],
      conteudo_tipo_id: ['', Validators.required],
      NomeRealizacao: ['', Validators.required],
      DescRealizacao: ['', Validators.required],
      TipoRealizacao: ['', Validators.required],
      Instrumento: ['', Validators],
      CodCategoria: ['', Validators.required],
      Abordagem: ['', Validators.required],
      CodProjeto: ['', Validators.required],
      CodAcao: ['', Validators.required],
      CpfResponsavel: ['', Validators.required],
      conteudo_tipo: this.fb.group({
        id: [''],
        nome: [''],
      })
    });
  }

  getId(id) {
    this.atendimentoConfiguracaoService.getId(id).toPromise().then(async response => {
      if (response.CodProjeto) {
        this.acoes = await this.projetoAcaoService.getAcoesPorProjeto(response.CodProjeto).toPromise();
      }      
      this.form.patchValue(response);
    });
  }

  async getAcoesPorProjeto() {
    this.acoes = [];
    if(this.form.controls.CodProjeto.value){
      this.acoes = await this.projetoAcaoService.getAcoesPorProjeto(this.form.controls.CodProjeto.value).toPromise();
    }
    this.form.patchValue({ CodAcao: null });
  }

  salvar() {
    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
      return false;
    }
    
    delete this.form.value.conteudo_tipo;
    this.atendimentoConfiguracaoService.salvar(this.form.value).subscribe(response => {
      this.toastr.success('Configuração gravada com sucesso.');
      this.router.navigate(['/atendimento-configuracoes']);
    }, e => {
      CustomServiceValidateService.customMensageService(e.error, this.form);
    });
  }

  get conteudo_tipo(): FormGroup {
    return this.form.get('conteudo_tipo') as FormGroup;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GruposPermissoesRoutingModule } from './grupos-permissoes-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeviewModule } from 'ngx-treeview';
import { ControlMessagesModule } from '../../shared/control-messages/control-messages.module';
 
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectRegistrosPorPaginaModule } from '../../shared/form-component/select-registros-por-pagina/select-registros-por-pagina.module';
import { GruposPermissoesComponent } from './grupos-permissoes.component';
import { GrupoPermissoesFormComponent } from './grupo-permissoes-form/grupo-permissoes-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GruposPermissoesRoutingModule,    
    TreeviewModule.forRoot(),    
    ReactiveFormsModule,    
    ControlMessagesModule,            
    PaginationModule.forRoot(),                
    NgSelectModule,        
    NgbModule,    
    PaginationModule.forRoot(),
    CollapseModule.forRoot(),
    SelectRegistrosPorPaginaModule,    
    TooltipModule.forRoot(),    
  ],
  declarations: [GruposPermissoesComponent, GrupoPermissoesFormComponent]
})
export class GruposPermissoesModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTypeaheadModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteClienteComponent } from './autocomplete-cliente.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbModule
  ],
  exports: [CommonModule, FormsModule, AutocompleteClienteComponent],
  declarations: [AutocompleteClienteComponent]
})
export class AutocompleteClienteModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlataformaConfiguracaoService {

  constructor(private http: HttpClient) { }

  get(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'PlataformaConfiguracoes/getAll.json', { params: params });
  }
  
  getPorNome(nome : string){
    let params : any = {nome: nome};
    return this.http.get(environment.environment.nomeServiceIntegra + 'PlataformaConfiguracoes/getAll.json', { params: params }).pipe(map((res) => res && res[0] ? res[0] : null));
  }

  salvar(params) {    
    return this.http.post(environment.environment.nomeServiceIntegra + 'PlataformaConfiguracoes/postSalvarConfiguracoes.json', params);
  }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTypeaheadModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteEmpreendimentoComponent } from './autocomplete-empreendimento.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbModule
  ],
  exports: [CommonModule, FormsModule, AutocompleteEmpreendimentoComponent],
  declarations: [AutocompleteEmpreendimentoComponent]
})
export class AutocompleteEmpreendimentoModule { }

import { WorkflowService } from './../../services/workflow.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmService } from './../../shared/confirm-modal/confirm-modal-and-service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { cloneDeep } from "lodash";
import { TabsetComponent } from 'ngx-bootstrap/tabs';
@Component({
    selector: 'app-gerar-workflow',
    templateUrl: './gerar-workflow.component.html',
    styleUrls: ['./gerar-workflow.component.css']
})
export class GerarWorkflowComponent implements OnInit {
    @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;

    limpar = 0;
    classes: any;
    classe = {
        chart_content: ["st=>start: Inicio|approved:$myFunction"],
        ativo: true,
        nome: "",
        objeto_json: {},
        descricao: ""
    };
    plataformas: any;
    listAtivos = [{ nome: 'Ativos', value: 1 }, { nome: 'Inativos', value: 0 }, { nome: 'Todos', value: '' }];
    filter: any = {
        ativo: 1,
        nome: '',
    };
    constructor(private confirmService: ConfirmService
        , private toastr: ToastrService, private workflowService: WorkflowService) {

    }
    selectTab(tabId: number) {
        if (this.staticTabs?.tabs[tabId]) {
            this.staticTabs.tabs[tabId].active = true;
        }
    }
    getTags(value) {
        if (!value) {
            return [];
        }

        return value.split(";").map(item => item.trim());
    }

    ngOnInit(): void {
        this.workflowService.getWorkflows(this.filter).then(w => this.classes = w);
    }
    pesquisar() {
        this.workflowService.getWorkflows(this.filter).then(w => this.classes = w);
    }
    copiar(item) {
        this.classe = cloneDeep(item);
        this.classe["id"] = null;
        this.classe.nome = "(CÓPIA) " + this.classe.nome;
        this.selectTab(1);
    }

    editar(item) {
        this.classe = item;
        this.selectTab(1);
    }
    remover(item, indice) {

        this.confirmService.confirm({
            title: 'Inativação', message: 'Essa ação não pode ser <strong>DESFEITA</strong>, portanto, você tem certeza que deseja inativar este workflow de nome ' + item.descricao + ' ?'
        }).then(
            () => {
                if (item['id']) {
                    item.ativo = 0;
                    this.workflowService.saveWorkflow(item).subscribe((s: any) => {
                        this.toastr.info("Inativado com sucesso");
                        this.workflowService.getWorkflows(this.filter).then(w => {
                            this.classes = w;
                        });
                    })
                } else {
                    this.classes.splice(indice, 1);
                    this.toastr.info("Inativado com sucesso");
                }

            },
            () => { });
    }

    salvar() {
        console.log(this.classe);
        let objeto = cloneDeep(this.classe);
        if ((typeof objeto.chart_content) != "string") {
            objeto.chart_content = JSON.stringify(objeto.chart_content.filter(f => f.indexOf('=>') < 0 || f.indexOf('cond-') >= 0 || f.indexOf('validacao-') >= 0 || f.indexOf('custom-') >= 0));
        }
        if (objeto.descricao == "") {
            objeto.descricao = objeto.nome;
        }
        objeto.ativo = 1;
        this.workflowService.saveWorkflow(objeto).subscribe((s: any) => {
            this.classe["id"] = s.id;
            this.toastr.info("Salvo  com sucesso");
            this.workflowService.getWorkflows(this.filter).then(w => {
                this.classes = w;
            });
        })
        // this.classes.push(this.classe);

    }


    atualizarObjeto(item) {
        this.classe.chart_content = item.chart_content;
        // this.treino(item.chart_content);
        this.classe.objeto_json = item.acoes;
        console.log("OBJETO PARA SALVAR");
        console.log(this.classe);

    }
    //     treino(teste){

    //       let sequencia = this.workflowService.montarSequencia(teste.filter(element => element.search("=>") < 0));

    //        this.workflowService.convertToJson(sequencia.filter(f => f != null)).then(t => {
    //             //this.textoJson = t;
    //           console.log(t);
    //        });
    //    }
    novo() {
        this.classe = {
            chart_content: ["st=>start: Inicio|approved:$myFunction"],
            ativo: true,
            nome: "",
            objeto_json: {},
            descricao: ""
        }
        this.limpar++
    }

}

import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { SessaoService } from '../../services/sessao.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { KeycloakService } from 'keycloak-angular';
import {ProjetoService} from "../../services/projeto.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit {

  public formLogin: FormGroup;
  public versao: String = '';
  public uf: String = '';
  public projetoNome: String = '';
  public isLoggedInAmei = false;

  constructor(
      private loginService: LoginService,
      private projetoService: ProjetoService,
      private sessao: SessaoService,
      private router: Router,
      private toastr: ToastrService,
      private fb: FormBuilder,
      private readonly keycloak: KeycloakService
  ) { }

  async ngOnInit() {

    this.versao = environment.environment.versao;
    this.uf = environment.environment.uf;
    this.projetoNome = environment.environment.projeto;
    this.projeto();
    this.init();
    this.isLoggedInAmei = await this.keycloak.isLoggedIn();
    if (this.isLoggedInAmei) {
      let userProfile = await this.keycloak.getKeycloakInstance().loadUserInfo();
      this.formLogin.controls.amei_interno.setValue(true);
      this.formLogin.controls.login.setValue(userProfile["upn"].split("@")[0]);
      this.formLogin.controls.senha.setValue('');

      //console.log("Profile->", userProfile);
      this.login();
    }
  }

  login() {
    if (this.formLogin.invalid) {
      Object.keys(this.formLogin.value).forEach(campo => this.formLogin.controls[campo].markAsTouched());
    } else {
      this.loginService.login(this.formLogin.value).subscribe(response => {
        this.toastr.success('Autenticado com sucesso');
        this.sessao.set('IsLoggedin', 'true');
        this.sessao.set('Login', response);

        //Direciona para a página inicial do sistema
        this.router.navigate([response.pagina_inicial ? response.pagina_inicial : '/rabbitmq']);
      });
    }
  }
  projeto() {
      this.projetoService.getPorNome(environment.environment.projeto).then(data => {
          this.formLogin.controls.projeto_id.setValue(data['id']);
      });

  }
  public loginAmei() {
    this.keycloak.login();
  }

  public logoutAmei() {
    this.keycloak.logout();
  }

  public init() {
    this.formLogin = this.fb.group({
      amei_interno: [false],
      login: ['', Validators.required],
      senha: [''],
      projeto_id: ['', Validators.required]
    },
    {
      validator: ValidarSenhaAmei()
    });
  }

}

export function ValidarSenhaAmei() {
  return (formGroup: FormGroup) => {
    if (formGroup.value.amei_interno == false) {
      if (formGroup.value.senha == "") {
        formGroup.controls.senha.setErrors({ required: true });
      }

    }
  };
}

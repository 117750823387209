import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SegmentoProdutoService {

  constructor(private http: HttpClient) { }

  get(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'SegmentoProdutos/getAll.json', { params: params });
  }

  salvar(params) {
    return this.http.post(environment.environment.nomeServiceIntegra + 'SegmentoProdutos/postSave.json', { SegmentoProdutos: params });
  }

  excluir(id) {
    return this.http.delete(environment.environment.nomeServiceIntegra + `SegmentoProdutos/delete/${id}.json`);
  }

}

export class SegmentoProduto {
  id: number;
  segmento_id: number;
  produto_id: number;
}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ClienteService } from '../../../services/cliente.service';
import { SegmentoAtividadeService } from '../../../services/segmento-atividade.service';
import { ConfirmService } from '../../../shared/confirm-modal/confirm-modal-and-service';
import { CustomServiceValidateService } from '../../../shared/control-messages/custom-service-validate.service';

@Component({
  selector: 'app-segmento-atividade-form',
  templateUrl: './segmento-atividade-form.component.html',
  styleUrls: ['./segmento-atividade-form.component.scss']
})
export class SegmentoAtividadeFormComponent implements OnInit {
  @Input() SegmentoId: any;
  @Input() SegmentoNome: any;
  form: FormGroup;
  segmentoAtividades: any;
  searchFailedAtividade = false;

  constructor(private fb: FormBuilder
    , private toastr: ToastrService    
    , private confirmService: ConfirmService
    , private segmentoAtividadeService: SegmentoAtividadeService
    , private clienteService: ClienteService
    ) { }

  ngOnInit() {    
    this.initForm();
    this.getSegmetoAtividades(this.SegmentoId);
  }

  initForm() {
    this.form = this.fb.group({
      id: [],
      segmento_id: [this.SegmentoId],
      atividade_economica:[null, Validators.required],
      CodAtivEcon: [null],      
      CodCnaeFiscal: [null],
      DescCnaeFiscal: [null],
      CodSetor: [null],
      DescSetor: [null],
    });    
  }  

  // facilitar o acesso no html
  get f() { return this.form.controls; }

  adicionar() {                  
    if (this.form.controls.atividade_economica.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());      
      this.toastr.warning('Por favor informe uma atividade econômica!', 'Atenção');
      return false;
    }
    
    this.form.patchValue(this.form.value.atividade_economica);

    this.segmentoAtividadeService.salvar(this.form.value).subscribe(response => {
      this.toastr.success('Atividade econômica adicionada com sucesso!');
      this.getSegmetoAtividades(this.SegmentoId);
      this.initForm();
    }, e => {
      CustomServiceValidateService.customMensageService(e.error, this.form);
    });
  }

  getSegmetoAtividades(segmento_id: number) {
    this.segmentoAtividadeService.get({ segmento_id: segmento_id }).toPromise().then(response => {
      this.segmentoAtividades = response;      
    });
  }

  excluir(atividade) {
    this.confirmService.confirm({
      title: 'Excluir', message: 'Você tem certeza que deseja excluir a atividade ' + atividade.DescCnaeFiscal + '?'
    }).then(
      () => {
        this.segmentoAtividadeService.excluir(atividade.id).subscribe(response => {
          this.getSegmetoAtividades(this.SegmentoId);
          this.toastr.success('Atividade econômica removida com sucesso');
        });
      },
      () => { });
  }
  
  searchAtividade = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        term.length < 5 ? of([])
          :
          this.clienteService.getAtividadesEconomicaAutoComplete(term).pipe(
            tap(() => this.searchFailedAtividade = false),
            catchError(() => {
              this.searchFailedAtividade = true;
              return of([]);
            }))
      )
    );

    formatterAtividade = (atividade: {
      CodAtivEcon: string,
      CodCnaeFiscal: string,
      DescCnaeFiscal: string      
    }
    ) => atividade.CodAtivEcon && atividade.DescCnaeFiscal ? atividade.CodAtivEcon.concat('', atividade.CodCnaeFiscal).concat(' - ', atividade.DescCnaeFiscal) : '';

}

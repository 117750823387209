import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class ProjetoAcaoService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  getProjetos() {
    return this.http.get(environment.environment.nomeServiceSas + 'ProjetoAcoes/consultarProjeto.json');
  }

  getAcoesPorProjeto(CodProjeto: string, Ativo = 'S') {
    const query = { CodPratif: CodProjeto, Ativo: Ativo };
    return this.http.get(environment.environment.nomeServiceSas + 'ProjetoAcoes/consultarAcao.json', { params: query });
  }

  listarTodosProjetos(Ativo = 'S') {
      const query = { Ativo: Ativo };
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceProjetos')) {
        resolve(this.sessaoService.get('ServiceProjetos'));
      } else {
        this.http.get(environment.environment.nomeServiceSas + 'ProjetoAcoes/consultarProjeto.json', { params: query })
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceProjetos', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

  listarTodasAcoesPorProjeto(CodProjeto) {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceAcoes_'+CodProjeto)) {
        resolve(this.sessaoService.get('ServiceAcoes_'+CodProjeto));
      } else {
        const query = { CodPratif: CodProjeto };
        this.http.get(environment.environment.nomeServiceSas + 'ProjetoAcoes/consultarAcao.json', { params: query })
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceAcoes_'+CodProjeto, res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

  listarTodasAcoes() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServiceAcoesAll')) {
        resolve(this.sessaoService.get('ServiceAcoesAll'));
      } else {
        const query = { Ativo: 'S' };
        this.http.get(environment.environment.nomeServiceSas + 'ProjetoAcoes/consultarAcao.json', { params: query })
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServiceAcoesAll', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConversoesRdSasService {

  constructor(private http: HttpClient) { }

  get(params) {
    return this.http.get<Leads>(environment.environment.nomeServiceIntegra + 'ConversoesRdSas/getAll.json', {params : params});
  }

}

export class Leads{
  TotalItems: number;
  ConversoesRdSas: object;
}
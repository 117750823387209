<div class="animated fadeIn">
  <div class="row">

    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-paperclip"></i>
          <strong>Cadastro de Tipo de Atividade</strong>

          <div class="pull-right">
            <button type="button" class="btn btn-primary" (click)="novo()">
              <i class="fa fa-plus"></i>
              Cadastrar Novo
            </button>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <button type="button" class="btn btn-success pull-right" (click)="salvar()">
              <i class="fa fa-check"></i>
              Salvar
            </button>
          </div>
        </div>
        <div class="card-body">

          <form [formGroup]="form">
            <div class="row">
              <div class="col-sm-10">
                <div class="form-group">
                  <label for="name">Nome da Atividade:</label>
                  <div class="input-group">
                    <input class="form-control" type="text" name="nome" formControlName="nome">
                  </div>
                  <app-control-messages [control]="form.controls.nome"></app-control-messages>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label for="name">Ativo:</label>
                  <div class="input-group">
                    <label class="switch switch-label switch-pill switch-primary">
                      <input type="checkbox" class="switch-input" checked value="1" formControlName="ativo">
                      <span class="switch-slider" data-checked="Sim" data-unchecked="Não"></span>
                    </label>
                  </div>
                  <app-control-messages [control]="form.controls.ativo"></app-control-messages>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">Canal de Atendimento: <small class="text-muted">Parâmetro SAS de atividade Moskit, utilizado na ausência do campo de origem</small></label>
                  <ng-select [items]="canaisAtendimento" bindLabel="DescMeioAtendimento" bindValue="CodMeioAtendimento"
                    placeholder="Selecione um canal de atendimento" formControlName="CodMeioAtendimento"
                    name="CodMeioAtendimento">
                  </ng-select>
                  <app-control-messages [control]="form.controls.CodMeioAtendimento"></app-control-messages>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">Instrumento: <small class="text-muted">Parâmetro SAS de atividade Moskit, utilizado na ausência do campo de origem</small></label>
                  <ng-select [items]="categoriasInstrumento" bindLabel="DescCategoria" bindValue="CodCategoria"
                    placeholder="Selecione um instrumento" formControlName="CodCategoria" name="CodCategoria">
                  </ng-select>
                  <app-control-messages [control]="form.controls.CodCategoria"></app-control-messages>
                </div>
              </div>
            </div>

            <div class="clearfix"></div>
          </form>
        </div>
      </div>
    </div>

  </div>
</div>
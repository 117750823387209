import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Dashboard2Service {

    constructor(private http: HttpClient) { }
    getDash(data_inicial, data_final) {
        let params = {inicio: data_inicial, fim: data_final};
        return this.http.get<Dashboard>(environment.environment.nomeServiceIntegra + 'dashboard.json', {params: params});
    }
    getDetalhes(filtro) {
        let params =cloneDeep(filtro);
        Object.keys(params).forEach((k) => params[k] == null && delete params[k]);
        return this.http.get<Dashboard>(environment.environment.nomeServiceIntegra + 'dashboard/detalhes.json', {params: params});
    }

}
export class Dashboard {
    geral: any;
    plataforma: any;
    identificador: any;
    atendimentos:any;
}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProdutoService } from '../../../services/produto.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmService } from '../../../shared/confirm-modal/confirm-modal-and-service';
import { SegmentoProdutoService } from '../../../services/segmento-produto.service';
import { CustomServiceValidateService } from '../../../shared/control-messages/custom-service-validate.service';

@Component({
  selector: 'app-segmento-produto-form',
  templateUrl: './segmento-produto-form.component.html',
  styleUrls: ['./segmento-produto-form.component.scss']
})
export class SegmentoProdutoFormComponent implements OnInit {

  @Input() SegmentoId: any;
  @Input() SegmentoNome: any;
  form: FormGroup;
  segmentoProdutos: any;
  produtos: any;

  constructor(private fb: FormBuilder
    , private toastr: ToastrService
    , private confirmService: ConfirmService
    , private segmentoProdutoService: SegmentoProdutoService
    , private produtoService: ProdutoService
  ) { }

  ngOnInit() {
    this.produtoService.listarTodosProdutosAtivo().then(response => {
      this.produtos = response;
    });

    this.initForm();
    this.getSegmetoProdutos(this.SegmentoId);
  }

  initForm() {
    this.form = this.fb.group({
      id: [],
      segmento_id: [this.SegmentoId],
      produto_id: [null, Validators.required],
    });
  }

  // facilitar o acesso no html
  get f() { return this.form.controls; }

  adicionar() {
    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor informe um produto!', 'Atenção');
      return false;
    }

    this.segmentoProdutoService.salvar(this.form.value).subscribe(response => {
      this.toastr.success('Produto adicionado com sucesso!');
      this.getSegmetoProdutos(this.SegmentoId);
      this.initForm();
    }, e => {
      CustomServiceValidateService.customMensageService(e.error, this.form);
    });
  }

  getSegmetoProdutos(segmento_id: number) {
    this.segmentoProdutoService.get({ segmento_id: segmento_id }).toPromise().then(response => {
      this.segmentoProdutos = response;
    });
  }

  excluir(p) {
    this.confirmService.confirm({
      title: 'Excluir', message: 'Você tem certeza que deseja excluir o produto ' + p.produto.nome + '?'
    }).then(
      () => {
        this.segmentoProdutoService.excluir(p.id).subscribe(response => {
          this.getSegmetoProdutos(this.SegmentoId);
          this.toastr.success('Produto removido com sucesso');
        });
      },
      () => { });
  }

}


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-carteiras',
  templateUrl: './carteiras.component.html',
  styleUrls: ['./carteiras.component.scss']
})
export class CarteirasComponent implements OnInit {

    paramsRoute: any;
    carteira_id: number = 0;
    carteira: any = [];
    links = [];

    constructor(public activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
      this.paramsRoute = this.activatedRoute.snapshot.params;
      this.carteira_id = this.paramsRoute.carteira_id != undefined && parseInt(this.paramsRoute.carteira_id) != 0 ? parseInt(this.paramsRoute.carteira_id) : 0;

      this.links = [
        { title: 'Consulta de Carteiras', fragment: 'search', url: `pesquisa`, hidden: false },
        { title: 'Carteira Atual da Empresa', fragment: 'current-company', url: `carteira-atual`, hidden: false },
        { title: 'Cadastro de Carteira', fragment: 'register', url: `cadastro`, hidden: false },
        { title: 'Base de Clientes da Carteira', fragment: 'clients', url: `clientes`, hidden: false },
        { title: 'Definição de Critérios', fragment: 'criteria', url: `criterios`, hidden: false },
      ];

    }


}

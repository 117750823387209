import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CriterioCarteiraService } from '../../../services/criterio-carteira.service';
import { PlataformaConfiguracaoService } from '../../../services/plataforma-configuracao.service';

@Component({
  selector: 'app-criterios-carteira',
  templateUrl: './criterios-carteira.component.html',
  styleUrls: ['./criterios-carteira.component.scss']
})
export class CriteriosCarteiraComponent implements OnInit {
  criterios: any;
  IntegraCarteiraCriterios: any;

  constructor(private criterioCarteiraService: CriterioCarteiraService
    , private toastr: ToastrService
    , private plataformaConfiguracoesService: PlataformaConfiguracaoService) { }

  async ngOnInit() {
    this.IntegraCarteiraCriterios = await this.plataformaConfiguracoesService.getPorNome('IntegraCarteiraCriterios').toPromise();         
    this.getCriterios();
  }

  async getCriterios() {
    this.criterios = await this.criterioCarteiraService.get({ criterios_escolhido: 1 }).toPromise();
  }

  order(keyAtual: number, up: boolean) {

    if (up) // move pra cima
    {
      if (keyAtual === 0) {
        //console.log('não faz nada');
      }
      else {
        const keyAnterior = (keyAtual - 1);
        const criterioAnterior = this.criterios[keyAnterior];
        const criterioAtual = this.criterios[keyAtual];

        var tempCriterioAtual = [];
        tempCriterioAtual['ordem'] = criterioAtual['ordem'] ? criterioAtual['ordem'] : 0;

        criterioAtual.ordem = criterioAnterior.ordem;
        criterioAnterior.ordem = tempCriterioAtual['ordem'];

        this.criterios[keyAnterior] = criterioAtual;
        this.criterios[keyAtual] = criterioAnterior;
      }
    }
    else { // move pra baixo
      if (keyAtual == this.criterios.length) {
        //console.log('não faz nada');
      }
      else {
        const keyProximo = (keyAtual + 1);
        const criterioProximo = this.criterios[keyProximo];
        const criterioAtual = this.criterios[keyAtual];

        var tempCriterioAtual = [];
        tempCriterioAtual['ordem'] = criterioAtual['ordem'] ? criterioAtual['ordem'] : 0;

        criterioAtual.ordem = criterioProximo.ordem;
        criterioProximo.ordem = tempCriterioAtual['ordem'];

        this.criterios[keyProximo] = criterioAtual;
        this.criterios[keyAtual] = criterioProximo;
      }
    }
  }

  salvar() {
    this.criterioCarteiraService.salvar(this.criterios).subscribe(response => {
      this.toastr.success('Critérios da carteira salvo com sucesso.');
      this.getCriterios();
    });
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarteirasComponent } from './carteiras.component';

  const routes: Routes = [
    {
      path: ':carteira_id',
      component: CarteirasComponent,
      data: {
        title: 'Listagem de Carteiras'
      },
      children: [
          {
            path: 'pesquisa',
            loadChildren: () => import('./carteira-list/carteira-list.module').then(m => m.CarteiraListModule)
          },
          {
            path: 'carteira-atual',
            loadChildren: () => import('./carteira-atual/carteira-atual.module').then(m => m.CarteiraAtualModule)
          },
          {
            path: 'cadastro',
            loadChildren: () => import('./carteira-form/carteira-form.module').then(m => m.CarteiraFormModule)
          },
          {
            path: 'clientes',
            loadChildren: () => import('./carteira-clientes/carteira-clientes.module').then(m => m.CarteiraClientesModule)
          },
          {
            path: 'criterios',
            loadChildren: () => import('./criterios-carteira/criterios-carteira.module').then(m => m.CriteriosCarteiraModule)
          },
      ]
    }
  ];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CarteirasRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  getClientesPfAutoComplete(term: String) {
    term = term.replace(/[\.-]/g, "");
    let params = {};
    if (this.isNumeric(term) && (term.length == 11 || term.length == 10)) {
      params = { cpf: term };
    } else {
      params = { nome: term, qtd_por_pagina: '6' };
    }
    return this.http.get(environment.environment.nomeServiceSas + 'Clientes/consultarPessoaFisicaPaginado.json', { params: params });
  }

  getClientesPjAutoComplete(term: String) {
    term = term.replace(/[\/.-]/g, "");
    let params = {};
    if (this.isNumeric(term) && (term.length == 13 || term.length == 14)) {
      params = { cnpj: term };
    } else {
      params = { razao_social: term, qtd_por_pagina: '6', NomeRazaoSocialOuFantasia: 1 };
    }
    return this.http.get(environment.environment.nomeServiceSas + 'Clientes/consultarPessoaJuridicaPaginado.json', { params: params });
  }

  isNumeric(str) {
    var er = /^[0-9]+$/;
    return (er.test(str));
  }

  getClientePorCpf(cpf) {
    let params = { cpf: cpf };
    return this.http.get<ClientePf>(environment.environment.nomeServiceSas + 'Clientes/consultarPessoaFisicaPaginado.json', { params: params });
  }

  getVinculosCliente(params) {
    return this.http.get(environment.environment.nomeServiceSas + 'Clientes/consultarVinculoCliente.json', { params: params });
  }

  getResponsavel(term) {
    var er = /^[0-9]+$/;
    if (!er.test(term)) {
      term = term.replace(/[\.-]/g, "");
    }
    let params = { CgcCpf: term };
    return this.http.get(environment.environment.nomeServiceSas + 'Clientes/consultarPessoaFisica.json', { params: params });
  }

  getAtividadesEconomicaAutoComplete(term: String) {
    term = term.replace(/[\.-]/g, "");
    let params = {};
    if (this.isNumeric(term) && term.length == 5) {
      params = { CodAtivEcon: term };
    } else if (this.isNumeric(term) && term.length == 6) {
      params = { CodAtivEcon: term.substr(0, 5), CodCnaeFiscal: term.substr(5) };
    } else if (this.isNumeric(term) && term.length == 7) {
      params = { CodAtivEcon: term.substr(0, 5), CodCnaeFiscal: term.substr(5, 2) };
    } else {
      params = { DescCnaeFiscal: term }
    }
    return this.http.get(environment.environment.nomeServiceSas + 'Clientes/consultarAtividadeEconomica.json', { params: params }).pipe(map((res) => res ? res : []));
  }

  getPortes(params) {
    return this.http.get(environment.environment.nomeServiceSas + 'Clientes/consultarPorte.json', { params: params });
  }

  listarTodosPortes() {
    let params: any = { Situacao: 'S' };

    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('ServicePortes')) {
        resolve(this.sessaoService.get('ServicePortes'));
      } else {
        this.http.get(environment.environment.nomeServiceSas + 'Clientes/consultarPorte.json', { params: params })
          .toPromise().then((res: any) => {
            this.sessaoService.set('ServicePortes', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }

}
export class ClientePf {
  CodParceiro: number;
  NomeRazaoSocial: string;

  constructor() { }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntegracaoFormAbasRoutingModule } from './integracao-form-abas-routing.module';
import { IntegracaoFormAbasComponent } from './integracao-form-abas.component';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';


@NgModule({
  declarations: [IntegracaoFormAbasComponent],
  imports: [
    CommonModule,
    IntegracaoFormAbasRoutingModule,
    NgbModule,
    FormsModule,
    NgbNavModule,
    TabsModule.forRoot(),
  ]
})
export class IntegracaoFormAbasModule { }

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-paperclip"></i>
        <strong>Grupos de Acesso</strong>

        <button type="button" class="btn btn-primary pull-right" (click)="vincularGrupo()">
          <i class="fa fa-plus"></i>
          Adicionar
        </button>
      </div>
      <div class="card-body">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <ng-select [items]="grupos" bindLabel="nome" bindValue="id" placeholder="Selecione um grupo de usuário e adicione"
                  formControlName="grupo_id" name="grupo_id">
                </ng-select>
                <app-control-messages [control]="form.controls.grupo_id"></app-control-messages>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </form>

        <div class="row">&nbsp;</div>

        <table class="table">
          <thead>
            <tr>
              <th>Código</th>
              <th>Nome</th>
              <th>Descrição</th>
              <th class="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let ug of usuarioGrupos; let i = index">
              <tr  *ngIf="ug?.grupo?.projeto_id == projetoId">
                <td>{{ug.grupo_id}}</td>
                <td>{{ug.grupo.nome}}</td>
                <td>{{ug.grupo.descricao}}</td>
                <td class="text-center">
                  <button type="button " class="btn btn-sm btn-danger " title="Desvincular Grupo" (click)="desvincularGrupo(ug) ">
                    <i class="fa fa-times fa-lg mt-1 "></i>
                  </button>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="usuarioGrupos && usuarioGrupos.length == 0">
              <td colspan="4">Não existe grupo(s) vinculado(s) para este usuário.</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>

<ng-template confirm>
   <confirm-modal-component></confirm-modal-component>
</ng-template>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IntegracaoFormAbasComponent } from './integracao-form-abas.component';

const routes: Routes = [
    {
        path: ':plataforma_id',
        component: IntegracaoFormAbasComponent,
        data: {
            title: 'Abas'
        },
        children: [
            {
                path: "cadastro",
                loadChildren: () =>
                    import(
                        "../integrador-form/integrador-form.module"
                    ).then((m) => m.IntegradorFormModule),
            },
            {
                path: "reprocessamentos",
                loadChildren: () =>
                    import(
                        "../reprocessamento-automatico-form/reprocessamento-automatico-form.module"
                    ).then((m) => m.ReprocessamentoAutomaticoFormModule),
            },
            {
                path: "configuracoes-customizadas",
                loadChildren: () =>
                    import(
                        "../integracao-configuracoes-form/integracao-configuracoes-form.module"
                    ).then((m) => m.IntegracaoConfiguracoesFormModule),
            },
            {
                path: "evento-entradas",
                loadChildren: () =>
                    import(
                        "../integracao-evento-entradas-form/integracao-evento-entradas-form.module"
                    ).then((m) => m.IntegracaoEventoEntradasFormModule),
            },
            {
                path: "campos",
                loadChildren: () =>
                    import(
                        "../integracao-campos-form/integracao-campos-form.module"
                    ).then((m) => m.IntegracaoCamposFormModule),
            },
            {
                path: "extensoes",
                loadChildren: () =>
                    import(
                        "../integracao-extensoes-form/integracao-extensoes-form.module"
                    ).then((m) => m.IntegracaoExtensoesFormModule),
            },
            {
                path: "processos",
                loadChildren: () =>
                    import(
                        "../integracao-processos-form/integracao-processos-form.module"
                    ).then((m) => m.IntegracaoProcessosFormModule),
            },
            {
                path: "sol-sas",
                loadChildren: () =>
                    import(
                        "../integracao-sol-sas-form/integracao-sol-sas-form.module"
                    ).then((m) => m.IntegracaoSolSasFormModule),
            },
            {
                path: "csv-rdstation",
                loadChildren: () =>
                    import(
                        "../importacao-csv-rdstation/importacao-csv-rdstation.module"
                    ).then((m) => m.ImportacaoCsvRdstationModule),
            },
            {
                path: "brasil-mais",
                loadChildren: () =>
                    import(
                        "../brasil-mais-form/brasil-mais-form.module"
                    ).then((m) => m.BrasilMaisFormModule),
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class IntegracaoFormAbasRoutingModule { }

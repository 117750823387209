<div class="animated fadeIn">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group pull-left">
        <a class="btn btn-success" href="#" [routerLink]="['/integracoes/0/cadastro']">
          <i class="fa fa-plus"></i> Cadastrar Novo</a>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-4 col-md-3" *ngFor="let i of integracoes">
      <div class="card">
        <div class="card-header text-white bg-primary text-center">
          <strong>{{i.nome}}</strong>

          <button type="button " class="btn btn-sm btn-primary float-right" title="Editar" (click)="editar(i.id)">
            <i class="fa fa-pencil fa-lg mt-1 "></i>
          </button>
        </div>
        <div class="card-body">
          <span
            [ngClass]="i.ativo == 1 ? 'badge badge-success' : 'badge badge-danger'">{{i.ativo ? 'ATIVO' : 'INATIVO'}}</span>
          &nbsp; {{i.descricao}}
        </div>
      </div>
    </div>
    <!--/.col-->
  </div>
</div>

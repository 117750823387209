<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <i class="fa fa-paperclip"></i>
                <strong>Produtos do Segmento [{{SegmentoNome}}]</strong>

                <div class="pull-right">
                    <button type="button" class="btn btn-primary" (click)="adicionar()">
                        <i class="fa fa-check"></i>
                        Adicionar
                    </button>
                </div>
            </div>
            <div class="card-body">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="name">Produto:</label>
                                <ng-select [items]="produtos" bindLabel="nomeCustom" bindValue="id"
                                    placeholder="Selecione um produto" formControlName="produto_id" name="produto_id">
                                </ng-select>
                                <app-control-messages [control]="form.controls.produto_id"></app-control-messages>
                                <app-control-messages [control]="form.controls.segmento_id"></app-control-messages>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </form>

                <div class="row">&nbsp;</div>

                <table class="table">
                    <tbody>
                        <tr>
                            <th width="15%">Cód. CRM</th>
                            <th width="15%">Cód. Externo</th>
                            <th width="60%">Nome do Produto</th>
                            <th width="10%" class="text-center">Ações</th>
                        </tr>
                        <tr *ngFor="let s of segmentoProdutos; let i = index">
                            <td>{{s?.produto?.cod_crm}}</td>
                            <td>{{s?.produto?.cod_externo}}</td>
                            <td>{{s?.produto?.nome}}</td>
                            <td class="text-center">
                                <button type="button " class="btn btn-sm btn-danger " title="Excluir"
                                    (click)="excluir(s) ">
                                    <i class="fa fa-times fa-lg mt-1 "></i>
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="segmentoProdutos && segmentoProdutos.length == 0">
                            <td colspan="4">Não existe produtos cadastrados para este segmento</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
</div>

<ng-template confirm>
     <confirm-modal-component></confirm-modal-component>
</ng-template>

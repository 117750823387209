import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-variaveis',
  templateUrl: './variaveis.component.html',
  styleUrls: ['./variaveis.component.css']
})
export class VariaveisComponent implements OnInit {

  @Output() addAcao: EventEmitter<any> = new EventEmitter();

 //TODO: fazer virar um form de array
  @Input() variaveis = [];
  @Input() edit;

  formCampo: FormGroup;
  campos = [];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if(this.campos == null || this.campos.length == 0){
      this.campos = [];
    }
    this.variaveis.forEach((f, i) => {
      f.id = i;
      f.valor =  f.valor.length > 0? f.valor : '';
      this.campos.push(f);
    });

  }
  ngOnChanges(changes: any) {
    if(this.edit && this.edit.variaveis){
        this.variaveis = this.edit.variaveis;
    }
  }
  adicionarAcao() {
    this.addAcao.emit({campos: this.campos, edit: this.edit});
    this.campos = [];

  }

  onSubmit() {
    this.formCampo.reset();
  }

}


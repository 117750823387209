import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CriteriosCarteiraRoutingModule } from './criterios-carteira-routing.module';
import { CriteriosCarteiraComponent } from './criterios-carteira.component';
 
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    CriteriosCarteiraRoutingModule,
    NgbModule,
    FormsModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot()
  ],
  declarations: [CriteriosCarteiraComponent]
})
export class CriteriosCarteiraModule { }

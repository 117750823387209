<div class="animated fadeIn">

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-paperclip"></i>
          Alterar Configuração de <strong>Atendimento - {{conteudo_tipo.controls.nome.value}}</strong>

          <button type="button" class="btn btn-success pull-right" (click)="salvar()">
            <i class="fa fa-check"></i>
            Salvar
          </button>
        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label for="name">Nome da realização:</label>
                  <div class="input-group">
                    <input class="form-control" type="text" name="NomeRealizacao" formControlName="NomeRealizacao">
                  </div>
                  <app-control-messages [control]="form.controls.NomeRealizacao"></app-control-messages>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">                  
                  <label for="name">Tipo da realização:</label>
                  <ng-select [items]="tiposRealizacoes" bindLabel="desctiporealizacao" bindValue="codtiporealizacao" placeholder="Selecione um tipo de realização"
                    formControlName="TipoRealizacao" name="TipoRealizacao">
                  </ng-select>
                  <app-control-messages [control]="form.controls.TipoRealizacao"></app-control-messages>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="name">Descrição completa da realização:</label>
                  <div class="input-group">
                    <textarea class="form-control" name="DescRealizacao" formControlName="DescRealizacao" rows="4"></textarea>
                  </div>
                  <app-control-messages [control]="form.controls.DescRealizacao"></app-control-messages>
                </div>
              </div>
            </div>
            <div class="row">              
              <div class="col-md-5">
                  <div class="form-group">
                    <label for="name">Instrumento:</label>
                    <ng-select [items]="categoriasInstrumento" bindLabel="DescCategoria" bindValue="CodCategoria"
                      placeholder="Selecione uma" formControlName="CodCategoria"
                      name="CodCategoria">
                    </ng-select>
                    <app-control-messages [control]="form.controls.CodCategoria"></app-control-messages>
                  </div>
                <!-- <div class="form-group">
                  <label for="name">Instrumento:</label>
                  <ng-select [items]="instrumentos" bindLabel="descricao" bindValue="descricao" placeholder="Selecione um instrumento"
                    formControlName="Instrumento" name="Instrumento">
                  </ng-select>
                  <app-control-messages [control]="form.controls.Instrumento"></app-control-messages>
                </div> -->
              </div>
              <div class="col-md-3">
                <div class="form-group">
                    <label for="name">Abordagem:</label>
                    <ng-select [items]="abordagens" bindLabel="descricao" bindValue="value" placeholder="Selecione uma abordagem"
                      formControlName="Abordagem" name="Abordagem">
                    </ng-select>                  
                  <app-control-messages [control]="form.controls.Abordagem"></app-control-messages>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="name">CPF Responsável:</label>
                  <div class="input-group">
                    <input class="form-control" type="text" name="CpfResponsavel" formControlName="CpfResponsavel">
                  </div>
                  <app-control-messages [control]="form.controls.CpfResponsavel"></app-control-messages>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Projeto:</label>
                    <ng-select [items]="projetos" bindLabel="NomePRATIF" bindValue="CodPRATIF" placeholder="Selecione um projeto"
                      formControlName="CodProjeto" name="CodProjeto" (change)="getAcoesPorProjeto()">
                    </ng-select>                  
                    <app-control-messages [control]="form.controls.CodProjeto"></app-control-messages>
                  </div>
                </div> 
                <div class="col-md-6">
                    <div class="form-group">
                      <label for="name">Ação:</label>
                      <ng-select [items]="acoes" bindLabel="NomeAcao" bindValue="CodAcaoSeq" placeholder="Selecione um projeto primeiro para seleciona a ação"
                        formControlName="CodAcao" name="CodAcao">
                      </ng-select>                  
                      <app-control-messages [control]="form.controls.CodAcao"></app-control-messages>
                    </div>
                  </div>              
              </div>
            <div class="clearfix"></div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>